import React, { useState, useEffect, useRef, useReducer } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';

import Select from "react-select";
import Datepicker from "../layouts/datepicker";
import moment from "moment";
import { getHyphenYYYYMMDDHHMMSS, getDDMMMYYYY, getHyphenYYYYMMDD, getHyphenDDMMYYYYHHMM, getHyphenDDMMYYYY, getHHMM, getHyphenYYYYMMDDHHMM } from '../common/utils';
import SearchIcon from "../../assets/icons/search-icon.svg";
import RefreshIcon from "../../assets/images/refresh-icon.svg";
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import 'ag-grid-enterprise';
import 'ag-grid-enterprise/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-enterprise/styles/ag-theme-balham.css';
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';


import CountryFlagComponent from '../layouts/countryFlag';
import ConsignmentTrucksLegs from '../layouts/containertruckslegs';
import DestinationFlagComponent from '../layouts/destinationCountryFlag';
import ViewDOdetailsComponent from "../layouts/viewDOdetails";
import customNextIcon from "../../assets/icons/next.png";
import customPreviousIcon from "../../assets/icons/back.png";
import customFirstIcon from "../../assets/icons/back_left.png";
import customLastIcon from "../../assets/icons/next_right.png";
import redirectURL from "../redirectURL";
var initialState = {
    detailCellRendererParams: {},
};
var reducer = (state, action) => {
    switch (action.type) {
        case 'SET_DETAIL_RENDERER_PARAMS':
            //console.log('2020')
            return {
                ...state,
                detailCellRendererParams: action.payload,
            };
        // case 'SET_DETAIL_RENDERER_PARAMS_FOR_STOPPAGES':
        // 	//console.log('6767')
        // 	return {
        // 		...state,
        // 		detailCellRendererParams: action.payload,
        // 	};
        default:
            return state;
    }
};
const DaywiseTracking = () => {
    var [state, dispatch] = useReducer(reducer, initialState);
    const gridApi = useRef(null);
    const gridColumnApi = useRef(null);

    var [selectedval, setselectedval] = useState({
        // dropdownval: { label: 'Stuffing Date', value: 'stuffing_date' },
        inputval: {label:'All',value:'All'}
    })
    var [selectedPlant_code,setSelectedPlant_code]  = useState( {label:'All',value:'All'})
    var [plantList, setplantlist] = useState([])
    var [rowData, setrowData] = useState([])
    var [checkinput, setcheckinput] = useState(0)
    var [checkForDateFilter, setcheckForDateFilter] = useState(1)
    var [checkresetfilter, setcheckresetfilter] = useState(0)
    var [originalData, setoriginalData] = useState([])
    var [containersdata, setcontainersdata] = useState([])
    var [show, setshow] = useState(false)
    var [reload, setreload] = useState(false)
    var [basicTitle, setbasicTitle] = useState('')
    var [basicType, setbasicType] = useState('')
    var [overly, setoverly] = useState('show-n')
    var [loadshow, setloadshow] = useState('show-n')
    var typeofselection = [
        { label: 'Stuffing Date', value: 'stuffing_date' },
        { label: 'Plant', value: 'plant' }
    ]
    var typeofselection1 = [
        { label: 'Stuffing Date', value: 'stuffing_date' },
    ]
    const currentDate = new Date();

    const initialDate = `${currentDate.getFullYear()}-${String(currentDate.getMonth() + 1).padStart(2, '0')}`;
    var [selectedDate, setSelectedDate] = useState(new Date());


    var [selectedfilters, setfilters] = useState({
        datefilter: initialDate,
    })
    const [isOpen, setIsOpen]= useState(false);
    const datePickerRef = useRef(null);
    useEffect(() => {
        if (gridApi.current) {
            getUserGridState();
        }
        loadDateTimeScript();
    }, [gridApi]);
    const onGridReady = params => {
        gridApi.current = params;
        // gridColumnApi.current = params.columnApi;
        getUserGridState();
    };
    const onClickHideAll = () => {
        this.setState({
            loadshow: 'show-n',
            overly: 'show-n',
            showTrucksList: "show-n",
        });
    }
    useEffect(() => {
        redirectURL.post("/consignments/getplantdata").then((resp) => {
            try {
                var data = resp.data
                //console.log(data,'64')
                var plant = [{ label: 'All', value: 'All' }]
                data.map((e) => {
                    plant.push({ label: e.plant_code, value: e.plant_code })
                })
                plant = removeDuplicateObjects(plant);
                setplantlist(plant)
            }
            catch (e) {
            }
        })
        getData()
        if (datePickerRef.current){
            datePickerRef.current.readOnly = true;
        }
    }, [])
    const getData = (formattedDate) => {
        setloadshow('show-m')
        setoverly('show-m')
        if (formattedDate != '' && formattedDate != undefined) {
            var date = formattedDate
        }
        else {
            var date = selectedfilters.datefilter
        }
        var plantcode = selectedval.inputval.value
        //console.log(selectedPlant_code,'selectedPlant_code')
        //console.log(plantcode,'plantcodeplantcode')
        var params = {
            month: date,
            //  'plant_code':plantcode
        }
        if(plantcode!='All'){
            params.plant_code = plantcode
        }
        //console.log(params, 'params')
        redirectURL.post('/consignments/getdaywisetracking', params).then((resp) => {
            var data = resp.data.daywise_report
            //console.log("data67", data)
            if (!Array.isArray(data)) {
                data = data.replace(/NaN/g, "0");
                data = JSON.parse(data);
            }
            var plantlist = []
            data.map((e) => {
                plantlist.push({ 'label': e.plant_code, 'value': e.plant_code })
            })
            plantlist = removeDuplicateObjects(plantlist)
            setrowData(data)
            setoriginalData(data)
            setloadshow('show-n')
            setoverly('show-n')
            //setcontainersdata(resp.data.legsdata)
        })
    }
    const handleInputfilter = (e) => {
        //console.log(e)
        setselectedval(prev => ({
            ...prev,
            inputval: e
        }))
        setSelectedPlant_code(e)
        // getData()
        setloadshow('show-m')
        setoverly('show-m')
        var date = selectedfilters.datefilter
        var plantcode = e.value
        //console.log(selectedPlant_code,'selectedPlant_code')
        //console.log(plantcode,'plantcodeplantcode')
        var params = {
            month: date,
            //  'plant_code':plantcode
        }
        if(plantcode!='All'){
            params.plant_code = plantcode
        }
        //console.log(params, 'params')
        redirectURL.post('/consignments/getdaywisetracking', params).then((resp) => {
            var data = resp.data.daywise_report
            //console.log(data, "5678")
            if (!Array.isArray(data)) {
                data = data.replace(/NaN/g, "0");
                data = JSON.parse(data);
            }
            var plantlist = []
            data.map((e) => {
                plantlist.push({ 'label': e.plant_code, 'value': e.plant_code })
            })
            plantlist = removeDuplicateObjects(plantlist)
            setrowData(data)
            setoriginalData(data)
            setloadshow('show-n')
            setoverly('show-n')
            //setcontainersdata(resp.data.legsdata)
        })
    }
    
    const getUserGridState = () => {
        redirectURL.post("/consignments/usergridstates", { screenurl: window.location.pathname })
            .then(async (resp) => {
                //console.log("resp.data0999", resp.data)
                restoreGridStates(resp.data);
            })
    }
    // var handleFilterOptions = (e) => {
    //     setfilters(prev => ({
    //         ...prev,
    //         transfilter: e
    //     }))
    // }
    const handleDateChange = (date) => {
        setSelectedDate(date);
        const inputDate = new Date(date);
        const year = inputDate.getFullYear();
        const month = String(inputDate.getMonth() + 1).padStart(2, "0"); // Add 1 to month since months are zero-based
        const formattedDate = `${year}-${month}`;
        //console.log(formattedDate, 'formattedDate')
        setfilters(prev => ({
            ...prev,
            datefilter: formattedDate
        }))
        getData(formattedDate)
    };
    const restoreGridStates = (griddata) => {
        // //console.log("11111",usergridstate,"griddata",griddata[0].gridcolumns)

        try {
            if (griddata.length > 0) {
                //console.log('hit here loading', griddata.length)
                var windowstates = griddata;
                //console.log("windowstates.gridcolumns", windowstates[0].gridcolumns)
                //  gridApi.current.columnApi.setColumnState(windowstates[0].gridcolumns);
                gridApi.current.columnApi.setColumnState(griddata[0].gridcolumns);
                // //console.log("windowstates[0].gridgroup", windowstates[0].gridgroup)
                gridApi.current.columnApi.setColumnGroupState(windowstates[0].gridgroup);

                // //console.log("windowstates[0].gridcolsort", windowstates[0].gridcolsort)
                gridApi.current.api.setSortModel(windowstates[0].gridcolsort);

                // //console.log("windowstates[0].gridcolfilter", windowstates[0].gridcolfilter)
                gridApi.current.api.setFilterModel(windowstates[0].gridcolfilter);
            }
        }
        catch (e) { }

    }
    const onGridState = () => {
        ////console.log(gridApi);

        /*Get  Current Columns State and Store in this.colState */
        const colState = gridApi.current.columnApi.getColumnState();

        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
        const rowGroupState = gridApi.current.columnApi.getRowGroupColumns();

        /*Get Current Pivot Columns State and Store in this.pivotColumns, 
         * User should enable the Pivot mode.*/
        const pivotColumns = gridApi.current.columnApi.getPivotColumns();

        /*Get Current Filter State and Store in window.filterState */

        window.filterState = gridApi.current.api.getFilterModel();
        gridApi.current.api.setFilterModel(window.filterState);
        getUserGridState();


    }
    // const handleFilterOptions = (e) => {
    //     if (e.value == 'stuffing_date') {
    //         setcheckForDateFilter(1)
    //         setcheckinput(0)
    //     }
    //     else {
    //         setcheckinput(1)
    //         setcheckForDateFilter(0)
    //     }
    //     setselectedval(prev => ({
    //         ...prev,
    //         dropdownval: e
    //     }))
    // }
    const filterData = () => {
        var typeoffield = selectedval.dropdownval.value
        //console.log(typeoffield, 'typeoffield')
        var val = selectedval.inputval
        //console.log(val, 'val')

        if (val != '' && val != undefined) {
            var filteredData

            if (typeoffield == 'plant') {
                filteredData = originalData.filter(e => e.plant_code == val);
            }
            else {
                //console.log('96')
                filteredData = originalData.filter(item => item.cha.toLowerCase() == val.toLowerCase());
            }
            setrowData(filteredData)
            setcheckresetfilter(1)
        }
        else {
            setbasicTitle('Please Enter Value')
            setbasicType('danger')
            setoverly('show-n')
            setshow(true)
        }
    }

    //     try {
    //         if (gridApi.current) {
    //             const columnState = gridColumnApi.current.getColumnState();
    //             const columnGroupState = gridColumnApi.current.getColumnGroupState();
    //             const sortState = gridApi.current.api.getSortModel(); // Accessing getSortModel from gridApi.current.api
    //             const filterState = gridApi.current.api.getFilterModel();

    //             var screenpage = '/shipmentplanningreport';
    //             let reqparams = {
    //                 gridcolumns: columnState,
    //                 gridgroup: columnGroupState,
    //                 gridcolsort: sortState,
    //                 gridcolfilter: filterState,
    //                 userId: localStorage.getItem("userid"),
    //                 screenurl: window.location.pathname,
    //                 screentitle: screenpage
    //             };

    //             redirectURL.post("/consignments/saveGridStates", reqparams)
    //                 .then((response) => {
    //                     setshow(true);
    //                     setbasicTitle("Successfully saved grid layout");
    //                     setbasicType("success");
    //                     setTimeout(() => {
    //                         getUserGridState();
    //                     }, 2000);
    //                 })
    //                 .catch(function (e) {
    //                     //console.log("Error ", e);
    //                 });
    //         } else {
    //             console.error('Grid API is not available.');
    //         }
    //     } catch (e) {
    //         //console.log(e);
    //     }
    // };


    const submitData = (event) => {
        event.preventDefault();
        const submittedData = {
            stuffing_month: selectedfilters.datefilter,
            plant: selectedval.inputval
        }
        //console.log(submittedData, "5555")
    }
    const refreshData = () => {
        setcheckresetfilter(0)
        setrowData(originalData)
        setselectedval(prev => ({
            ...prev,
            inputval: ''
        }))
        setSelectedPlant_code('')
    }
    const columnDefs = [
        {
            headerName: "Date",
            field: "report_date",
            width: 120,
            pinned: 'left',
        },
        {
            headerName: "Planned",
            field: "stuffing_planned",
            width: 120,
            filter: true,
            resizable: true,
        },
        // {
        //     headerName: "Plant",
        //     field: "plant_code",
        //     width: 160,
        //     filter: true,
        //     resizable: true,
        // },
        {
            headerName: "DO received",
            field: "do_received",
            width: 120,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Trucks Allocated",
            field: "trucks_allocated",
            width: 130,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Contianers Picked Up",
            field: "container_picked",
            width: 160,
            filter: true,
            resizable: true,
            //cellRenderer:'DestinationFlagComponent'

        },
        {
            headerName: "Reported At Plant",
            field: "reported_at_plant",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Invoice Generated",
            field: "invoice_generated",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Left From Plant",
            field: "left_from_plant",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Reported At Port",
            field: "reported_at_port",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Gate In",
            field: "gate_in_port",
            width: 120,
            filter: true,
            resizable: true,
        },
        {
            headerName: "B/L Received",
            field: "bl_received",
            width: 120,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Buffer Yard",
            field: "buffer_yard",
            width: 120,
            filter: true,
            resizable: true,
        }

    ]

    var closeAlert = () => {
        setshow(false)
        setloadshow('show-n')
    }
    var onClickSaveGridState = () => {
        try {
            // //console.log("SaveGrid", gridApi.current.getSortModel())
            window.colState = gridApi.current.columnApi.getColumnState();
            window.groupState = gridApi.current.columnApi.getColumnGroupState();
            // window.sortState = gridApi.current.columnApi.getSortModel();
            // window.filterState = gridApi.current.api.getFilterModel();
            var screenpage = '/daywisetracking'
            let reqparams = {
                gridcolumns: window.colState,
                gridgroup: window.groupState,
                gridcolsort: window.sortState,
                gridcolfilter: window.filterState,
                userId: localStorage.getItem("userid"),
                screenurl: '/daywisetracking',
                screentitle: screenpage
            }
            //console.log("reqparams after save grid", reqparams);
            redirectURL.post("/consignments/saveGridStates", reqparams)
                .then((response) => {
                    //console.log("response forr", response)
                    // if(response.data.status === "200"){
                    setshow(true)
                    setbasicTitle("Successfully saved grid layout")
                    setbasicType("success")
                    // setState1({
                    // 	screenurl: window.location.pathname,
                    // 	screentitle: screenpage
                    // })
                    setTimeout(() => {
                        getUserGridState();
                    }, 2000)
                    // getUserGridState();
                    // }
                })
                .catch(function (e) {
                    //console.log("Error ", e)
                })
            //console.log('hit')
        }
        catch (e) {
            //console.log(e)
        }
    }
    //console.log(selectedval.inputval,'472')
    const toggleDatePicker = ()=>{
        setIsOpen(prevIsOpen => !prevIsOpen)
    }
    return (
        <>
            <SweetAlert
                show={show}
                type={basicType}
                title={basicTitle}
                onConfirm={closeAlert}
            >
            </SweetAlert>
        <div className="col-xl-12 col-lg-12 col-sm-12 beffect">
           <div className="form-group d-flex justify-content-between">
               <h1 className="heading mt-30p">
                   <i className="icofont icofont-vehicle-delivery-van cus-i"></i>Day Wise Tracking (Container Level)
               </h1>
           </div>
           <div className="card m-10p">
            <div className="card-body" id="counterCardBody">
                <div className="row">
                <div className="col-sm-8 checkForDateFilter_gap">
                    <form action="" onSubmit={submitData}>

                        <div className="d-flex flex-row">
                            <Select
                                closeMenuOnSelect={true}
                                value={selectedval.inputval}
                                // multi={true}
                                // className={"border-radius-0"} 
                                onChange={handleInputfilter}
                                style={{ borderRadius: "0px" }}
                                styles={{
                                    control: (provided, state) => ({
                                        ...provided,
                                        fontSize: "0.8rem",
                                        color: "#6e707e",
                                        width: "260px",
                                        height: "48px",
                                        borderRadius: "10px"
                                    }),
                                    singleValue: (provided, state) => ({
                                        ...provided,
                                        fontSize: '0.9rem'
                                    }),
                                    menu: (provided, state) => ({
                                        ...provided,
                                        zIndex: 9999, // You can set the desired z-index value here
                                    }),
                                }}
                                options={plantList}
                            />
                            <div className="ml-40p">
                                <div onClick={toggleDatePicker}>
                                    <DatePicker
                                        selected={selectedDate}
                                        onChange={handleDateChange}
                                        dateFormat="MM/yyyy" // Format to display only month and year
                                        showMonthYearPicker
                                        minDate={new Date(1900, 0)} // Set the minimum year you want to allow
                                        maxDate={new Date(2100, 11)}
                                        readOnly
                                        open={isOpen}
                                        className="custom-datepicker"
                                        calendarClassName="custom-datepicker-dropdown"
                                    // placeholderText="Select Month/Year"// Set the maximum year you want to allow
                                    />
                                    </div>
                                </div>
                        </div>

                    </form>
                </div>    
                    <div className="col-sm-4">
                    <div className="row">
                    <div className="col textAlignRight">     
                        <a href={"/reportscreen"}className='f12 mr-5p btn btn-default'>
                        Back 
                        </a>
                        {/* <button type="button" className="f12 btn btn-outline-danger" onClick={onClickSaveGridState}>
                                            <i className="f12 icofont icofont-save" style={{ marginRight: '5px' }}></i>Save Grid Layout
                                        </button> */}
                         <button
                                        type="button"
                                        title="Save Grid Layout"
                                        name="savegrid"
                                        className="btn btn-danger action_btn"
                                        style={{height: "32px", marginTop: "30px", paddingBottom:"30px" }}
                                        onClick={onClickSaveGridState}>
                                        <i className="f12 icofont icofont-save"></i>
                                        </button>                
                    </div>
                </div>
                </div>
                </div>
                <div className="row" >
                <div className="col-sm-12" >
                <div id="idgrid" style={{ width: "100%", height: "480px", marginTop:"15px"}} className="ag-theme-balham ">
                    <AgGridReact
                        ref={gridApi}
                        // modules={AllModules}
                        columnDefs={columnDefs}
                        defaultColDef={{
                            sortable: true,
                            filter: true,
                            editable: false,
                            resizable: true,
                            menuTabs: ['filterMenuTab']
                        }}
                        rowData={rowData}
                        suppressRowClickSelection={true}
                        enableCellChangeFlash={true}
                        suppressCellFlash={true}
                        enableRangeSelection={true}
                        paginationPageSize={50}
                        onGridReady={onGridReady}
                        onGridState={onGridState}
                        rowSelection={'multiple'}
                        masterDetail={true}
                        // pagination={true}
                        pagination={true}
                        detailCellRendererParams={state.detailCellRendererParams}
                        sideBar={{
                            toolPanels: [
                                {
                                    id: "columns",
                                    labelDefault: "Columns",
                                    labelKey: "columns",
                                    iconKey: "columns",
                                    toolPanel: "agColumnsToolPanel"
                                },
                                {
                                    id: "filters",
                                    labelDefault: "Filters",
                                    labelKey: "filters",
                                    iconKey: "filter",
                                    toolPanel: "agFiltersToolPanel"
                                }
                            ]
                        }}
                        statusBar={{
                            statusPanels: [
                                { statusPanel: "agFilteredRowCountComponent" },
                                { statusPanel: "agSelectedRowCountComponent" },
                                { statusPanel: "agAggregationComponent" }
                            ]
                        }}
                        gridOptions={{
                            icons: {
                                next: `<img src="${customNextIcon}" alt="Next" />`,
                                previous: `<img src="${customPreviousIcon}" alt="Previous" />`,
                                first: `<img src="${customFirstIcon}" alt="First" />`,
                                last: `<img src="${customLastIcon}" alt="Last" />`,
                            },
                        }}
                    />

                </div>


             </div>
            </div>
                </div> 
                </div>
                <div className={"dataLoadpage " + (loadshow)}>
                </div>
                <div className={"dataLoadpageimg " + (loadshow)}>
                    <div className="loader-box"><div className="loader-box"><div className="loader"></div></div></div>
                </div>
                <div className={"overlay-part " + (overly)} onClick={onClickHideAll}></div>
            </div >
            
        </>

    )
}
function loadDateTimeScript() {
    // alert("timeDate");
    $('.datetimepicker_mask').datetimepicker({
        mask: '39-19-9999 29:59',
        format: 'd-m-Y H:i'
    });
    $('.datetimepicker_date').datetimepicker({
        mask: '39-19-9999',
        format: 'd-m-Y',
        timepicker: false
    });
    var index = window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src = "https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
    //script.src="http://xdsoft.net/jqplugins/datetimepicker/"
    script.async = true
    script.defer = true
    index.parentNode.insertBefore(script, index)
}
function removeDuplicateObjects(arr) {
    const uniqueObjects = new Set();
    const filteredArray = arr.filter(obj => {
        const key = JSON.stringify(obj);
        if (!uniqueObjects.has(key)) {
            uniqueObjects.add(key);
            return true;
        }
        return false;
    });
    return filteredArray;
}

export default DaywiseTracking;