import React, { Component,useState, useEffect, useRef,useReducer } from "react"
import SweetAlert from 'react-bootstrap-sweetalert';
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import 'ag-grid-enterprise';
import 'ag-grid-enterprise/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-enterprise/styles/ag-theme-balham.css';
import 'react-datetime/css/react-datetime.css';
import Datetime from 'react-datetime';

import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import Select from "react-select";
import CountryFlagComponent from "./countryFlag";
import SearchIcon from "../../assets/icons/search-icon.svg";
import SubmitBtn from "./submitBtn";
import Datepicker from "./datepicker";
import PTData from '../../assets/json/payment_term_description.json'
import { getHyphenYYYYMMDDHHMMSS, getDDMMMYYYY, getHyphenDDMMYYYY ,getHyphenYYYYMMDD, getHyphenDDMMYYYYHHMM} from '../common/utils';
import moment from "moment";
import BulkForceFileHandler from "../layouts/changebulkuploadhandler";
import ConsignmentTrucksLegs from "./containertruckslegs";
import UpdateShippingBillDetailsBtn from "./updateshippingbilldetailsbtn";
import Mapcard2 from "./drawmap";
import customNextIcon from "../../assets/icons/next.png";
import customPreviousIcon from "../../assets/icons/back.png";
import customFirstIcon from "../../assets/icons/back_left.png";
import customLastIcon from "../../assets/icons/next_right.png";
import AcknowledgeBtnComponent from "./acknowledgeBtn";
import {fetchGrid} from "../fetchGridStructure"
import redirectURL from "../redirectURL";
var initialState = {
	detailCellRendererParams: {},
};
var reducer = (state, action) => {
	switch (action.type) {
		case 'SET_DETAIL_RENDERER_PARAMS':
            //console.log('2020')
			return {
				...state,
				detailCellRendererParams: action.payload,
			};
		// case 'SET_DETAIL_RENDERER_PARAMS_FOR_STOPPAGES':
		// 	//console.log('6767')
		// 	return {
		// 		...state,
		// 		detailCellRendererParams: action.payload,
		// 	};
		default:
			return state;
	}
};
const AtPlantCha = (props) => {
    const gridApi = useRef(null);
    // //console.log("props ", props)
    var [state, dispatch] = useReducer(reducer, initialState);

    // var [gridApi, setGridApi] = useState(null);
    var [gridColumnApi, setgridColumnApi] = useState(null)
    var gridRef = useRef();
    var [summaryviewcheck, setsummaryviewcheck] = useState(1)
    var [detailedviewcheck, setdetailedviewcheck] = useState(0)
    var [rowData, setrowData] = useState([])
    var [originalData, setoriginalData] = useState([])
    var [checkinput, setcheckinput] = useState()
    var [checkForDateFilter, setcheckForDateFilter] = useState(0)
    var [loadshow, setloadshow] = useState('show-n')
    var [overly, setoverly] = useState('show-n')
    var [ptd, setptd] = useState(PTData.PTData ?? [])
    var [sliderForBulkupload, setsliderForBulkupload] = useState('')
    var [file, setfile] = useState('')
    var [csvcontent, setcsvcontent] = useState("")
    var [show, setshow] = useState(false)
    var [basicTitle, setbasicTitle] = useState('')
    var [basicType, setbasicType] = useState('')
    var [selectedval, setselectedval] = useState({
        dropdownval: '',
        inputval: ''
    })
    var [check, setcheck] = useState(0)
    var [woReleaseDate, setWoReleaseDate] = useState({
        wo_release_from_date: '',
        wo_release_to_date: ''
    })
    var [counters, setCounters] = useState({
        vessel_avai: []
    })
    var [workordernoList, setworkOrderList] = useState([])
    const [isClicked, setIsClicked] = useState({
        vessel_avail: false,
        uploadDo: false
    });
    var [containerLegsData , setContainerLegsData] = useState([])
    const [showAddForm, setshowAddForm] = useState('')
    const [manualUploadDetails, setmanualUploadDetails] = useState({
        wrno: '',
        shipping_bill_no:'',
        shipping_bill_date:''

    })
    var [selected_row,setselected_row] = useState({})
    var [stateForMap, setStateForMap] = useState({
        sliderRouteTranslate: '',
        showDiv: 'show-n',
        mapinfo: {
            coords: [
                // { lat: 8.76735, lng: 78.13425 },
                // { lat: 18.76735, lng: 88.13425 },
            ],
        },
        mapdata:[],
        dealer: '',
        consignment_code: '',
        truck_no: '',
        container_no: '',
        maptruckno: '',
        routeTruck: '',
        sidebarSubHeader: '',
        rownode: '',
        consigner_coordinates: '',
        consignee_coordinates: '',
        map_type_of_tracking: '',
        baseRouteCoords: '',
        mapHeight: "94vh",
    })
    var [tabDataforAtPlantCHA,settabDataforAtPlantCHA] = useState({
        atplant_modified:[],
        atplant_new:[],
        atplant_planned:[]
    })
    var [classForTab, setclassforTab] = useState({
        planned: 0,
        new: 1,
        modified: 0,
    })
    const [summaryViewcols, setsummaryViewcols] = useState([
        {
            headerName: "",
            field: "containerLegs",
            width: 90,
            pinned: 'left',
            cellRenderer: 'ConsignmentTrucksLegs'
        },
        {
            headerName: "Work Order No",
            field: "work_order_no",
            width: 130,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Production Date",
            field: "production_date",
            width: 150,
            filter: true,
            resizable: true,
            valueGetter: function (params) {
                try {
                    if (params.data.production_date != "" && params.data.production_date != undefined) {
                        return getHyphenDDMMYYYY(params.data.production_date);
                    }
                    else {
                        return "";
                    }
                }
                catch (e) { }
            },
        },
        // {
        //     headerName: "Tentative Vessel ETD",
        //     field: "tentative_vessel_etd",
        //     width: 160,
        //     filter: true,
        //     resizable: true,
        //     valueGetter: function (params) {
        //         try {
        //             if (params.data.tentative_vessel_etd != "" && params.data.tentative_vessel_etd != undefined) {
        //                 return getHyphenDDMMYYYY(params.data.tentative_vessel_etd);
        //             }
        //             else {
        //                 return "";
        //             }
        //         }
        //         catch (e) { }
        //     },
        // },
        {
            headerName: "Stuffing Date",
            field: "stuffing_date",
            width: 150,
            filter: true,
            resizable: true,
            editable: true,
            valueGetter: function (params) {
                try {
                    if (params.data.stuffing_date != "" && params.data.stuffing_date != undefined) {
                        return getHyphenDDMMYYYY(params.data.stuffing_date);
                    }
                    else {
                        return "";
                    }
                }
                catch (e) { }
            },
        },
        {
            headerName: "Ship To Country",
            field: "ship_to_country",
            width: 120,
            filter: true,
            resizable: true,
            // cellRenderer: 'CountryFlagComponent'
        },
        {
            headerName: "Brand",
            field: "brand",
            width: 120,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Brand Variant",
            field: "brand_variant",
            width: 150,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Quantity",
            field: "quantity",
            width: 120,
            filter: true,
            resizable: true,
        },
        {
            headerName: "40ft Containers",
            field: "ft40_containers",
            width: 150,
            filter: true,
            resizable: true,
        },
        {
            headerName: "20ft Containers",
            field: "ft20_containers",
            width: 150,
            filter: true,
            resizable: true,
        },

        {
            headerName: "Trucks",
            field: "truck_and_courier",
            width: 130,
            filter: true,
            resizable: true,
        },
        {
            headerName: "POD",
            field: "pod",
            width: 120,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Plant",
            field: "plant",
            width: 120,
            filter: true,
            resizable: true,
        },
        {
            headerName: "CHA Name",
            field: "cha_name",
            width: 120,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Plant Name",
            field: "plant_name",
            width: 120,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Transporter Name",
            field: "tpt_name",
            width: 200,
            filter: true,
            resizable: true,
        },

        {
            headerName: "PO NO",
            field: "po_no",
            width: 120,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Quotation",
            field: "quotation",
            width: 130,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Latest Shipment Date",
            field: "latest_shipment_date",
            width: 160,
            filter: true,
            resizable: true,
            valueGetter: function (params) {
                try {
                    if (params.data.latest_shipment_date != "" && params.data.latest_shipment_date != undefined) {
                        return getHyphenDDMMYYYY(params.data.latest_shipment_date);
                    }
                    else {
                        return "";
                    }
                }
                catch (e) { }
            },
        },
        {
            headerName: "Expected Payment Clearance By",
            field: "expected_payment_clearance_by",
            width: 220,
            filter: true,
            resizable: true,
            valueGetter: function (params) {
                try {
                    if (params.data.expected_payment_clearance_by != "" && params.data.expected_payment_clearance_by != undefined) {
                        return getHyphenDDMMYYYY(params.data.expected_payment_clearance_by);
                    }
                    else {
                        return "";
                    }
                }
                catch (e) { }
            },
        },
        {
            headerName: "HAZ Classification",
            field: "haz_classification",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Assy Config Type",
            field: "assy_config_type",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Payment Term",
            field: "payment_term",
            valueGetter: function (params) {
                try {
                    //console.log(ptd, "ptd");
                    //console.log(params.data.payment_term, "params.data.payment_term");
                    if (params.data.payment_term != "" && params.data.payment_term != undefined) {
                        let temp = ptd.find(e => e.payment_term == params.data.payment_term)
                        if (temp)
                            return temp.payment_term + " - " + temp.description
                        else
                            return params.data.payment_term
                    }
                    else {
                        return "Not Available";
                    }
                }
                catch (e) { }
            },
            width: 350,
            filter: true,
            resizable: true,
        },
        {
            headerName: "3rd Party Inspection Req",
            field: "third_party_inspection_req",
            width: 190,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Port Of Loading",
            field: "port_of_loading",
            width: 190,
            filter: true,
            resizable: true,
        },
        {
            headerName: "VIN Type Description",
            field: "vin_type_description",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "VIN Type",
            field: "vin_type",
            width: 160,
            filter: true,
            resizable: true,
        },
        // {
        //     headerName: "Tentative Shipping Line",
        //     field: "tentative_shipping_line",
        //     width: 180,
        //     filter: true,
        //     resizable: true,
        // },
        // {
        //     headerName: "Tentative Gate Open Date",
        //     field: "tentative_gate_open_date",
        //     width: 180,
        //     filter: true,
        //     resizable: true,
        //     valueGetter: function (params) {
        //         try {
        //             if (params.data.tentative_gate_open_date != "" && params.data.tentative_gate_open_date != undefined) {
        //                 return getHyphenDDMMYYYY(params.data.tentative_gate_open_date);
        //             }
        //             else {
        //                 return "";
        //             }
        //         }
        //         catch (e) { }
        //     },
        // },
        // {
        //     headerName: "Tentative Cut Off Date",
        //     field: "tentative_cut_off_date",
        //     width: 160,
        //     filter: true,
        //     resizable: true,
        //     valueGetter: function (params) {
        //         try {
        //             if (params.data.tentative_cut_off_date != "" && params.data.tentative_cut_off_date != undefined) {
        //                 return getHyphenDDMMYYYY(params.data.tentative_cut_off_date);
        //             }
        //             else {
        //                 return "";
        //             }
        //         }
        //         catch (e) { }
        //     },
        // },
        {
            headerName: "Remarks (By CHA)",
            field: "remarks_by_cha",
            width: 160,
            filter: true,
            resizable: true,
        },


    ])
    useEffect(()=>{
        if (showAddForm ==='slide30'){
           document.body.style.overflow = 'hidden';
        }else{
           document.body.style.overflow = 'auto';
        }
        return ()=>{
           document.body.style.overflow = 'auto';
        }
      }, [showAddForm])
    useEffect(() => {
        //console.log(gridApi.current,'195')
        if (gridApi.current) {
            getUserGridState();
            //console.log('197')
        }
        loadDateTimeScript();
    }, [gridApi]);
    useEffect(() => {
        if (props.checkforuploaddata == '5') {
            //console.log('')
          const validCols = ["Work Order No","Shipping Bill No", "Shipping Bill Date"];
          const bulkUploadFields = [
            { name: 'Work Order No', inputName: 'work_order_no', required: true },
            { name: 'Shipping Bill No', inputName: 'shipping_bill_no', required: true },
            { name: 'Shipping Bill Date', inputName: 'shipping_bill_date', required: true }
        ]
          const apiForUpload = 'uploadDataforshippingbillData';
          const csvfilename = '../../assets/json/cha_based_at_plant_details.csv'
          const columndefs = [
            {
                headerName: "Work Order No",
                field: "work_order_no",
                width: 120,
                filter: true,
                resizable: true,
            },
            // {
            //     headerName: "Shipping Bill No",
            //     field: "shipping_bill_no",
            //     width: 120,
            //     filter: true,
            //     resizable: true,
            // },
            // {
            //     headerName: "Shipping Bill Date",
            //     field: "shipping_bill_date",
            //     width: 190,
            //     filter: true,
            //     resizable: true,
            //     editable: (params) => {
            //         const date = params.data.shipping_bill_date;
            //         // Check if date is in DD-MM-YYYY format
            //         const datePattern = /^\d{2}-\d{2}-\d{4}$/;
            //         return !(date && datePattern.test(date)); // Editable if date is invalid
            //     },
            //     valueGetter: (params) => {
            //         const date = params.data.shipping_bill_date;
            //         // Check if date is in DD-MM-YYYY format
            //         const datePattern = /^\d{2}-\d{2}-\d{4}$/;
            //         if (date && datePattern.test(date)) {
            //             return date;
            //         } else {
            //             return "Invalid Date Format";
            //         }
            //     },
            //     cellStyle: (params) => {
            //         const date = params.value;
            //         // Apply red color if the date format is invalid
            //         if (date === "Invalid Date Format") {
            //             return { background: 'red', color: 'black' };
            //         }
            //         return null;
            //     }
            // },
          ]
          props.uploadBulkData(validCols, bulkUploadFields, apiForUpload,columndefs);
        }
      }, [props.checkforuploaddata]);
    useEffect(() => {
        loadDateTimeScript();
        var records = props.data
        var new_trucks = records.at_plant_new
        if(new_trucks!='' && new_trucks!=undefined){
            new_trucks=sortByCreatedDate(new_trucks)
        }
        var planned_trucks = records.at_plant_planned
        if(planned_trucks!='' && planned_trucks!=undefined){
            planned_trucks=sortByCreatedDate(planned_trucks)
        }
        var modified_trucks = records.at_plant_modified
        if(modified_trucks!='' && modified_trucks!=undefined){
            modified_trucks=sortByCreatedDate(modified_trucks)
        }
        setrowData(new_trucks)
         setoriginalData(new_trucks)
         settabDataforAtPlantCHA({
            atplant_new: new_trucks,
            atplant_planned: planned_trucks,
            atplant_modified: modified_trucks
        })
    }, [props.data])
    var typeofselection = [
        { label: 'Ship To Country', value: 'ship_to_country' },
        { label: 'POD', value: 'pod' },
        { label: 'Plant', value: 'plant' }, { label: 'Production Date', value: 'production_date' }
    ]

    const showdata = (e) => {
        if (e == 'detailed view') {
            setdetailedviewcheck(1)
            setsummaryviewcheck(0)
        }
        else if (e == 'summary view') {
            setdetailedviewcheck(0)
            setsummaryviewcheck(1)
        }
    }
    const changeHandleDate = (e) => {
        var d = moment(e._d);
        var formattedDate = d.format('DD-MM-YYYY');
        return formattedDate
    }
    const handleFilterOptions = (e) => {
        if (e.value == 'production_date') {
            setcheckForDateFilter(1)
            setcheckinput(0)
        }
        else {
            setcheckinput(1)
            setcheckForDateFilter(0)
        }
        setselectedval(prev => ({
            ...prev,
            dropdownval: e
        }))
    }
    const getUserGridState = () => {
        redirectURL.post("/consignments/usergridstates", { screenurl: '/shippingbillnew' })
            .then(async (resp) => {
                //console.log("resp.data0999", resp.data)
                restoreGridStates(resp.data);
                var grid = resp.data;
                var oCols= fetchGrid(summaryViewcols, grid[0].gridcolumns)
                //console.log("oCols",oCols)
                
                // setsummaryViewcols(oCols);
            })
    }
    const restoreGridStates = (griddata) => {
        try {
            if (griddata.length > 0) {
                var oCols= fetchGrid(summaryViewcols, griddata[0].gridcolumns)
                setsummaryViewcols(oCols);
            }
        } catch (e) {
            //console.log(e);
        }
    };
const onGridReady = params => {
    gridApi.current = params;
    // gridColumnApi.current = params.columnApi;
    getUserGridState();
};
    const onGridState = () => {
        ////console.log(gridApi);

        /*Get  Current Columns State and Store in this.colState */
        const colState = gridApi.current.columnApi.getColumnState();

        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
        // const rowGroupState = gridApi.current.columnApi.getRowGroupColumns();

        // /*Get Current Pivot Columns State and Store in this.pivotColumns, 
        //  * User should enable the Pivot mode.*/
        // const pivotColumns = gridApi.current.columnApi.getPivotColumns();

        // /*Get Current Filter State and Store in window.filterState */

        // window.filterState = gridApi.current.api.getFilterModel();
        gridApi.current.api.setFilterModel(window.filterState);
        getUserGridState();


    }
    var onClickSaveGridState = () => {
        try {
            // //console.log("SaveGrid", gridApi.current.getSortModel())
            window.colState = gridApi.current.columnApi.getColumnState();
            // window.groupState = gridApi.current.columnApi.getColumnGroupState();
            // window.sortState = gridApi.current.columnApi.getSortModel();
            // window.filterState = gridApi.current.api.getFilterModel();
            var screenpage = '/shippingbillnew'
            let reqparams = {
                gridcolumns: window.colState,
                // gridgroup: window.groupState,
                // gridcolsort: window.sortState,
                // gridcolfilter: window.filterState,
                userId: localStorage.getItem("userid"),
                screenurl: '/shippingbillnew',
                screentitle: screenpage
            }
            //console.log("reqparams after save grid", reqparams);
            redirectURL.post("/consignments/saveGridStates", reqparams)
                .then((response) => {
                    //console.log("response forr", response)
                    // if(response.data.status === "200"){
                    setshow(true)
                    setbasicTitle("Successfully saved grid layout")
                    setbasicType("success")
                    // setState1({
                    // 	screenurl: window.location.pathname,
                    // 	screentitle: screenpage
                    // })
                    setTimeout(() => {
                        getUserGridState();
                    }, 2000)
                    // getUserGridState();
                    // }
                })
                .catch(function (e) {
                    //console.log("Error ", e)
                })
            //console.log('hit')
        }
        catch (e) {
            //console.log(e)
        }
    }
    const onclickTab = (e) => {
        if (e == 'New') {
            setclassforTab({
                new: 1,
                planned: 0,
                modified: 0
            })
            props.setcheckforbulk(true)

            setrowData(tabDataforAtPlantCHA.atplant_new)
            setoriginalData(tabDataforAtPlantCHA.atplant_new)
        }
        else if (e == 'Planned') {
            setclassforTab({
                new: 0,
                planned: 1,
                modified: 0
            })
            props.setcheckforbulk(true)

            setrowData(tabDataforAtPlantCHA.atplant_planned)
            setoriginalData(tabDataforAtPlantCHA.atplant_planned)
        }
        else if (e == 'Modified') {
            setclassforTab({
                new: 0,
                planned: 0,
                modified: 1
            })
            props.setcheckforbulk(false)

            setrowData(tabDataforAtPlantCHA.atplant_modified)
            setoriginalData(tabDataforAtPlantCHA.atplant_modified)
        }
    }
    const handleDatesSelected = (startDate, endDate) => {
        // Access the selected dates in the parent component
        var wo_release_from_date = startDate.format('DD-MM-YYYY');
        var wo_release_to_date = endDate.format('DD-MM-YYYY')
        setWoReleaseDate({
            wo_release_from_date: wo_release_from_date,
            wo_release_to_date: wo_release_to_date
        })
    };
    const handleInputfilter = (e) => {
        setselectedval(prev => ({
            ...prev,
            inputval: e.target.value
        }))
    }
    const filterData = () => {
        var typeoffield = selectedval.dropdownval.value
        var val = selectedval.inputval
        if (typeoffield == 'ship_to_country') {
            var filteredData = originalData.filter(item => item.ship_to_country == val)
        }
        else if (typeoffield == 'pod') {
            var filteredData = originalData.filter(item => item.pod == val)
        }
        else if (typeoffield == 'plant') {
            var filteredData = originalData.filter(item => item.plant == val)
        }
        else if (typeoffield == 'brand') {
            var filteredData = originalData.filter(item => item.brand == val)
        }
        else if (typeoffield == 'variant') {
            var filteredData = originalData.filter(item => item.variant == val)
        }
        //console.log(filteredData)
        setrowData(filteredData)
    }
    const filterDatafordates = () => {
        var from_date = woReleaseDate.wo_release_from_date
        from_date = moment(from_date, 'DD-MM-YYYY')
        var to_date = woReleaseDate.wo_release_to_date
        to_date = moment(to_date, 'DD-MM-YYYY')
        var filteredData = rowData.filter(item => {
            var itemDate = getHyphenDDMMYYYY(item.wo_release_date)
            itemDate = moment(itemDate, 'DD-MM-YYYY')
            return itemDate.isBetween(from_date, to_date, null, '[]')
        });
        setrowData(filteredData)
    }
    const onsubmitdata = (e) => {
        e.preventDefault()
        var data= [{
            "work_order_no":selected_row.work_order_no,
            "shipping_bill_no":manualUploadDetails.shipping_bill_no,
            "shipping_bill_date":manualUploadDetails.shipping_bill_date
        }]
        var reqparams={
            data:data
        }
        if(manualUploadDetails.shipping_bill_no!='' && manualUploadDetails.shipping_bill_no!=undefined&&manualUploadDetails.shipping_bill_date!='' && manualUploadDetails.shipping_bill_date!=undefined){
            redirectURL.post('/consignments/uploadDataforshippingbillData', reqparams).then((resp) => {
                if (resp.data.status == 'Success') {
                    setshow(true)
                    setbasicTitle("Shipping Bill Details has been successfully updated!")
                    setbasicType("success")
                    setloadshow('show-n')
                    setoverly('show-n')
                    setshowAddForm('')
                    props.onRequest()
                    setclassforTab({
                        new: 1,
                        planned: 0,
                        modified: 0
                    })
                    setmanualUploadDetails(prev=>({
                        ...prev,
                        wrno:{label:'',value:''},
                        shipping_bill_no:'',
                        shipping_bill_date:''
                    }))
                }
                else {
                    setshow(true)
                     setbasicTitle(resp.data.message)
                    setbasicType("danger")
                    setloadshow('show-n')
                    setoverly('show-n')
                }
            })
        }
        else{
            setshow(true)
                     setbasicTitle('Please Enter All Manditory Fields!')
                    setbasicType("danger")
                    setloadshow('show-n')
                    setoverly('show-n')
        }

    }
    var onCloseRouteDiv = () => {
        setStateForMap(prevState => ({
            showDiv: 'show-n',
            sliderRouteTranslate: "",
        }))
        setoverly('show-n')
    }
    var changeOrderFileHandlerFcrBulk = async (e) => {
        setfile(e.target.files[0])
        var csvdata = await BulkForceFileHandler(e, [
            { name: 'Work Order No', inputName: 'work_order_no', required: true },
            { name: 'Tentative shipping line', inputName: 'tentative_shipping_line', required: true },
            { name: 'Tentative vessel ETD', inputName: 'tentative_vessel_etd', required: true },
            { name: 'Tentative Gate Open Date', inputName: 'tentative_gate_open_date', required: true },
            { name: 'Tentative cut off date', inputName: 'tentative_cut_off_date', required: true },
            { name: 'Remarks(by CHA)', inputName: 'remarks', required: true },

        ])
        //console.log(csvdata, '1234')
        setcsvcontent(csvdata)
    }
    const onSelectWON = (e) => {
        setmanualUploadDetails(prev => ({
            ...prev,
            wrno: { label: e.value, value: e.value }
        }))
    }
    const onSubmitOrderBulkUpload = (event) => {
        event.preventDefault();
        setloadshow('show-m')
        if (file != '') {
            var rparams = {
                data: csvcontent,
            }
            redirectURL.post('/consignments/uploadBulkuploadforCHAdata', rparams).then((resp) => {
                if (resp.data.status == 'Success') {
                    setshow(true)
                    setbasicTitle("Successfully Uploaded")
                    setbasicType("success")
                    setloadshow('show-n')
                    setoverly('show-n')
                    setsliderForBulkupload('')
                    document.getElementById("uploadfile").value = null
                }
                else {
                    setshow(true)
                    // setbasicTitle("Successfully Uploaded")
                    setbasicType("Failure")
                    setloadshow('show-n')
                    setoverly('show-n')
                    setsliderForBulkupload('')
                    document.getElementById("uploadfile").value = null
                }
                //console.log(resp.data)
            })
        }
    }
    const onClickHide = () => {
        setsliderForBulkupload('')
        document.getElementById("uploadfile").value = null
        setoverly('show-n')
    }
    const onClickHideManualUpload = () => {
        setshowAddForm('')
        setoverly('show-n')
        setmanualUploadDetails(prev=>({
            ...prev,
            wrno:{label:'',value:''},
            shipping_bill_no:'',
            shipping_bill_date:''
        }))

    }
    const onClickAcknowledgeBtn=(rowdata)=>{
        var row = rowdata.data 
        var params = {work_order_no:row.work_order_no,unsetField:'cha_do_modified',setField:'cha_do_planned',record:row}
        redirectURL.post('/consignments/acknowledgeData',params).then((resp)=>{
            if(resp.data.status=='Success'){
                setshow(true)
                    setbasicTitle("Acknowledgement successful")
                    setbasicType("success")
                    props.onRequest()
                    props.onReloadData(true);
            }
        })
    }
    const onClickshippingbillbtn=(e)=>{
        //console.log(e, "shipppinggg")
        if(e.data.shipping_new==1){
            setselected_row(e.data)
            setshowAddForm("slide30")
            setoverly("show-m")
        }else if(e.data.shipping_modified==1){
            setselected_row(e.data)
            setshowAddForm("slide30")
            setoverly("show-m")
        }
    }
    // const onShowShipmentLegRouteMap = async (e) => {
    //     var params = {
    //       work_order_no: e.work_order_no,
    //       truck_no: e.truck_no,
    //       container_no: e.container_no
    //     };
      
    //     setloadshow('show-m');
    //     setoverly('show-m');
      
    //     try {
    //       const resp = await redirectURL.post('/consignments/getmapdata', params);
    //       //console.log(resp.data);
    //       var records = resp.data.gps_df;
    //       //console.log(records, '342');
      
    //       if (!Array.isArray(records)) {
    //         records = records.replace(/NaN/g, "0");
    //         records = JSON.parse(records);
    //       }
    //       //console.log(records, '169');
    //       if (!records || records.length === 0) {
    //         setshow(true);
    //         setbasicTitle('No GPS Data Available');
    //         setbasicType('danger');
    //         setloadshow('show-n');
    //         setoverly('show-n');

    //       } else {
    //         var data = records[0];
    //         var allCoordinates = [];
    //         var allldbcoords = [];
    //         var gps_route = data.gps_route;
    //         var ldb_route = data.ldb_route;
            
    //         //console.log( 'gps_route', gps_route); 
    //         //console.log( 'ldb_route', ldb_route);
    //         if(ldb_route!='' && ldb_route!=undefined){
    //             if (ldb_route.length > 0) {
    //                 ldb_route=JSON.parse(ldb_route)
    //                 ldb_route.forEach((e) => {
    //                     var lt = e.latitude || e.lat || 0;
    //                     var ln = e.longitude || e.lng || 0;
                
    //                     allldbcoords.push({
    //                         lat: lt,
    //                         lng: ln,
    //                     });
    //                 });
    //             } else {
    //                 //console.log('ldb_route is not an array or is empty.');
    //             }
    //         }
    //         //console.log(gps_route,'gps_routegps_route')
    //         if(gps_route!='' && gps_route!=undefined){
    //             if (gps_route.length > 0) {
    //                 //console.log(typeof gps_route,'gps_routegps_route')
    //                 gps_route=JSON.parse(gps_route)
    //                 gps_route.map((e) => {
    //                     var lt = e.latitude || e.lat || 0; 
    //                     var ln = e.longitude || e.lng || 0; 
    //                     allCoordinates.push({
    //                         lat: lt,
    //                         lng: ln,
    //                     });
    //                 });
    //             } else {
    //                 //console.log('gps_route is not an array or is empty.');
    //             }
    //         }
            
    //         var truck_no;
    //         if (records.length > 0) {
    //           truck_no = records[0].truck_no;
    //         }
    //         //console.log(allCoordinates, 'allCoordinates');
    //             setoverly('show-m')
    //           setStateForMap((prev) => ({
    //             ...prev,
    //             sliderRouteTranslate: "slider-translate-60p",
    //             showDiv: 'show-m',
    //             container_no: truck_no,
    //             mapinfo: {
    //               ...state.mapinfo,
    //               coords: allCoordinates,
    //               ldb_route:allldbcoords
    //             },
    //           }));
    //         setloadshow('show-n');
    //       }
    //     } catch (error) {
    //       console.error(error);
    //     }
    //   };
      const onShowShipmentLegRouteMap = async (e) => {
        var params = {
            work_order_no: e.work_order_no,
            truck_no: e.truck_no,
            container_no: e.container_no
        };
        setloadshow('show-m');
        setoverly('show-m');

        try {
            const resp = await redirectURL.post('/consignments/getmapdata', params);
            //console.log(resp, "array");
            var gps_route = resp.data.gps_route.coords;
            if (!Array.isArray(gps_route)) {
                gps_route = gps_route.replace(/NaN/g, "0");
                gps_route = JSON.parse(gps_route);
            }
            if (!gps_route || gps_route.length === 0) {
                setshow(true);
                setbasicTitle('No GPS Data Available');
                setbasicType('danger');
                setloadshow('show-n');
                setoverly('show-n');

            } else {
                var allCoordinates = [];
                var allldbcoords = [];
                var gps_route =gps_route;
                var ldb_data = resp.data.ldb_route
                var ldb_route = ldb_data.ldb_route;

                //console.log('gps_route', gps_route);
                //console.log('ldb_route', ldb_route);
                if (ldb_route != '' && ldb_route != undefined) {
                    if (ldb_route.length > 0) {
                        ldb_route = JSON.parse(ldb_route)
                        ldb_route.forEach((e) => {
                            var lt = e.latitude || e.lat || 0;
                            var ln = e.longitude || e.lng || 0;

                            allldbcoords.push({
                                lat: lt,
                                lng: ln,
                            });
                        });
                    } else {
                        //console.log('ldb_route is not an array or is empty.');
                    }
                }
                //console.log(gps_route, 'gps_routegps_route')
                if (gps_route != '' && gps_route != undefined) {
                    if (gps_route.length > 0) {
                        //console.log(typeof gps_route, 'gps_routegps_route')
                        gps_route.map((e) => {
                            var lt = e.latitude || e.lat || 0;
                            var ln = e.longitude || e.lng || 0;
                            allCoordinates.push({
                                lat: lt,
                                lng: ln,
                            });
                        });
                    } else {
                        //console.log('gps_route is not an array or is empty.');
                    }
                }

                var truck_no;
                if (gps_route.length > 0) {
                    truck_no = gps_route[0].truck_no;
                }
                //console.log(allCoordinates, 'allCoordinates');
                setoverly('show-m')
                setStateForMap((prev) => ({
                    ...prev,
                    sliderRouteTranslate: "slider-translate-75p",
                    showDiv: 'show-m',
                    container_no: truck_no,
                    mapinfo: {
                        ...state.mapinfo,
                        coords: allCoordinates,
                        ldb_route: allldbcoords
                    },
                    mapdata:resp.data.gps_route

                }));
                setloadshow('show-n');
            }
        } catch (error) {
            console.error(error);
        }
    };
    const getRowClass = (params)=>{
        //console.log(params,'185')
        if(params.data && (params.data.at_plant_modified==1||params.data.ib_plant_gate_in_modified==1||params.data.tpt_container_modified==1||params.data.tpt_trucks_modified==1||params.data.ib_tpt_modified==1||params.data.modified_plant==1||params.data.cha_vessel_modified==1||params.data.ib_stuffing_modified==1||params.data.cha_do_modified==1)){
            return { backgroundColor: '#FFB9B9' }; 
        }
        return null
    }
    var onClickShowShipmentLegs = async (rownode) => {
        var commentHeaders = [
            {
                headerName: "",
                field: "",
                resizable: true,
                width: 60,
                filter: true,
                // cellRendererFramework: LegRouteActiveTruckMap,
                cellRendererFramework: (params) => {
                    return (
                        <div>
                            <button onClick={() => onShowShipmentLegRouteMap(params.data)} className="custom-btn label label-success">
                                <i className="icofont icofont-map-pins f20"></i>
                            </button>
                        </div>
                    );
                },
            },
            {
                headerName: 'Requirement Id',
                field: "requirement_id",
                resizable: true,
                width: 180,
                filter: true
            },
            {
                headerName: 'Work Order No',
                field: "work_order_no",
                resizable: true,
                width: 180,
                filter: true
            },
            {
                headerName: 'Truck No',
                field: "truck_no",
                resizable: true,
                width: 180,
                filter: true
            },
            {
                headerName: 'Container No',
                field: "container_no",
                resizable: true,
                width: 180,
                filter: true
               
            },
            // {
            //     headerName: 'Pickup Date',
            //     field: "pick_up_date",
            //     resizable: true,
            //     width: 180,
            //     filter: true,
               
            //     valueGetter: function (params) {
            //         try {
            //             if (params.data.pick_up_date != "" && params.data.pick_up_date != undefined) {
            //                 return getHyphenDDMMYYYY(params.data.pick_up_date);
            //             } else {
            //                 return "";
            //             }
            //         } catch (e) {
            //             // Handle the exception if needed
            //         }
            //     },
               
            // },
            // {
            //     headerName: 'Shipping Seal',
            //     field: "shipping_seal",
            //     resizable: true,
            //     width: 180,
            //     filter: true,
            
            // },
            {
                headerName: 'Invoice No',
                field: "invoice_no",
                resizable: true,
                width: 180,
                filter: true,
                editable:true,
                
            },
            {
                headerName: 'Invoice Date',
                field: "invoice_date",
                resizable: true,
                width: 180,
                filter: true,
                editable:true,
                cellEditor:DateEditor,
                valueGetter: function (params) {
                    try {
                        if (params.data.invoice_date != "" && params.data.invoice_date != undefined) {
                            return getHyphenDDMMYYYY(params.data.invoice_date);
                        } else {
                            return "";
                        }
                    } catch (e) {
                        // Handle the exception if needed
                    }
                },
                comparator: dateComparator1,
            },
            {
                headerName: 'RFID',
                field: "rfid",
                resizable: true,
                width: 180,
                filter: true,
            },
            {
                headerName: 'Plant Gate In Date & Time',
                field: "gate_in_time",
                resizable: true,
                width: 180,
                filter: true,
                editable:true,
                cellEditor:DateEditor,
                valueGetter: function (params) {
                    try {
                        if (params.data.gate_in_time != "" && params.data.gate_in_time != undefined) {
                            return getHyphenDDMMYYYYHHMM(params.data.gate_in_time);
                        } else {
                            return "";
                        }
                    } catch (e) {
                        // Handle the exception if needed
                    }
                },
                comparator: dateComparator1,
            },
            {
                headerName: 'Plant Gate Out Date & Time',
                field: "gate_out_time",
                resizable: true,
                width: 180,
                filter: true,
                valueGetter: function (params) {
                    try {
                        if (params.data.gate_out_time != "" && params.data.gate_out_time != undefined) {
                            return getHyphenDDMMYYYYHHMM(params.data.gate_out_time);
                        } else {
                            return "";
                        }
                    } catch (e) {
                        // Handle the exception if needed
                    }
                },
            },
            // {
            //     headerName: "",
            //     field: "",
            //     resizable: true,
            //     width: 60,
            //     filter: true,
            //     // cellRendererFramework: LegRouteActiveTruckMap,
            //     cellRendererFramework: (params) => {
            //         return (
            //             <div>
            //                 <button onClick={() => updateContainerDetails(params.data)} className="btn btn-success f12 label label-success">
            //                    Submit
            //                 </button>
            //             </div>
            //         );
            //     },
            // },
        ]
        var setdata = {
            detailGridOptions: {
                columnDefs: commentHeaders,
                overlayNoRowsTemplate: 'No rows to show',
    
            },
            getDetailRowData: async function (param) {
                param.successCallback([]);
                var row = param.data
                var parameter = {
                    work_order_no:row.work_order_no
                }
                var records = props.data.container_new_1
                if(classForTab.new){
                    var data = props.data.container_new_1 
                    //console.log(data,'518')
                    if(data!='' && data!=undefined){
                        records = data.filter(e=>row.work_order_no==e.work_order_no)
                    }
                }
                else if(classForTab.planned){
                    var data = props.data.container_new_2
                    if(data!='' && data!=undefined){
                        records = data.filter(e=>row.work_order_no==e.work_order_no)
                    }                }
                else if(classForTab.modified){
                    var data = props.data.container_new_3 
                    if(data!='' && data!=undefined){
                        records = data.filter(e=>row.work_order_no==e.work_order_no)
                    }                }
                    param.successCallback(records);
                // }).catch(function (error) {
                //     //console.log(error);
                // })
            },
            masterDetail: true
        }
        dispatch({ type: 'SET_DETAIL_RENDERER_PARAMS', payload: setdata });
            setTimeout(() => {
                if (rownode.column.colDef.field == 'containerLegs') {
                    rownode.node.setExpanded(!rownode.node.expanded);
                   
                }
                else {
    
                    rownode.node.setExpanded(false);
                }
            }, 0)
    }
    const onClickHideAll = () => {
        setloadshow('show-n')
    }
    var closeAlert = () => {
        setshow(false)
        setloadshow('show-n')
    }
    var onclickbulkupload = () => {
        setsliderForBulkupload("slider-translate")
        setoverly('show-m')
    }
    const onClickCounters = (props) => {
        if (props == 'vesselAvailability') {
            setcheck(1)
            setIsClicked({ vessel_avail: true })
        }
        if (props == 'uploadDO') {
            setcheck(2)
            setIsClicked({ uploadDo: true })
        }
        else {
            setIsClicked(false)
        }
    }
    var sDate = $("#wo_order_date").val()
    var updateShipping = [
        {
            headerName: "Update",
            field: "update_shipping_bill_details",
            width: 80,
            pinned: 'left',
            cellRenderer: 'UpdateShippingBillDetailsBtn'
        }
    ]
    var acknowledgebtn= {
        headerName: "",
        field: "",
        width: 120,
        filter: true, resizable: true,
        pinned: 'left',
        cellRendererFramework: AcknowledgeBtnComponent
    }
    var updatedfields = [
        // {
        //     headerName: "Shipping Bill No",
        //     field: "shipping_bill_no",
        //     width: 160,
        //     filter: true,
        //     resizable: true,
        // },
        // {
        //     headerName: "Shipping Bill Date",
        //     field: "shipping_bill_date",
        //     width: 200,
        //     filter: true,
        //     resizable: true,
        //     valueGetter:function(params){
        //         if(params.data.shipping_bill_date!='' && params.data.shipping_bill_date!=undefined){
        //             return getHyphenDDMMYYYY(params.data.shipping_bill_date)
        //         }
        //         else{
        //             return ''
        //         }
        //     }
        // },
    ]
    var columns1 = [...summaryViewcols];
    if (classForTab.new == 1) {
        columns1.push(...updateShipping);
    }
    if (classForTab.modified == 1) {
        columns1.push(acknowledgebtn,...updatedfields,...updateShipping);
    }
    if(classForTab.planned==1){
        columns1.push(...updatedfields)

    }
    
    var columnwithDefs = columns1;
    columnwithDefs.forEach(e => {
        e.cellClass = function (params) {
          if (params.data && params.data[`${e.field}_modified`] === 1) {
            return "bgColorDangerDark";
          } else {
            return null;
          }
        };
      });
    //console.log(manualUploadDetails, 'manualUploadDetails')
    var newCount = tabDataforAtPlantCHA.atplant_new
    var plannedCount = tabDataforAtPlantCHA.atplant_planned
    var modifiedCount = tabDataforAtPlantCHA.atplant_modified
    //console.log(props.check,'1384')
    return (
        <>
            <SweetAlert
                show={show}
                type={basicType}
                title={basicTitle}
                onConfirm={closeAlert}
            >
            </SweetAlert>

            <div className="row">
                
                {/* {props.check == 5 ?

                    <div className="form-group col-xl-2 col-lg-2 mt-24p">
                        <Select
                            closeMenuOnSelect={true}
                            name="filterModeType"
                            className="border-radius-0"
                            styles={{
                                control: (provided, state) => ({
                                            ...provided,
                                            fontSize: "0.8rem",
                                            color: "#6e707e",
                                            borderRadius:"11px",
                                            height:"48px",
                                            width:"260px",
                                            // padding:"22px",
                                            textAlign:"center"
                                        }),
                            }}
                            onChange={handleFilterOptions}
                            options={typeofselection}
                            value={selectedval.dropdownval}
                            required
                        />
                    </div> : ''} */}
                {/* {checkinput == 1 ?
                    <div
                        className="d-flex mt-24p ml-40p mr-10p"
                        style={{ position: "relative" }}
                    >
                        <input
                            style={{
                                border: "1px solid #cccccc",
                                borderRadius: "11px",
                                height: "48px",
                                outline: "none",
                                paddingLeft: "6px",
                            }}
                            onChange={handleInputfilter}
                            value={selectedval.inputval}
                        />
                        <img
                            src={SearchIcon}
                            className="search_icon"
                            onClick={filterData}
                        />
                    </div>
                    : ''}
                {checkForDateFilter == 1 ?
                    <div className="mt-24p ml-40p">  <Datepicker id='production_date' onDatesSelected={handleDatesSelected} /> <img
                        src={SearchIcon}
                        className="search_icon_for_dates"
                        onClick={filterDatafordates}
                    /></div>

                    : ""} */}
                {/* {props.check == 5 ?
                    <span className="float-right mt-24p" >
                        <button className='btn_style' onClick={() => manualbulkUploadForCha()} >Update CHA At Plant</button>
                    </span>


                    : ''} */}
            </div>

            {props.check == 5 ?

                <div className="m-10p" style={{ margin: '10px', marginTop: '0px' }}>
                    <div className="d-flex flex-row" style={{position:"relative"}}>
                    <button
                        className="d-flex flex-row justify-content-center align-items-center px-3"
                        style={{
                            background: classForTab.new == 1 ? '#0664AE' : '#FFFFFF',
                            // color: state.isTileView ? "": "#0664AE",
                            borderTopLeftRadius: "11px",
                            borderBottomLeftRadius: "11px",
                            border: "1px solid #0664AE",
                            height: "38px",
                            padding: '22px'
                        }}
                        onClick={() => onclickTab('New')}
                    >
                        <div
                            style={{
                                color: classForTab.new == 1 ? "white" : '#0664AE',
                                fontSize: "12px",
                                fontWeight: 700,
                                marginLeft: "10px",
                                lineHeight: "12px",
                            }}
                        >
                            New - <span>{(newCount != '' && newCount != undefined) ? newCount.length : 0}</span>
                        </div>
                    </button>

                    <button
                        className="d-flex flex-row justify-content-center align-items-center px-3"
                        style={{
                            background: classForTab.planned == 1 ? '#0664AE' : "#FFFFFF",
                            border: "1px solid #0664AE",
                            height: "38px",
                            padding: '22px'
                        }}
                        onClick={() => onclickTab('Planned')}
                    >
                        <div
                            style={{
                                color: classForTab.planned == 1 ? "white" : '#0664AE',
                                fontSize: "12px",
                                fontWeight: 700,
                                marginLeft: "10px",
                                lineHeight: "12px",
                            }}
                        >
                            Planned - <span>{(plannedCount != '' && plannedCount != undefined) ? plannedCount.length : 0}</span>
                        </div>
                    </button>
                    <button
                        className="d-flex flex-row justify-content-center align-items-center px-3"
                        style={{
                            background: classForTab.modified == 1 ? '#0664AE' : "#FFFFFF",
                            borderTopRightRadius: "11px",
                            borderBottomRightRadius: "11px",
                            border: "1px solid #0664AE",
                            height: "38px",
                            padding: '22px'
                        }}
                        onClick={() => onclickTab('Modified')}
                    >
                        <div
                            style={{
                                color: classForTab.modified == 1 ? "white" : '#0664AE',
                                fontSize: "12px",
                                fontWeight: 700,
                                marginLeft: "10px",
                                lineHeight: "12px",
                            }}
                        >
                            Modified - <span>{(modifiedCount != '' && modifiedCount != undefined) ? modifiedCount.length : 0}</span>
                        </div>
                    </button>
                    {classForTab.new===1?
                         <div style={{position:"absolute", right:"3px",top:"-25px" }}>
                            {/* <div className='btn_hover' style={{ height: "35px", marginTop: "32px", fontSize: "12px", textAlign: "center", paddingTop: "8px", padding:"5px" }} onClick={onClickSaveGridState}>
                             <i className="icofont icofont-save" style={{ marginRight: '5px' }}></i>Save Grid Layout
                         </div> */}
                          <button
                                        type="button"
                                        title="Save Grid Layout"
                                        name="savegrid"
                                        className="btn btn-danger action_btn"
                                        style={{height: "32px", marginTop: "30px", paddingBottom:"30px" }}

                                        onClick={onClickSaveGridState}>
                                        <i className="f12 icofont icofont-save"></i>
                                        </button>
                        </div>
                     :''}
                </div>
                {/* <div style={{display:'flex',justifyContent:'end' }}>

                <p style={{color:'red'}}>Note: Modification Pending At Previous Action</p>
                    </div> */}
                    <div id="idgrid" style={{ width: "100%", height: "478px" }} className="ag-theme-balham">
                        <AgGridReact
                            columnDefs={columnwithDefs}
                            defaultColDef={{
                                sortable: true,
                                filter: true,
                                editable: false,
                                resizable: true,
                                menuTabs: ['filterMenuTab']
                            }}
                            // paddingTop={10}
                            // rowHeight={70}
                            // headerHeight = {70}
                            rowData={rowData}
                            suppressRowClickSelection={true}
                            frameworkComponents={{ConsignmentTrucksLegs:ConsignmentTrucksLegs,CountryFlagComponent:CountryFlagComponent,UpdateShippingBillDetailsBtn:UpdateShippingBillDetailsBtn,AcknowledgeBtnComponent:AcknowledgeBtnComponent}}
                            detailCellRendererParams={state.detailCellRendererParams}

                            enableCellChangeFlash={true}
                            suppressCellFlash={true}
                            enableRangeSelection={true}
                            paginationPageSize={50}
                            rowSelection={'multiple'}
                            masterDetail={true}
                            pagination={true}
                            sideBar={{
                                toolPanels: [
                                    {
                                        id: "columns",
                                        labelDefault: "Columns",
                                        labelKey: "columns",
                                        iconKey: "columns",
                                        toolPanel: "agColumnsToolPanel"
                                    },
                                    {
                                        id: "filters",
                                        labelDefault: "Filters",
                                        labelKey: "filters",
                                        iconKey: "filter",
                                        toolPanel: "agFiltersToolPanel"
                                    }
                                ]
                            }}
                            statusBar={{
                                statusPanels: [
                                    // {
                                    //     statusPanel: "agTotalAndFilteredRowCountComponent",
                                    //     align: "left"
                                    // },
                                    // {
                                    //     statusPanel: "agTotalRowCountComponent",
                                    //     align: "center"
                                    // },
                                    { statusPanel: "agFilteredRowCountComponent" },
                                    { statusPanel: "agSelectedRowCountComponent" },
                                    { statusPanel: "agAggregationComponent" }
                                ]
                            }}
                            gridOptions={{
                                icons: {
                                    // You can customize other icons as well
                                    next: `<img src="${customNextIcon}" alt="Next" />`,
                                    previous: `<img src="${customPreviousIcon}" alt="Previous" />`,
                                    first: `<img src="${customFirstIcon}" alt="First" />`,
                                    last: `<img src="${customLastIcon}" alt="Last" />`,
                                  },
                            }}
                            // ref={gridRef}
                            ref={gridApi}
                            onGridReady={onGridReady}
                            onGridState={onGridState}
                            getRowStyle={getRowClass}
                            context={{onClickShowShipmentLegs,onClickshippingbillbtn,onClickAcknowledgeBtn}}
                        />

                    </div>
                </div> : ''}

            <div className={"slide-r " + sliderForBulkupload} style={{ overflow: "auto" }}>
                <div className="slide-r-title slider_title_style">
                    <h6>Bulk Upload</h6>
                    <span className="float-right closebtn" style={{ marginTop: '-30px', marginRight: "25px" }} onClick={onClickHide} >X</span>
                </div>
                <div className="slide-r-body" style={{ position: "relative" }}>
                    <div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div>
                    <div className={"row"} style={{ padding: '5px 0px', width: '100%', zIndex: '9999', }} >

                        <div className="theme-form col-xl-12 col-lg-12">

                            <form action="" onSubmit={onSubmitOrderBulkUpload}>
                                <div className="col-xl-12 col-lg-12 row form-group">
                                    <label className="col-xl-12 col-lg-12">Upload File *</label>
                                    <input
                                        type="file"
                                        name="uploadfile"
                                        id="uploadfile"
                                        className="form-control"
                                        onChange={changeOrderFileHandlerFcrBulk}
                                        required
                                    />
                                </div>

                                <div className="col-xl-12 col-lg-12 form-group">
                                    <button type="submit" className="btn btn-success">Submit</button>
                                </div>
                            </form>
                            <div className="col-xl-12 col-lg-12 form-group">
                                <a href={require("../../assets/json/cha_based_upload_data.csv")} target="_blank" className="btn btn-warning">Download Template</a>
                            </div>
                            <p style={{ color: 'red' }}>Note: </p>
                            <p>Tentative Shipment Line & Remarks should be in 'String' format. <br />
                                Tentative Vessel ETD & Tentative Gate Open Date & Tentative cut off date should be in 'DD-MM-YYYY' format</p>
                        </div>
                    </div>
                </div>

            </div>
            {stateForMap.sliderRouteTranslate != '' ?
				<div className={"sliderBlock2 " + stateForMap.sliderRouteTranslate} style={{ overflow: "auto" }}>
					<div className="slide-r-title">
						<h4>
							Truck No: {(stateForMap.container_no)}
                            <span className="float-right closebtn" style={{  marginRight: "100px" }} onClick={onCloseRouteDiv} >X</span>
						</h4>
					</div>
					<div className="slide-r-body" style={{ position: "relative" }}>

                        <div className="row col-xl-12 col-lg-12 ml-0p n-p-0">
                            {/* {//console.log("this.state.mapinfo ", this.state.mapinfo)} */}
                            <Mapcard2
                                 mapinfo={stateForMap.mapinfo}
                                 mapHeight={"100vh"}
                                 mapFor={"intransit"}
                                 mapData={stateForMap.mapdata}
                                geoLocShipmentsMap={state.geoLocShipmentsMap}
                            />
						</div>
					</div>

				</div>
				: ""
			}
            <div className={"sliderBlock2 " + showAddForm} style={{ overflow: "auto" }}>
                <div className="slide-r-title slider_title_style">
                    <h6>Enter Shipping Bill Details</h6>
                    <span className="float-right closebtn" style={{ marginTop: '-30px', marginRight: "25px" }} onClick={onClickHideManualUpload} >X</span>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <form onSubmit={onsubmitdata}>
                            <div className="slide-body">
                                <div className="row p-20p">
                                    <div className="form-group col-xl-12 col-lg-12">
                                        <p className='mb-5p'>Work Order No: {selected_row.work_order_no}</p>
                                        {/* <Select
                                            // placeholder="Change Activity Status"
                                            closeMenuOnSelect={true}
                                            value={manualUploadDetails.wrno}
                                            // multi={true}
                                            // className={"border-radius-0"} 
                                            onChange={(e) => onSelectWON(e)}
                                            style={{ borderRadius: "0px" }}
                                            styles={{
                                                control: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: "0.8rem",
                                                    color: "#6e707e",
                                                }),
                                            }}
                                            options={workordernoList}
                                        /> */}
                                    </div>
                                    <div className="form-group col-xl-12 col-lg-12">
                                        <label className='mb-5p'>Shipping Bill No <span className="err-txt">*</span></label>
                                        <input
                                            type="text"
                                            name="shipping_bill_no"
                                            id="shipping_bill_no"
                                            value={manualUploadDetails.shipping_bill_no}
                                            className="form-control"
                                            autoComplete="off" 
                                            onChange={(e) => setmanualUploadDetails(prev => ({ ...prev, shipping_bill_no: e.target.value }))}
                                            required
                                        />
                                    </div>
                                    <div className="form-group col-xl-12 col-lg-12">
                                        <label className='mb-5p'>Shipping Bill Date <span className="err-txt">*</span></label>
                                        <Datetime
                                            key={manualUploadDetails.shipping_bill_date}
                                            placeholder="Date"
                                            disableCloseOnClickOutside={false}
                                            closeOnSelect={true}
                                            name="shipping_bill_date"
                                            value={manualUploadDetails.shipping_bill_date}
                                            dateFormat="DD-MM-YYYY"
                                            id='shipping_bill_date'
                                            timeFormat={false}
                                            onChange={(e) => setmanualUploadDetails(prev => ({ ...prev, shipping_bill_date: changeHandleDate(e) }))}
                                            required 
                                            />
                                    </div>
                                    <div className="form-group col-sm-12 mb-20p text-center">
                                        <button type="submit" className="btn btn-success">Submit</button>
                                    </div>

                                </div>
                            </div>
                        </form>
                    </div>
                </div>


            </div>
            <div className={"dataLoadpage " + (loadshow)}>
            </div>
            <div className={"dataLoadpageimg " + (loadshow)}>
                <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
            </div>
            <div className={"overlay-part " + (overly)} onClick={onClickHideAll}></div>

        </>
    )
}
function isEmpty(obj) {
    for (var key in obj) {
        if (obj.hasOwnProperty(key))
            return false;
    }
    return true;
}
function timeConvert(n) {
    // var num = n/1000;
    // var hours = (num / (3600*24));
    // var rhours = Math.floor(hours);
    // var minutes = (n) / (60*60);
    // var rminutes = Math.round(minutes);

    var diffMs = n;
    var diffDays = Math.floor(diffMs / 86400000); // days
    var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
    var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes


    return diffHrs + " hour(s) and " + (diffMins / 60) + " minute(s).";
}
var currentinfowindow = null;
function getTimeInfo(marker, timedet, coords) {
    var timeinfo = new window.google.maps.InfoWindow({
        content: "Time at this location: " + timedet
    });

    marker.addListener('click', function () {

        if (currentinfowindow != null) {
            currentinfowindow.close();
            currentinfowindow = timeinfo;
            timeinfo.open(marker.get('map'), marker);
        }
        else {
            currentinfowindow = timeinfo;
            timeinfo.open(marker.get('map'), marker);
        }

    });
}



function secondsToString(seconds) {
    var numdays = Math.floor(seconds / 86400);
    var numhours = Math.floor((seconds % 86400) / 3600);
    var numminutes = Math.floor(((seconds % 86400) % 3600) / 60);
    var numseconds = ((seconds % 86400) % 3600) % 60;
    return numdays + " days " + numhours + " hours " + numminutes + " minutes ";
}

function secondsToDays(seconds) {
    var numdays = Math.floor(seconds / 86400);
    var numhours = Math.floor((seconds % 86400) / 3600);
    var numminutes = Math.floor(((seconds % 86400) % 3600) / 60);
    var numseconds = ((seconds % 86400) % 3600) % 60;
    return numdays;
}
function CheckForHtmlTag() {
    var rs = document.getElementById("invalid_reason");
    var reg = /<(.|\n)*?>/g;
    if (reg.test(rs.value)) {
        var ErrorText = "Oops! HTML or Script is not allowed.";
        rs.value = ''
        //alert();
        return 1;
    }
}
function dateComparator(date1, date2) {
    // //console.log("dateComparator");
    // //console.log(date1.length);
    if (date1 != "" && date2 != "" && date1 != undefined && date2 != undefined) {
        // //console.log(date1,date2);
        var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
        date1 = date1.replace(date1.split(" ")[0].split("-")[1], date_1);
        var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
        date2 = date2.replace(date2.split(" ")[0].split("-")[1], date_2);
        // //console.log(date1,date2);
        var date1Number = monthToComparableNumber(date1);
        var date2Number = monthToComparableNumber(date2);
        // //console.log(date1Number,date2Number);
        if (date1Number === null && date2Number === null) {
            return 0;
        }
        if (date1Number === null) {
            return -1;
        }
        if (date2Number === null) {
            return 1;
        }
        return date1Number - date2Number;
    }
}
function monthToComparableNumber(date) {
    // //console.log(date,date.length);
    if (date === undefined || date === null || date.length !== 16) {
        return null;
    }
    var yearNumber = date.substring(6, 10);
    var monthNumber = date.substring(3, 5);
    var dayNumber = date.substring(0, 2);
    ////console.log(yearNumber,monthNumber,dayNumber);
    var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
    return result;
}

function getMonthNumber(monthName) {
    var months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May',
        'Jun', 'Jul', 'Aug', 'Sep',
        'Oct', 'Nov', 'Dec'
    ];
    var monthNum = months.indexOf(monthName) + 1;
    if (monthNum < 10) {
        monthNum = "0" + monthNum.toString();
    }
    return monthNum;
}

function loadDateTimeScript() {
    $('.datetimepicker_mask').datetimepicker({
        mask: '39-19-9999 29:59',
        format: 'd-m-Y H:i',
        onShow: false
    });
    $('.datetimepicker_date').datetimepicker({
        // mask:'39-19-9999',
        format: 'd-m-Y',
        timepicker: false,
        onShow: false
    });
    var index = window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src = "https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
    //script.src="http://xdsoft.net/jqplugins/datetimepicker/"
    script.async = true
    script.defer = true
    index.parentNode.insertBefore(script, index)
}


function GetSortDescOrder(prop) {
    return function (a, b) {
        if (a[prop] < b[prop]) {
            return 1;
        } else if (a[prop] > b[prop]) {
            return -1;
        }
        return 0;
    }
}


function GetSortAscOrder(prop) {
    return function (a, b) {
        if (a[prop] > b[prop]) {
            return 1;
        } else if (a[prop] < b[prop]) {
            return -1;
        }
        return 0;
    }
}

function GetSortASCOrder(prop) {
    return function (a, b) {
        if (a[prop] > b[prop]) {
            return 1;
        } else if (a[prop] < b[prop]) {
            return -1;
        }
        return 0;
    }
}

function secondsToDhms(seconds) {
    seconds = Number(seconds);
    var d = Math.floor(seconds / (3600 * 24));
    var h = Math.floor(seconds % (3600 * 24) / 3600);
    var m = Math.floor(seconds % 3600 / 60);
    var s = Math.floor(seconds % 60);

    var dDisplay = d >= 0 ? d + (d == 1 ? " Day " : " Days ") : "";
    var hDisplay = h >= 0 ? h + (h == 1 ? " Hr " : " Hrs ") : "";
    var mDisplay = m >= 0 ? m + (m == 1 ? " Mins " : " Mins ") : "";
    var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
    return dDisplay + hDisplay + mDisplay;
}

function distinctArrayBy(arr, propName) {
    var result = arr.reduce(function (arr1, e1) {
        var matches = arr1.filter(function (e2) {
            return e1[propName] == e2[propName];
        })
        if (matches.length == 0)
            arr1.push(e1)
        return arr1;
    }, []);

    return result;
}
function dateComparator1(date1, date2) {
    ////console.log(date1,date2);
    if (date1 != "" && date2 != "" && date1 != undefined && date2 != undefined) {
        // //console.log(date1,date2);
        var date_1 = getMonthNumber(date1.split("-")[1]);
        date1 = date1.replace(date1.split("-")[1], date_1);
        var date_2 = getMonthNumber(date2.split("-")[1]);
        date2 = date2.replace(date2.split("-")[1], date_2);
        var date1Number = monthToComparableNumber1(date1);
        var date2Number = monthToComparableNumber1(date2);
        ////console.log(date1Number,date2Number);
        if (date1Number === null && date2Number === null) {
            return 0;
        }
        if (date1Number === null) {
            return -1;
        }
        if (date2Number === null) {
            return 1;
        }
        //console.log(date1Number, date2Number, "comparator")
        return date1Number - date2Number;
    }
}
function monthToComparableNumber1(date) {
    ////console.log(date.length);

    ////console.log(date);
    if (date === undefined || date === null || date.length !== 10) {

        return null;

    }

    var yearNumber = date.substring(6, 10);

    var monthNumber = date.substring(3, 5);

    var dayNumber = date.substring(0, 2);

    var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;

    return result;

}
function loadDateTimeScript2() {
    // alert("timeDate");
    $('.datetimepicker_mask').datetimepicker({
        format: 'd-m-Y'
    });
    $('.device_filter_data').datetimepicker({
        format: 'Y-m-d',
        timepicker: true
    });
    var index = window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src = "https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
    //script.src="http://xdsoft.net/jqplugins/datetimepicker/"
    script.async = true
    script.defer = true
    index.parentNode.insertBefore(script, index)
}
function getDatePicker() {

    function Datepicker() { }

    Datepicker.prototype.init = function (params) {

        this.eInput = document.createElement("input");

        //this.eInput = this.eInput.setAttribute("class","datepicker");

        this.eInput.value = params.value;

        //console.log(this.eInput)

        window.$(".datepicker").datepicker({ dateFormat: "dd-mm-yy" });

    };

    Datepicker.prototype.getGui = function () {

        return this.eInput;

    };

    Datepicker.prototype.afterGuiAttached = function () {

        this.eInput.focus();

        this.eInput.select();

    };

    Datepicker.prototype.getValue = function () {

        return this.eInput.value;

    };

    Datepicker.prototype.destroy = function () { };

    Datepicker.prototype.isPopup = function () {

        return false;

    };

    return Datepicker;

}
function DateEditor() { }
// gets called once before the renderer is used

DateEditor.prototype.init = function (params) {

    // create the cell

    //console.log(params, "params")

    this.eInput = document.createElement('input');

    this.eInput.value = params.value;
    // https://jqueryui.com/datepicker/

    $(this.eInput).datetimepicker({

        dateFormat: "yy-mm-dd",

        changeMonth: true,

        changeYear: true

    });

};




// gets called once when grid ready to insert the element

DateEditor.prototype.getGui = function () {

    return this.eInput;

};




// focus and select can be done after the gui is attached

DateEditor.prototype.afterGuiAttached = function () {

    this.eInput.focus();

    this.eInput.select();

};




// returns the new value after editing

DateEditor.prototype.getValue = function () {

    return this.eInput.value;

};




// any cleanup we need to be done here

DateEditor.prototype.destroy = function () {

    // but this example is simple, no cleanup, we could

    // even leave this method out as it's optional

};




// if true, then this editor will appear in a popup

DateEditor.prototype.isPopup = function () {

    // and we could leave this method out also, false is the default

    return false;

};
function sortByCreatedDate(arr, descending = true) {
    if (arr != '' && arr != undefined) {
      if (arr.length > 0) {
        const comparison = (a, b) => {
          const dateA = new Date(a.modified_date);
          const dateB = new Date(b.modified_date);
          if (descending) {
            return dateB - dateA; // Ascending order
          } else {
            return dateA - dateB; // Descending order
          }
        };
          arr.sort(comparison);
        return arr; 
      }
    } else {
      return arr; 
    }
  }
export default AtPlantCha