import React, { Component } from 'react';
import { Map, InfoWindow, Marker, GoogleApiWrapper, Polygon, Polyline } from 'google-maps-react';
import { getDDMMYYYY, getDDMMYYYYHHMMSS, getDDMMYYYYHHMM, getHyphenDDMMYYYYHHMMSS } from '../common/utils';
import Modal from 'react-responsive-modal';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';

import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
// import GoogleInfoWindow from '../common/google-infowindow';
// var GoogleInfoWindow = require('../common/google-infowindow');
var moment = require('moment');
var drawingManager;
var selectedShape;
var colors = ['#1D9567', '#1692A5', '#F4D749', '#F73E3E', '#B7B3B3'];
var selectedColor;
var colorButtons = {};
var fencingLength = 0;
var mapZoom = 12;
var coordinates = [];
var viewMarkersArr = [];
var coords_copy = [];
var geoFencingArea;
var markersArray;
var map;
var route_deviation_polyline_dict = { 1: "route_deviation_polyline_1", 2: "route_deviation_polyline_2", 3: "route_deviation_polyline_3" }
var pathcolor = ["#157254", "blue", "red"];
var liveRouteColor = ""
class Mapcard2 extends Component {
	constructor(props) {
		super(props);
		this.state = {
			mapinfo: '',
			tolls: [],
			proptruckno: '',
			defTransitCoords: '',
			activeMarker: {},
			selectedPlace: {},
			dealerCode: '',
			timelinesmarkers: [],
			viewtimelinemarkers: false,
			viewgoogleroutes: true,
			googleroutepath: [],
			loadshow: 'show-n',
			open: false,
			modules: AllModules,
			defaultColDef: {
				sortable: true,
				filter: true,
				resizable: true,
				menuTabs: ['filterMenuTab']

			},
			rowData: null,

			rowModelhighchartoptionsType: 'enterprise',
			rowGroupPanelShow: 'always',
			overlayNoRowsTemplate: "",
			frameworkComponents: {
				// customLoadingCellRenderer: CustomLoadingCellRenderer
			},

			animateRows: true,
			debug: true,
			showToolPanel: false,
			uppressAggFuncInHeader: true,

			childconfs: [],
			childrow: [],
			rowModelType: "serverSide",
			paginationPageSize: 50,
			cacheBlockSize: 10,
			maxBlocksInCache: 1,
			onClickdownload: false,
			showMarkers: 0,
			statusBar: {
				statusPanels: [
					{
						statusPanel: "agTotalAndFilteredRowCountComponent",
						align: "left"
					},
					{
						statusPanel: "agTotalRowCountComponent",
						align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
			},
			sideBar: {
				toolPanels: [
					{
						id: "columns",
						labelDefault: "Columns",
						labelKey: "columns",
						iconKey: "columns",
						toolPanel: "agColumnsToolPanel"
					},
					{
						id: "filters",
						labelDefault: "Filters",
						labelKey: "filters",
						iconKey: "filter",
						toolPanel: "agFiltersToolPanel"
					}
				]
			},
			detailCellRendererParams: {},
			rowClassRules: {
				"highlitRow": "data.transit_delay == 1",
				"green": "data.status >= 3"

			},
			data_type: "",
			selectedorigin: "",
			selecteddestination: "",
			returnTripsDashboardRowData: [],
			isclick: false
		};
		this.plotMultiColorPolyline = this.plotMultiColorPolyline.bind(this)
	}
	componentDidMount() {
		this.renderMap()
		this.renderMapKey()
	}
	componentDidUpdate(prevProps) {
		if (prevProps.truckno !== this.props.truckno) {
			this.renderMap();
		}
	}

	renderMapKey = () => {
		const script = document.createElement('script');
		script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyDOkvMDXyKxdnXp4K-QMNIdjw_dgpzebo8&libraries=places,drawing&callback=initMap`;
		script.defer = true;
		script.async = true;
		document.head.appendChild(script);
	}
	renderMap = () => {
		window.initMap = this.initMap
	}
	onGridReady = params => {
		this.gridApi = params.api;

		//console.log("Grid is: ", params);
		this.gridColumnApi = params.columnApi;
		if (this.state.deptcode == 'LOG-PRT') {
			this.gridColumnApi.setColumnsVisible(["nightdriving_exception"], false);
			this.gridColumnApi.setColumnsVisible(["loading_delay_exception"], false);
		}
		if (this.state.deptcode == 'SNDG') {
			this.gridColumnApi.setColumnsVisible(["nightdriving_exception"], true);
			this.gridColumnApi.setColumnsVisible(["loading_delay_exception"], true);
		}
	};
	onGridState = () => {
		//console.log(this.gridApi);

		/*Get  Current Columns State and Store in this.colState */
		this.colState = this.gridColumnApi.getColumnState();

		/*Get Current RowGroup Columns State and Store in this.rowGroupState */
		this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		/*Get Current Pivot Columns State and Store in this.pivotColumns, 
		 * User should enable the Pivot mode.*/
		this.pivotColumns = this.gridColumnApi.getPivotColumns();

		/*Get Current Filter State and Store in window.filterState */
		window.filterState = this.gridApi.getFilterModel();
		this.gridApi.setFilterModel(window.filterState);
	}
	plotMultiColorPolyline(arr2, map, bounds, baseroute = false) {
		if (arr2.length) {
			var data_type = ""
			var data_type_list = [...new Set(arr2.map(item => item.type))];
			if (data_type_list.includes("transshipment")) {
				data_type = "transshipment"
			}
			if (data_type_list.includes("rake")) {
				data_type = "rake"
			}
			this.setState({
				data_type: data_type
			})
		}
		if (this.props.stepcoords == undefined) {
			if (arr2.length <= 1000) {
				var icon_cnt = 60;
				var icon_divisible = 60;
			}
			else {
				var icon_cnt = 500;
				var icon_divisible = 500;
			}
		}
		else {
			var icon_cnt = 1;
			var icon_divisible = 1;
		}
		//console.log(arr2, "coords")

		for (var i = 0; i < arr2.length - 1; i++) {
			var locationLatLng = [];
			locationLatLng.push(new window.google.maps.LatLng(arr2[i].lat, arr2[i].lng));
			locationLatLng.push(new window.google.maps.LatLng(arr2[i + 1].lat, arr2[i + 1].lng));
			if (icon_cnt % icon_divisible == 0) {
				var lineicon = [{
					icon: {
						path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
						strokeColor: '#157254',
						fillColor: '#157254',
						fillOpacity: 1,
						strokeWeight: 2
					},
					repeat: '100px',
					path: []
				}];
			} else { lineicon = [] }
			icon_cnt = icon_cnt + 1
			var consignments_missing_route_line = new window.google.maps.Polyline({
				map: map,
				path: locationLatLng,
				strokeColor: '#157254',
				strokeOpacity: 1.0,
				strokeWeight: 2.5,
				icons: lineicon
			});
			liveRouteColor = "#157254";
			if (arr2[i].msgtype == "G") {
				consignments_missing_route_line.setOptions({
					strokeColor: "#452a68",
					strokeWeight: 5.5,
				})
				liveRouteColor = "#452a68"
			}

			if (arr2[i].set == undefined && arr2[i].msgtype != "G") {
				if (!baseroute) {
					consignments_missing_route_line.setOptions({
						strokeColor: "#157254"
					})
					liveRouteColor = "#157254"
				}
				else {
					consignments_missing_route_line.setOptions({
						strokeColor: "#157254"
					})
				}

			}
			else if (arr2[i].set == 1 && arr2[i].msgtype != "G") {
				consignments_missing_route_line.setOptions({
					strokeColor: "#157254"
				})
				liveRouteColor = "#157254"
			}
			else if (arr2[i].set == 2 && arr2[i].msgtype != "G") {
				consignments_missing_route_line.setOptions({
					strokeColor: "#0000ff",
					// strokeWeight: 5.5,
				})
				liveRouteColor = "#0000ff"
			}
			else if (arr2[i].set == 3 && arr2[i].msgtype != "G") {
				consignments_missing_route_line.setOptions({
					strokeColor: "#980000",
					// strokeWeight: 5.5,
				});
				liveRouteColor = "#980000"
			}
			bounds.extend(new window.google.maps.LatLng(arr2[i].lat, arr2[i].lng));
		}
	}

	initMap = () => {
		var currentwindow;
		var dealer = '';
		var dealers = [];
		var tolls = this.props.tolls;
		var chokepoints = this.props.chokepoints;
		var baseRouteCoords = []
		this.setState({
			dealerCode: this.props.dealer
		});
		var arr = [];
		if (this.props.baseRouteCoords != undefined) {
			baseRouteCoords = this.props.baseRouteCoords;
		}
		if (this.props.mapFor == 'truck') {
			console.log('291')
			var jsondata = this.props.mapinfo;
			console.log(jsondata,'293')
			var locations = jsondata.breaks;
			var arr = jsondata
			console.log(arr,'295')
			coords_copy = arr;
			var routeinfo = this.props.data
			var dataarr = this.props.mapData
			console.log(dataarr,"dataarr")

			dealers = jsondata.consignee_coordinates;
		}
		if (this.props.mapFor == 'intransit'){
			console.log('291')
			var jsondata = this.props.mapinfo.coords;
			console.log(jsondata,'293')
			var locations = jsondata.breaks;
			var arr = jsondata
			console.log(arr,'295')
			coords_copy = arr;
			var routeinfo = this.props.data
			var dataarr = this.props.mapData.coords
			console.log(dataarr,"dataarr")

			dealers = jsondata.consignee_coordinates;
		}
		if (this.props.mapFor == 'truckgps') {
			var jsondata = this.props.mapinfo;
			// var locations = jsondata.breaks;
			var arr = jsondata;
			coords_copy = arr;
			var routeinfo = this.props.data
			dealers = jsondata.consignee_coordinates;
		}
		//console.log(locations,"breaks")
		console.log("arr ",arr)
		if (arr == undefined) {
			var lt = 28.4519751;
			var ln = 77.0310713;
		}
		else {
			if (arr.length > 0 && typeof arr.length != undefined) {
				if (this.props.mapFor == 'ticket' && this.props.consignercords != undefined) {
					var lt = this.props.consignercords.lat;
					var ln = this.props.consignercords.lng;
				}
				else {
					var lt = arr[0].lat;
					var ln = arr[0].lng;
				}
			}
			else {
				if (this.props.defTransitCoords == '' || typeof this.props.defTransitCoords == undefined) {
					var lt = 21.141058047268885;
					var ln = 72.65782317936426;

				}
				else {
					try {
						var lt = JSON.parse(this.props.defTransitCoords).lat;
						var ln = JSON.parse(this.props.defTransitCoords).lng;
					}
					catch (e) {
						var lt = 21.141058047268885;
						var ln = 72.65782317936426;
					}

				}

			}
		}
		try {
			var mapOptions = {
				zoom: 13,
				zoomControl: true,
				mapTypeControl: true,
				scaleControl: true,
				streetViewControl: true,
				rotateControl: true,
				fullscreenControl: true,
				labels: true,
				mapTypeControlOptions: {
					mapTypeIds: ['satellite', 'roadmap'],
				},
				center: new window.google.maps.LatLng(lt, ln),
				// mapTypeId: window.google.maps.MapTypeId.ROADMAP
			};

		}
		catch (e) {
			var mapOptions = {
				zoom: 13,
				zoomControl: true,
				mapTypeControl: true,
				scaleControl: true,
				streetViewControl: true,
				rotateControl: true,
				fullscreenControl: true,
				labels: true,
				mapTypeControlOptions: {
					mapTypeIds: ['satellite', 'roadmap'],
				},
				center: new window.google.maps.LatLng(28.4519751, 77.0310713),
				// mapTypeId: window.google.maps.MapTypeId.ROADMAP
			};

		}
		try {
			console.log(mapOptions, "mapOptions--614")
			map = new window.google.maps.Map(document.getElementById('map'), mapOptions);
		} catch (e) { }

		var bounds = new window.google.maps.LatLngBounds();
		bounds.extend(new window.google.maps.LatLng(lt, ln));
		// Create our info window content
		var currentinfowindow = null;
		var line = new window.google.maps.Polyline(
			{
				map: map,
				strokeColor: '#157254',
				strokeOpacity: 1.0,
				strokeWeight: 2.5,
				icons: [{
					icon: {
						path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
						strokeColor: '#ff8c52',
						fillColor: '#ff8c52',
						fillOpacity: 1,
						strokeWeight: 2
					},
					repeat: '100px',
					path: []
				}]
			});
		if (arr == undefined) {

		}
		else {
			try {
				if (arr.length > 0) {
					var allpoints = [];
					var arr1 = arr;
					var arr2 = arr;


					if (this.props.mapFor == 'truck') {
						console.log('s1')
						var data = arr
						
						this.plotMultiColorPolyline(arr, map, bounds)
						// console.log(baseRouteCoords,"baseRouteCoords---3263")
						this.plotMultiColorPolyline(baseRouteCoords, map, bounds, true)
						var boundaries = this.props.boundaries

						markersArray = arr;
						var infowindow = new window.google.maps.InfoWindow();
						var marker, l;
						/*START ICON*/
						console.log(arr,'431')
						console.log(arr[0],'432')
						marker = new window.google.maps.Marker({
							position: new window.google.maps.LatLng(arr[0].lat, arr[0].lng),
							title:'Truck No.:'+dataarr[0].truck_no,
							icon: require('../../assets/icons/track_start.png'),
							map: map,
						});
						window.google.maps.event.addListener(marker, 'click', (function (marker) {
							return function () {
								//	console.log(clusters[0])
								var contentarr = []
								console.log(data[0], '470')
								var header = 'Start Point'
								contentarr.push({ "key": "Truck No", "value": dataarr[0].truck_no})
								contentarr.push({ "key": "Address", "value": dataarr[0].area})

								contentarr.push({ "key": "Start at", "value": getHyphenDDMMYYYYHHMMSS(dataarr[0].stime) })

								console.log(contentarr, '474')
								var contentString = GoogleInfoWindow(marker.icon, header, contentarr, '')
								infowindow.setContent(contentString);
								currentwindow = infowindow;
								infowindow.open(map, marker);
								//console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}');
							}
						})(marker));
						try {
							marker = new window.google.maps.Marker({
								position: new window.google.maps.LatLng(arr[(arr.length - 1)].lat, arr[(arr.length - 1)].lng),
								icon: require('../../assets/icons/truck-end.png'),
								map: map,
							});
							window.google.maps.event.addListener(marker, 'click', (function (marker) {
								return function () {
									//	console.log(clusters[0])
									var contentarr = []
									//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> End Point</B></p><p>End at "+routeinfo.end_time+"</p>";
									var header = "End Point"
									contentarr.push({ "key": "Truck No", "value": dataarr[dataarr.length - 1].truck_no})
									contentarr.push({ "key": "Address", "value": dataarr[dataarr.length - 1].area})
									contentarr.push({ "key": "End at ", "value": getHyphenDDMMYYYYHHMMSS(dataarr[dataarr.length - 1].stime) });
									console.log("jsondata ", jsondata.trucksdetails);
									try {
										// console.log(jsondata.trucksdetails)
										if (jsondata.trucksdetails != undefined) {
											if (jsondata.trucksdetails.length > 0) {
												contentarr.push({ "key": "Address ", "value": jsondata.trucksdetails[0].last_known_address });
											}

										}
									}
									catch (e) {

									}
									var contentString = GoogleInfoWindow(marker.icon, header, contentarr)

									infowindow.setContent(contentString);
									currentwindow = infowindow;
									infowindow.open(map, marker);
									// console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}');
								}
							})(marker));
						}
						catch (e) { }
						try {
							var drop_coordinates = this.props.drop_coordinates;
							console.log(drop_coordinates, "step-1")
							if (drop_coordinates != undefined && drop_coordinates != "") {
								drop_coordinates = JSON.parse(drop_coordinates);
								marker = new window.google.maps.Marker({
									position: new window.google.maps.LatLng(drop_coordinates.lat, drop_coordinates.lng),
									icon: require('../../assets/icons/finish-flag.png'),
									map: map,
								});
								window.google.maps.event.addListener(marker, 'click', (function (marker) {
									return function () {
										var contentarr = []
										var header = "Unloading Point"
										contentarr.push({ "key": "End at ", "value": getDDMMYYYYHHMMSS(routeinfo.end_time) });

										try {
											// console.log(jsondata.trucksdetails)
											if (this.props.unloading_address != undefined) {
												contentarr.push({ "key": "Address ", "value": this.props.unloading_address });
											}
										}
										catch (e) {

										}
										var contentString = GoogleInfoWindow(marker.icon, header, contentarr)

										infowindow.setContent(contentString);
										currentwindow = infowindow;
										infowindow.open(map, marker);
										// console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}');
									}
								})(marker));
							}
						}
						catch (e) { console.log(e, "error at unloading point") }

						var groutepath = this.state.googleroutepath;
						if (groutepath.length > 0) {
							var lineg = new window.google.maps.Polyline(
								{
									map: map,
									strokeColor: '#157254',
									strokeOpacity: 1.0,
									strokeWeight: 2.5,
									icons: [{
										icon: {
											path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
											strokeColor: '#157254',
											fillColor: '#157254',
											fillOpacity: 1,
											strokeWeight: 2
										},
										repeat: '100px',
										path: []
									}]
								});
							// console.log("groutepath ", groutepath)
							for (let g = 0; g < groutepath.length; g++) {
								var pathg = lineg.getPath().getArray();

								let latLng = new window.google.maps.LatLng(groutepath[g].lat, groutepath[g].lng);
								pathg.push(latLng);
								lineg.setPath(pathg);
								//console.log("path ", pathg)
								//Change line color based on map type
								window.google.maps.event.addListener(map, 'maptypeid_changed', function () {

									var x = map.getZoom();
									var c = map.getCenter();
									window.google.maps.event.trigger(map, 'resize');
									map.setZoom(x);
									map.setCenter(c);

									var pathg = lineg.getPath().getArray();
									let latLng = new window.google.maps.LatLng(groutepath[g].lat, groutepath[g].lng);
									pathg.push(latLng);
									lineg.setPath(pathg);
								});

								bounds.extend(new window.google.maps.LatLng(groutepath[g].lat, groutepath[g].lng));
							}
						}
					}

					// TRUCK MAP
					if (this.props.mapFor == 'truck') {
						this.plotMultiColorPolyline(arr, map, bounds)
						// console.log(baseRouteCoords,"baseRouteCoords---3263")
						this.plotMultiColorPolyline(baseRouteCoords, map, bounds, true)
						var boundaries = this.props.boundaries

						markersArray = arr2;
						var infowindow = new window.google.maps.InfoWindow();
						var marker, l;

						for (l = 0; l < locations.length; l++) {
							marker = new window.google.maps.Marker({
								position: new window.google.maps.LatLng(locations[l].lat, locations[l].lng),
								icon: require('../../assets/icons/cf.png'),
								map: map,
							});

							window.google.maps.event.addListener(marker, 'click', (function (marker, l) {
								return function () {
									//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Break - "+(l+1)+"</B></p><p>Break time "+locations[l].break_start+" - "+locations[l].break_end+" ("+timeConvert(locations[l].break_time_seconds)+")</p>";
									var contentarr = []
									var header = "Break - " + (l + 1)
									var break_start = locations[l].break_start;
									if (break_start.includes(".") == true) {
										break_start = break_start.split(".")[0];
									}
									var break_end = locations[l].break_end;
									if (break_end.includes(".") == true) {
										break_end = break_end.split(".")[0];
									}
									contentarr.push({ "key": "Break Start", "value": break_start })
									contentarr.push({ "key": "Break End", "value": break_end })
									contentarr.push({ "key": "Break time", "value": timeConvert(locations[l].break_time_seconds) })

									var contentString = GoogleInfoWindow(marker.icon, header, contentarr)
									infowindow.setContent(contentString);
									currentwindow = infowindow;
									infowindow.open(map, marker);
									//console.log(marker.position.toJSON());
									//console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}');
								}
							})(marker, l));
						}

						/*START ICON*/
						console.log(arr, 'arrrrrrrrrrrr')
						marker = new window.google.maps.Marker({
							position: new window.google.maps.LatLng(arr[0].lat, arr[0].lng),
							icon: require('../../assets/icons/track_start.png'),
							map: map,
						});
						window.google.maps.event.addListener(marker, 'click', (function (marker) {
							return function () {
								//	console.log(clusters[0])
								var contentarr = []
								//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
								var header = "Starting Point"
								contentarr.push({ "key": "Started at ", "value": getDDMMYYYYHHMMSS(routeinfo.start_time) })
								var contentString = GoogleInfoWindow(marker.icon, header, contentarr, '')


								infowindow.setContent(contentString);
								currentwindow = infowindow;
								infowindow.open(map, marker);
								//console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}');
							}
						})(marker));
						try {
							marker = new window.google.maps.Marker({
								position: new window.google.maps.LatLng(arr[(arr.length - 1)].lat, arr[(arr.length - 1)].lng),
								icon: require('../../assets/icons/truck-end.png'),
								map: map,
							});
							window.google.maps.event.addListener(marker, 'click', (function (marker) {
								return function () {
									//	console.log(clusters[0])
									var contentarr = []
									//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> End Point</B></p><p>End at "+routeinfo.end_time+"</p>";
									var header = "End Point"
									contentarr.push({ "key": "End at ", "value": getDDMMYYYYHHMMSS(routeinfo.end_time) });
									console.log("jsondata ", jsondata.trucksdetails);
									try {
										// console.log(jsondata.trucksdetails)
										if (jsondata.trucksdetails != undefined) {
											if (jsondata.trucksdetails.length > 0) {
												contentarr.push({ "key": "Address ", "value": jsondata.trucksdetails[0].last_known_address });
											}

										}
									}
									catch (e) {

									}
									var contentString = GoogleInfoWindow(marker.icon, header, contentarr)

									infowindow.setContent(contentString);
									currentwindow = infowindow;
									infowindow.open(map, marker);
									// console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}');
								}
							})(marker));
						}
						catch (e) { }
						try {
							var drop_coordinates = this.props.drop_coordinates;
							console.log(drop_coordinates, "step-1")
							if (drop_coordinates != undefined && drop_coordinates != "") {
								drop_coordinates = JSON.parse(drop_coordinates);
								marker = new window.google.maps.Marker({
									position: new window.google.maps.LatLng(drop_coordinates.lat, drop_coordinates.lng),
									icon: require('../../assets/icons/finish-flag.png'),
									map: map,
								});
								window.google.maps.event.addListener(marker, 'click', (function (marker) {
									return function () {
										var contentarr = []
										var header = "Unloading Point"
										contentarr.push({ "key": "End at ", "value": getDDMMYYYYHHMMSS(routeinfo.end_time) });

										try {
											// console.log(jsondata.trucksdetails)
											if (this.props.unloading_address != undefined) {
												contentarr.push({ "key": "Address ", "value": this.props.unloading_address });
											}
										}
										catch (e) {

										}
										var contentString = GoogleInfoWindow(marker.icon, header, contentarr)

										infowindow.setContent(contentString);
										currentwindow = infowindow;
										infowindow.open(map, marker);
										// console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}');
									}
								})(marker));
							}
						}
						catch (e) { console.log(e, "error at unloading point") }

						var groutepath = this.state.googleroutepath;
						if (groutepath.length > 0) {
							var lineg = new window.google.maps.Polyline(
								{
									map: map,
									strokeColor: '#157254',
									strokeOpacity: 1.0,
									strokeWeight: 2.5,
									icons: [{
										icon: {
											path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
											strokeColor: '#157254',
											fillColor: '#157254',
											fillOpacity: 1,
											strokeWeight: 2
										},
										repeat: '100px',
										path: []
									}]
								});
							// console.log("groutepath ", groutepath)
							for (let g = 0; g < groutepath.length; g++) {
								var pathg = lineg.getPath().getArray();

								let latLng = new window.google.maps.LatLng(groutepath[g].lat, groutepath[g].lng);
								pathg.push(latLng);
								lineg.setPath(pathg);
								//console.log("path ", pathg)
								//Change line color based on map type
								window.google.maps.event.addListener(map, 'maptypeid_changed', function () {

									var x = map.getZoom();
									var c = map.getCenter();
									window.google.maps.event.trigger(map, 'resize');
									map.setZoom(x);
									map.setCenter(c);

									var pathg = lineg.getPath().getArray();
									let latLng = new window.google.maps.LatLng(groutepath[g].lat, groutepath[g].lng);
									pathg.push(latLng);
									lineg.setPath(pathg);
								});

								bounds.extend(new window.google.maps.LatLng(groutepath[g].lat, groutepath[g].lng));
							}
						}
					}

					if (this.props.mapFor == 'intransit') {
						console.log('s1')
						var data = arr
						
						this.plotMultiColorPolyline(arr, map, bounds)
						// console.log(baseRouteCoords,"baseRouteCoords---3263")
						this.plotMultiColorPolyline(baseRouteCoords, map, bounds, true)
						var boundaries = this.props.boundaries

						markersArray = arr;
						var infowindow = new window.google.maps.InfoWindow();
						var marker, l;
						/*START ICON*/
						console.log(arr,'431')
						console.log(arr[0],'432')
						marker = new window.google.maps.Marker({
							position: new window.google.maps.LatLng(arr[0].lat, arr[0].lng),
							title:'Truck No.:'+dataarr[0].truck_no,
							icon: require('../../assets/icons/track_start.png'),
							map: map,
						});
						window.google.maps.event.addListener(marker, 'click', (function (marker) {
							return function () {
								//	console.log(clusters[0])
								var contentarr = []
								console.log(data[0], '470')
								var header = 'Start Point'
								contentarr.push({ "key": "Truck No", "value": dataarr[0].truck_no})
								contentarr.push({ "key": "Address", "value": dataarr[0].area})

								contentarr.push({ "key": "Start at", "value": getHyphenDDMMYYYYHHMMSS(dataarr[0].stime) })

								console.log(contentarr, '474')
								var contentString = GoogleInfoWindow(marker.icon, header, contentarr, '')
								infowindow.setContent(contentString);
								currentwindow = infowindow;
								infowindow.open(map, marker);
								//console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}');
							}
						})(marker));
						try {
							marker = new window.google.maps.Marker({
								position: new window.google.maps.LatLng(arr[(arr.length - 1)].lat, arr[(arr.length - 1)].lng),
								icon: require('../../assets/icons/truck-end.png'),
								map: map,
							});
							window.google.maps.event.addListener(marker, 'click', (function (marker) {
								return function () {
									//	console.log(clusters[0])
									var contentarr = []
									//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> End Point</B></p><p>End at "+routeinfo.end_time+"</p>";
									var header = "End Point"
									contentarr.push({ "key": "Truck No", "value": dataarr[dataarr.length - 1].truck_no})
									contentarr.push({ "key": "Address", "value": dataarr[dataarr.length - 1].area})
									contentarr.push({ "key": "End at ", "value": getHyphenDDMMYYYYHHMMSS(dataarr[dataarr.length - 1].stime) });
									console.log("jsondata ", jsondata.trucksdetails);
									try {
										// console.log(jsondata.trucksdetails)
										if (jsondata.trucksdetails != undefined) {
											if (jsondata.trucksdetails.length > 0) {
												contentarr.push({ "key": "Address ", "value": jsondata.trucksdetails[0].last_known_address });
											}

										}
									}
									catch (e) {

									}
									var contentString = GoogleInfoWindow(marker.icon, header, contentarr)

									infowindow.setContent(contentString);
									currentwindow = infowindow;
									infowindow.open(map, marker);
									// console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}');
								}
							})(marker));
						}
						catch (e) { }
						try {
							var drop_coordinates = this.props.drop_coordinates;
							console.log(drop_coordinates, "step-1")
							if (drop_coordinates != undefined && drop_coordinates != "") {
								drop_coordinates = JSON.parse(drop_coordinates);
								marker = new window.google.maps.Marker({
									position: new window.google.maps.LatLng(drop_coordinates.lat, drop_coordinates.lng),
									icon: require('../../assets/icons/finish-flag.png'),
									map: map,
								});
								window.google.maps.event.addListener(marker, 'click', (function (marker) {
									return function () {
										var contentarr = []
										var header = "Unloading Point"
										contentarr.push({ "key": "End at ", "value": getDDMMYYYYHHMMSS(routeinfo.end_time) });

										try {
											// console.log(jsondata.trucksdetails)
											if (this.props.unloading_address != undefined) {
												contentarr.push({ "key": "Address ", "value": this.props.unloading_address });
											}
										}
										catch (e) {

										}
										var contentString = GoogleInfoWindow(marker.icon, header, contentarr)

										infowindow.setContent(contentString);
										currentwindow = infowindow;
										infowindow.open(map, marker);
										// console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}');
									}
								})(marker));
							}
						}
						catch (e) { console.log(e, "error at unloading point") }

						var groutepath = this.state.googleroutepath;
						if (groutepath.length > 0) {
							var lineg = new window.google.maps.Polyline(
								{
									map: map,
									strokeColor: '#157254',
									strokeOpacity: 1.0,
									strokeWeight: 2.5,
									icons: [{
										icon: {
											path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
											strokeColor: '#157254',
											fillColor: '#157254',
											fillOpacity: 1,
											strokeWeight: 2
										},
										repeat: '100px',
										path: []
									}]
								});
							// console.log("groutepath ", groutepath)
							for (let g = 0; g < groutepath.length; g++) {
								var pathg = lineg.getPath().getArray();

								let latLng = new window.google.maps.LatLng(groutepath[g].lat, groutepath[g].lng);
								pathg.push(latLng);
								lineg.setPath(pathg);
								//console.log("path ", pathg)
								//Change line color based on map type
								window.google.maps.event.addListener(map, 'maptypeid_changed', function () {

									var x = map.getZoom();
									var c = map.getCenter();
									window.google.maps.event.trigger(map, 'resize');
									map.setZoom(x);
									map.setCenter(c);

									var pathg = lineg.getPath().getArray();
									let latLng = new window.google.maps.LatLng(groutepath[g].lat, groutepath[g].lng);
									pathg.push(latLng);
									lineg.setPath(pathg);
								});

								bounds.extend(new window.google.maps.LatLng(groutepath[g].lat, groutepath[g].lng));
							}
						}
					}

					// TRUCK MAP
					if (this.props.mapFor == 'intransit') {
						this.plotMultiColorPolyline(arr, map, bounds)
						// console.log(baseRouteCoords,"baseRouteCoords---3263")
						this.plotMultiColorPolyline(baseRouteCoords, map, bounds, true)
						var boundaries = this.props.boundaries

						markersArray = arr2;
						var infowindow = new window.google.maps.InfoWindow();
						var marker, l;

						for (l = 0; l < locations.length; l++) {
							marker = new window.google.maps.Marker({
								position: new window.google.maps.LatLng(locations[l].lat, locations[l].lng),
								icon: require('../../assets/icons/cf.png'),
								map: map,
							});

							window.google.maps.event.addListener(marker, 'click', (function (marker, l) {
								return function () {
									//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Break - "+(l+1)+"</B></p><p>Break time "+locations[l].break_start+" - "+locations[l].break_end+" ("+timeConvert(locations[l].break_time_seconds)+")</p>";
									var contentarr = []
									var header = "Break - " + (l + 1)
									var break_start = locations[l].break_start;
									if (break_start.includes(".") == true) {
										break_start = break_start.split(".")[0];
									}
									var break_end = locations[l].break_end;
									if (break_end.includes(".") == true) {
										break_end = break_end.split(".")[0];
									}
									contentarr.push({ "key": "Break Start", "value": break_start })
									contentarr.push({ "key": "Break End", "value": break_end })
									contentarr.push({ "key": "Break time", "value": timeConvert(locations[l].break_time_seconds) })

									var contentString = GoogleInfoWindow(marker.icon, header, contentarr)
									infowindow.setContent(contentString);
									currentwindow = infowindow;
									infowindow.open(map, marker);
									//console.log(marker.position.toJSON());
									//console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}');
								}
							})(marker, l));
						}

						/*START ICON*/
						console.log(arr, 'arrrrrrrrrrrr')
						marker = new window.google.maps.Marker({
							position: new window.google.maps.LatLng(arr[0].lat, arr[0].lng),
							icon: require('../../assets/icons/track_start.png'),
							map: map,
						});
						window.google.maps.event.addListener(marker, 'click', (function (marker) {
							return function () {
								//	console.log(clusters[0])
								var contentarr = []
								//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
								var header = "Starting Point"
								contentarr.push({ "key": "Started at ", "value": getDDMMYYYYHHMMSS(routeinfo.start_time) })
								var contentString = GoogleInfoWindow(marker.icon, header, contentarr, '')


								infowindow.setContent(contentString);
								currentwindow = infowindow;
								infowindow.open(map, marker);
								//console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}');
							}
						})(marker));
						try {
							marker = new window.google.maps.Marker({
								position: new window.google.maps.LatLng(arr[(arr.length - 1)].lat, arr[(arr.length - 1)].lng),
								icon: require('../../assets/icons/truck-end.png'),
								map: map,
							});
							window.google.maps.event.addListener(marker, 'click', (function (marker) {
								return function () {
									//	console.log(clusters[0])
									var contentarr = []
									//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> End Point</B></p><p>End at "+routeinfo.end_time+"</p>";
									var header = "End Point"
									contentarr.push({ "key": "End at ", "value": getDDMMYYYYHHMMSS(routeinfo.end_time) });
									console.log("jsondata ", jsondata.trucksdetails);
									try {
										// console.log(jsondata.trucksdetails)
										if (jsondata.trucksdetails != undefined) {
											if (jsondata.trucksdetails.length > 0) {
												contentarr.push({ "key": "Address ", "value": jsondata.trucksdetails[0].last_known_address });
											}

										}
									}
									catch (e) {

									}
									var contentString = GoogleInfoWindow(marker.icon, header, contentarr)

									infowindow.setContent(contentString);
									currentwindow = infowindow;
									infowindow.open(map, marker);
									// console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}');
								}
							})(marker));
						}
						catch (e) { }
						try {
							var drop_coordinates = this.props.drop_coordinates;
							console.log(drop_coordinates, "step-1")
							if (drop_coordinates != undefined && drop_coordinates != "") {
								drop_coordinates = JSON.parse(drop_coordinates);
								marker = new window.google.maps.Marker({
									position: new window.google.maps.LatLng(drop_coordinates.lat, drop_coordinates.lng),
									icon: require('../../assets/icons/finish-flag.png'),
									map: map,
								});
								window.google.maps.event.addListener(marker, 'click', (function (marker) {
									return function () {
										var contentarr = []
										var header = "Unloading Point"
										contentarr.push({ "key": "End at ", "value": getDDMMYYYYHHMMSS(routeinfo.end_time) });

										try {
											// console.log(jsondata.trucksdetails)
											if (this.props.unloading_address != undefined) {
												contentarr.push({ "key": "Address ", "value": this.props.unloading_address });
											}
										}
										catch (e) {

										}
										var contentString = GoogleInfoWindow(marker.icon, header, contentarr)

										infowindow.setContent(contentString);
										currentwindow = infowindow;
										infowindow.open(map, marker);
										// console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}');
									}
								})(marker));
							}
						}
						catch (e) { console.log(e, "error at unloading point") }

						var groutepath = this.state.googleroutepath;
						if (groutepath.length > 0) {
							var lineg = new window.google.maps.Polyline(
								{
									map: map,
									strokeColor: '#157254',
									strokeOpacity: 1.0,
									strokeWeight: 2.5,
									icons: [{
										icon: {
											path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
											strokeColor: '#157254',
											fillColor: '#157254',
											fillOpacity: 1,
											strokeWeight: 2
										},
										repeat: '100px',
										path: []
									}]
								});
							// console.log("groutepath ", groutepath)
							for (let g = 0; g < groutepath.length; g++) {
								var pathg = lineg.getPath().getArray();

								let latLng = new window.google.maps.LatLng(groutepath[g].lat, groutepath[g].lng);
								pathg.push(latLng);
								lineg.setPath(pathg);
								//console.log("path ", pathg)
								//Change line color based on map type
								window.google.maps.event.addListener(map, 'maptypeid_changed', function () {

									var x = map.getZoom();
									var c = map.getCenter();
									window.google.maps.event.trigger(map, 'resize');
									map.setZoom(x);
									map.setCenter(c);

									var pathg = lineg.getPath().getArray();
									let latLng = new window.google.maps.LatLng(groutepath[g].lat, groutepath[g].lng);
									pathg.push(latLng);
									lineg.setPath(pathg);
								});

								bounds.extend(new window.google.maps.LatLng(groutepath[g].lat, groutepath[g].lng));
							}
						}
					}
					if (this.props.mapFor == 'truckgps') {
						
						this.plotMultiColorPolyline(arr, map, bounds)
						// console.log(baseRouteCoords,"baseRouteCoords---3263")
						this.plotMultiColorPolyline(baseRouteCoords, map, bounds, true)
						var boundaries = this.props.boundaries
						var mapData = this.props.mapData
						markersArray = arr2;
						var infowindow = new window.google.maps.InfoWindow();
						var marker, l;
						/*START ICON*/
						marker = new window.google.maps.Marker({
							position: new window.google.maps.LatLng(arr[0].lat, arr[0].lng),
							icon: require('../../assets/icons/track_start.png'),
							map: map,
						});
						window.google.maps.event.addListener(marker, 'click', (function (marker) {
							return function () {
								var contentarr = []
								var header = "Starting Point"
								contentarr.push({ "key": "Started at ", "value": getHyphenDDMMYYYYHHMMSS(mapData[0].stime) })
								contentarr.push({ "key": "Address ", "value": mapData[0].area });
								var contentString = GoogleInfoWindow(marker.icon, header, contentarr, '')
								infowindow.setContent(contentString);
								currentwindow = infowindow;
								infowindow.open(map, marker);
								//console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}');
							}
						})(marker));
						try {
							marker = new window.google.maps.Marker({
								position: new window.google.maps.LatLng(arr[(arr.length - 1)].lat, arr[(arr.length - 1)].lng),
								icon: require('../../assets/icons/truck-end.png'),
								map: map,
							});
							console.log(data,'dataa808')
							window.google.maps.event.addListener(marker, 'click', (function (marker) {
								return function () {
									//	console.log(clusters[0])
									var contentarr = []
									//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> End Point</B></p><p>End at "+routeinfo.end_time+"</p>";
									var header = "End Point"
									contentarr.push({ "key": "End at ", "value": getHyphenDDMMYYYYHHMMSS(mapData[mapData.length - 1].stime) });
									contentarr.push({ "key": "Address ", "value": mapData[mapData.length - 1].area });

									console.log("jsondata ", contentarr);
									// try {
									// 	// console.log(jsondata.trucksdetails)
									// 	if (jsondata.trucksdetails != undefined) {
									// 		if (jsondata.trucksdetails.length > 0) {
									// 			contentarr.push({ "key": "Address ", "value": jsondata.trucksdetails[0].last_known_address });
									// 		}

									// 	}
									// }
									// catch (e) {

									// }
									var contentString = GoogleInfoWindow(marker.icon, header, contentarr)

									infowindow.setContent(contentString);
									currentwindow = infowindow;
									infowindow.open(map, marker);
									// console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}');
								}
							})(marker));
						}
						catch (e) { }
						try {
							var drop_coordinates = this.props.drop_coordinates;
							console.log(drop_coordinates, "step-1")
							if (drop_coordinates != undefined && drop_coordinates != "") {
								drop_coordinates = JSON.parse(drop_coordinates);
								marker = new window.google.maps.Marker({
									position: new window.google.maps.LatLng(drop_coordinates.lat, drop_coordinates.lng),
									icon: require('../../assets/icons/finish-flag.png'),
									map: map,
								});
								window.google.maps.event.addListener(marker, 'click', (function (marker) {
									return function () {
										var contentarr = []
										var header = "Unloading Point"
										contentarr.push({ "key": "End at ", "value": getDDMMYYYYHHMMSS(routeinfo.end_time) });

										try {
											// console.log(jsondata.trucksdetails)
											if (this.props.unloading_address != undefined) {
												contentarr.push({ "key": "Address ", "value": this.props.unloading_address });
											}
										}
										catch (e) {

										}
										var contentString = GoogleInfoWindow(marker.icon, header, contentarr)

										infowindow.setContent(contentString);
										currentwindow = infowindow;
										infowindow.open(map, marker);
										// console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}');
									}
								})(marker));
							}
						}
						catch (e) { console.log(e, "error at unloading point") }

						var groutepath = this.state.googleroutepath;
						if (groutepath.length > 0) {
							var lineg = new window.google.maps.Polyline(
								{
									map: map,
									strokeColor: '#157254',
									strokeOpacity: 1.0,
									strokeWeight: 2.5,
									icons: [{
										icon: {
											path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
											strokeColor: '#157254',
											fillColor: '#157254',
											fillOpacity: 1,
											strokeWeight: 2
										},
										repeat: '100px',
										path: []
									}]
								});
							// console.log("groutepath ", groutepath)
							for (let g = 0; g < groutepath.length; g++) {
								var pathg = lineg.getPath().getArray();

								let latLng = new window.google.maps.LatLng(groutepath[g].lat, groutepath[g].lng);
								pathg.push(latLng);
								lineg.setPath(pathg);
								//console.log("path ", pathg)
								//Change line color based on map type
								window.google.maps.event.addListener(map, 'maptypeid_changed', function () {

									var x = map.getZoom();
									var c = map.getCenter();
									window.google.maps.event.trigger(map, 'resize');
									map.setZoom(x);
									map.setCenter(c);

									var pathg = lineg.getPath().getArray();
									let latLng = new window.google.maps.LatLng(groutepath[g].lat, groutepath[g].lng);
									pathg.push(latLng);
									lineg.setPath(pathg);
								});

								bounds.extend(new window.google.maps.LatLng(groutepath[g].lat, groutepath[g].lng));
							}
						}
					}

					// TRUCK MAP
					if (this.props.mapFor == 'truckgps') {
						this.plotMultiColorPolyline(arr, map, bounds)
						// console.log(baseRouteCoords,"baseRouteCoords---3263")
						this.plotMultiColorPolyline(baseRouteCoords, map, bounds, true)
						var boundaries = this.props.boundaries

						markersArray = arr2;
						var infowindow = new window.google.maps.InfoWindow();
						var marker, l;

						for (l = 0; l < locations.length; l++) {
							marker = new window.google.maps.Marker({
								position: new window.google.maps.LatLng(locations[l].lat, locations[l].lng),
								icon: require('../../assets/icons/cf.png'),
								map: map,
							});

							window.google.maps.event.addListener(marker, 'click', (function (marker, l) {
								return function () {
									//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Break - "+(l+1)+"</B></p><p>Break time "+locations[l].break_start+" - "+locations[l].break_end+" ("+timeConvert(locations[l].break_time_seconds)+")</p>";
									var contentarr = []
									var header = "Break - " + (l + 1)
									var break_start = locations[l].break_start;
									if (break_start.includes(".") == true) {
										break_start = break_start.split(".")[0];
									}
									var break_end = locations[l].break_end;
									if (break_end.includes(".") == true) {
										break_end = break_end.split(".")[0];
									}
									contentarr.push({ "key": "Break Start", "value": break_start })
									contentarr.push({ "key": "Break End", "value": break_end })
									contentarr.push({ "key": "Break time", "value": timeConvert(locations[l].break_time_seconds) })

									var contentString = GoogleInfoWindow(marker.icon, header, contentarr)
									infowindow.setContent(contentString);
									currentwindow = infowindow;
									infowindow.open(map, marker);
									//console.log(marker.position.toJSON());
									//console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}');
								}
							})(marker, l));
						}

						/*START ICON*/
						console.log(arr, 'arrrrrrrrrrrr')
						marker = new window.google.maps.Marker({
							position: new window.google.maps.LatLng(arr[0].lat, arr[0].lng),
							icon: require('../../assets/icons/track_start.png'),
							map: map,
						});
						window.google.maps.event.addListener(marker, 'click', (function (marker) {
							return function () {
								//	console.log(clusters[0])
								var contentarr = []
								//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
								var header = "Starting Point"
								contentarr.push({ "key": "Started at ", "value": getDDMMYYYYHHMMSS(routeinfo.start_time) })
								var contentString = GoogleInfoWindow(marker.icon, header, contentarr, '')


								infowindow.setContent(contentString);
								currentwindow = infowindow;
								infowindow.open(map, marker);
								//console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}');
							}
						})(marker));
						try {
							marker = new window.google.maps.Marker({
								position: new window.google.maps.LatLng(arr[(arr.length - 1)].lat, arr[(arr.length - 1)].lng),
								icon: require('../../assets/icons/truck-end.png'),
								map: map,
							});
							window.google.maps.event.addListener(marker, 'click', (function (marker) {
								return function () {
									//	console.log(clusters[0])
									var contentarr = []
									//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> End Point</B></p><p>End at "+routeinfo.end_time+"</p>";
									var header = "End Point"
									contentarr.push({ "key": "End at ", "value": getDDMMYYYYHHMMSS(routeinfo.end_time) });
									console.log("jsondata ", jsondata.trucksdetails);
									try {
										// console.log(jsondata.trucksdetails)
										if (jsondata.trucksdetails != undefined) {
											if (jsondata.trucksdetails.length > 0) {
												contentarr.push({ "key": "Address ", "value": jsondata.trucksdetails[0].last_known_address });
											}

										}
									}
									catch (e) {

									}
									var contentString = GoogleInfoWindow(marker.icon, header, contentarr)

									infowindow.setContent(contentString);
									currentwindow = infowindow;
									infowindow.open(map, marker);
									// console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}');
								}
							})(marker));
						}
						catch (e) { }
						try {
							var drop_coordinates = this.props.drop_coordinates;
							console.log(drop_coordinates, "step-1")
							if (drop_coordinates != undefined && drop_coordinates != "") {
								drop_coordinates = JSON.parse(drop_coordinates);
								marker = new window.google.maps.Marker({
									position: new window.google.maps.LatLng(drop_coordinates.lat, drop_coordinates.lng),
									icon: require('../../assets/icons/finish-flag.png'),
									map: map,
								});
								window.google.maps.event.addListener(marker, 'click', (function (marker) {
									return function () {
										var contentarr = []
										var header = "Unloading Point"
										contentarr.push({ "key": "End at ", "value": getDDMMYYYYHHMMSS(routeinfo.end_time) });

										try {
											// console.log(jsondata.trucksdetails)
											if (this.props.unloading_address != undefined) {
												contentarr.push({ "key": "Address ", "value": this.props.unloading_address });
											}
										}
										catch (e) {

										}
										var contentString = GoogleInfoWindow(marker.icon, header, contentarr)

										infowindow.setContent(contentString);
										currentwindow = infowindow;
										infowindow.open(map, marker);
										// console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}');
									}
								})(marker));
							}
						}
						catch (e) { console.log(e, "error at unloading point") }

						var groutepath = this.state.googleroutepath;
						if (groutepath.length > 0) {
							var lineg = new window.google.maps.Polyline(
								{
									map: map,
									strokeColor: '#157254',
									strokeOpacity: 1.0,
									strokeWeight: 2.5,
									icons: [{
										icon: {
											path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
											strokeColor: '#157254',
											fillColor: '#157254',
											fillOpacity: 1,
											strokeWeight: 2
										},
										repeat: '100px',
										path: []
									}]
								});
							// console.log("groutepath ", groutepath)
							for (let g = 0; g < groutepath.length; g++) {
								var pathg = lineg.getPath().getArray();

								let latLng = new window.google.maps.LatLng(groutepath[g].lat, groutepath[g].lng);
								pathg.push(latLng);
								lineg.setPath(pathg);
								//console.log("path ", pathg)
								//Change line color based on map type
								window.google.maps.event.addListener(map, 'maptypeid_changed', function () {

									var x = map.getZoom();
									var c = map.getCenter();
									window.google.maps.event.trigger(map, 'resize');
									map.setZoom(x);
									map.setCenter(c);

									var pathg = lineg.getPath().getArray();
									let latLng = new window.google.maps.LatLng(groutepath[g].lat, groutepath[g].lng);
									pathg.push(latLng);
									lineg.setPath(pathg);
								});

								bounds.extend(new window.google.maps.LatLng(groutepath[g].lat, groutepath[g].lng));
							}
						}
					}



				}
				map.fitBounds(bounds)
			}
			catch (e) {

			}
		}
		try {
			if (tolls.length > 0) {
				var infowindow = new window.google.maps.InfoWindow();
				var l;
				tolls.map(function (e, index) {
					var tollMarker = new window.google.maps.Marker({
						position: new window.google.maps.LatLng(e.lat, e.lon),
						icon: require('../../assets/icons/barrier.png'),
						map: map,
					});
					window.google.maps.event.addListener(tollMarker, 'mouseover', (function (tollMarker, index) {
						return function () {
							var contentarr = []
							var header = "Toll - " + e.name
							contentarr.push({ "key": "Address", "value": e.location })
							var contentString = GoogleInfoWindow(tollMarker.icon, header, contentarr)
							infowindow.setContent(contentString);
							var currentwindow = infowindow;
							infowindow.open(map, tollMarker);
						}
					})(tollMarker, index));
					window.google.maps.event.addListener(tollMarker, 'mouseout', function () {
						infowindow.close();
					});
				})

			}
			map.fitBounds(bounds)
		} catch (e) { }


		try {
			if (chokepoints.length > 0) {
				var infowindow = new window.google.maps.InfoWindow();
				var l;
				chokepoints = chokepoints.filter(function (e) {
					return e.qualified_to_display == 1
				})
				chokepoints.map(function (e, index) {

					var tollMarker = new window.google.maps.Marker({
						position: new window.google.maps.LatLng(e.lat, e.lng),
						icon: require('../../assets/icons/cf.png'),
						map: map,
					});
					window.google.maps.event.addListener(tollMarker, 'mouseover', (function (tollMarker, index) {
						return function () {
							var contentarr = []
							var header = "Chokepoint"
							contentarr.push({ "key": "Avg. Time Waiting ", "value": e.avg_break_time_text })
							// avg_break_time_text
							if (e.near_state_border != undefined) {
								contentarr.push({ "key": "State Border ", "value": e.state_border_name })
								contentarr.push({ "key": "Choke Point Type", "value": "State Border" })
								contentarr.push({ "key": "Distance From State Border ", "value": e.distance_from_state_border })

							}
							if (e.near_toll != undefined) {
								contentarr.push({ "key": "toll_name", "value": e.toll_name })
								contentarr.push({ "key": "Choke Point Type", "value": "Toll" })
							}
							var contentString = GoogleInfoWindow(tollMarker.icon, header, contentarr)
							infowindow.setContent(contentString);
							var currentwindow = infowindow;
							infowindow.open(map, tollMarker);
						}
					})(tollMarker, index));
					window.google.maps.event.addListener(tollMarker, 'mouseout', function () {
						infowindow.close();
					});
				})

			}
			map.fitBounds(bounds)
		} catch (e) { }

		try {
			setTimeout(() => {
				// console.log("Zoom here ",map.getZoom())
				try {
					if (map.getZoom() > 20) {
						map.setZoom(map.getZoom() - 6);
					}
					else {
						map.setZoom(map.getZoom() - 1);
					}
				}
				catch (e) { }


			}, 1000);
		}
		catch (e) { }


	}


	onOpenModal = () => {
		this.setState({ open: true });
	};

	onCloseModal = () => {
		this.setState({ open: false });
	};

	onShowTimelines() {
		this.setState({
			viewtimelinemarkers: !this.state.viewtimelinemarkers
		})
		if (this.state.viewtimelinemarkers === true) {
			var arr = [];
			if (this.props.mapFor == 'consignment' || this.props.mapFor == 'ticket' || this.props.mapFor == 'truck') {
				var jsondata = this.props.mapinfo;
				var locations = jsondata.breaks;
				var arr = jsondata.coords;
			}
			if (this.props.mapFor == 'importsconsignment') {
				var jsondata = this.props.mapinfo;
				var locations = jsondata.points;
				var arr = jsondata.route;
			}
			if (this.props.mapFor == 'truckgps' || this.props.mapFor == 'tpttruckgps') {
				var jsondata = this.props.mapinfo;
				var locations = jsondata.breaks;
				var arr = jsondata.coords;
			}
			if (arr.length > 0) {

				var allpoints = [];


				// if(arr.length < 50)
				// {
				// 	var ratio = (arr.length/5);
				// }
				// else if(arr.length > 50 && arr.length <200)
				// {
				// 	var ratio = 5;
				// }
				// else if(arr.length > 200 && arr.length <400)
				// {
				// 	var ratio = 20;
				// }
				// else{
				// 	var ratio = 100;
				// }

				// //console.log("Total ",arr.length);
				// var arr1 = arr.filter(function (value, index, arr) {
				// 	//return (index % ratio == 0);
				// 	return index;
				// } );
				var darr = [];
				darr = arr.filter(function (value, index, arr) {
					//console.log("Vale ",value)
					return (value.dist_from_prev_point > 0);
				});
				if (darr.length < 50) {
					var ratio = (arr.length / 5);
				}
				else if (darr.length > 50 && arr.length < 200) {
					var ratio = 5;
				}
				else if (darr.length > 200 && darr.length < 400) {
					var ratio = 20;
				}
				else {
					var ratio = 100;
				}
				var arr2 = []
				//console.log("darr.length ", darr.length)
				arr2 = darr.filter(function (value, index, darr) {
					return (index % ratio == 0);
				});

				this.setState({
					timelinesmarkers: arr2
				})
			}
		}
		else {
			this.setState({
				timelinesmarkers: []
			})
		}
		this.renderMap()

	}
	onViewTimelines(e) {
		this.setState({
			viewtimelinemarkers: !this.state.viewtimelinemarkers
		})
		//console.log("viewtimelinemarkers ", this.state.viewtimelinemarkers)
	}
	onShowTripSummary(e) {
		this.setState({
			isclick: true,
			onClickdownload: false
		})
		if (this.props.mapFor == 'consignment' || this.props.mapFor == 'ticket' || this.props.mapFor == 'truck') {
			this.setState({
				loadshow: 'show-m',

			})
			var jsondata = this.props.mapinfo;
			var locations = jsondata.breaks;
			var coordinates = jsondata.trip_summary;
			console.log("truckno ", this.props)
			var dataarr = []
			if (coordinates != "" && coordinates != undefined) {
				if (coordinates.length > 0) {
					coordinates.map((item) => {
						var truckNo = "";
						if (this.props.truckno != undefined) { truckNo = this.props.truckno } else { truckNo = this.props.mapinfo.coords[0].truck_no }
						dataarr.push({ "truck_no": item.truck_no, "current_loc_status": item.current_loc_status, "address": item.address, "start_time": item.start_time, "end_time": item.end_time, "distance_km": item.distance_km, "cumm_distance": item.cumm_distance, "remarks": item.remarks, "duration": item.duration, "avg_speed": item.avg_speed })
					});
				}
			}
			this.setState({
				rowData: dataarr,
				open: true,
				loadshow: 'show-n'
			})
		}
	}
	onShowGridPop(e) {
		if (this.props.mapFor == 'consignment' || this.props.mapFor == 'ticket' || this.props.mapFor == 'truck') {
			this.setState({
				loadshow: 'show-m',
				onClickdownload: true,
				isclick: false
			})
			var jsondata = this.props.mapinfo;
			var locations = jsondata.breaks;
			var coordinates = jsondata.coords;
			console.log("truckno ", this.props)
			console.log("coordinates ", coordinates)
			var dataarr = []
			//console.log("coordinates ", coordinates)
			if (coordinates != "" && coordinates != undefined) {
				if (coordinates.length > 0) {
					coordinates.map((item) => {
						var truckNo = "";
						if (this.props.truckno != undefined) { truckNo = this.props.truckno } else { truckNo = this.props.mapinfo.coords[0].truck_no }
						dataarr.push({
							"truck_no": truckNo, "lat": item.lat, "lng": item.lng, "speed": item.speed, "stime": item.stime, "dist_from_prev_point": item.dist_from_prev_point, "received_on": item.received_on, "time_from_prev_point": item.time_from_prev_point, "area": item.area, "state": item.state, "city": item.city, "break_no": item.break_no,
							"cumm_distance": item.cumm_distance, "current_loc_status": item.current_loc_status, "dist_from_prev_point": item.dist_from_prev_point
						})
					});
				}
			}
			this.setState({
				rowData: dataarr,
				open: true,
				loadshow: 'show-n'
			})
		}
	}


	onShowGoogleRoutes() {
		this.setState({
			viewgoogleroutes: !this.state.viewgoogleroutes
		})
		if (this.state.viewgoogleroutes == true) {
			if (this.props.mapFor == 'consignment') {
				try {
					var googleroutes = JSON.parse(this.props.googleroutes);
					//console.log("googleroutes ", googleroutes)
					if (googleroutes == 0) {
						this.props.context.showGoogleRouteAlert();
						this.setState({
							viewgoogleroutes: !this.state.viewgoogleroutes
						});
					}
					else {
						this.setState({
							googleroutepath: googleroutes
						})
					}
				}
				catch (e) {
					console.log(e)
				}

			}
		}
		else {
			this.setState({
				googleroutepath: [],
				viewgoogleroutes: !this.state.viewgoogleroutes
			})
		}

		this.renderMap()
	}
	showMarkers(event) {
		console.log("event.target.value ", event.target.value)
		if (event.target.value == 0) {
			this.setState({
				showMarkers: 1
			});
			// viewMarkersArr.map(function(e){
			// 	e.setVisible(true);
			// })
			console.log("markersArray ", markersArray)
			var infowindow = new window.google.maps.InfoWindow();
			if (markersArray.length > 0) {
				var stepby = Math.round(markersArray.length * 0.007)
				// var counter = ( stepby > 0)?stepby:1;
				// var incrementby = counter
				var counter = 1;
				var incrementby = 1;
				console.log(markersArray.length, counter);
				while (counter < markersArray.length) {
					var marker = new window.google.maps.Marker({
						position: new window.google.maps.LatLng(markersArray[counter].lat, markersArray[counter].lng),
						icon: {
							path: window.google.maps.SymbolPath.CIRCLE,
							strokeColor: '#157254',
							fillColor: '#157254',
							fillOpacity: 1,
							strokeWeight: 5,
							scale: 1,
						},
						map: map,
						content: markersArray[counter]
					});
					// marker.setVisible(false);
					window.google.maps.event.addListener(marker, 'mouseover', (function (marker) {
						return function () {
							//console.log("Arr ",marker)
							var contentarr = []
							//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
							var header = "Current Status"
							contentarr.push({ "key": "Time ", "value": getDDMMYYYYHHMMSS(marker.content.stime) })
							contentarr.push({ "key": "Speed ", "value": marker.content.speed + " KMPH" })

							var contentString = GoogleInfoWindow("", header, contentarr, '')


							infowindow.setContent(contentString);
							infowindow.open(map, marker);
							//console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}'); 
						}
					})(marker));
					window.google.maps.event.addListener(marker, 'mouseout', function () {
						infowindow.close();
					});

					viewMarkersArr.push(marker);
					counter = counter + incrementby;
					// console.log("counter",counter)
				}
				// for(var a=0;a<markersArray.length;a++)
				// {

				// }
			}

		}
		else {
			this.setState({
				showMarkers: 0
			});
			viewMarkersArr.map(function (e) {
				e.setVisible(false);
			});
		}
	}

	setRouteDeviationPathVisibility(routeno) {
		routeno = routeno + 1
		console.log(route_deviation_polyline_dict[routeno])
		if (route_deviation_polyline_dict[routeno].getVisible()) {
			route_deviation_polyline_dict[routeno].setVisible(false)
		}
		else {
			route_deviation_polyline_dict[routeno].setVisible(true)
		}
	}
	onRTDashboardRowClicked(row) {
		var breaklocations = [];
		var bounds = new window.google.maps.LatLngBounds();
		row.data.break_clusters.map(function (e) {
			bounds.extend(new window.google.maps.LatLng(e.lat, e.lng));
		})
		map.fitBounds(bounds)
	}
	render() {
		const modalStyles = {
			width: '500px !important',
		}
		const { open } = this.state;
		var columnwithDefs = []

		if (this.state.isclick == true) {
			columnwithDefs = [
				{
					headerName: "Truck no",
					field: "truck_no",
					width: 100,
					filter: true,
					resizable: true

				},
				// {
				// 	headerName: "Device Id",
				// 	field: "device_id",
				// 	width: 100,
				// 	filter: true,
				// 	resizable: true

				// },
				{
					headerName: "Current Status",
					field: "current_loc_status",
					width: 100,
					filter: true,
					resizable: true,
				},
				{
					headerName: "Address",
					field: "address",
					width: 450,
					filter: true,
					resizable: true,
				},

				{
					headerName: "Start Time",
					field: "start_time",
					width: 100,
					filter: true,
					resizable: true,
				},
				{
					headerName: "End Time",
					field: "end_time",
					width: 100,
					filter: true,
					resizable: true,
				},
				{
					headerName: "Duration(min)",
					field: "duration",
					width: 100,
					filter: true,
					resizable: true,
					valueGetter: function (params) {
						var duration_num = params.data.duration
						var result = Math.round(duration_num)
						return result
					}
				},
				{
					headerName: "Average Speed",
					field: "avg_speed",
					width: 100,
					filter: true,
					resizable: true,
				},
				{
					headerName: "Distance(km)",
					field: "distance_km",
					width: 100,
					filter: true,
					resizable: true,
				},
				{
					headerName: "Cumulative Distance",
					field: "cumm_distance",
					width: 100,
					filter: true,
					resizable: true,
				},


			]

		}
		if (this.state.onClickdownload == true) {
			columnwithDefs = [
				{
					headerName: "Truck no",
					field: "truck_no",
					width: 100,
					filter: true,
					resizable: true

				},
				{
					headerName: "City",
					field: "city",
					width: 140,
					filter: true,
					resizable: true,
					// valueGetter: function(params){
					// 	if(params.data.break_no !=undefined && params.data.break_no !="" && params.data.break_no !="NA")
					// 	{
					// 		return params.data.break_no;
					// 	}
					// 	else
					// 	{
					// 		return params.data.city;
					// 	}
					// }
				},
				{
					headerName: "State",
					field: "state",
					width: 140,
					filter: true,
					resizable: true,
				},
				{
					headerName: "Address",
					field: "area",
					width: 450,
					filter: true,
					resizable: true,
				},
				{
					headerName: "Packet Time",
					field: "stime",
					width: 140,
					filter: true,
					resizable: true,
					valueGetter: function (params) {
						//console.log(params);
						return getDDMMYYYYHHMMSS(params.data.stime);
					}
					// valueGetter:function(params){
					// 	console.log(params);
					// 	return params.data.stime;
					// }

				},
				// {
				// 	headerName: "Break No",
				// 	field: "break_no",
				// 	width: 140,
				// 	filter: true,
				// 	resizable: true,


				// },
				{
					headerName: "Latitude",
					field: "lat",
					width: 120,
					filter: true,
					resizable: true,
					valueGetter: function (params) {
						return params.data.lat.toFixed(6);
					}

				},
				{
					headerName: "Longitude",
					field: "lng",
					width: 120,
					filter: true,
					resizable: true,
					valueGetter: function (params) {
						return params.data.lng.toFixed(6);
					}

				},
				{
					headerName: "Speed (Kms)",
					field: "speed",
					width: 80,
					filter: true,
					resizable: true

				},

				{
					headerName: "Location Status",
					field: "current_loc_status",
					width: 140,
					filter: true,
					resizable: true,


				},
				{
					headerName: "Distance from Prev Point",
					field: "dist_from_prev_point",
					width: 140,
					filter: true,
					resizable: true,


				},
				{
					headerName: "Total Distance",
					field: "cumm_distance",
					width: 140,
					filter: true,
					resizable: true,


				},


			]
		}



		return (
			<div className="col-xl-12 col-md-12">
				{/* {(this.props.mapFor == 'consignment' || this.props.mapFor == 'ticket' || this.props.mapFor == 'truck')?
				<ul>
					{(localStorage.getItem('roles')!="CUSTOMER")?
					<li className="float-right">
					<button type="button" className="btn btn-success btn-xs float-right" > <span style={{fontSize:"14px"}}>Gps Route</span></button>
					<button type="button" className="btn btn-warning btn-xs float-right" > <span style={{fontSize:"14px"}}>LDB Route</span></button>

					</li>
					:""}	
				</ul>
				:""} */}
				{(this.props.mapFor != 'truckgps') ?
					<ul className='ml-0p n-p-0'>
						{(this.props.mapFor == "route_deviation") ?
							<li class="float-left" style={{ marginLeft: "1em" }}>
								{this.props.consignments_list.map((e, index) => {
									return (<span style={{ marginRight: "1em" }}><input type="checkbox" onClick={this.setRouteDeviationPathVisibility.bind(this, index)} /> {e.label}</span>)
								})}
							</li>
							:
							(this.props.mapFor == "deviation") ? ""
								:
								<li className="float-left" style={{ listStyleType: 'none' }}>
									<input type="checkbox" name="viewtimelinemarkers" value={this.state.showMarkers} onClick={this.showMarkers.bind(this)} /> <span style={{ fontSize: "14px" }}>View Markers</span>
								</li>
						}
					</ul>
					: ""}
				<div id="map" className="" style={{ width: '100%', height: "70vh"}}>
				</div>
				{(this.props.mapFor=='truck')?<div className="legend-overlay">
					{/* <span className="baserut">Ldb Route</span> */}
					{/* <span className="onwardrut">LDB Route</span> */}
					<span className="baserut">GPS Route</span>

				</div>:''}


				{(this.state.data_type == "regular") ?
					<span>
						<i class="fa fa-minus fa-2x" style={{ color: "#157254", }}></i> <b style={{ "paddingRight": "10px" }}>Current Truck </b>
						<i class="fa fa-minus fa-2x" style={{ color: "#452a68", }}></i> <b style={{ "paddingRight": "10px" }}>Missed GPS Route </b>
					</span>
					:
					<span>
						{(this.state.data_type == "rake" && localStorage.getItem('user_type') != "CUSTOMER") ?
							<span>
								<i class="fa fa-minus fa-2x" style={{ color: "#157254", }}></i><b style={{ "paddingRight": "10px" }}> First Mile Truck </b>
								<i class="fa fa-minus fa-2x" style={{ color: "#0000ff", }}></i><b style={{ "paddingRight": "10px" }}> Rake </b>
								<i class="fa fa-minus fa-2x" style={{ color: "#980000", }}></i><b style={{ "paddingRight": "10px" }}> Last Mile Truck </b>
								<i class="fa fa-minus fa-2x" style={{ color: "#452a68", }}></i> <b style={{ "paddingRight": "10px" }}>Missed GPS Route </b>
							</span>
							:
							""
						}
					</span>
				}
				<Modal open={open} onClose={this.onCloseModal} styles={modalStyles} >
					<div className={"row"} style={{ height: '70vh', padding: '5px 0px', width: '1185px', overflow: 'hidden', zIndex: '9999', }} >
						<div className={"col-sm-12 col-lg-12 col-md-12"} style={{ padding: '20px', background: '#fff' }}>
							{/*<div style={{position:'absolute',top:'5px',right:'10px',padding:'0px'}}>
	                			<a href={"#"} title="Close" style={{fontSize:'12px', color:'#333', textDecoration:'none'}} onClick={this.onCloseModal}>
	                				<img src={require("../../assets/icons/close.png")} style={{width:'8px',marginTop:'-2px'}} title="Close" />
	                				 &nbsp;Close
	                			</a>
	                		</div>
							*/}
							<div id="myGrid" style={{ height: "480px", width: "100%", marginTop: '10px' }} className="ag-theme-balham">
								<AgGridReact
									modules={this.state.modules}
									columnDefs={columnwithDefs}
									defaultColDef={this.state.defaultColDef}
									rowData={this.state.rowData}
									enableCharts={true}
									enableRangeSelection={true}
									autoGroupColumnDef={this.state.autoGroupColumnDef}
									onGridReady={this.onGridReady}
									onGridState={this.onGridState}
									statusBar={this.state.statusBar}
									sideBar={this.state.sideBar}
									paginationPageSize={this.state.paginationPageSize}
									pagination={true}
									frameworkComponents={this.state.frameworkComponents}
									detailCellRendererParams={this.state.detailCellRendererParams}
									//editType={this.state.editType}
									stopEditingWhenGridLosesFocus={true}
									floatingFilter={false}
									enableCellChangeFlash={true}
									suppressCellFlash={true}
									rowClassRules={this.state.rowClassRules}
									//onCellClicked={this.onRowClicked.bind(this)}
									gridOptions={{
										context: { componentParent: this }
									}}
									masterDetail={true}
									overlayNoRowsTemplate={this.state.overlayNoRowsTemplate}
								/>

								<div className={"dataLoadpage " + (this.state.loadshow)}>
								</div>
								<div className={"dataLoadpageimg " + (this.state.loadshow)}>
									<div class="loader-box"><div class="loader-box"><div class="rotate dashed colored"></div></div></div>
								</div>

							</div>
						</div>
					</div>
				</Modal>
			</div>
		)
	}
}

function loadScript(url) {
	var index = window.document.getElementsByTagName("script")[0]
	var script = window.document.createElement("script")
	script.src = url
	script.async = true
	script.defer = true
	index.parentNode.insertBefore(script, index)
}

function timeConvert(n) {
	var num = n;
	var hours = (num / (3600));
	var rhours = parseInt(hours);
	var minutes = (num - (rhours * 3600)) / (60);
	var rminutes = Math.round(minutes);
	return rhours + " hour(s) and " + rminutes + " minute(s).";
}

function intervals(startString, endString) {
	var start = moment(startString, 'DD-MM-YYYY HH:mm:ss');
	var end = moment(endString, 'DD-MM-YYYY HH:mm:ss');

	start.minutes(Math.ceil(start.minutes() / 15) * 15);

	var result = [];

	var current = moment(start);

	while (current <= end) {
		result.push(current.format('DD-MM-YYYY HH:mm'));
		current.add(15, 'minutes');
	}

	return result;
}


function secondsToString(seconds) {
	var numdays = Math.floor(seconds / 86400);
	var numhours = Math.floor((seconds % 86400) / 3600);
	var numminutes = Math.floor(((seconds % 86400) % 3600) / 60);
	var numseconds = ((seconds % 86400) % 3600) % 60;
	return numdays + " days " + numhours + " hours " + numminutes + " minutes ";
}


function getPolygonCoords() {

	var len = geoFencingArea.getPath().getLength();
	console.log(len);
	var htmlStr = "";
	coordinates = [];
	for (var i = 0; i < len; i++) {
		// var latlngd = {"lat":parseFloat(each_lat_lng[0]),"lng":parseFloat(each_lat_lng[1])};
		// coordinates.push()
		var strlatlng = geoFencingArea.getPath().getAt(i).toUrlValue(5);
		var spliting = strlatlng.split(",");
		var latlngd = { "lat": parseFloat(spliting[0]), "lng": parseFloat(spliting[1]) };
		coordinates.push(latlngd);
		// htmlStr += "new google.maps.LatLng(" + geoFencingArea.getPath().getAt(i).toUrlValue(5) + "), ";
		//Use this one instead if you want to get rid of the wrap > new google.maps.LatLng(),
		//htmlStr += "" + geoFencingArea.getPath().getAt(i).toUrlValue(5);
	}
	// console.log("htmlStr", coordinates);

}


function groupBy(list, keyGetter) {
	const map11 = new Map();
	list.forEach((item) => {
		const key = keyGetter(item);
		const collection = map11.get(key);
		if (!collection) {
			map11.set(key, [item]);
		} else {
			collection.push(item);
		}
	});
	return map;
}
function setBg() {
	const randomColor = Math.floor(Math.random() * 16777215).toString(16);
	return "#" + randomColor
}

var GoogleInfoWindow = (icon, headerTitle, rows, mode) => {
	console.log("Props ", headerTitle)
	var imgis;
	var content = "<div>";
	if (icon === '') {
		imgis = "<img class='c-google-img' src='" + require("../../assets/icons/dot.png") + "' />"
	}
	else {
		imgis = "<img class='c-google-img' src='" + icon + "' />";
	}
	content += "<h5 class='g-header'> " + imgis + " <span class='ml-10p'>" + headerTitle + "</span></h5>";
	if (mode === 'table') {
		content += rows;
	}
	else {

		content += "<table class='table table-border'>";
		rows.map((item) => {
			content += "<tr><th>" + item.key + "</th><td class='fw400'>" + item.value + "</td></tr>"
		})
		content += "</table>";
	}
	content += "</div>";
	//console.log("Geo Infowindow content ", content)
	return content;
}

export default Mapcard2;