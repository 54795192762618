
import React, { useState, useEffect, useRef, useReducer } from "react"
import SweetAlert from 'react-bootstrap-sweetalert';

import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import 'ag-grid-enterprise';
import 'ag-grid-enterprise/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-enterprise/styles/ag-theme-balham.css';
import 'react-datetime/css/react-datetime.css';
import { getHyphenYYYYMMDDHHMMSS, getDDMMMYYYY, getHyphenYYYYMMDD ,getHyphenDDMMYYYY,getDDMMYYYYHHMMSS,getHyphenYYYYMMDDHHMM, getHyphenDDMMYYYYHHMM} from '../common/utils';
import ConsignmentTrucksLegs from "./containertruckslegs";
import CountryFlagComponent from "./countryFlag";
import moment from "moment";
import Select from "react-select";
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import Mapcard2 from "./drawmap";
import AcknowledgeBtnComponent from "./acknowledgeBtn";
import customNextIcon from "../../assets/icons/next.png";
import customPreviousIcon from "../../assets/icons/back.png";
import customFirstIcon from "../../assets/icons/back_left.png";
import customLastIcon from "../../assets/icons/next_right.png";
import {fetchGrid} from "../fetchGridStructure"
import redirectURL from "../redirectURL";
var initialState = {
    detailCellRendererParams: {},
};

var reducer = (state, action) => {
    switch (action.type) {
        case 'SET_DETAIL_RENDERER_PARAMS':
            //console.log('2020')
            return {
                ...state,
                detailCellRendererParams: action.payload,
            };
        // case 'SET_DETAIL_RENDERER_PARAMS_FOR_STOPPAGES':
        // 	//console.log('6767')
        // 	return {
        // 		...state,
        // 		detailCellRendererParams: action.payload,
        // 	};
        default:
            return state;
    }
};
const IntransitToPlant = (props) => {
    const gridApi = useRef(null);
    var [state, dispatch] = useReducer(reducer, initialState);
    const [rowData, setrowdata] = useState([])
    var [originalData, setoriginalData] = useState([])
    // var [gridApi, setGridApi] = useState(null);
    var [gridColumnApi, setgridColumnApi] = useState(null)
    var gridRef = useRef();
    var [summaryviewcheck, setsummaryviewcheck] = useState(1)
    var [detailedviewcheck, setdetailedviewcheck] = useState(0)
    const [showAddForm, setshowAddForm] = useState('')
    var [checkfortruck, setcheckfortruck] = useState(0)
    var selectconfirmn = [
        { label: 'Yes', value: 'yes' }, { label: 'No', value: 'no' }
    ]
    var [loadshow, setloadshow] = useState('show-n')
    var [overly, setoverly] = useState('show-n')
    var [trucksList, settrucksList] = useState([])
    const [manualUploadDetails, setmanualUploadDetails] = useState({
        trucks: '',
        wrno: ''
    })
    var [workordernoList, setworkOrderList] = useState([])
    var [show, setshow] = useState(false)
    var [basicTitle, setbasicTitle] = useState('')
    var [basicType, setbasicType] = useState('')
    var [parentrow, setparentrow] = useState({})
    var [containerLegsData, setContainerLegsData] = useState([])
    var [check, setcheck] = useState(false)
    var [pendencyValue, setpendencyValue] = useState("");
    var [stateForMap, setStateForMap] = useState({
        sliderRouteTranslate: '',
        showDiv: 'show-n',
        mapinfo: {
            coords: [
                // { lat: 8.76735, lng: 78.13425 },
                // { lat: 18.76735, lng: 88.13425 },
            ],
        },
        mapdata:[],
        dealer: '',
        consignment_code: '',
        truck_no: '',
        container_no: '',
        maptruckno: '',
        routeTruck: '',
        sidebarSubHeader: '',
        rownode: '',
        consigner_coordinates: '',
        consignee_coordinates: '',
        map_type_of_tracking: '',
        baseRouteCoords: '',
        mapHeight: "94vh",
    })
    var [tabDataforintransit_plant,settabDataforintransit_plant] = useState({
        intransit_modified:[],
        intransit_new:[],
        intransit_planned:[]
    })
    var [classForTab, setclassforTab] = useState({
        planned: 0,
        new: 1,
        modified: 0,
    })
    const [summaryViewcols, setsummaryViewcols] = useState([
        {
            headerName: "",
            field: "containerLegs",
            width: 60,
            pinned: 'left',
            cellRenderer: 'ConsignmentTrucksLegs'
        },
        {
            headerName: "Work Order No",
            field: "work_order_no",
            width: 140,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Production Date",
            field: "production_date",
            width: 150,
            filter: true,
            resizable: true,
            editable: true,
            cellEditor:DateEditor,
            valueGetter: function (params) {
                try {
                    if (params.data.production_date != "" && params.data.production_date != undefined) {
                        return getHyphenDDMMYYYY(params.data.production_date);
                    } else {
                        return "Not Available";
                    }
                } catch (e) {
                    // Handle the exception if needed
                }
            },
            comparator: dateComparator1,
            
        },
        {
            headerName: "Vessel ETD",
            field: "vessel_etd_date",
            width: 160,
            filter: true,
            resizable: true,
            valueGetter: function (params) {
                try {
                    if (params.data.vessel_etd_date != "" && params.data.vessel_etd_date != undefined) {
                        return getHyphenDDMMYYYY(params.data.vessel_etd_date);
                    }
                    else {
                        return "Not Available";
                    }
                }
                catch (e) { }
            },
        },
        {
            headerName: "Stuffing Date",
            field: "stuffing_date",
            width: 150,
            filter: true,
            resizable: true,
            editable: true,
            valueGetter: function (params) {
                try {
                    if (params.data.stuffing_date != "" && params.data.stuffing_date != undefined) {
                        return getHyphenDDMMYYYY(params.data.stuffing_date);
                    }
                    else {
                        return "Not Available";
                    }
                }
                catch (e) { }
            },
        },
        {
            headerName: "Ship To Country",
            field: "ship_to_country",
            width: 130,
            filter: true,
            resizable: true,
            // cellRenderer: CountryFlagComponent
        },
        {
            headerName: "40ft Containers",
            field: "ft40_containers",
            width: 140,
            filter: true,
            resizable: true,
        },
        {
            headerName: "20ft Containers",
            field: "ft20_containers",
            width: 140,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Pendency",
            field: "pendency_gate_in",
            width: 140,
            filter: true,
            resizable: true,
            hide:true,
            valueGetter: function(params) {
                if (params.data && (params.data.pendency_gate_in !== '' && params.data.pendency_gate_in !== undefined)) {
                    return params.data.pendency_gate_in;
                } else {
                    return params.data ? params.data.container_count : null;
                }
            }            
        },
        // {
        //     headerName: "Pendency",
        //     field: "remaining_containers",
        //     width: 140,
        //     filter: true,
        //     resizable: true,
        //     valueGetter:function(params){
        //         if(check){
        //             setcheck(false)
        //             return params.data.remaining_containers-1
        //         }
        //         else{
        //             return params.data.remaining_containers
        //         }
        //     },
        //     valueSetter: params => {
        //         var count = params.data.remaining_containers = params.newValue-1
        //         //console.log(count)
        //         return count;
        //     }
        // },
        {
            headerName: "POD",
            field: "pod",
            width: 200,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Plant",
            field: "plant",
            width: 120,
            filter: true,
            resizable: true,
        },
        {
            headerName: "CHA Name",
            field: "cha_name",
            width: 120,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Plant Name",
            field: "plant_name",
            width: 120,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Transporter Name",
            field: "tpt_name",
            width: 200,
            filter: true,
            resizable: true,
        },

        {
            headerName: "Booking No",
            field: "booking_no",
            width: 160,
            filter: true,
            resizable: true,
        },

        {
            headerName: "CHA",
            field: "cha",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "3rd Party Inspection Req",
            field: "third_party_inspection_req",
            width: 190,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Port Of Loading",
            field: "port_of_loading",
            width: 190,
            filter: true,
            resizable: true,
        },
        {
            headerName: "VIN Type Description",
            field: "vin_type_description",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "VIN Type",
            field: "vin_type",
            width: 160,
            filter: true,
            resizable: true,
        },

    ])

    useEffect(() => {
        if (gridApi.current) {
            getUserGridState();
        }
        loadDateTimeScript();
    }, [gridApi]);

    useEffect(() => {
        var records = props.data
        //console.log(records,'1188888888')
        var new_trucks = records.intransit_new
        var planned_trucks = records.intransit_planned
        var modified_trucks = records.intransit_modified
        sortByCreatedDate(new_trucks)
        if (classForTab.modified == 1) {
            setrowdata(modified_trucks);
        } else if (classForTab.planned == 1) {
            setrowdata(planned_trucks);
        } else {
            setrowdata(new_trucks);
        }
        // setrowdata(new_trucks)
         setoriginalData(new_trucks)
        settabDataforintransit_plant({
            intransit_new: sortByCreatedDate(new_trucks),
            intransit_planned: sortByCreatedDate(planned_trucks),
            intransit_modified: sortByCreatedDate(modified_trucks)
        })
    }, [props.data])
    const showdata = (e) => {
        if (e == 'detailed view') {
            setdetailedviewcheck(1)
            setsummaryviewcheck(0)
        }
        else if (e == 'summary view') {
            setdetailedviewcheck(0)
            setsummaryviewcheck(1)
        }
    }
    const updateBtn = () => {
        setshowAddForm("slide30")
        setoverly("show-m")
    }
    const onSelectWON = (e) => {
        //console.log(e)
        setmanualUploadDetails(prev => ({
            ...prev,
            wrno: e
        }))
    }
    var onCloseRouteDiv = () => {
        setStateForMap(prevState => ({
            showDiv: 'show-n',
            sliderRouteTranslate: "",
        }))
        setoverly('show-n')
        setloadshow('show-n')
    }
    const getpendency = async (e) => {
        const params = {
            work_order_no: e
        };
        try {
            const resp = await redirectURL.post('/consignments/getpendencydata', params);
            //console.log(resp.data, "resssssspppp");
            const records = resp.data[0].pendency_gate_in;
            //console.log(records, "recccc");
    
            if (records === 0) {
                setclassforTab({
                    new: 1,
                    planned: 0,
                    modified: 0
                });
                props.onRequest();
            }
        } catch (error) {
            console.error("Error fetching pendency data:", error);
        }
    };
    
    const getdata = async (e) => {
        try {
            const resp = await redirectURL.post("/consignments/getIntransitToPlantData");
            const intransit_to_plant_new = resp.data.work_order_level_data1;
            const intransit_to_plant_planned = resp.data.work_order_level_data2;
            const intransit_to_plant_modified = resp.data.work_order_level_data3;
    
            if (e === 'New') {
                setclassforTab({
                    new: 1,
                    planned: 0,
                    modified: 0
                });
                setrowdata(sortByCreatedDate(intransit_to_plant_new));
                setoriginalData(sortByCreatedDate(intransit_to_plant_new));
            } else if (e === 'Planned') {
                setclassforTab({
                    new: 0,
                    planned: 1,
                    modified: 0
                });
                setrowdata(sortByCreatedDate(intransit_to_plant_planned));
                setoriginalData(sortByCreatedDate(intransit_to_plant_planned));
            } else if (e === 'Modified') {
                setclassforTab({
                    new: 0,
                    planned: 0,
                    modified: 1
                });
                setrowdata(sortByCreatedDate(intransit_to_plant_modified));
                setoriginalData(sortByCreatedDate(intransit_to_plant_modified));
            }
            props.onRequest()            
            props.onReloadData(true);
        } catch (error) {
            console.error("Error fetching in-transit data:", error);
        }
    };
    
    const updateContainerDetails = async (e) => {
        const gate_in_time = moment.parseZone(e.gate_in_time).format('DD-MM-YYYY HH:mm');
        const params = {
            data: [{
                container_no: e.container_no,
                gate_in_time: gate_in_time,
                truck_no: e.truck_no,
                work_order_no: e.work_order_no
            }]
        };
    
        //console.log(parentrow, 'parentrow');
        if (e.gate_in_time !== '' && e.gate_in_time !== undefined) {
            try {
                const resp = await redirectURL.post('/consignments/updateContainergateintime', params);
                //console.log(resp.data, "gygy");
                if (resp.data.status === 'Success') {
                    setcheck(true);
                    setshow(true);
                    setbasicTitle("Successfully Updated Plant Gate In");
                    setbasicType("success");
                    setloadshow('show-n');
                    setoverly('show-n');       
                    let activeTab;
                    if (classForTab.new === 1) {
                        activeTab = 'New';
                    } else if (classForTab.planned === 1) {
                        activeTab = 'Planned';
                    } else if (classForTab.modified === 1) {
                        activeTab = 'Modified';
                    }
                    
                    props.onRequest()    
                    await getdata(activeTab);
                    await getpendency(e.work_order_no);
    
                    if (classForTab.modified === 1 && activeTab !== 'Modified') {
                        setclassforTab({
                            new: 1,
                            planned: 0,
                            modified: 0,
                        });
                    }
                } else {
                    setcheck(true);
                    setshow(true);
                    setbasicTitle(resp.data.message);
                    setbasicType("danger");
                    setloadshow('show-n');
                    setoverly('show-n');
                }
            } catch (error) {
                console.error("Error updating container details:", error);
            }
        }
    };
    
    const onGridReady = params => {
        gridApi.current = params;
        getUserGridState();
    };
    const getUserGridState = () => {
        redirectURL.post("/consignments/usergridstates", { screenurl: '/intransittoplantnew' })
            .then(async (resp) => {
                //console.log("resp.data0999", resp.data)
                restoreGridStates(resp.data);
                var grid = resp.data;
                var oCols= fetchGrid(summaryViewcols, grid[0].gridcolumns)
                //console.log("oCols",oCols)
            })
    }
    const restoreGridStates = (griddata) => {
        try {
            if (griddata.length > 0) {
                var oCols= fetchGrid(summaryViewcols, griddata[0].gridcolumns)
                setsummaryViewcols(oCols);
            }
        } catch (e) {
            //console.log(e);
        }
    };
    const onGridState = () => {
        ////console.log(gridApi);

        /*Get  Current Columns State and Store in this.colState */
        const colState = gridApi.current.columnApi.getColumnState();

        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
        const rowGroupState = gridApi.current.columnApi.getRowGroupColumns();

        /*Get Current Pivot Columns State and Store in this.pivotColumns, 
         * User should enable the Pivot mode.*/
        const pivotColumns = gridApi.current.columnApi.getPivotColumns();

        /*Get Current Filter State and Store in window.filterState */

        window.filterState = gridApi.current.api.getFilterModel();
        gridApi.current.api.setFilterModel(window.filterState);
        getUserGridState();


    }
    var onClickSaveGridState = () => {
        try {
            // //console.log("SaveGrid", gridApi.current.getSortModel())
            window.colState = gridApi.current.columnApi.getColumnState();
            window.groupState = gridApi.current.columnApi.getColumnGroupState();
            // window.sortState = gridApi.current.columnApi.getSortModel();
            // window.filterState = gridApi.current.api.getFilterModel();
            var screenpage = '/intransittoplantnew'
            let reqparams = {
                gridcolumns: window.colState,
                gridgroup: window.groupState,
                gridcolsort: window.sortState,
                gridcolfilter: window.filterState,
                userId: localStorage.getItem("userid"),
                screenurl: '/intransittoplantnew',
                screentitle: screenpage
            }
            //console.log("reqparams after save grid", reqparams);
            redirectURL.post("/consignments/saveGridStates", reqparams)
                .then((response) => {
                    //console.log("response forr", response)
                    // if(response.data.status === "200"){
                    setshow(true)
                    setbasicTitle("Successfully saved grid layout")
                    setbasicType("success")
                    // setState1({
                    // 	screenurl: window.location.pathname,
                    // 	screentitle: screenpage
                    // })
                    setTimeout(() => {
                        getUserGridState();
                    }, 2000)
                    // getUserGridState();
                    // }
                })
                .catch(function (e) {
                    //console.log("Error ", e)
                })
            //console.log('hit')
        }
        catch (e) {
            //console.log(e)
        }
    }
    const onclickTab = (e) => {
        if (e == 'New') {
            setclassforTab({
                new: 1,
                planned: 0,
                modified: 0
            })
            setrowdata(tabDataforintransit_plant.intransit_new)
            setoriginalData(tabDataforintransit_plant.intransit_new)
        }
        else if (e == 'Planned') {
            setclassforTab({
                new: 0,
                planned: 1,
                modified: 0
            })
            setrowdata(tabDataforintransit_plant.intransit_planned)
            setoriginalData(tabDataforintransit_plant.intransit_planned)
        }
        else if (e == 'Modified') {
            setclassforTab({
                new: 0,
                planned: 0,
                modified: 1
            })
            setrowdata(tabDataforintransit_plant.intransit_modified)
            setoriginalData(tabDataforintransit_plant.intransit_modified)
        }
    }
    const onShowShipmentLegRouteMap = async (e) => {
        var params = {
            work_order_no: e.work_order_no,
            truck_no: e.truck_no,
            container_no: e.container_no
        };
        setloadshow('show-m');
        setoverly('show-m');
        //console.log(params, 'rparams')
        try {
            const resp = await redirectURL.post('/consignments/getmapdata', params);
            //console.log(resp.data, "mapdata");
            var gps_route = resp.data.gps_route.coords;
            var route_details = resp.data.gps_route.route_details;
            if (!Array.isArray(gps_route)) {
                gps_route = gps_route.replace(/NaN/g, "0");
                gps_route = JSON.parse(gps_route);
            }
            if (!gps_route || gps_route.length === 0) {
                setshow(true);
                setbasicTitle('No GPS Data Available');
                setbasicType('danger');
                setloadshow('show-n');
                setoverly('show-n');

            } else {
                var allCoordinates = [];
                var allldbcoords = [];
                var gps_route =gps_route;
                var ldb_data = resp.data.ldb_route
                var ldb_route = ldb_data.ldb_route;

                //console.log('gps_route', gps_route);
                //console.log('ldb_route', ldb_route);
                if (ldb_route != '' && ldb_route != undefined) {
                    if (ldb_route.length > 0) {
                        ldb_route = JSON.parse(ldb_route)
                        ldb_route.forEach((e) => {
                            var lt = e.latitude || e.lat || 0;
                            var ln = e.longitude || e.lng || 0;

                            allldbcoords.push({
                                lat: lt,
                                lng: ln,
                            });
                        });
                    } else {
                        //console.log('ldb_route is not an array or is empty.');
                    }
                }
                //console.log(gps_route, 'gps_routegps_route')
                if (gps_route != '' && gps_route != undefined) {
                    if (gps_route.length > 0) {
                        //console.log(typeof gps_route, 'gps_routegps_route')
                        gps_route.map((e) => {
                            var lt = e.latitude || e.lat || 0;
                            var ln = e.longitude || e.lng || 0;
                            allCoordinates.push({
                                lat: lt,
                                lng: ln,
                                stime : e.stime,
                                speed : e.speed
                            });
                        });
                    } else {
                        //console.log('gps_route is not an array or is empty.');
                    }
                }

                var truck_no;
                if (gps_route.length > 0) {
                    truck_no = gps_route[0].truck_no;
                }
                //console.log(allCoordinates, 'allCoordinates');
                setoverly('show-m')
                setStateForMap((prev) => ({
                    ...prev,
                    sliderRouteTranslate: "slider-translate-75p",
                    showDiv: 'show-m',
                    container_no: truck_no,
                    routeTruck:	{"truck_no":e.truck_no,"startTime":route_details['start_time'],
                        "endTime": route_details['end_time'],"distance_without_google" :route_details['distance_without_google'] },
                    mapinfo: {
                        ...state.mapinfo,
                        coords: allCoordinates,
                        ldb_route: allldbcoords
                    },
                    mapdata:resp.data.gps_route,
                    sidebarSubHeader : "Truck Information"
                }));
                setloadshow('show-n');
            }
        } catch (error) {
            console.error(error);
        }
    };
    // const onShowShipmentLegRouteMap = async (e) => {
    //     var params = {
    //       work_order_no: e.work_order_no,
    //       truck_no: e.truck_no,
    //       container_no: e.container_no
    //     };
      
    //     setloadshow('show-m');
    //     setoverly('show-m');
      
    //     try {
    //       const resp = await redirectURL.post('/consignments/getmapdata', params);
    //       //console.log(resp.data);
    //       var records = resp.data.gps_df;
    //       //console.log(records, '342');
      
    //       if (!Array.isArray(records)) {
    //         records = records.replace(/NaN/g, "0");
    //         records = JSON.parse(records);
    //       }
    //       //console.log(records, '169');
    //       if (!records || records.length === 0) {
    //         setshow(true);
    //         setbasicTitle('No GPS Data Available');
    //         setbasicType('danger');
    //         setloadshow('show-n');
    //         setoverly('show-n');

    //       } else {
    //         var data = records[0];
    //         var allCoordinates = [];
    //         var allldbcoords = [];
    //         var gps_route = data.gps_route;
    //         var ldb_route = data.ldb_route;
            
    //         //console.log( 'gps_route', gps_route); 
    //         //console.log( 'ldb_route', ldb_route);
    //         if(ldb_route!='' && ldb_route!=undefined){
    //             if (ldb_route.length > 0) {
    //                 ldb_route=JSON.parse(ldb_route)
    //                 ldb_route.forEach((e) => {
    //                     var lt = e.latitude || e.lat || 0;
    //                     var ln = e.longitude || e.lng || 0;
                
    //                     allldbcoords.push({
    //                         lat: lt,
    //                         lng: ln,
    //                     });
    //                 });
    //             } else {
    //                 //console.log('ldb_route is not an array or is empty.');
    //             }
    //         }
    //         //console.log(gps_route,'gps_routegps_route')
    //         if(gps_route!='' && gps_route!=undefined){
    //             if (gps_route.length > 0) {
    //                 //console.log(typeof gps_route,'gps_routegps_route')
    //                 gps_route=JSON.parse(gps_route)
    //                 gps_route.map((e) => {
    //                     var lt = e.latitude || e.lat || 0; 
    //                     var ln = e.longitude || e.lng || 0; 
    //                     allCoordinates.push({
    //                         lat: lt,
    //                         lng: ln,
    //                     });
    //                 });
    //             } else {
    //                 //console.log('gps_route is not an array or is empty.');
    //             }
    //         }
            
    //         var truck_no;
    //         if (records.length > 0) {
    //           truck_no = records[0].truck_no;
    //         }
    //         //console.log(allCoordinates, 'allCoordinates');
    //             setoverly('show-m')
    //           setStateForMap((prev) => ({
    //             ...prev,
    //             sliderRouteTranslate: "slider-translate-60p",
    //             showDiv: 'show-m',
    //             container_no: truck_no,
    //             mapinfo: {
    //               ...state.mapinfo,
    //               coords: allCoordinates,
    //               ldb_route:allldbcoords
    //             },
    //           }));
    //         setloadshow('show-n');
    //       }
    //     } catch (error) {
    //       console.error(error);
    //     }
    //   };
    const getRowClass = (params)=>{
        //console.log(params,'185')
        if(params.data && (params.data.tpt_container_modified==1||params.data.tpt_trucks_modified==1||params.data.ib_tpt_modified==1||params.data.modified_plant==1||params.data.cha_vessel_modified==1||params.data.ib_stuffing_modified==1||params.data.cha_do_modified==1)){
            return { backgroundColor: '#FFB9B9' }; 
        }
        return null
    }
    var onClickShowShipmentLegs = async (rownode) => {
        var selectedrecord = rownode.data
        if(selectedrecord.modified_plant==1||selectedrecord.cha_vessel_modified==1||selectedrecord.ib_stuffing_modified==1||selectedrecord.cha_do_modified==1||selectedrecord.ib_tpt_modified==1|| selectedrecord.tpt_trucks_modified==1){
            var checkEditable = false
        }
        else {
            var checkEditable = true
        }
        var commentHeaders = [
            {
                headerName: "",
                field: "",
                resizable: true,
                width: 60,
                filter: true,
                // cellRendererFramework: LegRouteActiveTruckMap,
                cellRendererFramework: (params) => {
                    return (
                        <div>
                            <button onClick={() => onShowShipmentLegRouteMap(params.data)} className="custom-btn label label-success">
                                <i className="icofont icofont-map-pins f20"></i>
                            </button>
                        </div>
                    );
                },
            },
            {
                headerName: 'Work Order No',
                field: "work_order_no",
                resizable: true,
                width: 180,
                filter: true
            },
            {
                headerName: 'Requirement Id',
                field: "requirement_id",
                resizable: true,
                width: 180,
                filter: true
            },
            {
                headerName: 'Truck No',
                field: "truck_no",
                resizable: true,
                width: 180,
                filter: true
            },
            {
                headerName: 'Container No',
                field: "container_no",
                resizable: true,
                width: 180,
                filter: true

            },
            // {
            //     headerName: 'Pickup Date',
            //     field: "pick_up_date",
            //     resizable: true,
            //     width: 180,
            //     filter: true,

            //     valueGetter: function (params) {
            //         try {
            //             if (params.data.pick_up_date != "" && params.data.pick_up_date != undefined) {
            //                 return getHyphenDDMMYYYY(params.data.pick_up_date);
            //             } else {
            //                 return "";
            //             }
            //         } catch (e) {
            //             // Handle the exception if needed
            //         }
            //     },

            // },
            // {
            //     headerName: 'Shipping Seal',
            //     field: "shipping_seal",
            //     resizable: true,
            //     width: 180,
            //     filter: true,

            // },
            // {
            //     headerName: 'Container Tare Weight(Kgs)',
            //     field: 'container_tare_weight',
            //     resizable: true,
            //     width: 190,
            //     filter: true,
            // },
            // {
            //     headerName: 'Container Gross Weight(Kgs)',
            //     field: 'container_gross_weight',
            //     resizable: true,
            //     width: 200,
            //     filter: true,
                
            // },
            // {
            //     headerName: 'Driver Mobile No',
            //     field: 'driver_mobile_no',
            //     resizable: true,
            //     width: 160,
            //     filter: true,
            // },
            {
                headerName: 'Plant Gate-In Date & Time',
                field: "gate_in_time",
                resizable: true,
                width: 180,
                filter: true,
                editable: function(params){
                    if(checkEditable){
                        return true
                    }else{
                        return false
                    }
                },
                cellEditor: DateEditor,
                valueGetter: function (params) {
                    try {
                        //console.log(params,'407')
                        if (params.data.gate_in_time != "" && params.data.gate_in_time != undefined) {
                            return getHyphenDDMMYYYYHHMM(params.data.gate_in_time);
                        } else {
                            return "";
                        }
                    } catch (e) {
                        // Handle the exception if needed
                    }
                },
                comparator: dateComparator1,
            },
        ]
        if(rownode.data.ib_plant_gate_in_new==1){
            commentHeaders.push( {
                headerName: "",
                field: "",
                resizable: true,
                width: 100,
                filter: true,
                // cellRendererFramework: LegRouteActiveTruckMap,
                cellRendererFramework: (params) => {
                    return (
                        <div>
                            <button onClick={() => updateContainerDetails(params.data)} className="btn btn-success f12 label label-success">
                                Submit
                            </button>
                        </div>
                    );
                },
            },)
        }else if (rownode.data.ib_plant_gate_in_modified==1 && rownode.data.container_count_increase_check==1){
            commentHeaders.push( {
                headerName: "",
                field: "",
                resizable: true,
                width: 100,
                filter: true,
                // cellRendererFramework: LegRouteActiveTruckMap,
                cellRendererFramework: (params) => {
                    return (
                        <div>
                            <button onClick={() => updateContainerDetails(params.data)} className="btn btn-success f12 label label-success">
                                Submit
                            </button>
                        </div>
                    );
                },
            },)
        }
        var setdata = {
            detailGridOptions: {
                columnDefs: commentHeaders,
                overlayNoRowsTemplate: 'No rows to show',

            },
            getDetailRowData: async function (param) {
                param.successCallback([]);
                var row = param.data
                var records = props.data.container_new_1
                if(classForTab.new){
                    var data = props.data.container_new_1 
                    //console.log(data,'518')
                    if(data!='' && data!=undefined){
                        records = data.filter(e=>row.work_order_no==e.work_order_no)
                    }
                }
                else if(classForTab.planned){
                    var data = props.data.container_new_2
                    if(data!='' && data!=undefined){
                        records = data.filter(e=>row.work_order_no==e.work_order_no)
                    }                }
                else if(classForTab.modified){
                    var data = props.data.container_new_3 
                    if(data!='' && data!=undefined){
                        records = data.filter(e=>row.work_order_no==e.work_order_no)
                    }                }
                // await redirectURL.post("/consignments/truckslegsdata",parameter).then(async (response) => {
                //     //console.log(response.data,'response')
                //     var records = response.data 
                //     records = records.filter(e=>e.containerDetailscheck!=1)
                param.successCallback(records);
                // }).catch(function (error) {
                //     //console.log(error);
                // })
            },
            masterDetail: true
        }
        dispatch({ type: 'SET_DETAIL_RENDERER_PARAMS', payload: setdata });
        setTimeout(() => {
            if (rownode.column.colDef.field == 'containerLegs') {
                rownode.node.setExpanded(!rownode.node.expanded);

            }
            else {

                rownode.node.setExpanded(false);
            }
        }, 0)
    }
    const onClickHideManualUpload = () => {
        setshowAddForm('')
        setoverly('show-n')

    }
    const onsubmitdata = (e) => {
        e.preventDefault()
        var trucks = manualUploadDetails.trucks
        var truckList = []
        trucks.map((e) => {
            truckList.push(e.value)
        })
        //console.log(truckList, 'truckList')
        var wrno = manualUploadDetails.wrno.value
        //console.log(originalData, 'originalData')
        var record = originalData.filter(e => e.work_order_no == wrno)
        record = record[0]

        var containerCount = record.container_count
        //console.log(containerCount, 'containerCount')
        var params = {
            work_order_no: wrno,
            trucks: truckList
        }
        if (containerCount == trucks.length) {
            redirectURL.post('/consignments/updateTrucksDetails', params).then((resp) => {

            })
        }
        else {

        }
    }
    const onClickAcknowledgeBtn=(rowdata)=>{
        var row = rowdata.data 
        var params = {work_order_no:row.work_order_no,unsetField:'cha_do_modified',setField:'cha_do_planned',record:row}
        redirectURL.post('/consignments/acknowledgeData',params).then((resp)=>{
            if(resp.data.status=='Success'){
                setshow(true)
                    setbasicTitle("Acknowledgement successful")
                    setbasicType("success")
                    props.onRequest()
                    props.onReloadData(true);
            }
        })
    }
    var closeAlert = () => {
        setshow(false)
        setloadshow('show-n')
    }
    const onSelectConfirm = (e) => {
        if (e.value == 'yes') {
            setcheckfortruck(1)
        }
        else {
            setshowAddForm('')
            setoverly('show-n')
        }
    }
    const handleTrucksList = (e) => {
        setmanualUploadDetails(prev => ({
            ...prev,
            trucks: e
        }))


    }
    const onClickHideAll = () => {
        setloadshow('show-n')
    }
    const detailedviewcols = []
    //console.log(check, '435')
    var acknowledgebtn= {
        headerName: "",
        field: "",
        width: 120,
        filter: true, resizable: true,
        pinned: 'left',
        cellRendererFramework: AcknowledgeBtnComponent
    }
    var columns1 = [...summaryViewcols]
    if (classForTab.new==1){
        const pendencyIndex = columns1.findIndex(col=>col.field==="pendency_gate_in");
        if (pendencyIndex!==-1){
            columns1[pendencyIndex].hide=false;
        }
    }
    if (classForTab.planned == 1) {
        const columnToRemove = 'pendency_gate_in'; // Replace this with the actual field name of the column to remove
        columns1 = columns1.filter(column => column.field !== columnToRemove);
    }
    if(classForTab.modified==1){
        columns1.push(acknowledgebtn)
    }
    const columnwithDefs = summaryviewcheck == 1 ? columns1 : detailedviewcols
    columnwithDefs.forEach(e => {
        e.cellClass = function (params) {
          if (params.data && params.data[`${e.field}_modified`] === 1) {
            return "bgColorDangerDark";
          } else {
            return null;
          }
        };
      });
    var newCount = tabDataforintransit_plant.intransit_new
    var plannedCount = tabDataforintransit_plant.intransit_planned
    var modifiedCount = tabDataforintransit_plant.intransit_modified
    return (
        <>
            <SweetAlert
                show={show}
                type={basicType}
                title={basicTitle}
                onConfirm={closeAlert}
            >
            </SweetAlert>
            <div className="" style={{ margin: '0px', marginTop: '0px', position:"relative", top:"-40px" }}>
            <div className="d-flex flex-row" style={{position:"relative"}}>
                            <button
                                className="d-flex flex-row justify-content-center align-items-center px-3"
                                style={{
                                    background: classForTab.new == 1 ? '#0664AE' : '#FFFFFF',
                                    // color: state.isTileView ? "": "#0664AE",
                                    borderTopLeftRadius: "11px",
                                    borderBottomLeftRadius: "11px",
                                    border: "1px solid #0664AE",
                                    height: "38px",
                                    padding: '22px'
                                }}
                                onClick={() => onclickTab('New')}
                            >
                                <div
                                    style={{
                                        color: classForTab.new == 1 ? "white" : '#0664AE',
                                        fontSize: "12px",
                                        fontWeight: 700,
                                        marginLeft: "10px",
                                        lineHeight: "12px",
                                    }}
                                >
                                    New - <span>{(newCount != '' && newCount != undefined) ? newCount.length : 0}</span>
                                </div>
                            </button>

                            <button
                                className="d-flex flex-row justify-content-center align-items-center px-3"
                                style={{
                                    background: classForTab.planned == 1 ? '#0664AE' : "#FFFFFF",
                                    border: "1px solid #0664AE",
                                    height: "38px",
                                    padding: '22px'
                                }}
                                onClick={() => onclickTab('Planned')}
                            >
                                <div
                                    style={{
                                        color: classForTab.planned == 1 ? "white" : '#0664AE',
                                        fontSize: "12px",
                                        fontWeight: 700,
                                        marginLeft: "10px",
                                        lineHeight: "12px",
                                    }}
                                >
                                    Planned - <span>{(plannedCount != '' && plannedCount != undefined) ? plannedCount.length : 0}</span>
                                </div>
                            </button>
                            <button
                                className="d-flex flex-row justify-content-center align-items-center px-3"
                                style={{
                                    background: classForTab.modified == 1 ? '#0664AE' : "#FFFFFF",
                                    borderTopRightRadius: "11px",
                                    borderBottomRightRadius: "11px",
                                    border: "1px solid #0664AE",
                                    height: "38px",
                                    padding: '22px'
                                }}
                                onClick={() => onclickTab('Modified')}
                            >
                                <div
                                    style={{
                                        color: classForTab.modified == 1 ? "white" : '#0664AE',
                                        fontSize: "12px",
                                        fontWeight: 700,
                                        marginLeft: "10px",
                                        lineHeight: "12px",
                                    }}
                                >
                                    Modified - <span>{(modifiedCount != '' && modifiedCount != undefined) ? modifiedCount.length : 0}</span>
                                </div>
                            </button>
                                {classForTab.new===1?
                         <div style={{position:"absolute", right:"3px",top:"-25px" }}>
                             <button
                                        type="button"
                                        title="Save Grid Layout"
                                        name="savegrid"
                                        className="btn btn-danger action_btn"
                                        style={{height: "32px", marginTop: "30px", paddingBottom:"30px" }}

                                        onClick={onClickSaveGridState}>
                                        <i className="f12 icofont icofont-save"></i>
                                        </button>
                                </div>
                            :''} 
                    </div>
                {/* <div style={{display:'flex',justifyContent:'end'}}>

                <p style={{color:'red'}}>Note: Modification Pending At Previous Action</p>
                </div> */}
            <div id="idgrid" style={{ width: "100%", height: "478px"}} className="ag-theme-balham">
                <AgGridReact
                 ref={gridApi}
                    columnDefs={columnwithDefs}
                    defaultColDef={{
                        sortable: true,
                        filter: true,
                        editable: false,
                        resizable: true,
                        menuTabs: ['filterMenuTab']
                        // rowHeight:80,
                    }}
                    // paddingTop={10}
                    // rowHeight={70}
                    // headerHeight = {70}
                    rowData={rowData}
                    suppressRowClickSelection={true}
                    detailCellRendererParams={state.detailCellRendererParams}
                    frameworkComponents={{ ConsignmentTrucksLegs: ConsignmentTrucksLegs, CountryFlagComponent: CountryFlagComponent,AcknowledgeBtnComponent:AcknowledgeBtnComponent }}
                    enableCellChangeFlash={true}
                    suppressCellFlash={true}
                    enableRangeSelection={true}
                    paginationPageSize={50}
                    onGridReady={onGridReady}
                    onGridState={onGridState}
                    rowSelection={'multiple'}
                    masterDetail={true}
                    pagination={true}
                    sideBar={{
                        toolPanels: [
                            {
                                id: "columns",
                                labelDefault: "Columns",
                                labelKey: "columns",
                                iconKey: "columns",
                                toolPanel: "agColumnsToolPanel"
                            },
                            {
                                id: "filters",
                                labelDefault: "Filters",
                                labelKey: "filters",
                                iconKey: "filter",
                                toolPanel: "agFiltersToolPanel"
                            }
                        ]
                    }}
                    statusBar={{
                        statusPanels: [
                            // {
                            //     statusPanel: "agTotalAndFilteredRowCountComponent",
                            //     align: "left"
                            // },
                            // {
                            //     statusPanel: "agTotalRowCountComponent",
                            //     align: "center"
                            // },
                            { statusPanel: "agFilteredRowCountComponent" },
                            { statusPanel: "agSelectedRowCountComponent" },
                            { statusPanel: "agAggregationComponent" }
                        ]
                    }}
                    gridOptions={{
                        icons: {
                            // You can customize other icons as well
                            next: `<img src="${customNextIcon}" alt="Next" />`,
                            previous: `<img src="${customPreviousIcon}" alt="Previous" />`,
                            first: `<img src="${customFirstIcon}" alt="First" />`,
                            last: `<img src="${customLastIcon}" alt="Last" />`,
                          },
                    }}s
                    // ref={gridRef}
                    getRowStyle={getRowClass}

                    context={{ onClickShowShipmentLegs, updateContainerDetails ,onClickAcknowledgeBtn}}
                />
            </div>
            </div>
            <div className={"sliderBlock2 " + showAddForm} style={{ overflow: "auto" }}>
                <div className="slide-r-title slider_title_style">
                    <h6>Enter Details</h6>
                    <span className="float-right closebtn" style={{ marginTop: '-30px', marginRight: "25px" }} onClick={onClickHideManualUpload} >X</span>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <form onSubmit={onsubmitdata}>
                            <div className="slide-body">
                                <div className="row p-20p">
                                    <div className="form-group col-xl-12 col-lg-12">
                                        <label className='mb-5p'>Work Order No: </label>
                                        <Select
                                            // placeholder="Change Activity Status"
                                            closeMenuOnSelect={true}
                                            value={manualUploadDetails.wrno}
                                            // multi={true}
                                            // className={"border-radius-0"} 
                                            onChange={(e) => onSelectWON(e)}
                                            style={{ borderRadius: "0px" }}
                                            styles={{
                                                control: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: "0.8rem",
                                                    color: "#6e707e",
                                                }),
                                            }}
                                            options={workordernoList}
                                        />
                                    </div>
                                    <div className="form-group col-xl-12 col-lg-12">
                                        <label className='mb-5p'>Confirm</label>
                                        <Select
                                            // placeholder="Change Activity Status"
                                            closeMenuOnSelect={true}
                                            // value={manualUploadDetails.wrno}
                                            // multi={true}
                                            // className={"border-radius-0"} 
                                            onChange={(e) => onSelectConfirm(e)}
                                            style={{ borderRadius: "0px" }}
                                            styles={{
                                                control: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: "0.8rem",
                                                    color: "#6e707e",
                                                }),
                                            }}
                                            options={selectconfirmn}
                                        />
                                    </div>
                                    {checkfortruck == 1 ?
                                        <div className="form-group col-xl-12 col-lg-12">
                                            <label className='mb-5p'>Trucks*</label>
                                            <Select
                                                // placeholder="Change Activity Status"
                                                closeMenuOnSelect={true}
                                                value={manualUploadDetails.trucks}
                                                isMulti="true"
                                                className={"border-radius-0"}
                                                onChange={(e) => handleTrucksList(e)}
                                                style={{ borderRadius: "0px" }}
                                                styles={{
                                                    control: (provided, state) => ({
                                                        ...provided,
                                                        fontSize: "0.8rem",
                                                        color: "#6e707e",
                                                    }),
                                                }}
                                                options={trucksList}
                                            />
                                        </div> : ''}
                                    <div className="form-group col-sm-12 mb-20p text-center">
                                        <button type="submit" className="btn btn-success">Submit</button>
                                    </div>

                                </div>
                            </div>
                        </form>
                    </div>
                </div>

            </div>
            {stateForMap.sliderRouteTranslate != '' ?
				<div className={"sliderBlock2 " + stateForMap.sliderRouteTranslate} style={{ overflow: "auto" }}>
					<div className="slide-r-title">
						<h4>
							Truck No: {(stateForMap.container_no)}
                            <span className="float-right closebtn" style={{  marginRight: "100px" }} onClick={onCloseRouteDiv} >X</span>
						</h4>
					</div>
					<div className="slide-r-body" style={{ position: "relative" }}>

                        <div className="row col-xl-12 col-lg-12 ml-0p n-p-0">
                            {/* {//console.log("this.state.mapinfo ", this.state.mapinfo)} */}
                            <Mapcard2
                                 mapinfo={stateForMap.mapinfo}
                                 mapHeight={"100vh"}
                                 mapFor={"intransit"}
                                 mapData={stateForMap.mapdata}
                                geoLocShipmentsMap={state.geoLocShipmentsMap}
                            />
                            <div className="col-xl-12 col-lg-12 n-p-0">
                                <div className="crm-numbers pb-0">
                                    <h3 className="subH">{stateForMap.sidebarSubHeader}</h3>
                                    <div className="col-xl-12 col-lg-12 row">
                                        <div className="col route-block" style={{ borderRight: '1px solid #ccc' }}>
                                            <label className="sidebar-label">Truck No</label>
                                            <div>{stateForMap.routeTruck.truck_no}</div>
                                        </div>
                                        <div className="col route-block" style={{ borderRight: '1px solid #ccc' }}>
                                            <label className="sidebar-label">Start Time</label>
                                            <div>{(!isEmpty(stateForMap.routeTruck)) ? getDDMMYYYYHHMMSS(stateForMap.routeTruck.startTime) : "-"}</div>
                                        </div>
                                        <div className="col route-block" style={{ borderRight: '1px solid #ccc' }}>
                                            <label className="sidebar-label">End Time</label>
                                            <div>{(!isEmpty(stateForMap.routeTruck)) ? getDDMMYYYYHHMMSS(stateForMap.routeTruck.endTime) : "-"}</div>
                                        </div>

                                        <div className="col route-block" style={{ borderRight: '1px solid #ccc' }}>
                                            <label className="sidebar-label">GPS Distance</label>
                                            <div>{(!isEmpty(stateForMap.routeTruck)) ? Math.round(stateForMap.routeTruck.distance_without_google / 1000) : "0"} Kms</div>
                                        </div>
                                        {/* {(localStorage.getItem('roles') != "CUSTOMER") ?
                                            <div className="col route-block">
                                                <label className="sidebar-label">Tracking Type</label>
                                                {(this.state.map_type_of_tracking == 1) ?
                                                    <div>GPS Tracking</div>
                                                    :
                                                    <div>SIM Tracking</div>
                                                }
                                            </div>
                                            : ""} */}

                                    </div>
                                </div>
                            </div>
						</div>
					</div>

				</div>
				: ""
			}
            <div className={"dataLoadpage " + (loadshow)}>
            </div>
            <div className={"dataLoadpageimg " + (loadshow)}>
                <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
            </div>
            <div className={"overlay-part " + (overly)} onClick={onClickHideAll}></div>

        </>



    )
}
function isEmpty(obj) {
    for(var key in obj) {
        if(obj.hasOwnProperty(key))
            return false;
    }
    return true;
}                 
function timeConvert(n) {
	// var num = n/1000;
	// var hours = (num / (3600*24));
	// var rhours = Math.floor(hours);
	// var minutes = (n) / (60*60);
	// var rminutes = Math.round(minutes);

	var diffMs = n;
	var diffDays = Math.floor(diffMs / 86400000); // days
	var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
	var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
	

	return diffHrs + " hour(s) and " + (diffMins/60) + " minute(s).";
}                              
var currentinfowindow = null;
function getTimeInfo(marker,timedet,coords)
{
	var timeinfo = new window.google.maps.InfoWindow({
          content: "Time at this location: " + timedet
        });

	marker.addListener('click', function() {
		
				if (currentinfowindow != null) {
					currentinfowindow.close();
					currentinfowindow = timeinfo;
					timeinfo.open(marker.get('map'), marker);
				}
				else
				{
					currentinfowindow = timeinfo;
					timeinfo.open(marker.get('map'), marker);
				}
				
        });
}



function secondsToString(seconds)
{
var numdays = Math.floor(seconds / 86400);
var numhours = Math.floor((seconds % 86400) / 3600);
var numminutes = Math.floor(((seconds % 86400) % 3600) / 60);
var numseconds = ((seconds % 86400) % 3600) % 60;
return numdays + " days " + numhours + " hours " + numminutes + " minutes ";
}

function secondsToDays(seconds)
{
	var numdays = Math.floor(seconds / 86400);
	var numhours = Math.floor((seconds % 86400) / 3600);
	var numminutes = Math.floor(((seconds % 86400) % 3600) / 60);
	var numseconds = ((seconds % 86400) % 3600) % 60;
	return numdays;
}
function CheckForHtmlTag(){
	var rs = document.getElementById("invalid_reason");
	var reg =/<(.|\n)*?>/g; 
	if (reg.test(rs.value)) {
		var ErrorText = "Oops! HTML or Script is not allowed.";		
		rs.value=''
		//alert();
		return 1;
	}
}
function dateComparator(date1, date2) {
	// //console.log("dateComparator");
	// //console.log(date1.length);
	if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
		// //console.log(date1,date2);
	var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
	date1 = date1.replace(date1.split(" ")[0].split("-")[1],date_1);
	var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
	date2 = date2.replace(date2.split(" ")[0].split("-")[1],date_2);
	// //console.log(date1,date2);
	var date1Number = monthToComparableNumber(date1);
	var date2Number = monthToComparableNumber(date2);
	// //console.log(date1Number,date2Number);
	if (date1Number === null && date2Number === null) {
	  return 0;
	}
	if (date1Number === null) {
	  return -1;
	}
	if (date2Number === null) {
	  return 1;
	}
	return date1Number - date2Number;
	}
  }
function monthToComparableNumber(date) {
	// //console.log(date,date.length);
	if (date === undefined || date === null || date.length !== 16) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	////console.log(yearNumber,monthNumber,dayNumber);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}

function getMonthNumber(monthName){
	var months = [
		'Jan', 'Feb', 'Mar', 'Apr', 'May',
		'Jun', 'Jul', 'Aug', 'Sep',
		'Oct', 'Nov', 'Dec'
		];
	var monthNum = months.indexOf(monthName)+1;
	if(monthNum < 10){
		monthNum = "0"+monthNum.toString();
	}
	return monthNum;
}

function loadDateTimeScript(){
	$('.datetimepicker_mask').datetimepicker({
		mask:'39-19-9999 29:59',
		format:'d-m-Y H:i',
		onShow:false
	});
	$('.datetimepicker_date').datetimepicker({
		// mask:'39-19-9999',
		format:'d-m-Y',
		timepicker:false,
		onShow:false
	});
    var index  =window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src="https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
	//script.src="http://xdsoft.net/jqplugins/datetimepicker/"
	script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}


function GetSortDescOrder(prop) {    
	return function(a, b) {    
		if (a[prop] < b[prop]) {    
			return 1;    
		} else if (a[prop] > b[prop]) {    
			return -1;    
		}    
		return 0;    
	}    
} 


function GetSortAscOrder(prop) {    
	return function(a, b) {    
		if (a[prop] > b[prop]) {    
			return 1;    
		} else if (a[prop] < b[prop]) {    
			return -1;    
		}    
		return 0;    
	}    
} 

function GetSortASCOrder(prop) {    
	return function(a, b) {    
		if (a[prop] > b[prop]) {    
			return 1;    
		} else if (a[prop] < b[prop]) {    
			return -1;    
		}    
		return 0;    
	}    
} 

function secondsToDhms(seconds) {
    seconds = Number(seconds);
    var d = Math.floor(seconds / (3600*24));
    var h = Math.floor(seconds % (3600*24) / 3600);
    var m = Math.floor(seconds % 3600 / 60);
    var s = Math.floor(seconds % 60);
    
    var dDisplay = d >= 0 ? d + (d == 1 ? " Day " : " Days ") : "";
    var hDisplay = h >= 0 ? h + (h == 1 ? " Hr " : " Hrs ") : "";
    var mDisplay = m >= 0 ? m + (m == 1 ? " Mins " : " Mins ") : "";
    var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
    return dDisplay + hDisplay + mDisplay;
}

function distinctArrayBy(arr, propName) {
    var result = arr.reduce(function (arr1, e1) {
        var matches = arr1.filter(function (e2) {
            return e1[propName] == e2[propName];
        })
        if (matches.length == 0)
            arr1.push(e1)
        return arr1;
    }, []);

    return result;
}
function dateComparator1(date1,date2){
    ////console.log(date1,date2);
    if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
        // //console.log(date1,date2);
    var date_1 = getMonthNumber(date1.split("-")[1]);
    date1 = date1.replace(date1.split("-")[1],date_1);
    var date_2 = getMonthNumber(date2.split("-")[1]);
    date2 = date2.replace(date2.split("-")[1],date_2);
    var date1Number = monthToComparableNumber1(date1);
    var date2Number = monthToComparableNumber1(date2);
    ////console.log(date1Number,date2Number);
    if (date1Number === null && date2Number === null) {
      return 0;
    }
    if (date1Number === null) {
      return -1;
    }
    if (date2Number === null) {
      return 1;
    }
    //console.log(date1Number, date2Number, "comparator")
    return date1Number - date2Number;
    }  
}
function monthToComparableNumber1(date) {
    ////console.log(date.length);

    ////console.log(date);
    if (date === undefined || date === null || date.length !== 10) {

        return null;

    }

    var yearNumber = date.substring(6, 10);

    var monthNumber = date.substring(3, 5);

    var dayNumber = date.substring(0, 2);

    var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;

    return result;

}
function loadDateTimeScript2(){
    // alert("timeDate");
    $('.datetimepicker_mask').datetimepicker({
        format:'d-m-Y H:i'
    });
    $('.device_filter_data').datetimepicker({
        format:'Y-m-d H:i',
        timepicker:true
    });
    var index  =window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src="https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
    //script.src="http://xdsoft.net/jqplugins/datetimepicker/"
    script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}
function getDatePicker() {

    function Datepicker() {}

    Datepicker.prototype.init = function(params) {

      this.eInput = document.createElement("input");

      //this.eInput = this.eInput.setAttribute("class","datepicker");

      this.eInput.value = params.value;

      //console.log(this.eInput)

      window.$(".datepicker").datepicker({ dateFormat: "dd/mm/yy" });

    };

    Datepicker.prototype.getGui = function() {

      return this.eInput;

    };

    Datepicker.prototype.afterGuiAttached = function() {

      this.eInput.focus();

      this.eInput.select();

    };

    Datepicker.prototype.getValue = function() {

      return this.eInput.value;

    };

    Datepicker.prototype.destroy = function() {};

    Datepicker.prototype.isPopup = function() {

      return false;

    };

    return Datepicker;

  }
function DateEditor () {}
// gets called once before the renderer is used

DateEditor.prototype.init = function(params) {

    // create the cell

    //console.log(params, "params")

    this.eInput = document.createElement('input');

    this.eInput.value = params.value;
    this.eInput.setAttribute('readonly', 'true');

    // https://jqueryui.com/datepicker/

    $(this.eInput).datetimepicker({

        dateFormat: "yy-mm-dd",

        changeMonth: true,

        changeYear: true

    });

};




// gets called once when grid ready to insert the element

DateEditor.prototype.getGui = function() {

    return this.eInput;

};




// focus and select can be done after the gui is attached

DateEditor.prototype.afterGuiAttached = function() {

    this.eInput.focus();

    this.eInput.select();

};




// returns the new value after editing

DateEditor.prototype.getValue = function() {

    return this.eInput.value;

};




// any cleanup we need to be done here

DateEditor.prototype.destroy = function() {

    // but this example is simple, no cleanup, we could

    // even leave this method out as it's optional

};




// if true, then this editor will appear in a popup

DateEditor.prototype.isPopup = function() {

    // and we could leave this method out also, false is the default

    return false;

};
// function isEmpty(obj) {
//     for (var key in obj) {
//         if (obj.hasOwnProperty(key))
//             return false;
//     }
//     return true;
// }
// function timeConvert(n) {
//     var diffMs = n;
//     var diffDays = Math.floor(diffMs / 86400000); // days
//     var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
//     var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
//     return diffHrs + " hour(s) and " + (diffMins / 60) + " minute(s).";
// }
// var currentinfowindow = null;
// function getTimeInfo(marker, timedet, coords) {
//     var timeinfo = new window.google.maps.InfoWindow({
//         content: "Time at this location: " + timedet
//     });
//     marker.addListener('click', function () {

//         if (currentinfowindow != null) {
//             currentinfowindow.close();
//             currentinfowindow = timeinfo;
//             timeinfo.open(marker.get('map'), marker);
//         }
//         else {
//             currentinfowindow = timeinfo;
//             timeinfo.open(marker.get('map'), marker);
//         }
//     });
// }
// function secondsToString(seconds) {
//     var numdays = Math.floor(seconds / 86400);
//     var numhours = Math.floor((seconds % 86400) / 3600);
//     var numminutes = Math.floor(((seconds % 86400) % 3600) / 60);
//     var numseconds = ((seconds % 86400) % 3600) % 60;
//     return numdays + " days " + numhours + " hours " + numminutes + " minutes ";
// }
// function secondsToDays(seconds) {
//     var numdays = Math.floor(seconds / 86400);
//     var numhours = Math.floor((seconds % 86400) / 3600);
//     var numminutes = Math.floor(((seconds % 86400) % 3600) / 60);
//     var numseconds = ((seconds % 86400) % 3600) % 60;
//     return numdays;
// }
// function CheckForHtmlTag() {
//     var rs = document.getElementById("invalid_reason");
//     var reg = /<(.|\n)*?>/g;
//     if (reg.test(rs.value)) {
//         var ErrorText = "Oops! HTML or Script is not allowed.";
//         rs.value = ''
//         //alert();
//         return 1;
//     }
// }
// function dateComparator(date1, date2) {
//     if (date1 != "" && date2 != "" && date1 != undefined && date2 != undefined) {
//         var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
//         date1 = date1.replace(date1.split(" ")[0].split("-")[1], date_1);
//         var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
//         date2 = date2.replace(date2.split(" ")[0].split("-")[1], date_2);
//         var date1Number = monthToComparableNumber(date1);
//         var date2Number = monthToComparableNumber(date2);
//         if (date1Number === null && date2Number === null) {
//             return 0;
//         }
//         if (date1Number === null) {
//             return -1;
//         }
//         if (date2Number === null) {
//             return 1;
//         }
//         return date1Number - date2Number;
//     }
// }
// function monthToComparableNumber(date) {
//     if (date === undefined || date === null || date.length !== 16) {
//         return null;
//     }
//     var yearNumber = date.substring(6, 10);
//     var monthNumber = date.substring(3, 5);
//     var dayNumber = date.substring(0, 2);
//     var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
//     return result;
// }
// function getMonthNumber(monthName) {
//     var months = [
//         'Jan', 'Feb', 'Mar', 'Apr', 'May',
//         'Jun', 'Jul', 'Aug', 'Sep',
//         'Oct', 'Nov', 'Dec'
//     ];
//     var monthNum = months.indexOf(monthName) + 1;
//     if (monthNum < 10) {
//         monthNum = "0" + monthNum.toString();
//     }
//     return monthNum;
// }
// function loadDateTimeScript() {
//     $('.datetimepicker_mask').datetimepicker({
//         mask: '39-19-9999',
//         format: 'd-m-Y',
//         onShow: false
//     });
//     $('.datetimepicker_date').datetimepicker({
//         format: 'd-m-Y',
//         timepicker: false,
//         onShow: false
//     });
//     var index = window.document.getElementsByTagName("script")[1]
//     var script = window.document.createElement("script")
//     script.src = "https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
//     script.async = true
//     script.defer = true
//     index.parentNode.insertBefore(script, index)
// }
// function GetSortDescOrder(prop) {
//     return function (a, b) {
//         if (a[prop] < b[prop]) {
//             return 1;
//         } else if (a[prop] > b[prop]) {
//             return -1;
//         }
//         return 0;
//     }
// }
// function GetSortAscOrder(prop) {
//     return function (a, b) {
//         if (a[prop] > b[prop]) {
//             return 1;
//         } else if (a[prop] < b[prop]) {
//             return -1;
//         }
//         return 0;
//     }
// }
// function GetSortASCOrder(prop) {
//     return function (a, b) {
//         if (a[prop] > b[prop]) {
//             return 1;
//         } else if (a[prop] < b[prop]) {
//             return -1;
//         }
//         return 0;
//     }
// }
// function secondsToDhms(seconds) {
//     seconds = Number(seconds);
//     var d = Math.floor(seconds / (3600 * 24));
//     var h = Math.floor(seconds % (3600 * 24) / 3600);
//     var m = Math.floor(seconds % 3600 / 60);
//     var s = Math.floor(seconds % 60);
//     var dDisplay = d >= 0 ? d + (d == 1 ? " Day " : " Days ") : "";
//     var hDisplay = h >= 0 ? h + (h == 1 ? " Hr " : " Hrs ") : "";
//     var mDisplay = m >= 0 ? m + (m == 1 ? " Mins " : " Mins ") : "";
//     var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
//     return dDisplay + hDisplay + mDisplay;
// }
// function distinctArrayBy(arr, propName) {
//     var result = arr.reduce(function (arr1, e1) {
//         var matches = arr1.filter(function (e2) {
//             return e1[propName] == e2[propName];
//         })
//         if (matches.length == 0)
//             arr1.push(e1)
//         return arr1;
//     }, []);

//     return result;
// }
// function dateComparator1(date1, date2) {
//     if (date1 != "" && date2 != "" && date1 != undefined && date2 != undefined) {
//         var date_1 = getMonthNumber(date1.split("-")[1]);
//         date1 = date1.replace(date1.split("-")[1], date_1);
//         var date_2 = getMonthNumber(date2.split("-")[1]);
//         date2 = date2.replace(date2.split("-")[1], date_2);
//         var date1Number = monthToComparableNumber1(date1);
//         var date2Number = monthToComparableNumber1(date2);
//         if (date1Number === null && date2Number === null) {
//             return 0;
//         }
//         if (date1Number === null) {
//             return -1;
//         }
//         if (date2Number === null) {
//             return 1;
//         }
//         //console.log(date1Number, date2Number, "comparator")
//         return date1Number - date2Number;
//     }
// }
// function monthToComparableNumber1(date) {
//     if (date === undefined || date === null || date.length !== 10) {
//         return null;
//     }
//     var yearNumber = date.substring(6, 10);
//     var monthNumber = date.substring(3, 5);
//     var dayNumber = date.substring(0, 2);
//     var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
//     return result;
// }
// function loadDateTimeScript2() {
//     $('.datetimepicker_mask').datetimepicker({
//         format: 'd-m-Y'
//     });
//     $('.device_filter_data').datetimepicker({
//         format: 'Y-m-d',
//         timepicker: true
//     });
//     var index = window.document.getElementsByTagName("script")[1]
//     var script = window.document.createElement("script")
//     script.src = "https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
//     script.async = true
//     script.defer = true
//     index.parentNode.insertBefore(script, index)
// }
// function getDatePicker() {
//     function Datepicker() { }
//     Datepicker.prototype.init = function (params) {
//         this.eInput = document.createElement("input");
//         this.eInput.value = params.value;
//         //console.log(this.eInput)
//         window.$(".datepicker").datepicker({ dateFormat: "dd-mm-yy" });
//     };
//     Datepicker.prototype.getGui = function () {
//         return this.eInput;
//     };
//     Datepicker.prototype.afterGuiAttached = function () {
//         this.eInput.focus();
//         this.eInput.select();
//     };
//     Datepicker.prototype.getValue = function () {
//         return this.eInput.value;
//     };
//     Datepicker.prototype.destroy = function () { };
//     Datepicker.prototype.isPopup = function () {
//         return false;
//     };
//     return Datepicker;
// }
// function DateEditor() { }
// DateEditor.prototype.init = function (params) {
//     this.eInput = document.createElement('input');
//     this.eInput.value = params.value;
//     $(this.eInput).datepicker({
//         dateFormat: "yy-mm-dd",
//         changeMonth: true,
//         changeYear: true,
//         showTime: false,
//     });
// };
// DateEditor.prototype.getGui = function () {
//     return this.eInput;
// };
// DateEditor.prototype.afterGuiAttached = function () {
//     this.eInput.focus();
//     this.eInput.select();
// };
// DateEditor.prototype.getValue = function () {
//     return this.eInput.value;
// };
// DateEditor.prototype.destroy = function () {
// };
// DateEditor.prototype.isPopup = function () {
//     return false;
// };
function removeDuplicates(arr, prop) {
    const seen = new Set();
    return arr.filter(obj => {
        const propValue = obj[prop];
        const propString = typeof propValue === 'object' ? JSON.stringify(propValue) : propValue;
        if (seen.has(propString)) {
            return false;
        }
        seen.add(propString);
        return true;
    });
}
function sortByCreatedDate(arr, descending = true) {
    if (arr != '' && arr != undefined) {
      if (arr.length > 0) {
        const comparison = (a, b) => {
          const dateA = new Date(a.modified_date);
          const dateB = new Date(b.modified_date);
          if (descending) {
            return dateB - dateA; // Ascending order
          } else {
            return dateA - dateB; // Descending order
          }
        };
          arr.sort(comparison);
        return arr; 
      }
    } else {
      return arr; 
    }
  }
export default IntransitToPlant