
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
var redirectURL = require('../redirectURL');
const EdittruckComponent = (props) => {
    const handleRouteClick = (e) => {
        e.stopPropagation();
        console.log(props,"props10")
         props.context.onClickedittruck(props);
    };
    return (
        <div className="map-icon-component">
               {(false
                // (props.data.tpt_container_planned==1 && props.data.container_count_increase_check != 1) || (props.data.tpt_trucks_modified==1 && props.data.container_count_decrease_before_pickup ==1 && props.data.container_count_increase_before_pickup != 1) || (props.data.tpt_trucks_modified!=1 && props.data.container_count_decrease_before_pickup !=1 && props.data.container_count_increase_before_pickup !=1 )
                )?<img  src={require("../../assets/images/update-gray.png")} style={{height:'15px'}}/>:<img onClick={handleRouteClick}  src={require("../../assets/images/Update.png")} style={{height:'15px'}}/>}

               {/* <img onClick={handleRouteClick}  src={require("../../assets/images/Update.png")} style={{height:'20px',marginLeft:'30px'}}/> */}
        </div>
    );
};
export default EdittruckComponent;
