import React, { useState, useEffect, useRef } from 'react';
// import SweetAlert from 'react-bootstrap-sweetalert';

// import Datepicker from "../layouts/datepicker";
// import moment from "moment";
// import { getHyphenYYYYMMDDHHMMSS, getDDMMMYYYY, getHyphenYYYYMMDD, getHyphenDDMMYYYY, getHyphenDDMMMYYYYHHMM } from '../common/utils';
// import SearchIcon from "../../assets/icons/search-icon.svg";
// import RefreshIcon from "../../assets/images/refresh-icon.svg";
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import 'ag-grid-enterprise';
import 'ag-grid-enterprise/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-enterprise/styles/ag-theme-balham.css';
import EditPartner from './updateportgateinbtn';
import DeletePartner from './deleteUser';
import ForgetPasswordBtn from './forgetpasswordbtn';
// import Modal from 'react-responsive-modal';
import Select from 'react-select';
import indiaFlag from '../../assets/images/indianFlag1.png';

// import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import 'react-datetime/css/react-datetime.css';
// import Datetime from 'react-datetime';
// import CountryFlagComponent from '../layouts/countryFlag';
// import DestinationFlagComponent from '../layouts/destinationCountryFlag';
// import ViewDOdetailsComponent from "../layouts/viewDOdetails";
import customNextIcon from "../../assets/icons/next.png";
import customPreviousIcon from "../../assets/icons/back.png";
import customFirstIcon from "../../assets/icons/back_left.png";
import customLastIcon from "../../assets/icons/next_right.png";
// import { fetchGrid } from "../fetchGridStructure"
import redirectURL from "../redirectURL";
import Plant from './plantUpdates';
// import { getchadata } from '../../../../bajaj_node/routes/controller/consignmentControllerAutometrics';
// import { e } from 'mathjs';
var moment = require('moment');

const Users = () => {
    const gridApi = useRef(null);
    const gridColumnApi = useRef(null);


    var [rowData, setrowData] = useState([])
    var [selectedData, setselectedData] = useState([])
    var [filterdata, setfilterdata] = useState([])

    var [originalData, setoriginalData] = useState([])
    var [show, setshow] = useState(false)
    var [reload, setreload] = useState(false)
    var [basicTitle, setbasicTitle] = useState('')
    var [basicType, setbasicType] = useState('')
    var [overly, setoverly] = useState('show-n')
    var [loadshow, setloadshow] = useState('show-n')
    var [showCreateUser, setShowCreateUser] = useState("");
    var [created, setCreated] = useState("");
    var [created_error,setcreated_error] = useState('')
    var [modified, setModified] = useState("");
    var [deleted, setDeleted]= useState('');
    var [firstName, setFirstName] = useState("");
    var [lastName, setLastName] = useState("");
    // const [countryCode, setCountryCode] = useState('+91'); // Default country code
    const [mobileNumber, setMobileNumber] = useState('');
    var [showEditForm, setShowEditForm] = useState('');
    var [selectedrowdata,setselectedrowdata] = useState({});
    var [deleteRow, setDeleteRow] = useState({});
    // var [roleList1, setRoleList1]=useState([]);
    var [moduleList, setModuleList]= useState([]);
    var [activeAlert, setActiveAlert] = useState('');
    var [active, setActive] = useState(0);
    var [statusChangeRow, setStatusChangeRow]= useState({});
    const [manualUploadDetails, setmanualUploadDetails] = useState({
       created_date: moment.parseZone().format("YYYY-MM-DD HH:mm:ss"),
       first_name:'',
       last_name:'',
       selected_role:{'label':'','value':''},
       modules:'',
       email_id:'',
       mobile_no:'',
       specific_role:{'label':'', 'value':''}

    })
    const [editmanualUploadDetails, seteditmanualUploadDetails] = useState({
        created_date:'',
        first_name:'',
        last_name:'',
        selected_role:{'label':'','value':''},
        modules:'',
        email_id:'',
        mobile_no:'',
        specific_role:{'label':'', 'value':''}
 
     })
    const [roles_list,setroles_list] = useState([
        {'label':'Admin','value':'admin'},
        {'label':'CHA','value':'cha'},
        {'label':'Plant','value':'plant'},
        {'label':'Transporter','value':'tpt'}
    ])
    const [cha_list, setCha_list]= useState([
        {'label':'CHA Vessel Availability', 'value':'cha_vessel_availability'},
        {'label':'Upload DO', 'value':'upload_do'},
        {'label':'Update Shipping Bill', 'value':'update_shipping_bill'},
        {'label':'Update Reporting Time', 'value':'update_reporting_time'},
        {'label':'Update LEO Date', 'value':'update_leo_date'},
        {'label':'Gate-In To Port', 'value':'gatein_to_port'},
        {'label':'Shipping Onboard', 'value':'shipping_onboard'},
        {'label':'Bill Of Lading', 'value':'bill_of_lading'},
        {'label':'Buffer Yard', 'value':'buffer_yard'},
    ])
    var [deleteSlide, setDeleteSlide] = useState('');
    const [error, setError] = useState('');
    const [showError, setShowError] = useState(false);
    // const handleCountryCodeChange = (e) => {
    //   setCountryCode(e.target.value);
    // };
    const [mailErrorMsg, setMailErrorMsg] = useState('');
    const [showMailError, setShowMailError] = useState(false);
    const handleMobileNumberChange = (e) => {
        setMobileNumber(e.target.value);
    };
    const [roleList1, setRoleList1] = useState([])
    const [plantList, setPlantList] = useState([])
    const [chaList, setChaList] = useState([])
    const [tptList, setTptList] = useState([])
    const [selectDropdown, setSelectDropdown] = useState(false)

    var operationsColumnDefs = [
        {
            headerName: "",
            field: "_id",
            width: 90,
            cellRenderer: 'EditPartner'
            
            // suppressSizeToFit: true,
        },
        {
            headerName: "",
            field: "_id",
            width: 50,
            cellRenderer: 'DeletePartner'
            // suppressSizeToFit: true,
        },
        // {
        //     headerName: "",
        //     field: "_id",
        //     width: 130,
        //     cellRenderer: 'ForgetPasswordBtn'
        //     // suppressSizeToFit: true,
        // },
    ]

    const columnDefs = [

        {
            headerName: "Name",
            field: "username",
            filter: true,
            width: 200,
            resizable: true,
        },

        {
            headerName: "Role",
            width: 120,
            field: "role",
            // colId:"start_alert",
            resizable: true,
            // valueGetter : function(params){
            //     //console.log(params.data.start_alert);
            //     return getHyphenDDMMMYYYYHHMM(params.data.start_alert);
            // },

        },
        {
            headerName: "Mobile No",
            field: "mobile_no",
            // colId:"end_alert",
            width: 120,
            filter: true,
            resizable: true,
            // valueGetter : function(params){
            //     return getHyphenDDMMMYYYYHHMM(params.data.end_alert);
            // },
        },
        {
            headerName: "Status",
            field: "is_active",
            // colId:"end_alert",
            width: 120,
            filter: true,
            resizable: true,
            cellRenderer: params => {
                const toggleActive = () => {
                  setActiveAlert("slide95");
                  setoverly("show-m");
                  setActive(params.value);
                  setStatusChangeRow(params.data);
                //   const newValue = params.value === 1 ? 0 : 1;
                  // Call an update function here to update the value in your data source
                //   console.log("New value:", newValue);
                };
            
                return (
                  <img
                    src={params.value == 1 ? require('../../assets/images/activeuser.png') : require('../../assets/images/inactiveuser.png')}
                    alt={params.value == 1 ? "Active" : "Inactive"}
                    className='activeIcon'
                    onClick={toggleActive}
                    style={{ cursor: "pointer", marginTop: "-5px" }}
                  />
                );
              },
            // valueGetter : function(params){
            //     return getHyphenDDMMMYYYYHHMM(params.data.end_alert);
            // },
        },
        {
            headerName: "Email",
            width: 200,
            // colId:"email",
            field: "email",
            resizable: true,
        },
        {
            headerName: "Module Access",
            field: "module_access",
            // colId:"notify_via_email_cc",
            width: 200,
            filter: true,
            resizable: true,
        },
        ...operationsColumnDefs
    ]
    useEffect(()=>{
        if (showCreateUser ==='slide55'){
           document.body.style.overflow = 'hidden';
        }else{
           document.body.style.overflow = 'auto';
        }
        return ()=>{
           document.body.style.overflow = 'auto';
        }
      }, [showCreateUser])
      useEffect(()=>{
        if (showEditForm ==='slide55'){
           document.body.style.overflow = 'hidden';
        }else{
           document.body.style.overflow = 'auto';
        }
        return ()=>{
           document.body.style.overflow = 'auto';
        }
      }, [showEditForm])
    useEffect(() => {
        if (created) {
            const timer = setTimeout(() => {
                setCreated(false);
                setoverly("show-n") // Remove the created class after 3 seconds
            }, 3000); // 3000 milliseconds = 3 seconds

            return () => clearTimeout(timer); // Cleanup the timer on component unmount
        }
    }, [created]);
    useEffect(() => {
        if (created_error) {
            const timer = setTimeout(() => {
                setcreated_error
                (false);     

                setoverly("show-n") // Remove the created class after 3 seconds
                setShowCreateUser('slide55'); 
            },  1000); // 3000 milliseconds = 3 seconds

            return () => clearTimeout(timer); // Cleanup the timer on component unmount
        }
    }, [created_error]);
   
    useEffect(() => {
        if (modified) {
            const timer = setTimeout(() => {
                setModified(false);
                setoverly("show-n") // Remove the created class after 3 seconds
            }, 3000); // 3000 milliseconds = 3 seconds

            return () => clearTimeout(timer); // Cleanup the timer on component unmount
        }
    }, [modified]);
    useEffect(() => {
        if (deleted) {
            const timer = setTimeout(() => {
                setDeleted(false);
                setoverly("show-n") // Remove the created class after 3 seconds
            }, 3000); // 3000 milliseconds = 3 seconds

            return () => clearTimeout(timer); // Cleanup the timer on component unmount
        }
    }, [deleted]);
    useEffect(()=>{
       getData()
       getchadata1()
       getplantdata1()
       gettptdata1()
    },[])
    const getplantdata1 = async()=>{
        await redirectURL.post("/consignments/getplantdata").then((resp) => {
            try {
                var data = resp.data
                // console.log(data,'64')
                var plant = []
                data.map((e) => {
                    plant.push({ label: e.plant_code, value: e.plant_code })
                })
                plant = removeDuplicateObjects(plant);
                setPlantList(plant)
            }
            catch (e) {

            }
        })
    }
    const getchadata1 = async()=>{
        redirectURL.post("/consignments/getchaCodes").then((resp) => {
                    try {
                        var data = resp.data
                        // console.log(data,'cha64')
                        var cha = []
                        data.map((e) => {
                            cha.push({ label: e.cha_name, value: e.cha_code })
                        })
                        // console.log(cha,'327')
                        // plant = removeDuplicateObjects(cha);
                        setChaList(cha)
                    }
                    catch (e) {
        
                    }
                })
    }
    const gettptdata1 = async()=>{
        redirectURL.post("/consignments/gettptnames").then((resp) => {
                    try {
                        var data = resp.data
                        // console.log(data,'tpt64')
                        var tpt = []
                        data.map((e) => {
                            tpt.push({ label: e.transporter_name, value: e.transporter_code })
                        })
                        // console.log(tpt,'327')
                        // plant = removeDuplicateObjects(cha);
                        setTptList(tpt)
                    }
                    catch (e) {
        
                    }
                })
    }
   
    const getData = async ()=>{
        await redirectURL.post('/consignments/getusers').then((resp)=>{
            var records = resp.data 
            setrowData(records)
        })
    }
    const handleCreateUser = () => {
        setShowCreateUser("slide55");
        setoverly("show-m");
        
    }
    const hideSlideBlock = () => {
        setShowCreateUser("");
        setoverly("show-n");
        setModuleList([]);
        setShowError(false);
        setShowMailError(false);
        setSelectDropdown(false);
        setloadshow("show-n");
        setmanualUploadDetails({
            first_name:'',
            last_name:'',
            email_id:'',
            mobile_no:'',
            selected_role:{'label':'','value':''},
            role:{'label':'','value':''},
            specific_role:{'label':'','value':''}
        })
    }
    const onClickCreate = (e) => {
        e.preventDefault()
        setoverly("show-m");
        setSelectDropdown(false);
        // setloadshow('show-m')
        if (!showMailError && !showError){
        var params = {
            // created_date:manualUploadDetails.created_date,
            firstname:manualUploadDetails.first_name,
            username:manualUploadDetails.first_name,
            lastname:manualUploadDetails.last_name,
            email:manualUploadDetails.email_id,
            mobile_no:manualUploadDetails.mobile_no,
            role:manualUploadDetails.selected_role.value,
            user_type:manualUploadDetails.selected_role.value,
            modules:manualUploadDetails.modules,
              // plant_code: manualUploadDetails.specific_role.value
        }
        var role = manualUploadDetails.selected_role.value 
        if(role=='plant'){
            params.plant_code_list = [manualUploadDetails.specific_role.value]
        }
        else{
            params.plant_code = manualUploadDetails.specific_role.value
        }
        if(manualUploadDetails.selected_role.value=='tpt'){
            params.user_type = 'transporter'
        }
        // console.log(manualUploadDetails.selected_role,'manualUploadDetails.selected_role')
        //3280 - Addded mandatory field condition
        if(manualUploadDetails.selected_role.value!='' && manualUploadDetails.selected_role.value!=undefined &&manualUploadDetails.selected_role.value!=null && manualUploadDetails.first_name!='' && manualUploadDetails.first_name!=undefined && manualUploadDetails.first_name!=null && manualUploadDetails.last_name!='' && manualUploadDetails.last_name!= undefined && manualUploadDetails.last_name!=null && manualUploadDetails.email_id!='' && manualUploadDetails.email_id!=undefined && manualUploadDetails.email_id !=null && manualUploadDetails.mobile_no!='' && manualUploadDetails.mobile_no!=undefined && manualUploadDetails.mobile_no!=null){
            // console.log(params,'178')
            redirectURL.post('/consignments/adduser',params).then((resp)=>{
                // console.log(resp.data, "addUser")
                if(resp.data.message=='success'){
                    setShowCreateUser("");
                    setCreated("slide85");
                    setoverly("show-m");
                    setloadshow('show-n');
                    getData()
                    setmanualUploadDetails({
                        created_date:'',
                        first_name:'',
                        last_name:'',
                        email_id:'',
                        mobile_no:'',
                        selected_role:{'label':'','value':''},
                        role:{'label':'','value':''},
                        specific_role:{'label':'','value':''}
                    })
                    setModuleList([]);

                }
            })
    
    
        }
        else{
            setshow(true)
            setShowCreateUser('');      
            setcreated_error("slide85");
            setoverly("show-m");
            setloadshow('show-n');
         
        }
    }
       
    }
    const [countryCode, setCountryCode] = useState({ value: '+91', label: <div><img src={indiaFlag} alt="Indian Flag" className='indianFlagImg' /> +91</div> });

    const handleCountryCodeChange = (selectedOption) => {
        setCountryCode(selectedOption);
    };


    const options = [
        { value: '+91', label: <div><img src={indiaFlag} alt="Indian Flag" className='indianFlagImg' /> +91</div> },
        { value: '+401', label: <div><img src={indiaFlag} alt="Indian Flag" className='indianFlagImg' /> +401</div> },
        // Add more options as needed
    ];
    const restrictToAlphabets=(event)=>{
        const input = event.target.value;
        const inputName = event.target.name;
        const regex = /^[a-zA-Z\s]*$/;
        if (regex.test(input)){
            if (inputName === "firstName"){
                setmanualUploadDetails(prev => ({...prev, first_name: input}))
            }
            if (inputName === 'lastName'){
                setmanualUploadDetails(prev => ({...prev, last_name:input}))
            } 
        }
       
    }

const restrictToNumbers = (event) => {
    let input = event.target.value.replace(/\D/g, '');  // Remove non-digits
    if (input.length > 10) {
        event.preventDefault();
        return;
    }
    setmanualUploadDetails(prev => ({ ...prev, mobile_no: input }));

    var validNumbers = ["6", "7", "8", "9"]
    // Validate length and starting digit after change
    if (input.length === 10 && (validNumbers.includes(input[0]))) {
        setError('');
        // console.log("error no")
        setShowError(false);

    } else if (input.length < 10) {
        setError('Please enter a valid mobile number');
        setShowError(true);

    } else if (!validNumbers.includes(input[0])) {
        setError('Please enter a valid mobile number');
        setShowError(true);

    } 
};

const restrictToAlphabets1=(event)=>{
    const input = event.target.value;
    const inputName = event.target.name;
    const regex = /^[a-zA-Z\s]*$/;
    if (regex.test(input)){
        if (inputName === "firstName"){
            seteditmanualUploadDetails(prev => ({...prev, first_name: input}))
        }
        if (inputName === 'lastName'){
            seteditmanualUploadDetails(prev => ({...prev, last_name:input}))
        } 
    }
   
}
//3281- Added phone number validation
const restrictToNumbers1 = (event) => {
    let input = event.target.value.replace(/\D/g, '');  // Remove non-digits
    if (input.length > 10) {
        event.preventDefault();
        return;
    }
    seteditmanualUploadDetails(prev => ({ ...prev, mobile_no: input }));

    var validNumbers = ["6", "7", "8", "9"]
    // Validate length and starting digit after change
    if (input == '') {
        setError('');
        setShowError(false);

    } else if (input.length === 10 && (validNumbers.includes(input[0]))) {
        setError('');
        // console.log("error no")
        setShowError(false);

    } 
    else if (input.length < 10 || !validNumbers.includes(input[0])) {
        setError('Please enter a valid mobile number');
        setShowError(true);

    } 
};

const onClickportgatein = async (rowNode)=>{
    // console.log(rowNode, "777")
    setselectedrowdata(rowNode.data)
    // var date1 =rowNode.data.created_date
    // console.log(date1, "hhh")
    // var date = ISODate("${date1}")
    seteditmanualUploadDetails({
        created_date:rowNode.data.created_date,
        first_name:rowNode.data.username,
        last_name:rowNode.data.lastname,
        selected_role:{'label':rowNode.data.role,'value':rowNode.data.role},
        modules:rowNode.data.modules,
        email_id:rowNode.data.email,
        mobile_no:rowNode.data.mobile_no,
        _id: rowNode.data._id
    })
    setShowEditForm("slide55");
    setoverly("show-m");
}
const hideEditBlock = ()=>{
    setSelectDropdown(false);
    setShowEditForm("");
    setoverly("show-n");
    setselectedrowdata({});
    setShowError(false);
    setShowMailError(false);


}
const hideDeleteBlock = ()=>{
    setDeleteSlide("");
    setoverly("show-n");
    setselectedrowdata({})

}
const onClickModified = async(e)=>{
    e.preventDefault();
    setSelectDropdown(false);
    if (!showError && !showMailError){
        // console.log(editmanualUploadDetails.selected_role,'editmanualUploadDetails.selected_role')
        var params = {
            created_date:editmanualUploadDetails.created_date,
            username:editmanualUploadDetails.first_name,
            lastname:editmanualUploadDetails.last_name,
            email:editmanualUploadDetails.email_id,
            mobile_no:editmanualUploadDetails.mobile_no,
            role:editmanualUploadDetails.selected_role.value,
            modules:editmanualUploadDetails.modules,
            specific_role : editmanualUploadDetails.specific_role.value,
            rowid:selectedrowdata._id
        }
        // console.log("params", params)
        redirectURL.post('/consignments/updateUsers',params).then((resp)=>{
            // console.log(resp.data, "9876")
            if(resp.data.status=='success'){
                getData();
                setShowEditForm("")
                setModified("slide85");
                setoverly("show-m");
                setloadshow('show-n');
                seteditmanualUploadDetails({
                    created_date:'',
                    first_name:'',
                    last_name:'',
                    email_id:'',
                    mobile_no:'',
                    selected_role:{'label':'','value':''},
                    role:{'label':'','value':''},
                    specific_role:{'label':'','value':''}
                })
            }
        })
    }
    
    
   
}

const onClickDelete = async(rowNode)=>{
    // console.log(rowNode, "999")
    if (rowNode.data.created_date){
        setDeleteSlide("slide95");
        setoverly("show-m");
        setDeleteRow(rowNode.data);
    }

}
const onClickDeleteItem = async()=>{
    // console.log(deleteRow, "deldata");
    if (deleteRow.created_date){
        const created_date = {
            created_date:deleteRow.created_date
        }
        await redirectURL.post('/consignments/deleteUser',created_date).then((resp)=>{
            // console.log(resp.data, "9876")
            if(resp.data.status=='success'){
                getData();
                setDeleteSlide("");
                setDeleted("slide85");
                setoverly("show-m");
                setloadshow('show-n')
            }
        })
    }
   


}
const onChangeSelectRole = (event,selectType)=>{
    if(selectType == "edit"){
        seteditmanualUploadDetails(prev => ({ ...prev, selected_role: event }))
    }
    else if(selectType == "create"){
        setmanualUploadDetails(prev => ({ ...prev, selected_role: event }))
    }
    let value = event.value;
    if (value==='cha'){
        // setModuleList(cha_list)
        setRoleList1(chaList)
        setSelectDropdown(true)
    }else if(value === 'plant'){
        // setModuleList([])
        setRoleList1(plantList)
        setSelectDropdown(true)

    }else if (value === 'admin'){
        setSelectDropdown(false)
    }else if (value === 'tpt'){
        setRoleList1(tptList)
        setSelectDropdown(true)
    }
}
const hideActiveBlock=()=>{
    setActiveAlert("");
    setoverly("show-n");
}
const changeStatus =(e)=>{
    e.preventDefault();
    // console.log(e,"statusrow")
    // console.log("changess")
    // console.log(statusChangeRow, "statusChangeRow")
    const toggleActive = active===1 ? 0 : 1; 
    var params = {
        is_active: toggleActive,
        rowid:statusChangeRow._id

    }
    redirectURL.post('/consignments/updateUserStatus',params).then((resp)=>{
        // console.log(resp.data, "9876")
        if(resp.data.status=='success'){
            getData();
            setActiveAlert("")
            // setM("slide85");
            setoverly("show-n");
            setloadshow('show-n')
        }
    })

}
// console.log(selectedrowdata,'selectedrowdata')
// const handleKeyPress = (e)=>{
//     if (e.target.value>10 && e.key!== 'Backspace'){
//         e.preventDefault();
//     }
// }
//3281 - Added email validation
const emailValidation = (event)=>{
    var email = event.target.value;
    setmanualUploadDetails(prev => ({...prev, email_id: email}));

    if (email.endsWith('.com') || email.endsWith('.in')){
        setMailErrorMsg('');
        setShowMailError(false);
    }else{
        setShowMailError(true);
        setMailErrorMsg('Please enter a valid email id')
    }
}
const emailValidation1 = (event)=>{
    var email = event.target.value;
    seteditmanualUploadDetails(prev => ({...prev, email_id: email}));

    if (email.endsWith('.com') || email.endsWith('.in')){
        setMailErrorMsg('');
        setShowMailError(false);
    }else{
        setShowMailError(true);
        setMailErrorMsg('Please enter a valid email id')
    }
}
    const onChangeSpecificRole = (event, selectType) => {
        console.log(selectType,"selectType111")
        if (selectType == "edit") {
            seteditmanualUploadDetails(prev => ({ ...prev, specific_role: event }))
        }
        else if (selectType == "create") {
            setmanualUploadDetails(prev => ({ ...prev, specific_role: event }))
        }
    }
    return (
        <>
            {/* <SweetAlert
                show={show}
                type={basicType}
                title={basicTitle}
                onConfirm={closeAlert}
            >
            </SweetAlert> */}
            <div className="col-xl-12 col-lg-12 col-sm-12 beffect">
                <div className='d-flex justify-content-between mt-30p'>
                    <h1 className="heading">
                        Users
                    </h1>
                    <button type="button" className="f12 create-btn-outline" onClick={handleCreateUser}>
                        <img src={require('../../assets/images/add.png')} className='add_img' />Create User
                    </button>
                </div>
                <div className="row" style={{ marginTop: '20px' }}>
                    <div className="col-sm-12" >
                        <div id="idgrid" style={{ width: "100%", height: "550px" }} className="ag-theme-balham">
                            <AgGridReact
                                ref={gridApi}
                                // modules={AllModules}
                                columnDefs={columnDefs}
                                defaultColDef={{
                                    sortable: true,
                                    filter: true,
                                    editable: false,
                                    resizable: true,
                                    menuTabs: ['filterMenuTab']
                                }}
                                rowData={rowData}
                                suppressRowClickSelection={true}
                                enableCellChangeFlash={true}
                                suppressCellFlash={true}
                                enableRangeSelection={true}
                                paginationPageSize={100}
                                // onGridReady={onGridReady}
                                // onGridState={onGridState}
                                frameworkComponents={{EditPartner: EditPartner,
                                    DeletePartner: DeletePartner,ForgetPasswordBtn:ForgetPasswordBtn}}
                                rowSelection={'multiple'}
                                masterDetail={true}
                                pagination={true}
                                // frameworkComponents={{ CountryFlagComponent: CountryFlagComponent, DestinationFlagComponent: DestinationFlagComponent }}
                                sideBar={{
                                    toolPanels: [
                                        {
                                            id: "columns",
                                            labelDefault: "Columns",
                                            labelKey: "columns",
                                            iconKey: "columns",
                                            toolPanel: "agColumnsToolPanel"
                                        },
                                        {
                                            id: "filters",
                                            labelDefault: "Filters",
                                            labelKey: "filters",
                                            iconKey: "filter",
                                            toolPanel: "agFiltersToolPanel"
                                        }
                                    ]
                                }}
                                statusBar={{
                                    statusPanels: [
                                        { statusPanel: "agFilteredRowCountComponent" },
                                        { statusPanel: "agSelectedRowCountComponent" },
                                        { statusPanel: "agAggregationComponent" }
                                    ]
                                }}
                                gridOptions={{
                                    icons: {
                                        // You can customize other icons as well
                                        next: `<img src="${customNextIcon}" alt="Next" />`,
                                        previous: `<img src="${customPreviousIcon}" alt="Previous" />`,
                                        first: `<img src="${customFirstIcon}" alt="First" />`,
                                        last: `<img src="${customLastIcon}" alt="Last" />`,
                                      },
                                }}
                                context={{onClickportgatein, onClickDelete,onClickDeleteItem}}

                            // gridOptions={{
                            //     icons: {
                            //         next: `<img src="${customNextIcon}" alt="Next" />`,
                            //         previous: `<img src="${customPreviousIcon}" alt="Previous" />`,
                            //         first: `<img src="${customFirstIcon}" alt="First" />`,
                            //         last: `<img src="${customLastIcon}" alt="Last" />`,
                            //     },
                            // }}
                            />
                        </div>
                    </div>
                </div>
            </div>
            {/* <Modal open={open} onClose={onCloseModal}  style={{ width: '0%'}}>
                        <h1 className='heading ml-40p mt-30p' >Create User</h1>
                </Modal>     */}

            <div className={"sliderBlock3 " + (showCreateUser)} >
                <h1 className='user-heading ml-40p mt-40p' >Create User</h1>
                <span className="float-right ffclosebtn1" style={{ marginRight: "25px", fontSize: '20px' }} onClick={hideSlideBlock} >
                    <img src={require('../../assets/images/closeIcon.png')} className='closeIcon mt-5p' style={{ marginTop: "-40px" }} />
                </span>

                <div className='d-flex flex-row ml-30p mt-20p'>
                    <div className="col-xl-3 col-lg-3 mr-50p">
                        <label className='user-fields'>First Name<span className="err-txt">*</span></label>
                        <input
                            type="text"
                            // placeholder='Enter First Name'
                            name="firstName"
                            // id="inputcode"
                            value={manualUploadDetails.first_name}
                            className="form-control1"
                            // onChange={(e) => setmanualUploadDetails(prev => ({ ...prev, first_name: e.target.value }))}
                            onChange = {restrictToAlphabets}


                            required
                        />
                    </div>
                    <div className="col-xl-3 col-lg-3 mr-50p">
                        <label className='user-fields'>Last Name<span className="err-txt">*</span></label>
                        <input
                            type="text"
                            // placeholder='Enter First Name'
                            name="lastName"
                            // id="inputcode"
                             value={manualUploadDetails.last_name}
                            className="form-control1"
                            onChange={restrictToAlphabets}
                            required
                        />
                    </div>
                    <div className="col-xl-3 col-lg-3">
                        <label className='user-fields'>Email Id<span className="err-txt">*</span></label>
                        <input
                            type="text"
                            // placeholder='Enter First Name'
                            name="emailId"
                            // id="inputcode"
                             value={manualUploadDetails.email_id}
                            className="form-control1"
                            onChange={emailValidation}
                            required
                        />
                        {showMailError && <span className="err-txt1">*Please enter a valid email id</span>}
                    </div>
                </div>

                <div className='d-flex flex-row ml-30p mt-15p'>
                    {/* <div className="col-xl-3 col-lg-3 mr-50p">
            <label className='user-fields'>Mobile Number</label>
            <input
                    type="text"
                    // placeholder='Enter First Name'
                    name="firstName"
                    // id="inputcode"
                    // value={this.state.newPartnerName}
                    className="form-control1"
                    // onChange={(e) => { this.onChangeHandler(e) }}
                    required
                                                />
        </div> */}
                    <div className="col-xl-3 col-lg-3 mr-50p mt-5p">

                        <div className="mobile-number-field">
                            <label className='user-fields'>Mobile Number<span className="err-txt">*</span></label>
                            <div className='d-flex flex-row'>
                                <Select
                                    value={countryCode}
                                    onChange={handleCountryCodeChange}
                                    options={options}
                                    className='select-style'
                                    styles={{
                                        control: (provided, state) => ({
                                            ...provided,
                                            width: "70px",
                                            height: "24px"
                                        }),
                                        menu: (provided, state) => ({
                                            ...provided,
                                            width: "80px"
                                        }),
                                        dropdownIndicator: (provided, state) => ({
                                            ...provided,
                                            width: "8px", // Adjust the width as needed
                                            height: "8px", // Adjust the height as needed
                                            padding: "0",
                                            marginTop: "-3px",
                                            marginLeft: "-14px",
                                            marginBottom: "10px", // Adjust the margin as needed
                                            color: "#000000", // Arrow color
                                            '&:hover': {
                                                color: "#000000", // Hover color
                                            },
                                        }),
                                    }}
                                />
                                <input
                                    type="text"
                                    name="mobileNumber"
                                    className="form-control2"
                                    // placeholder="Enter Mobile Number"
                                    value={manualUploadDetails.mobile_no}
                                    onChange={restrictToNumbers}
                                    required
                                />
                            </div>
                            {showError && <span className="err-txt2">*Please enter a valid mobile number</span>}
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 mr-50p">
                        <label className='user-fields'>Role<span className="err-txt">*</span></label>
                        <Select

                            closeMenuOnSelect={true}
                             value={manualUploadDetails.selected_role}
                            // multi={true}
                            // className={"border-radius-0"} 
                            onChange={(event) => onChangeSelectRole(event, "create")}
                            // style={{ borderRadius: "0px" }}
                            styles={{
                                control: (provided, state) => ({
                                    ...provided,
                                    fontSize: "0.8rem",
                                    color: "#6e707e",
                                    minHeight: "22px",
                                    width: "240px"
                                }),
                                menu: (provided, state) => ({
                                    ...provided,
                                    minHeight: "22px",
                                    width: "240px",
                                    overflowY:"auto",
                                    maxHeight:"100px"

                                }),
                                menuList: (provided, state) => ({
                                    ...provided,
                                    maxHeight: "100px",  // Ensures the inner list also has a max height for scrolling
                                }),
                            }}
                         options={roles_list}
                        />
                    </div>
                    {selectDropdown && (<div className="col-xl-3 col-lg-3 mr-50p">
                        <label className='user-fields'>Search {manualUploadDetails.selected_role.label} Name<span className="err-txt">*</span></label>
                        <Select

                            closeMenuOnSelect={true}
                            value={manualUploadDetails.specific_role}
                            // multi={true}
                            // className={"border-radius-0"} 
                            onChange={(event) => onChangeSpecificRole(event, "create")}                            
                            // style={{ borderRadius: "0px" }}
                            styles={{
                                control: (provided, state) => ({
                                    ...provided,
                                    fontSize: "0.8rem",
                                    color: "#6e707e",
                                    minHeight: "22px",
                                    width: "240px",
                                    
                                }),
                                menu: (provided, state) => ({
                                    ...provided,
                                    minHeight: "22px",
                                    width: "240px",
                                    overflowY:"hidden",
                                    maxHeight:"125px",
                                }),
                                menuList: (provided, state) => ({
                                    ...provided,
                                    maxHeight: "125px",
                                    overflowY: "auto" // Allow scrolling in the menu list
                                  })

                            }}
                           
                            options={roleList1}
                        />
                    </div>)}

                    {/* <div className="col-xl-3 col-lg-3">
                        <label className='user-fields'>Modules<span className="err-txt">*</span></label>
                        <Select
                            value={manualUploadDetails.modules}

                            onChange={(e) => setmanualUploadDetails(prev => ({ ...prev, modules: e }))}
                            styles={{
                                control: (provided, state) => ({
                                    ...provided,
                                    fontSize: "0.8rem",
                                    color: "#6e707e",
                                    height: "32px",
                                    width: "178px"
                                }),
                                menu: (provided, state) => ({
                                    ...provided,
                                    height: "32px",
                                    width: "178px",
                                    overflowY:"auto",
                                    maxHeight:"100px"

                                })
                            }}
                            options={moduleList}
                            required/>
                    </div> */}

                </div>
                <div className='d-flex flex-row ml-30p mt-50p'>
                    <button className='btn_cancel' style={{ marginRight: "18px", marginLeft: "15px" ,width:"120px"}} onClick={hideSlideBlock} >Cancel</button>
                    <button className='btn_hover6' style={{width:"120px"}} onClick={onClickCreate}>Create</button>
                </div>

            </div>
            <div className={"sliderBlock3 " + (created)} >
                <div className='d-flex flex-row'>
                    <img src={require('../../assets/images/successfullIcon.png')} className="created-tick mr-30p ml-40p mt-25p" /><h1 className='heading' style={{ marginTop: "50px" }}>Created Successfully</h1>
                </div>
            </div>
            <div className={"sliderBlock3 " + (created_error)} >
                <div className='d-flex flex-row'>
                    <img src={require('../../assets/images/error.png')} className="created-tick mr-30p ml-40p mt-25p" /><h1 className='heading' style={{ marginTop: "50px" }}>Please Enter All Fields</h1>
                </div>
            </div>
            
           
            <div className={"sliderBlock3 " + (showEditForm)} >
                <h1 className='user-heading ml-40p mt-40p' >Modify User</h1>
                <span className="float-right ffclosebtn1" style={{ marginRight: "25px", fontSize: '20px' }} onClick={hideEditBlock} >
                    <img src={require('../../assets/images/closeIcon.png')} className='closeIcon mt-5p' style={{ marginTop: "-40px" }} />
                </span>

                <div className='d-flex flex-row ml-30p mt-20p'>
                    <div className="col-xl-3 col-lg-3 mr-50p">
                        <label className='user-fields'>First Name</label>
                        <input
                            type="text"
                            // placeholder='Enter First Name'
                            name="firstName"
                            // id="inputcode"
                            value={editmanualUploadDetails.first_name}
                            className="form-control1"
                            // onChange={(e) => setmanualUploadDetails(prev => ({ ...prev, first_name: e.target.value }))}
                            onChange = {restrictToAlphabets1}


                            required
                        />
                    </div>
                    <div className="col-xl-3 col-lg-3 mr-50p">
                        <label className='user-fields'>Last Name</label>
                        <input
                            type="text"
                            // placeholder='Enter First Name'
                            name="lastName"
                            // id="inputcode"
                             value={editmanualUploadDetails.last_name}
                            className="form-control1"
                            onChange={restrictToAlphabets1}
                            required
                        />
                    </div>
                    <div className="col-xl-3 col-lg-3">
                        <label className='user-fields'>Email Id</label>
                        <input
                            type="text"
                            // placeholder='Enter First Name'
                            name="emailId"
                            // id="inputcode"
                             value={editmanualUploadDetails.email_id}
                            className="form-control1"
                            onChange={emailValidation1}
                            required
                        />
                         {showMailError && <span className="err-txt1">*Please enter a valid email id</span>}
                    </div>
                </div>

                <div className='d-flex flex-row ml-30p mt-15p'>
                    {/* <div className="col-xl-3 col-lg-3 mr-50p">
            <label className='user-fields'>Mobile Number</label>
            <input
                    type="text"
                    // placeholder='Enter First Name'
                    name="firstName"
                    // id="inputcode"
                    // value={this.state.newPartnerName}
                    className="form-control1"
                    // onChange={(e) => { this.onChangeHandler(e) }}
                    required
                                                />
        </div> */}
                    <div className="col-xl-3 col-lg-3 mr-50p mt-5p">

                        <div className="mobile-number-field">
                            <label className='user-fields'>Mobile Number</label>
                            <div className='d-flex flex-row'>
                                <Select
                                    value={countryCode}
                                    onChange={handleCountryCodeChange}
                                    options={options}
                                    className='select-style'
                                    styles={{
                                        control: (provided, state) => ({
                                            ...provided,
                                            width: "70px",
                                            height: "24px"
                                        }),
                                        menu: (provided, state) => ({
                                            ...provided,
                                            width: "80px"
                                        }),
                                        dropdownIndicator: (provided, state) => ({
                                            ...provided,
                                            width: "8px", // Adjust the width as needed
                                            height: "8px", // Adjust the height as needed
                                            padding: "0",
                                            marginTop: "-3px",
                                            marginLeft: "-14px",
                                            marginBottom: "10px", // Adjust the margin as needed
                                            color: "#000000", // Arrow color
                                            '&:hover': {
                                                color: "#000000", // Hover color
                                            },
                                        }),
                                    }}
                                />
                                
                                <input
                                    type="text"
                                    name="mobileNumber"
                                    className="form-control2"
                                    // placeholder="Enter Mobile Number"
                                    value={editmanualUploadDetails.mobile_no}
                                    onChange={restrictToNumbers1}
                                    // onKeyDown={handleKeyPress}
                                    required
                                />
                            </div>
                            {showError && <span className="err-txt2">*Please enter a valid mobile number</span>}

                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 mr-50p">
                        <label className='user-fields'>Role</label>
                        <Select

                            closeMenuOnSelect={true}
                             value={editmanualUploadDetails.selected_role}
                            // multi={true}
                            // className={"border-radius-0"} 
                            // onChange={(e) => seteditmanualUploadDetails(prev => ({ ...prev, selected_role: e }))}
                            onChange={(event) => onChangeSelectRole(event, "edit")}
                            // style={{ borderRadius: "0px" }}
                            styles={{
                                control: (provided, state) => ({
                                    ...provided,
                                    fontSize: "0.8rem",
                                    color: "#6e707e",
                                    minHeight: "22px",
                                    width: "240px",
                                }),
                                menu: (provided, state) => ({
                                    ...provided,
                                    minHeight: "22px",
                                    width: "240px",
                                    overflowY:"auto",
                                    maxHeight:"100px"

                                }),
                                menuList: (provided, state) => ({
                                    ...provided,
                                    maxHeight: "100px",  // Ensures the inner list also has a max height for scrolling
                                }),
                            }}
                         options={roles_list}
                        />
                    </div>
                    {selectDropdown && (<div className="col-xl-3 col-lg-3 mr-50p">
                        <label className='user-fields'>Search {editmanualUploadDetails.selected_role.label} Name<span className="err-txt">*</span></label>
                        <Select

                            closeMenuOnSelect={true}
                            value={editmanualUploadDetails.specific_role}
                            // multi={true}
                            // className={"border-radius-0"} 
                            onChange={(event) => onChangeSpecificRole(event, "edit")}                            
                            // style={{ borderRadius: "0px" }}
                            styles={{
                                control: (provided, state) => ({
                                    ...provided,
                                    fontSize: "0.8rem",
                                    color: "#6e707e",
                                    minHeight: "22px",
                                    width: "240px",
                                    
                                }),
                                menu: (provided, state) => ({
                                    ...provided,
                                    minHeight: "22px",
                                    width: "240px",
                                    overflowY:"hidden",
                                    maxHeight:"125px",
                                }),
                                menuList: (provided, state) => ({
                                    ...provided,
                                    maxHeight: "125px",
                                    overflowY: "auto" // Allow scrolling in the menu list
                                  })

                            }}
                           
                            options={roleList1}
                        />
                    </div>)}
                    {/* <div className="col-xl-3 col-lg-3">
                        <label className='user-fields'>Modules</label>
                        <Select
                            value={editmanualUploadDetails.modules}

                            onChange={(e) => seteditmanualUploadDetails(prev => ({ ...prev, modules: e }))}
                            styles={{
                                control: (provided, state) => ({
                                    ...provided,
                                    fontSize: "0.8rem",
                                    color: "#6e707e",
                                    height: "32px",
                                    width: "178px"
                                }),
                                menu: (provided, state) => ({
                                    ...provided,
                                    height: "32px",
                                    width: "178px",
                                    overflowY:"auto",
                                    maxHeight:"100px"

                                })
                            }} />
                    </div> */}

                </div>
                <div className='d-flex flex-row ml-30p mt-50p'>
                    <button className='btn_cancel' style={{ marginRight: "18px", marginLeft: "15px" ,width:"120px"}} onClick={hideEditBlock} >Cancel</button>
                    <button className='btn_hover6' style={{width:"120px"}} onClick={onClickModified}>Modify</button>
                </div>

            </div>
            <div className={"sliderBlock3 " + (modified)} >
                <div className='d-flex flex-row'>
                    <img src={require('../../assets/images/successfullIcon.png')} className="created-tick mr-30p ml-40p mt-25p" /><h1 className='heading' style={{ marginTop: "50px" }}>Modified Successfully</h1>
                </div>
            </div>
            <div className={"sliderBlock3 " + (deleteSlide)} >
                <span className="float-right ffclosebtn1" style={{ marginRight: "25px", fontSize: '20px' }} onClick={hideDeleteBlock} >
                    <img src={require('../../assets/images/closeIcon.png')} className='closeIcon mt-5p' style={{ marginTop: "-40px" }} />
                </span>

                <div className='d-flex mt-60p' style={{marginLeft:"42px"}}>
                            <img src={require('../../assets/images/deleteWarning.png')} className='delWarningIcon mr-10p'/>
                            <div className='d-flex flex-column ml-20p'>
                                <h1 className='del-heading'>Are you sure you want to delete this user ?</h1>
                            </div>
                </div>

                
                <div className='d-flex flex-row ml-30p mt-20p'>
                    <button className='btn_del_cancel' style={{ marginRight: "36px", marginLeft: "15px" }} onClick={hideDeleteBlock} >Cancel</button>
                    <button className='btn_hover7' onClick={onClickDeleteItem}>Delete</button>
                </div>

            </div>
            <div className={"sliderBlock3 " + (deleted)} >
                <div className='d-flex flex-row'>
                    <img src={require('../../assets/images/deletedSuccessfulIcon.png')} className="created-tick mr-30p ml-40p mt-25p" /><h1 className='heading' style={{ marginTop: "50px" }}>Deleted Successfully</h1>
                </div>
            </div>
            <div className={"sliderBlock3 " + (activeAlert)} >
                <span className="float-right ffclosebtn1" style={{ marginRight: "25px", fontSize: '20px' }} onClick={hideDeleteBlock} >
                    <img src={require('../../assets/images/closeIcon.png')} className='closeIcon mt-5p' style={{ marginTop: "-40px" }} />
                </span>

                <div className='d-flex mt-60p' style={{marginLeft:"42px"}}>
                            <div className='d-flex flex-column'>
                                <h1 className='del-heading'>Are you sure you want to change the status of this user ?</h1>
                            </div>
                </div>

                
                <div className='d-flex flex-row ml-30p mt-20p'>
                    <button className='btn_del_cancel' style={{ marginRight: "36px", marginLeft: "15px" }} onClick={hideActiveBlock} >No</button>
                    <button className='btn_hover8' onClick={changeStatus}>Yes</button>
                </div>

            </div>
            <div className={"dataLoadpage " + (loadshow)}>
            </div>
            <div className={"dataLoadpageimg " + (loadshow)}>
                <div className="loader-box"><div className="loader-box"><div className="loader"></div></div></div>
            </div>
            <div className={"overlay-part " + (overly)}></div>




        </>

    )
}
function removeDuplicateObjects(arr) {
    const uniqueObjects = new Set();
    const filteredArray = arr.filter(obj => {
        const key = JSON.stringify(obj);
        if (!uniqueObjects.has(key)) {
            uniqueObjects.add(key);
            return true;
        }
        return false;
    });
    return filteredArray;
}


export default Users;