import React, { useState, useEffect, useRef, useReducer } from "react"
import SweetAlert from 'react-bootstrap-sweetalert';

import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import 'ag-grid-enterprise';
import 'ag-grid-enterprise/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-enterprise/styles/ag-theme-balham.css';
import 'react-datetime/css/react-datetime.css';
import { getHyphenYYYYMMDDHHMMSS, getDDMMMYYYY, getHyphenDDMMYYYY,getHyphenYYYYMMDD,getHyphenYYYYMMDDHHMM,getHyphenDDMMYYYYHHMM } from '../common/utils';
import ConsignmentTrucksLegs from "./containertruckslegs";
import CountryFlagComponent from "./countryFlag";
import AcknowledgeBtnComponent from "./acknowledgeBtn";
import OpenexaminationBtnComponent from "./openexaminationbtn";
import UpdateUploadDOBtn1 from "./updateuploaddo1";
import ViewDOdetailsComponent from "./viewDOdetails";
import customNextIcon from "../../assets/icons/next.png";
import customPreviousIcon from "../../assets/icons/back.png";
import customFirstIcon from "../../assets/icons/back_left.png";
import customLastIcon from "../../assets/icons/next_right.png";
import LeoDateComponent from "./leodatecomponent";
import Select from "react-select";
import Mapcard2 from "./drawmap";
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import {fetchGrid} from "../fetchGridStructure"
import redirectURL from "../redirectURL";
import moment from "moment";
var initialState = {
    detailCellRendererParams: {},
};

var reducer = (state, action) => {
    switch (action.type) {
        case 'SET_DETAIL_RENDERER_PARAMS':
            //console.log('2020')
            return {
                ...state,
                detailCellRendererParams: action.payload,
            };
        // case 'SET_DETAIL_RENDERER_PARAMS_FOR_STOPPAGES':
        //  //console.log('6767')
        //  return {
        //      ...state,
        //      detailCellRendererParams: action.payload,
        //  };
        default:
            return state;
    }
};
const ContainerReporting = (props) => {
    const gridApi = useRef(null);
    var [state, dispatch] = useReducer(reducer, initialState);
    const [rowData, setrowData] = useState([])
    var [originalData, setoriginalData] = useState([])
    // var [gridApi, setGridApi] = useState(null);
    var [gridColumnApi, setgridColumnApi] = useState(null)
    var gridRef = useRef();
    var [summaryviewcheck, setsummaryviewcheck] = useState(1)
    var [detailedviewcheck, setdetailedviewcheck] = useState(0)
    const [showAddForm1, setshowAddForm1] = useState('')
    const [showAddForm, setshowAddForm] = useState('')

    var [checkfortruck, setcheckfortruck] = useState(0)
    var selectconfirmn = [
        { label: 'Yes', value: 'yes' }, { label: 'No', value: 'no' }
    ]
    var [loadshow, setloadshow] = useState('show-n')
    var [overly, setoverly] = useState('show-n')
    var [trucksList, settrucksList] = useState([])
    var [selected_row, setselected_row] = useState({})

    const [manualUploadDetails, setmanualUploadDetails] = useState({
        trucks: '',
        wrno: '',
        file_1:'',
        container:'',
    })
    var [workordernoList, setworkOrderList] = useState([])
    var [containerLegsData, setContainerLegsData] = useState([])
    var [show, setshow] = useState(false)
    var [basicTitle, setbasicTitle] = useState('')
    var [basicType, setbasicType] = useState('')
    var [parentrow, setparentrow] = useState({})
    var [stateForMap, setStateForMap] = useState({
        sliderRouteTranslate: '',
        showDiv: 'show-n',
        mapinfo: {
            coords: [
                // { lat: 8.76735, lng: 78.13425 },
                // { lat: 18.76735, lng: 88.13425 },
            ],
        },
        mapdata:[],
        dealer: '',
        consignment_code: '',
        truck_no: '',
        container_no: '',
        maptruckno: '',
        routeTruck: '',
        sidebarSubHeader: '',
        rownode: '',
        consigner_coordinates: '',
        consignee_coordinates: '',
        map_type_of_tracking: '',
        baseRouteCoords: '',
        mapHeight: "94vh",
    })
    var [tabforleodate, settabforleodate] = useState({
        leo_new: [],
        leo_planned: [],
        leo_modified: []
    })
    var [classForTab, setclassforTab] = useState({
        planned: 0,
        new: 1,
        modified: 0,
    })
    var [containerslist,setcontainerslist] =useState([])
    const [summaryViewcols, setsummaryViewcols] = useState([

        {
            headerName: "",
            field: "containerLegs",
            width: 60,
            pinned: 'left',
            cellRenderer: 'ConsignmentTrucksLegs'
        },
        {
            headerName: "Work Order No",
            field: "work_order_no",
            width: 140,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Production Date",
            field: "production_date",
            width: 150,
            filter: true,
            resizable: true,
            cellEditor:DateEditor,
            valueGetter: function (params) {
                try {
                    if (params.data.production_date != "" && params.data.production_date != undefined) {
                        return getHyphenDDMMYYYY(params.data.production_date);
                    } else {
                        return "Not Available";
                    }
                } catch (e) {
                    // Handle the exception if needed
                }
            },
            comparator: dateComparator1,
            
        },
        // {
        //     headerName: "Tentative Vessel ETD",
        //     field: "tentative_vessel_etd",
        //     width: 160,
        //     filter: true,
        //     resizable: true,
        //     valueGetter: function (params) {
        //         try {
        //             if (params.data.tentative_vessel_etd != "" && params.data.tentative_vessel_etd != undefined) {
        //                 return getHyphenDDMMYYYY(params.data.tentative_vessel_etd);
        //             }
        //             else {
        //                 return "Not Available";
        //             }
        //         }
        //         catch (e) { }
        //     },
        // },
        {
            headerName: "Stuffing Date",
            field: "stuffing_date",
            width: 150,
            filter: true,
            resizable: true,
            editable: true,
            valueGetter: function (params) {
                try {
                    if (params.data.stuffing_date != "" && params.data.stuffing_date != undefined) {
                        return getHyphenDDMMYYYY(params.data.stuffing_date);
                    }
                    else {
                        return "Not Available";
                    }
                }
                catch (e) { }
            },
        },
        {
            headerName: "Ship To Country",
            field: "ship_to_country",
            width: 130,
            filter: true,
            resizable: true,
            // cellRenderer: 'CountryFlagComponent'
        },
        {
            headerName: "40ft Containers",
            field: "ft40_containers",
            width: 140,
            filter: true,
            resizable: true,
        },
        {
            headerName: "20ft Containers",
            field: "ft20_containers",
            width: 140,
            filter: true,
            resizable: true,
        },
        {
            headerName: "POD",
            field: "pod",
            width: 200,
            filter: true,
            resizable: true,
        },

        {
            headerName: "Plant",
            field: "plant",
            width: 120,
            filter: true,
            resizable: true,
        },
        {
            headerName: "CHA Name",
            field: "cha_name",
            width: 120,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Plant Name",
            field: "plant_name",
            width: 120,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Transporter Name",
            field: "tpt_name",
            width: 200,
            filter: true,
            resizable: true,
        },

        {
            headerName: "Booking No",
            field: "booking_no",
            width: 160,
            filter: true,
            resizable: true,
        },

        {
            headerName: "CHA",
            field: "cha",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "3rd Party Inspection Req",
            field: "third_party_inspection_req",
            width: 190,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Port Of Loading",
            field: "port_of_loading",
            width: 190,
            filter: true,
            resizable: true,
        },
        {
            headerName: "VIN Type Description",
            field: "vin_type_description",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "VIN Type",
            field: "vin_type",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: 'LEO Date',
            field: "leo_date",
            resizable: true,
            width: 90,
            filter: true,
            pinned:'left',
            editable: (params)=>params.data.cha_leo_new==1,
            cellEditor: DateEditor,
            valueGetter: function (params) {
                try {
                    if (params.data.leo_date != "" && params.data.leo_date != undefined) {
                        return getHyphenDDMMYYYY(params.data.leo_date);
                    } else {
                        return "";
                    }
                } catch (e) {
                    // Handle the exception if needed
                }
            },
            comparator: dateComparator1,
        },

    ])
    useEffect(() => {
        if (gridApi.current) {
            getUserGridState();
        }
        loadDateTimeScript();
    }, [gridApi]);

    useEffect(() => {
        if (props.checkforuploaddata == 13) {
          const validCols = ["Work Order No","LEO Date"];
          const bulkUploadFields = [
            { name: 'Work Order No', inputName: 'work_order_no', required: true },
            { name: 'LEO Date', inputName: 'leo_date', required: true },

        ]
          const apiForUpload = 'updateContainerleodate';
          const csvfilename = '../../assets/json/cha_based_leo_date.csv'
          const columndefs = [
            {
                headerName: "Work Order No",
                field: "work_order_no",
                width: 120,
                filter: true,
                resizable: true,
            },
            {
                headerName: "LEO Date",
                field: "leo_date",
                width: 120,
                filter: true,
                resizable: true,
                editable: (params) => {
                    const date = params.data.leo_date;
                    // Check if date is in DD-MM-YYYY format
                    const datePattern = /^\d{2}-\d{2}-\d{4}$/;
                    return !(date && datePattern.test(date)); // Editable if date is invalid
                },
                valueGetter: (params) => {
                    const dateTime = params.data.leo_date;
                    // Check if dateTime is in DD-MM-YYYY HH:mm format
                    const dateTimePattern = /^\d{2}-\d{2}-\d{4}$/;
                    if (dateTime && dateTimePattern.test(dateTime)) {
                        return dateTime;
                    } else {
                        return "Invalid Date Format";
                    }
                },
                cellStyle: (params) => {
                    const dateTime = params.value;
                    // Apply red color if the date format is invalid
                    if (dateTime === "Invalid Date Format") {
                        return { background: 'red', color: 'black' };
                    }
                    return null;
                }
            },
           
          ]
          props.uploadBulkData(validCols, bulkUploadFields, apiForUpload,columndefs);
          props.onRequest()
        }
      }, [props.checkforuploaddata]);
    
    useEffect(() => {
        // redirectURL.post('/consignments/getContainersDetails', { "status": { "$in": [ 15] } }).then((resp) => {
        //     var records = resp.data
        //     setContainerLegsData(resp.data.containerDetails)
        //     var work_order_level_data = resp.data.work_order_level_data
        //     setrowData(work_order_level_data)
        //     setoriginalData(work_order_level_data)
        // })
        if (gridApi.current && gridApi.current.api) {
            gridApi.current.api.forEachNode((node) => {
                if (node.expanded) {
                    node.setExpanded(false);  // Collapse the row if expanded
                }
            });
        } else {
            console.log("gridApi is not initialized");
        }
        var records = props.data
        var new_trucks = records.leo_new
        if(new_trucks!='' && new_trucks!=undefined){
            new_trucks = sortByCreatedDate(new_trucks)
        }
            var planned_trucks = records.leo_planned
            if(planned_trucks!='' && planned_trucks!=undefined){
                planned_trucks = sortByCreatedDate(planned_trucks)
            }
            var modified_trucks = records.leo_modified
            if(modified_trucks!='' && modified_trucks!=undefined){
                modified_trucks = sortByCreatedDate(modified_trucks)
            }
        var containerRepData
        if (classForTab.new) {
            var containerRepData = new_trucks
        }
        else if (classForTab.planned) {
            var containerRepData = planned_trucks
        }
        else if (classForTab.modified) {
            var containerRepData = modified_trucks
        }    
            setrowData(containerRepData)
            setoriginalData(containerRepData)
            settabforleodate({
                leo_new: new_trucks,
                leo_planned: planned_trucks,
                leo_modified: modified_trucks
            })
            var container_list = props.data.container_new_1
            container_list = container_list.filter(e=>e.examination_check!=1)
            var containerslist = []
            container_list.map((e)=>{
                containerslist.push({'label':e.container_no,'value':e.container_no})
            })
            setcontainerslist(containerslist)
    }, [props.data])
    const onGridReady = params => {
        gridApi.current = params;
        // gridColumnApi.current = params.columnApi;
        getUserGridState();
    };
    const showdata = (e) => {
        if (e == 'detailed view') {
            setdetailedviewcheck(1)
            setsummaryviewcheck(0)
        }
        else if (e == 'summary view') {
            setdetailedviewcheck(0)
            setsummaryviewcheck(1)
        }
    }
    const updateBtn = () => {
        setshowAddForm("slide30")
        setoverly("show-m")
    }
    const onSelectWON = (e) => {
        //console.log(e)
        setmanualUploadDetails(prev => ({
            ...prev,
            wrno: e
        }))
    }

    var onCloseRouteDiv = () => {
        setStateForMap(prevState => ({
            showDiv: 'show-n',
            sliderRouteTranslate: "",
        }))
        setoverly('show-n')
    }
    const getUserGridState = () => {
        redirectURL.post("/consignments/usergridstates", { screenurl: '/updateleonew' })
            .then(async (resp) => {
                //console.log("resp.data0999", resp.data)
                restoreGridStates(resp.data);
                var grid = resp.data;
                var oCols= fetchGrid(summaryViewcols, grid[0].gridcolumns)
                //console.log("oCols",oCols)
                
                // setsummaryViewcols(oCols);
            })
    }
    const restoreGridStates = (griddata) => {
        try {
            if (griddata.length > 0) {
                var oCols= fetchGrid(summaryViewcols, griddata[0].gridcolumns)
                setsummaryViewcols(oCols);
            }
        } catch (e) {
            //console.log(e);
        }
    };

    const onGridState = () => {
        ////console.log(gridApi);

        /*Get  Current Columns State and Store in this.colState */
        const colState = gridApi.current.columnApi.getColumnState();

        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
        const rowGroupState = gridApi.current.columnApi.getRowGroupColumns();

        /*Get Current Pivot Columns State and Store in this.pivotColumns, 
         * User should enable the Pivot mode.*/
        const pivotColumns = gridApi.current.columnApi.getPivotColumns();

        /*Get Current Filter State and Store in window.filterState */

        window.filterState = gridApi.current.api.getFilterModel();
        gridApi.current.api.setFilterModel(window.filterState);
        getUserGridState();


    }
    var onClickSaveGridState = () => {
        try {
            // //console.log("SaveGrid", gridApi.current.getSortModel())
            window.colState = gridApi.current.columnApi.getColumnState();
            window.groupState = gridApi.current.columnApi.getColumnGroupState();
            // window.sortState = gridApi.current.columnApi.getSortModel();
            // window.filterState = gridApi.current.api.getFilterModel();
            var screenpage = '/updateleonew'
            let reqparams = {
                gridcolumns: window.colState,
                gridgroup: window.groupState,
                gridcolsort: window.sortState,
                gridcolfilter: window.filterState,
                userId: localStorage.getItem("userid"),
                screenurl: '/updateleonew',
                screentitle: screenpage
            }
            //console.log("reqparams after save grid", reqparams);
            redirectURL.post("/consignments/saveGridStates", reqparams)
                .then((response) => {
                    //console.log("response forr", response)
                    // if(response.data.status === "200"){
                    setshow(true)
                    setbasicTitle("Successfully saved grid layout")
                    setbasicType("success")
                    // setState1({
                    // 	screenurl: window.location.pathname,
                    // 	screentitle: screenpage
                    // })
                    setTimeout(() => {
                        getUserGridState();
                    }, 2000)
                    // getUserGridState();
                    // }
                })
                .catch(function (e) {
                    //console.log("Error ", e)
                })
            //console.log('hit')
        }
        catch (e) {
            //console.log(e)
        }
    }
    const onclickTab = (e) => {
        if (e == 'New') {
            setclassforTab({
                new: 1,
                planned: 0,
                modified: 0
            })
            props.setcheckforbulk(true)

            setrowData(tabforleodate.leo_new)
            setoriginalData(tabforleodate.leo_new)
        }
        else if (e == 'Planned') {
            setclassforTab({
                new: 0,
                planned: 1, 
                modified: 0
            })
            props.setcheckforbulk(false)
             // As Requested From QA Hiding the bulk upload option in planned Tab
            setrowData(tabforleodate.leo_planned)
            setoriginalData(tabforleodate.leo_planned)
        }
        else if (e == 'Modified') {
            setclassforTab({
                new: 0,
                planned: 0,
                modified: 1
            })
            props.setcheckforbulk(false)

            setrowData(tabforleodate.leo_modified)
            setoriginalData(tabforleodate.leo_modified)
        }
    }
    var onClickuploadLeoDate = async (e) => {
        var params = {
            // container_no:e.container_no ,
            work_order_no: e.data.work_order_no,
            // truck_no:e.truck_no,
        }
        var check = 0
        if (e.data.leo_date != undefined && e.data.leo_date != null && e.data.leo_date != "") {
            var leo_date = moment.parseZone(e.data.leo_date).format('DD-MM-YYYY')
            params.leo_date = leo_date
            check = 1
        }
        if (check != 0) {
            //console.log(params, 'params')
            redirectURL.post('/consignments/updateContainerleodate', params).then((resp) => {
                //console.log(resp.data)
                if (resp.data.status == 'Success') {
                    setshow(true)
                    setbasicTitle("LEO Date has been successfully updated!")
                    setbasicType("success")
                    setloadshow('show-n')
                    setoverly('show-n')
                    props.onRequest()

                } else {
                    setshow(true)
                    setbasicTitle(resp.data.message)
                    setbasicType("danger")
                    setloadshow('show-n')
                    setoverly('show-n')
                }
            })
        } else {
            setshow(true)
            setbasicTitle("Please Provide LEO Date")
            setbasicType("danger")
            setloadshow('show-n')
            setoverly('show-n')
        }
    }
    const onShowShipmentLegRouteMap = async (e) => {
        var params = {
            work_order_no: e.work_order_no,
            truck_no: e.truck_no,
            container_no: e.container_no
        };
        setloadshow('show-m');
        setoverly('show-m');

        try {
            const resp = await redirectURL.post('/consignments/getmapdata', params);
            //console.log(resp.data);
            var gps_route = resp.data.gps_route.coords;
            if (!Array.isArray(gps_route)) {
                gps_route = gps_route.replace(/NaN/g, "0");
                gps_route = JSON.parse(gps_route);
            }
            if (!gps_route || gps_route.length === 0) {
                setshow(true);
                setbasicTitle('No GPS Data Available');
                setbasicType('danger');
                setloadshow('show-n');
                setoverly('show-n');

            } else {
                var allCoordinates = [];
                var allldbcoords = [];
                var gps_route =gps_route;
                var ldb_data = resp.data.ldb_route
                var ldb_route = ldb_data.ldb_route;

                //console.log('gps_route', gps_route);
                //console.log('ldb_route', ldb_route);
                if (ldb_route != '' && ldb_route != undefined) {
                    if (ldb_route.length > 0) {
                        ldb_route = JSON.parse(ldb_route)
                        ldb_route.forEach((e) => {
                            var lt = e.latitude || e.lat || 0;
                            var ln = e.longitude || e.lng || 0;

                            allldbcoords.push({
                                lat: lt,
                                lng: ln,
                            });
                        });
                    } else {
                        //console.log('ldb_route is not an array or is empty.');
                    }
                }
                //console.log(gps_route, 'gps_routegps_route')
                if (gps_route != '' && gps_route != undefined) {
                    if (gps_route.length > 0) {
                        //console.log(typeof gps_route, 'gps_routegps_route')
                        gps_route.map((e) => {
                            var lt = e.latitude || e.lat || 0;
                            var ln = e.longitude || e.lng || 0;
                            allCoordinates.push({
                                lat: lt,
                                lng: ln,
                            });
                        });
                    } else {
                        //console.log('gps_route is not an array or is empty.');
                    }
                }

                var truck_no;
                if (gps_route.length > 0) {
                    truck_no = gps_route[0].truck_no;
                }
                //console.log(allCoordinates, 'allCoordinates');
                setoverly('show-m')
                setStateForMap((prev) => ({
                    ...prev,
                    sliderRouteTranslate: "slider-translate-75p",
                    showDiv: 'show-m',
                    container_no: truck_no,
                    mapinfo: {
                        ...state.mapinfo,
                        coords: allCoordinates,
                        ldb_route: allldbcoords
                    },
                    mapdata:resp.data.gps_route

                }));
                setloadshow('show-n');
            }
        } catch (error) {
            console.error(error);
        }
    };
    // const onShowShipmentLegRouteMap = async (e) => {
    //     var params = {
    //       work_order_no: e.work_order_no,
    //       truck_no: e.truck_no,
    //       container_no: e.container_no
    //     };
      
    //     setloadshow('show-m');
    //     setoverly('show-m');
      
    //     try {
    //       const resp = await redirectURL.post('/consignments/getmapdata', params);
    //       //console.log(resp.data);
    //       var records = resp.data.gps_df;
    //       //console.log(records, '342');
      
    //       if (!Array.isArray(records)) {
    //         records = records.replace(/NaN/g, "0");
    //         records = JSON.parse(records);
    //       }
    //       //console.log(records, '169');
    //       if (!records || records.length === 0) {
    //         setshow(true);
    //         setbasicTitle('No GPS Data Available');
    //         setbasicType('danger');
    //         setloadshow('show-n');
    //         setoverly('show-n');

    //       } else {
    //         var data = records[0];
    //         var allCoordinates = [];
    //         var allldbcoords = [];
    //         var gps_route = data.gps_route;
    //         var ldb_route = data.ldb_route;
            
    //         //console.log( 'gps_route', gps_route); 
    //         //console.log( 'ldb_route', ldb_route);
    //         if(ldb_route!='' && ldb_route!=undefined){
    //             if (ldb_route.length > 0) {
    //                 ldb_route=JSON.parse(ldb_route)
    //                 ldb_route.forEach((e) => {
    //                     var lt = e.latitude || e.lat || 0;
    //                     var ln = e.longitude || e.lng || 0;
                
    //                     allldbcoords.push({
    //                         lat: lt,
    //                         lng: ln,
    //                     });
    //                 });
    //             } else {
    //                 //console.log('ldb_route is not an array or is empty.');
    //             }
    //         }
    //         //console.log(gps_route,'gps_routegps_route')
    //         if(gps_route!='' && gps_route!=undefined){
    //             if (gps_route.length > 0) {
    //                 //console.log(typeof gps_route,'gps_routegps_route')
    //                 gps_route=JSON.parse(gps_route)
    //                 gps_route.map((e) => {
    //                     var lt = e.latitude || e.lat || 0; 
    //                     var ln = e.longitude || e.lng || 0; 
    //                     allCoordinates.push({
    //                         lat: lt,
    //                         lng: ln,
    //                     });
    //                 });
    //             } else {
    //                 //console.log('gps_route is not an array or is empty.');
    //             }
    //         }
            
    //         var truck_no;
    //         if (records.length > 0) {
    //           truck_no = records[0].truck_no;
    //         }
    //         //console.log(allCoordinates, 'allCoordinates');
    //             setoverly('show-m')
    //           setStateForMap((prev) => ({
    //             ...prev,
    //             sliderRouteTranslate: "slider-translate-60p",
    //             showDiv: 'show-m',
    //             container_no: truck_no,
    //             mapinfo: {
    //               ...state.mapinfo,
    //               coords: allCoordinates,
    //               ldb_route:allldbcoords
    //             },
    //           }));
    //         setloadshow('show-n');
    //       }
    //     } catch (error) {
    //       console.error(error);
    //     }
    //   };
    const getRowClass = (params)=>{
        //console.log(params,'185')
        if(params.data && (params.data.cha_reporting_modified==1||params.data.shipping_modified==1||params.data.at_plant_modified==1||params.data.ib_plant_gate_in_modified==1||params.data.tpt_container_modified==1||params.data.tpt_trucks_modified==1||params.data.ib_tpt_modified==1||params.data.modified_plant==1||params.data.cha_vessel_modified==1||params.data.ib_stuffing_modified==1||params.data.cha_do_modified==1)){
            return { backgroundColor: '#FFB9B9' }; 
        }
        return null
    }
    const onClickOpenexaminationfile=(params)=>{
        //console.log('163',params)
        var fileparams = {
            container_no:params.container_no
        }
        redirectURL.post("/consignments/downloadOpenExamination",fileparams,{
        }).then((response)=>{
            //console.log(response.data,'357')
            const imageURL = response.data;
            
             window.open(imageURL, '_blank');
        }).catch(function(error){
            //console.log(error);
        })
    }
    const onClickAcknowledgeBtn=(rowdata)=>{
        var row = rowdata.data 
        var params = {work_order_no:row.work_order_no,unsetField:'cha_do_modified',setField:'cha_do_planned',record:row}
        redirectURL.post('/consignments/acknowledgeData',params).then((resp)=>{
            if(resp.data.status=='Success'){
                setshow(true)
                    setbasicTitle("Acknowledgement successful")
                    setbasicType("success")
                    props.onRequest()
                    props.onReloadData(true);
            }
        })
    }
    const onsubmitdata2 = (e) => {
        e.preventDefault()
        var saveData = new FormData()
        saveData.append("do_copy",manualUploadDetails.file_1)
        saveData.append("container_no",manualUploadDetails.container.value)

        if(manualUploadDetails.container!='' && manualUploadDetails.container!=undefined){
        //console.log(saveData,'file_1')
        redirectURL.post('/consignments/uploadOpenExamination',saveData).then((resp)=>{
            if(resp.data.status=='Success'){
                setshow(true)
                        setbasicTitle('Successfully Uploaded')
                        setbasicType("success")
                        setloadshow('show-n')
                        setoverly('show-n')
                        setshowAddForm('')
                        setshowAddForm1('')
                        props.onRequest()
                        setmanualUploadDetails({
                            container:'',
                           file_1:''
                        })
                        document.getElementById('file_1').value=''
            }
            else {
                setshow(true)
                 setbasicTitle(resp.data.message)
                setbasicType("danger")
                setloadshow('show-n')
                setoverly('show-n')
                setshowAddForm('')
            }
            document.getElementById('bl_file').value=''
        })
        }
        else{
            setshow(true)
                    setbasicTitle('Please Select Container No')
                    setbasicType("danger")
                    setloadshow('show-n')
                    setoverly('show-n')
        }

    }
    var onClickopenexaminationbtn=(e)=>{
        setselected_row(e.data)
        setshowAddForm1("slide30")
        setoverly("show-m")
    }
    var onClickShowShipmentLegs = async (rownode) => {
        var selectedrecord = rownode.data
        if(selectedrecord.modified_plant==1||selectedrecord.cha_vessel_modified==1||selectedrecord.ib_stuffing_modified==1||selectedrecord.cha_do_modified==1||selectedrecord.ib_tpt_modified==1|| selectedrecord.tpt_trucks_modified==1||selectedrecord.tpt_container_modified==1||selectedrecord.ib_plant_gate_in_modified==1||selectedrecord.at_plant_modified==1||selectedrecord.shipping_modified==1||selectedrecord.cha_reporting_modified==1){
            var checkEditable = false
        }
        else {
            var checkEditable = true

        }
        var commentHeaders = [
            {
                headerName: "",
                field: "",
                resizable: true,
                width: 60,
                filter: true,
                // cellRendererFramework: LegRouteActiveTruckMap,
                cellRendererFramework: (params) => {
                    return (
                        <div>
                            <button onClick={() => onShowShipmentLegRouteMap(params.data)} className="custom-btn label label-success">
                                <i className="icofont icofont-map-pins f20"></i>
                            </button>
                        </div>
                    );
                },
            },
            {
                headerName: "Open Examination",
                field: "",
                width: 170,
                cellRendererFramework: (params) => {
                    return (
                        params.data.uploaded_open_examination !== '' && params.data.uploaded_open_examination !== undefined ? (
                            <div className="map-icon-component" onClick={() => onClickOpenexaminationfile(params.data)}>
                                <span className="custom-btn label label-success" title="Download Attachment">
                                    <i style={{ color: "#1976d2", cursor: "pointer", marginTop: "10%" }} className="fa fa-download f20"></i>
                                </span>
                            </div>
                        ) :  (
                            <div className="map-icon-component" >
                                <span className="custom-btn label label-success" title="Download Attachment">
                                    <i style={{ color: "#fff", cursor: "pointer", marginTop: "10%" }} className="fa fa-download f20"></i>
                                </span>
                            </div>
                        ) 
                    );
                },
            },
            
            {
                headerName: 'Work Order No',
                field: "work_order_no",
                resizable: true,
                width: 180,
                filter: true
            },
            {
                headerName: 'Truck No',
                field: "truck_no",
                resizable: true,
                width: 180,
                filter: true
            },
            {
                headerName: 'Container No',
                field: "container_no",
                resizable: true,
                width: 180,
                filter: true

            },
            // {
            //     headerName: 'Pickup Date',
            //     field: "pick_up_date",
            //     resizable: true,
            //     width: 180,
            //     filter: true,

            //     valueGetter: function (params) {
            //         try {
            //             if (params.data.pick_up_date != "" && params.data.pick_up_date != undefined) {
            //                 return getHyphenDDMMYYYY(params.data.pick_up_date);
            //             } else {
            //                 return "";
            //             }
            //         } catch (e) {
            //             // Handle the exception if needed
            //         }
            //     },

            // },
            // {
            //     headerName: 'Shipping Seal',
            //     field: "shipping_seal",
            //     resizable: true,
            //     width: 180,
            //     filter: true,

            // },
            {
                headerName: 'Invoice No',
                field: "invoice_no",
                resizable: true,
                width: 180,
                filter: true,
                editable: false,

            },
            {
                headerName: 'Invoice Date',
                field: "invoice_date",
                resizable: true,
                width: 180,
                filter: true,
                editable: false,
                cellEditor: DateEditor,
                valueGetter: function (params) {
                    try {
                        if (params.data.invoice_date != "" && params.data.invoice_date != undefined) {
                            return getHyphenDDMMYYYY(params.data.invoice_date);
                        } else {
                            return "";
                        }
                    } catch (e) {
                        // Handle the exception if needed
                    }
                },
                comparator: dateComparator1,
            },
            {
                headerName: 'RFID',
                field: "rfid",
                resizable: true,
                width: 180,
                filter: true,
                editable: false,
            },
            {
                headerName: '   Plant Gate In Time',
                field: "gate_in_time",
                resizable: true,
                width: 200,
                filter: true,
                // editable: true,
                cellEditor: DateEditor,
                valueGetter: function (params) {
                    try {
                        if (params.data.gate_in_time != "" && params.data.gate_in_time != undefined) {
                            return getHyphenDDMMYYYYHHMM(params.data.gate_in_time);
                        } else {
                            return "";
                        }
                    } catch (e) {
                        // Handle the exception if needed
                    }
                },
                comparator: dateComparator1,
            },
            {
                headerName: 'Gate Out Time',
                field: "gate_out_time",
                resizable: true,
                width: 180,
                filter: true,
                editable: false,
                cellEditor: DateEditor,
                valueGetter: function (params) {
                    try {
                        if (params.data.gate_out_time != "" && params.data.gate_out_time != undefined) {
                            return getHyphenDDMMYYYYHHMM(params.data.gate_out_time);
                        } else {
                            return "";
                        }
                    } catch (e) {
                        // Handle the exception if needed
                    }
                },
                comparator: dateComparator1,
            },
            {
                headerName: 'Reporting Time At Parking Plaza',
                field: "reporting_at_parking",
                resizable: true,
                width: 270,
                filter: true,
                editable: false,
                cellEditor: DateEditor,
                valueGetter: function (params) {
                    try {
                        if (params.data.reporting_at_parking != "" && params.data.reporting_at_parking != undefined) {
                            return getHyphenDDMMYYYYHHMM(params.data.reporting_at_parking);
                        } else {
                            return "";
                        }
                    } catch (e) {
                        // Handle the exception if needed
                    }
                },
                comparator: dateComparator1,
            },
        ]
        var setdata = {
            detailGridOptions: {
                columnDefs: commentHeaders,
                overlayNoRowsTemplate: 'No rows to show',

            },
            getDetailRowData: async function (param) {
                param.successCallback([]);
                var row = param.data
                var parameter = {
                    work_order_no: row.work_order_no
                }
                // await redirectURL.post("/consignments/truckslegsdata",parameter).then(async (response) => {
                //     //console.log(response.data,'response')
                //     var records = response.data 
                // var records = containerLegsData.filter(e => e.work_order_no == row.work_order_no)
                var records = props.data.container_new_1
                if(classForTab.new){
                    var data = props.data.container_new_1 
                    //console.log(data,'518')
                    if(data!='' && data!=undefined){
                        records = data.filter(e=>row.work_order_no==e.work_order_no)
                    }
                }
                else if(classForTab.planned){
                    var data = props.data.container_new_2
                    if(data!='' && data!=undefined){
                        records = data.filter(e=>row.work_order_no==e.work_order_no)
                    }                }
                else if(classForTab.modified){
                    var data = props.data.container_new_3 
                    if(data!='' && data!=undefined){
                        records = data.filter(e=>row.work_order_no==e.work_order_no)
                    }                }
                param.successCallback(records);
                // }).catch(function (error) {
                //     //console.log(error);
                // })
            },
            masterDetail: true
        }
        dispatch({ type: 'SET_DETAIL_RENDERER_PARAMS', payload: setdata });
        setTimeout(() => {
            if (rownode.column.colDef.field == 'containerLegs') {
                rownode.node.setExpanded(!rownode.node.expanded);

            }
            else {

                rownode.node.setExpanded(false);
            }
        }, 0)
    }
    const onClickHideManualUpload = () => {
        setshowAddForm('')
        setoverly('show-n')

    }
    const onClickHideManualUpload1 = () => {
        setshowAddForm('')
        setoverly('show-n')
        setshowAddForm1('')

    }
    const onchangeFileHandler =(e)=>{
        var check = e.target.files[0].name
        var file_check = check.split(".")
        var next_check = file_check[file_check.length -1]
        //console.log(e.target.files[0],"adfdfadfa")
            if(next_check == "pdf" || next_check == "png" || next_check == "jpeg"){
                setmanualUploadDetails(prev => ({ ...prev, file_1: e.target.files[0] }))
            }else{
                setbasicTitle("Please Upload pdf or png File")
                setbasicType("warning")
                setshow(true)
                $("#file_1").val("")
            }
        }
    const onsubmitdata = (e) => {
        e.preventDefault()
        var trucks = manualUploadDetails.trucks
        var truckList = []
        trucks.map((e) => {
            truckList.push(e.value)
        })
        //console.log(truckList, 'truckList')
        var wrno = manualUploadDetails.wrno.value
        //console.log(originalData, 'originalData')
        var record = originalData.filter(e => e.work_order_no == wrno)
        record = record[0]

        var containerCount = record.container_count
        //console.log(containerCount, 'containerCount')
        var params = {
            work_order_no: wrno,
            trucks: truckList
        }
        if (containerCount == trucks.length) {
            redirectURL.post('/consignments/updateTrucksDetails', params).then((resp) => {

            })
        }
        else {

        }
    }
    var closeAlert = () => {
        setshow(false)
        setloadshow('show-n')
    }
    const onSelectConfirm = (e) => {
        if (e.value == 'yes') {
            setcheckfortruck(1)
        }
        else {
            setshowAddForm('')
            setoverly('show-n')
        }
    }
    const handleTrucksList = (e) => {
        setmanualUploadDetails(prev => ({
            ...prev,
            trucks: e
        }))


    }
    const onClickHideAll = () => {
        setloadshow('show-n')
    }
    const detailedviewcols = []
    var newCount = tabforleodate.leo_new
    var plannedCount = tabforleodate.leo_planned
    var modifiedCount = tabforleodate.leo_modified
    var acknowledgebtn= {
        headerName: "",
        field: "",
        width: 120,
        filter: true, resizable: true,
        pinned: 'left',
        cellRendererFramework: AcknowledgeBtnComponent
    }
    var submitBtn=[
        {
            headerName: "",
            field: "submit_btn",
            resizable: true,
            width: 100,
            pinned:'left',
            filter: true,
            cellRenderer: LeoDateComponent
        }
    ]
    var updatecha = {
        headerName: "Open Examination",
        field: "open_examination",
        width: 160,
        // pinned: 'left',
        cellRenderer: 'OpenexaminationBtnComponent'
    }
    var columns1 = [...summaryViewcols];
    if(classForTab.new==1){
        columns1.push(...submitBtn)
        columns1.push(updatecha)
        detailedviewcols.push(updatecha)
    }
    if(classForTab.modified==1){
        columns1.push(acknowledgebtn)
    }
    const columnwithDefs = columns1
    return (
        <>
            <SweetAlert
                show={show}
                type={basicType}
                title={basicTitle}
                onConfirm={closeAlert}
            >
            </SweetAlert>
            {props.check == 6 ?
            <div className="m-10p" style={{ margin: '10px', marginTop: '0px' }}>
            <div className="d-flex flex-row" style={{position:"relative"}}>
                            <button
                                className="d-flex flex-row justify-content-center align-items-center px-3"
                                style={{
                                    background: classForTab.new == 1 ? '#0664AE' : '#FFFFFF',
                                    // color: state.isTileView ? "": "#0664AE",
                                    borderTopLeftRadius: "11px",
                                    borderBottomLeftRadius: "11px",
                                    border: "1px solid #0664AE",
                                    height: "38px",
                                    padding: '22px'
                                }}
                                onClick={() => onclickTab('New')}
                            >
                                <div
                                    style={{
                                        color: classForTab.new == 1 ? "white" : '#0664AE',
                                        fontSize: "12px",
                                        fontWeight: 700,
                                        marginLeft: "10px",
                                        lineHeight: "12px",
                                    }}
                                >
                                    New - <span>{(newCount != '' && newCount != undefined) ? newCount.length : 0}</span>
                                </div>
                            </button>

                            <button
                                className="d-flex flex-row justify-content-center align-items-center px-3"
                                style={{
                                    background: classForTab.planned == 1 ? '#0664AE' : "#FFFFFF",
                                    border: "1px solid #0664AE",
                                    height: "38px",
                                    padding: '22px'
                                }}
                                onClick={() => onclickTab('Planned')}
                            >
                                <div
                                    style={{
                                        color: classForTab.planned == 1 ? "white" : '#0664AE',
                                        fontSize: "12px",
                                        fontWeight: 700,
                                        marginLeft: "10px",
                                        lineHeight: "12px",
                                    }}
                                >
                                    Planned - <span>{(plannedCount != '' && plannedCount != undefined) ? plannedCount.length : 0}</span>
                                </div>
                            </button>
                            <button
                                className="d-flex flex-row justify-content-center align-items-center px-3"
                                style={{
                                    background: classForTab.modified == 1 ? '#0664AE' : "#FFFFFF",
                                    borderTopRightRadius: "11px",
                                    borderBottomRightRadius: "11px",
                                    border: "1px solid #0664AE",
                                    height: "38px",
                                    padding: '22px'
                                }}
                                onClick={() => onclickTab('Modified')}
                            >
                                <div
                                    style={{
                                        color: classForTab.modified == 1 ? "white" : '#0664AE',
                                        fontSize: "12px",
                                        fontWeight: 700,
                                        marginLeft: "10px",
                                        lineHeight: "12px",
                                    }}
                                >
                                    Modified - <span>{(modifiedCount != '' && modifiedCount != undefined) ? modifiedCount.length : 0}</span>
                                </div>
                            </button>
                                {classForTab.new===1?
                         <div style={{position:"absolute", right:"3px",top:"-25px" }}>
                             <button
                                        type="button"
                                        title="Save Grid Layout"
                                        name="savegrid"
                                        className="btn btn-danger action_btn"
                                        style={{height: "32px", marginTop: "30px", paddingBottom:"30px" }}

                                        onClick={onClickSaveGridState}>
                                        <i className="f12 icofont icofont-save"></i>
                                        </button>
                                </div>
                            :''} 
                    </div>
                    <div id="idgrid" style={{ width: "100%", height: "478px" }} className="ag-theme-balham">
                        <AgGridReact
                            columnDefs={columnwithDefs}
                            defaultColDef={{
                                sortable: true,
                                filter: true,
                                editable: false,
                                resizable: true,
                                menuTabs: ['filterMenuTab']
                                // rowHeight:80,
                            }}
                            // paddingTop={10}
                            // rowHeight={70}
                            // headerHeight={70}
                            //  headerHeight={50}
                            rowData={rowData}
                            suppressRowClickSelection={true}
                            detailCellRendererParams={state.detailCellRendererParams}
                            frameworkComponents={{ ConsignmentTrucksLegs: ConsignmentTrucksLegs, CountryFlagComponent: CountryFlagComponent, LeoDateComponent: LeoDateComponent,AcknowledgeBtnComponent:AcknowledgeBtnComponent ,OpenexaminationBtnComponent:OpenexaminationBtnComponent,ViewDOdetailsComponent:ViewDOdetailsComponent}}
                            enableCellChangeFlash={true}
                            suppressCellFlash={true}
                            enableRangeSelection={true}
                            paginationPageSize={50}
                            rowSelection={'multiple'}
                            masterDetail={true}
                            pagination={true}
                            sideBar={{
                                toolPanels: [
                                    {
                                        id: "columns",
                                        labelDefault: "Columns",
                                        labelKey: "columns",
                                        iconKey: "columns",
                                        toolPanel: "agColumnsToolPanel"
                                    },
                                    {
                                        id: "filters",
                                        labelDefault: "Filters",
                                        labelKey: "filters",
                                        iconKey: "filter",
                                        toolPanel: "agFiltersToolPanel"
                                    }
                                ]
                            }}
                            statusBar={{
                                statusPanels: [
                                    // {
                                    //     statusPanel: "agTotalAndFilteredRowCountComponent",
                                    //     align: "left"
                                    // },
                                    // {
                                    //     statusPanel: "agTotalRowCountComponent",
                                    //     align: "center"
                                    // },
                                    { statusPanel: "agFilteredRowCountComponent" },
                                    { statusPanel: "agSelectedRowCountComponent" },
                                    { statusPanel: "agAggregationComponent" }
                                ]
                            }}
                            gridOptions={{
                                icons: {
                                    // You can customize other icons as well
                                    next: `<img src="${customNextIcon}" alt="Next" />`,
                                    previous: `<img src="${customPreviousIcon}" alt="Previous" />`,
                                    first: `<img src="${customFirstIcon}" alt="First" />`,
                                    last: `<img src="${customLastIcon}" alt="Last" />`,
                                  },
                            }}
                            ref={gridApi}
                            onGridReady={onGridReady}
                            onGridState={onGridState}
                            getRowStyle={getRowClass}
                            context={{onClickopenexaminationbtn, onClickShowShipmentLegs, onClickuploadLeoDate,onClickAcknowledgeBtn }}
                        />

                    </div>
                </div> : ''}
            <div className={"sliderBlock2 " + showAddForm} style={{ overflow: "auto" }}>
                <div className="slide-r-title slider_title_style">
                    <h6>Enter Details</h6>
                    <span className="float-right closebtn" style={{ marginTop: '-30px', marginRight: "25px" }} onClick={onClickHideManualUpload} >X</span>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <form onSubmit={onsubmitdata}>
                            <div className="slide-body">
                                <div className="row p-20p">
                                    <div className="form-group col-xl-12 col-lg-12">
                                        <label className='mb-5p'>Work Order No: </label>
                                        <Select
                                            // placeholder="Change Activity Status"
                                            closeMenuOnSelect={true}
                                            value={manualUploadDetails.wrno}
                                            // multi={true}
                                            // className={"border-radius-0"} 
                                            onChange={(e) => onSelectWON(e)}
                                            style={{ borderRadius: "0px" }}
                                            styles={{
                                                control: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: "0.8rem",
                                                    color: "#6e707e",
                                                }),
                                            }}
                                            options={workordernoList}
                                        />
                                    </div>
                                    <div className="form-group col-xl-12 col-lg-12">
                                        <label className='mb-5p'>Confirm</label>
                                        <Select
                                            // placeholder="Change Activity Status"
                                            closeMenuOnSelect={true}
                                            // value={manualUploadDetails.wrno}
                                            // multi={true}
                                            // className={"border-radius-0"} 
                                            onChange={(e) => onSelectConfirm(e)}
                                            style={{ borderRadius: "0px" }}
                                            styles={{
                                                control: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: "0.8rem",
                                                    color: "#6e707e",
                                                }),
                                            }}
                                            options={selectconfirmn}
                                        />
                                    </div>
                                    {checkfortruck == 1 ?
                                        <div className="form-group col-xl-12 col-lg-12">
                                            <label className='mb-5p'>Trucks*</label>
                                            <Select
                                                // placeholder="Change Activity Status"
                                                closeMenuOnSelect={true}
                                                value={manualUploadDetails.trucks}
                                                isMulti="true"
                                                className={"border-radius-0"}
                                                onChange={(e) => handleTrucksList(e)}
                                                style={{ borderRadius: "0px" }}
                                                styles={{
                                                    control: (provided, state) => ({
                                                        ...provided,
                                                        fontSize: "0.8rem",
                                                        color: "#6e707e",
                                                    }),
                                                }}
                                                options={trucksList}
                                            />
                                        </div> : ''}
                                    <div className="form-group col-sm-12 mb-20p text-center">
                                        <button type="submit" className="btn btn-success">Submit</button>
                                    </div>

                                </div>
                            </div>
                        </form>
                    </div>
                </div>

            </div>
            <div className={"sliderBlock2 " + showAddForm1} style={{ overflow: "auto" }}>
                <div className="slide-r-title slider_title_style">
                    <h6>Upload Open Examination Details</h6>
                    <span className="float-right closebtn" style={{ marginTop: '-30px', marginRight: "25px" }} onClick={onClickHideManualUpload1} >X</span>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <form onSubmit={onsubmitdata2}>
                            <div className="slide-body">
                                <div className="row p-20p">
                                <div className="form-group col-xl-12 col-lg-12">
                                        <label className='mb-5p'>Container No <span className="err-txt">*</span> </label>
                                        <Select
                                            // placeholder="Change Activity Status"
                                            closeMenuOnSelect={true}
                                            value={manualUploadDetails.container}
                                            // multi={true}
                                            // className={"border-radius-0"} 
                                            onChange={(e) => setmanualUploadDetails((prev)=>({...prev,container:e}))}
                                            style={{ borderRadius: "0px" }}
                                            styles={{
                                                control: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: "0.8rem",
                                                    color: "#6e707e",
                                                }),
                                            }}
                                            options={containerslist}
                                        />
                                    </div>
                                <div className="form-group col-xl-12 col-lg-12">
                                        <label className='mb-5p'> Upload File<span className="err-txt">*</span></label>
                                        <input type="file" name="file_1" id="file_1" className="form-control requestFields" onChange={(e)=>onchangeFileHandler(e)}  required/>
                                    </div>
                                    <div className="form-group col-sm-12 mb-20p text-center">
                                        <button type="submit" className="btn btn-success">Submit</button>
                                    </div>

                                </div>
                            </div>
                        </form>

                    </div>
                </div>


            </div>
            {stateForMap.sliderRouteTranslate != '' ?
                <div className={"sliderBlock2 " + stateForMap.sliderRouteTranslate} style={{ overflow: "auto" }}>
                    <div className="slide-r-title">
                        <h4>
                            Truck No: {(stateForMap.container_no)}
                            <span className="float-right closebtn" style={{ marginRight: "100px" }} onClick={onCloseRouteDiv} >X</span>
                        </h4>
                    </div>
                    <div className="slide-r-body" style={{ position: "relative" }}>

                        <div className="row col-xl-12 col-lg-12 ml-0p n-p-0">
                            {/* {//console.log("this.state.mapinfo ", this.state.mapinfo)} */}
                            <Mapcard2
                                mapinfo={stateForMap.mapinfo}
                                mapHeight={"100vh"}
                                mapFor={"intransit"}
                                mapData={stateForMap.mapdata}
                                geoLocShipmentsMap={state.geoLocShipmentsMap}
                            />
                        </div>
                    </div>

                </div>
                : ""
            }
            <div className={"dataLoadpage " + (loadshow)}>
            </div>
            <div className={"dataLoadpageimg " + (loadshow)}>
                <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
            </div>
            <div className={"overlay-part " + (overly)} onClick={onClickHideAll}></div>

        </>



    )
}
function isEmpty(obj) {
    for (var key in obj) {
        if (obj.hasOwnProperty(key))
            return false;
    }
    return true;
}
function timeConvert(n) {
    var diffMs = n;
    var diffDays = Math.floor(diffMs / 86400000); // days
    var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
    var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
    return diffHrs + " hour(s) and " + (diffMins / 60) + " minute(s).";
}
var currentinfowindow = null;
function getTimeInfo(marker, timedet, coords) {
    var timeinfo = new window.google.maps.InfoWindow({
        content: "Time at this location: " + timedet
    });
    marker.addListener('click', function () {

        if (currentinfowindow != null) {
            currentinfowindow.close();
            currentinfowindow = timeinfo;
            timeinfo.open(marker.get('map'), marker);
        }
        else {
            currentinfowindow = timeinfo;
            timeinfo.open(marker.get('map'), marker);
        }
    });
}
function secondsToString(seconds) {
    var numdays = Math.floor(seconds / 86400);
    var numhours = Math.floor((seconds % 86400) / 3600);
    var numminutes = Math.floor(((seconds % 86400) % 3600) / 60);
    var numseconds = ((seconds % 86400) % 3600) % 60;
    return numdays + " days " + numhours + " hours " + numminutes + " minutes ";
}
function secondsToDays(seconds) {
    var numdays = Math.floor(seconds / 86400);
    var numhours = Math.floor((seconds % 86400) / 3600);
    var numminutes = Math.floor(((seconds % 86400) % 3600) / 60);
    var numseconds = ((seconds % 86400) % 3600) % 60;
    return numdays;
}
function CheckForHtmlTag() {
    var rs = document.getElementById("invalid_reason");
    var reg = /<(.|\n)*?>/g;
    if (reg.test(rs.value)) {
        var ErrorText = "Oops! HTML or Script is not allowed.";
        rs.value = ''
        //alert();
        return 1;
    }
}
function dateComparator(date1, date2) {
    if (date1 != "" && date2 != "" && date1 != undefined && date2 != undefined) {
        var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
        date1 = date1.replace(date1.split(" ")[0].split("-")[1], date_1);
        var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
        date2 = date2.replace(date2.split(" ")[0].split("-")[1], date_2);
        var date1Number = monthToComparableNumber(date1);
        var date2Number = monthToComparableNumber(date2);
        if (date1Number === null && date2Number === null) {
            return 0;
        }
        if (date1Number === null) {
            return -1;
        }
        if (date2Number === null) {
            return 1;
        }
        return date1Number - date2Number;
    }
}
function monthToComparableNumber(date) {
    if (date === undefined || date === null || date.length !== 16) {
        return null;
    }
    var yearNumber = date.substring(6, 10);
    var monthNumber = date.substring(3, 5);
    var dayNumber = date.substring(0, 2);
    var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
    return result;
}
function getMonthNumber(monthName) {
    var months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May',
        'Jun', 'Jul', 'Aug', 'Sep',
        'Oct', 'Nov', 'Dec'
    ];
    var monthNum = months.indexOf(monthName) + 1;
    if (monthNum < 10) {
        monthNum = "0" + monthNum.toString();
    }
    return monthNum;
}
function loadDateTimeScript() {
    $('.datetimepicker_mask').datetimepicker({
        mask: '39-19-9999',
        format: 'd-m-Y',
        onShow: false
    });
    $('.datetimepicker_date').datetimepicker({
        format: 'd-m-Y',
        timepicker: false,
        onShow: false
    });
    var index = window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src = "https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
    script.async = true
    script.defer = true
    index.parentNode.insertBefore(script, index)
}
function GetSortDescOrder(prop) {
    return function (a, b) {
        if (a[prop] < b[prop]) {
            return 1;
        } else if (a[prop] > b[prop]) {
            return -1;
        }
        return 0;
    }
}
function GetSortAscOrder(prop) {
    return function (a, b) {
        if (a[prop] > b[prop]) {
            return 1;
        } else if (a[prop] < b[prop]) {
            return -1;
        }
        return 0;
    }
}
function GetSortASCOrder(prop) {
    return function (a, b) {
        if (a[prop] > b[prop]) {
            return 1;
        } else if (a[prop] < b[prop]) {
            return -1;
        }
        return 0;
    }
}
function secondsToDhms(seconds) {
    seconds = Number(seconds);
    var d = Math.floor(seconds / (3600 * 24));
    var h = Math.floor(seconds % (3600 * 24) / 3600);
    var m = Math.floor(seconds % 3600 / 60);
    var s = Math.floor(seconds % 60);
    var dDisplay = d >= 0 ? d + (d == 1 ? " Day " : " Days ") : "";
    var hDisplay = h >= 0 ? h + (h == 1 ? " Hr " : " Hrs ") : "";
    var mDisplay = m >= 0 ? m + (m == 1 ? " Mins " : " Mins ") : "";
    var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
    return dDisplay + hDisplay + mDisplay;
}
function distinctArrayBy(arr, propName) {
    var result = arr.reduce(function (arr1, e1) {
        var matches = arr1.filter(function (e2) {
            return e1[propName] == e2[propName];
        })
        if (matches.length == 0)
            arr1.push(e1)
        return arr1;
    }, []);

    return result;
}
function dateComparator1(date1, date2) {
    if (date1 != "" && date2 != "" && date1 != undefined && date2 != undefined) {
        var date_1 = getMonthNumber(date1.split("-")[1]);
        date1 = date1.replace(date1.split("-")[1], date_1);
        var date_2 = getMonthNumber(date2.split("-")[1]);
        date2 = date2.replace(date2.split("-")[1], date_2);
        var date1Number = monthToComparableNumber1(date1);
        var date2Number = monthToComparableNumber1(date2);
        if (date1Number === null && date2Number === null) {
            return 0;
        }
        if (date1Number === null) {
            return -1;
        }
        if (date2Number === null) {
            return 1;
        }
        //console.log(date1Number, date2Number, "comparator")
        return date1Number - date2Number;
    }
}
function monthToComparableNumber1(date) {
    if (date === undefined || date === null || date.length !== 10) {
        return null;
    }
    var yearNumber = date.substring(6, 10);
    var monthNumber = date.substring(3, 5);
    var dayNumber = date.substring(0, 2);
    var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
    return result;
}
function loadDateTimeScript2() {
    $('.datetimepicker_mask').datetimepicker({
        format: 'd-m-Y'
    });
    $('.device_filter_data').datetimepicker({
        format: 'Y-m-d',
        timepicker: true
    });
    var index = window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src = "https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
    script.async = true
    script.defer = true
    index.parentNode.insertBefore(script, index)
}
function getDatePicker() {
    function Datepicker() { }
    Datepicker.prototype.init = function (params) {
        this.eInput = document.createElement("input");
        this.eInput.value = params.value;
        this.eInput.setAttribute('readonly', 'true');

        //console.log(this.eInput)
        window.$(".datepicker").datepicker({ dateFormat: "dd-mm-yy" });
    };
    Datepicker.prototype.getGui = function () {
        return this.eInput;
    };
    Datepicker.prototype.afterGuiAttached = function () {
        this.eInput.focus();
        this.eInput.select();
    };
    Datepicker.prototype.getValue = function () {
        return this.eInput.value;
    };
    Datepicker.prototype.destroy = function () { };
    Datepicker.prototype.isPopup = function () {
        return false;
    };
    return Datepicker;
}
function DateEditor() { }
DateEditor.prototype.init = function (params) {
    this.eInput = document.createElement('input');
    this.eInput.value = params.value;
    $(this.eInput).datepicker({
        dateFormat: "yy-mm-dd",
        changeMonth: true,
        changeYear: true,
        showTime: false,
    });
};
DateEditor.prototype.getGui = function () {
    return this.eInput;
};
DateEditor.prototype.afterGuiAttached = function () {
    this.eInput.focus();
    this.eInput.select();
};
DateEditor.prototype.getValue = function () {
    return this.eInput.value;
};
DateEditor.prototype.destroy = function () {
};
DateEditor.prototype.isPopup = function () {
    return false;
};
function removeDuplicates(arr, prop) {
    const seen = new Set();
    return arr.filter(obj => {
        const propValue = obj[prop];
        const propString = typeof propValue === 'object' ? JSON.stringify(propValue) : propValue;
        if (seen.has(propString)) {
            return false;
        }
        seen.add(propString);
        return true;
    });
}
function sortByCreatedDate(arr, descending = true) {
    if (arr != '' && arr != undefined) {
      if (arr.length > 0) {
        const comparison = (a, b) => {
          const dateA = new Date(a.modified_date);
          const dateB = new Date(b.modified_date);
          if (descending) {
            return dateB - dateA; // Ascending order
          } else {
            return dateA - dateB; // Descending order
          }
        };
          arr.sort(comparison);
        return arr; 
      }
    } else {
      return arr; 
    }
  }
export default ContainerReporting