import React, { useState, useEffect, useRef,useCallback } from "react"
import SweetAlert from 'react-bootstrap-sweetalert';
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import 'ag-grid-enterprise';
import 'ag-grid-enterprise/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-enterprise/styles/ag-theme-balham.css';
import 'react-datetime/css/react-datetime.css';
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import Select from "react-select";
import SearchIcon from "../../assets/icons/search-icon.svg";
import SubmitBtn from "../layouts/submitBtn";
import SubmitRevisedBtn from "./submitrevisedbtn";
import BulkForceFileHandler from "../layouts/changebulkuploadhandler";

import { getHyphenYYYYMMDDHHMMSS, getDDMMMYYYY, getHyphenDDMMYYYY,getHyphenYYYYMMDD } from '../common/utils';
import Datepicker from "../layouts/datepicker";
import moment from "moment";
import Newcomponent from "./plantNewcomponent";
import Plannedcomponent from "./plantPlannedcomponent";
import Modifiedcomponent from "./plantModifiedcomponent";
import ClosedComponentForPlant from "./closedForPlantScreen";
import IBStuffingForPlant from "./ibStuffingforPlant";
import redirectURL from "../redirectURL";
import Modal from 'react-responsive-modal';

const Plant = (props) => {
    const gridApi = useRef(null);
    var [gridColumnApi, setgridColumnApi] = useState(null)
    var gridRef = useRef();
    var [summaryviewcheck, setsummaryviewcheck] = useState(1)
    var [detailedviewcheck, setdetailedviewcheck] = useState(0)
    var [rowData, setrowData] = useState([])
    var [rowData1, setrowData1] = useState([])
    var [showGrid, setshowGrid] = useState(false)
    var [originalData, setoriginalData] = useState([])
    var [woReleaseDate, setWoReleaseDate] = useState({
        wo_release_from_date: '',
        wo_release_to_date: ''
    })
    var [sliderForBulkupload, setsliderForBulkupload] = useState('')
    var [file, setfile] = useState('')
    var [csvcontent, setcsvcontent] = useState("")
    var [checkinput, setcheckinput] = useState(0)
    var [checkForDateFilter, setcheckForDateFilter] = useState(0)
    var [loadshow, setloadshow] = useState('show-n')
    var [overly, setoverly] = useState('show-n')
    var [validbulkfile,setvalidbulkfile] = useState(false)
    var [errormsg,seterrormsg] = useState('')
    const [rerenderTrigger, setRerenderTrigger] = useState(false);
    var [show, setshow] = useState(false)
    var [reload, setreload] = useState(false)
    var [basicTitle, setbasicTitle] = useState('')
    var [basicType, setbasicType] = useState('')
    const [editRowIds, setEditRowsIds] = useState([]);
    const [modifiedRows, setModifiedRows] = useState(new Set());
    var [selectedval, setselectedval] = useState({
        dropdownval: '',
        inputval: ''
    })
    var [counters,setCounters] = useState({
        allData:[],
        new_plant:[],
        planned_plant:[],
        modified_plant:[],
        closed_data:[],
        ib_stuffing_data:[]
    })
    var [tabsforcontainerReport,setTabsForContainerReport] = useState({
        container_report_new:[],
        container_report_planned:[],
        container_report_modified:[],
        container_new_1:[],
        container_new_2:[]
    })
    const [isClicked, setIsClicked] = useState({
        // all:true,
        new:true,
        planned:false,
        modified:false,
        closed:false,
        ib_stuffing:false
    });
    const [check,setcheck] = useState(1)
    const [reloadData, setReloadData] = useState(true);
    var [checkforbulk,setcheckforbulk] = useState(true);
    var [open, setopen]= useState(false) 

    useEffect(() => {
        if (gridRef.current) {
            setgridColumnApi(gridRef.current.columnApi)
        }
    }, [gridRef]);
    useEffect(() => {
        setloadshow("show-m");
        getPlantsData()
            setReloadData(false);
    }, [props.data]);
    const getPlantsData = () => {
        redirectURL.post('/consignments/getplantsData').then((resp)=>{
        //     var plantData = resp.data;
        // //console.log(plantData,'94')
        // var new_plant = plantData.new_plant || [];
        // var planned_plant = plantData.planned_plant || [];
        // var modified_plant = plantData.modified_plant || [];
      var new_plant = resp.data.new_plant 
      if(localStorage.getItem('plant_code_list')!='' && localStorage.getItem('plant_code_list')!=undefined && localStorage.getItem('plant_code_list')!='undefined'){
        var plantList = localStorage.getItem('plant_code_list')
        var plantListnames = plantList.split(',');
        var data_new = []
        plantListnames.map((e)=>{
            var filtered_data = new_plant.filter(f=>f.plant==e)
            data_new.push(...filtered_data)
        })
        new_plant = data_new
      }
      var planned_plant = resp.data.planned_plant 
      if(localStorage.getItem('plant_code_list')!='' && localStorage.getItem('plant_code_list')!=undefined&&localStorage.getItem('plant_code_list')!='undefined'){
        var plantList = localStorage.getItem('plant_code_list')
        var plantListnames = plantList.split(',');
        var data_planned = []
        plantListnames.map((e)=>{
            var filtered_data = planned_plant.filter(f=>f.plant==e)
            data_planned.push(...filtered_data)
        })
        planned_plant = data_planned
      }
    
      var modified_plant = resp.data.modified_plant
      if(localStorage.getItem('plant_code_list')!='' && localStorage.getItem('plant_code_list')!=undefined&&localStorage.getItem('plant_code_list')!='undefined'){
        var plantList = localStorage.getItem('plant_code_list')
        var plantListnames = plantList.split(',');
        var data_modified = []
        plantListnames.map((e)=>{
            var filtered_data = modified_plant.filter(f=>f.plant==e)
            data_modified.push(...filtered_data)
        })
        modified_plant = data_modified
      }
      sortByCreatedDate(new_plant)
      sortByCreatedDate(planned_plant)
      sortByCreatedDate(modified_plant)
        setrowData(new_plant);
        setoriginalData(new_plant);
      
        setCounters((prev) => ({
          ...prev,
          new_plant: new_plant,
          planned_plant: planned_plant,
          modified_plant: modified_plant,
        }));
        setloadshow("show-n");
        })
        redirectURL.post('/consignments/getdataforplantcompleted').then((resp3) => {
          var container_report_new = resp3.data.work_order_level_data1 || [];
          var container_new_1 = resp3.data.containerDetails1 || [];
          var container_new_2 = resp3.data.containerDetails2 || [];
          var container_report_planned = resp3.data.work_order_level_data2 || [];
          container_report_planned = container_report_planned.filter(e=>e.pendency_gate_out<=0)
          var container_report_modified = resp3.data.work_order_level_data3 || [];
          setTabsForContainerReport({
            container_report_new: container_report_new,
            container_report_planned: container_report_planned,
            container_report_modified: container_report_modified,
            container_new_1: container_new_1,
            container_new_2: container_new_2,
          });
      
          setCounters((prev) => ({
            ...prev,
            closed_data: container_report_new,
          }));
          setloadshow("show-n");
        });
      };
      const onGridReady = useCallback((params) => {
        gridApi.current = params.api;
      }, []);
      
    var typeofselection = [
        { label: 'Ship to Country', value: 'ship_to_country' },
        { label: 'POD', value: 'pod' },
        { label: 'SKU', value: 'sku_code' },
        { label: 'Brand', value: 'brand' },
        { label: 'Variant', value: 'variant' }, { label: 'WO Release Date', value: 'wo_release_date' },
        { label: 'Work Order No', value: 'work_order_no' },
    ]
    const handleReloadData = (e) => {
        setReloadData(e);
      };
    const filterDatafordates=()=>{
        var from_date = woReleaseDate.wo_release_from_date 
        from_date=moment(from_date,'DD-MM-YYYY')
        var to_date = woReleaseDate.wo_release_to_date 
        to_date = moment(to_date,'DD-MM-YYYY')

        var filteredData = originalData.filter(item => {
            var itemDate = getHyphenDDMMYYYY(item.wo_release_date)
            itemDate = moment(itemDate,'DD-MM-YYYY')
            return itemDate.isBetween(from_date, to_date, null, '[]') 
          });
          setrowData(filteredData)
    }
    // const onClickHide= () => {
    //     setsliderForBulkupload('')
    //     setshowGrid(false)
    //     document.getElementById("uploadfile").value = null
    //     setoverly('show-n')
    // }
    const onclickbulkupload = (props) => {
        setsliderForBulkupload("slider-translate")
        setopen(true)
        setoverly('show-m')
    }
    const showdata = (e) => {
        if (e == 'detailed view') {
            setdetailedviewcheck(1)
            setsummaryviewcheck(0)
        }
        else if (e == 'summary view') {
            setdetailedviewcheck(0)
            setsummaryviewcheck(1)
        }
    }
    const handleFilterOptions = (e) => {
        if (e.value == 'wo_release_date') {
            setcheckForDateFilter(1)
            setcheckinput(0)
        }
        else {
            setcheckinput(1)
            setcheckForDateFilter(0)
        }
        setselectedval(prev => ({
            ...prev,
            dropdownval: e
        }))
    }
    const handleInputfilter = (e) => {
        const val = e.target.value.trim().toUpperCase(); // Trim and convert input value to uppercase
        const typeoffield = selectedval.dropdownval.value;
        let filteredData;

        if (typeoffield === 'work_order_no') {
            if (val !== '') {
                filteredData = originalData.filter((each) => {
                    return each.work_order_no.includes(val);
                });
            } else {
                filteredData = originalData;
            }
        } else {
            // Handle other field types if needed
        }

        setselectedval(prev => ({
            ...prev,
            inputval: e.target.value
        }))
        setrowData(filteredData);

    }
    const handleDatesSelected = (startDate, endDate) => {
        // Access the selected dates in the parent component
        var wo_release_from_date = startDate.format('DD-MM-YYYY');
        var wo_release_to_date = endDate.format('DD-MM-YYYY')
        setWoReleaseDate({
            wo_release_from_date: wo_release_from_date,
            wo_release_to_date: wo_release_to_date
        })
    };
    const filterData = () => {
        var typeoffield = selectedval.dropdownval.value
        var val = selectedval.inputval
        var filteredData
        if (typeoffield == 'ship_to_country') {
             filteredData = originalData.filter(item => item.ship_to_country.toLowerCase() == val.toLowerCase());
        }
        else if (typeoffield == 'pod') {
             filteredData = originalData.filter(item => item.pod.toLowerCase() == val.toLowerCase());
        }
        else if (typeoffield == 'sku_code') {
             filteredData = originalData.filter(item => item.sku_code.toLowerCase() == val.toLowerCase());
        }
        else if (typeoffield == 'brand') {
             filteredData = originalData.filter(item => item.brand.toLowerCase() == val.toLowerCase());
        }
        else if (typeoffield == 'variant') {
             filteredData = originalData.filter(item => item.variant.toLowerCase() == val.toLowerCase());
        }
        setrowData(filteredData)
    }
    var summaryViewcols = [
        {
            headerName: "Work Order No",
            field: "work_order_no",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "WO Release Date",
            field: "wo_release_date",
            width: 160,
            filter: true,
            resizable: true,
            valueGetter: function (params) {
                try {
                    if (params.data.wo_release_date != "" && params.data.wo_release_date != undefined) {
                        return getHyphenYYYYMMDD(params.data.wo_release_date);
                    }
                    else {
                        return "Not Available";
                    }
                }
                catch (e) { }
            },
        },
        {
            headerName: "Plan Month",
            field: "plan_month",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Status",
            field: "status",
            width: 160,
            filter: true,
            resizable: true,
            valueGetter:function(params){
                if(params.data.status==1){
                    return 'New'
                }
                else if(params.data.status==2){
                    return 'Planned'
                }
                else if(params.data.status==3) {
                    return 'Modified'
                }
            }
        },
        {
            headerName: "Ship to Country",
            field: "ship_to_country",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Work Order No",
            field: "work_order_no",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "SKU Code",
            field: "sku_code",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Brand",
            field: "brand",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Brand Variant",
            field: "brand_variant",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Quantity",
            field: "quantity",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Assy Config Type",
            field: "assy_config_type",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Quotation",
            field: "quotation",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "40ft Containers",
            field: "ft40_containers",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "20ft Containers",
            field: "ft20_containers",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "POD",
            field: "pod",
            width: 200,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Trucks",
            field: "truck_and_courier",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Latest Shipment Date",
            field: "latest_shipment_date",
            width: 160,
            filter: true,
            resizable: true,
            valueGetter: function (params) {
                try {
                    if (params.data.latest_shipment_date != "" && params.data.latest_shipment_date != undefined) {
                        return getHyphenDDMMYYYY(params.data.latest_shipment_date);
                    }
                    else {
                        return "Not Available";
                    }
                }
                catch (e) { }
            },
        },
        {
            headerName: "VIN Type",
            field: "vin_type",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Hardware Mode",
            field: "hardware_mode",
            width: 160,
            filter: true,
            resizable: true,
        },
    ]

    var detailedviewcols = [
        {
            headerName: "Work Order No",
            field: "work_order_no",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "WO Created Date",
            field: "wo_created_date",
            width: 160,
            filter: true,
            resizable: true,
            valueGetter: function (params) {
                try {
                    if (params.data.wo_created_date != "" && params.data.wo_created_date != undefined) {
                        return getHyphenDDMMYYYY(params.data.wo_created_date);
                    }
                    else {
                        return "Not Available";
                    }
                }
                catch (e) { }
            },
        },
        {
            headerName: "WO Release Date",
            field: "wo_release_date",
            width: 160,
            filter: true,
            resizable: true,
            valueGetter: function (params) {
                try {
                    if (params.data.wo_release_date != "" && params.data.wo_release_date != undefined) {
                        return getHyphenDDMMYYYY(params.data.wo_release_date);
                    }
                    else {
                        return "Not Available";
                    }
                }
                catch (e) { }
            },
        },
        {
            headerName: "Plan Month",
            field: "plan_month",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Ship to Country",
            field: "ship_to_country",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Destination Country",
            field: "destination_country",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "POD",
            field: "pod",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Plant",
            field: "plant",
            width: 60,
            filter: true,
            resizable: true,
        },
        {
            headerName: "SKU Code",
            field: "sku_code",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Brand",
            field: "brand",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Brand Variant",
            field: "brand_variant",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Quantity",
            field: "quantity",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Assy Config Type",
            field: "assy_config_type",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Order Type",
            field: "order_type",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Quotation",
            field: "quotation",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "2wh/3wh/4wh",
            field: "wh2_wh3_wh4",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "40ft Containers",
            field: "ft40_containers",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "20ft Containers",
            field: "ft20_containers",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Trucks",
            field: "truck_and_courier",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "PO NO",
            field: "po_no",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Requested Delivery Date",
            field: "requested_delivery_date",
            width: 190,
            filter: true,
            resizable: true,
            valueGetter: function (params) {
                try {
                    if (params.data.requested_delivery_date != "" && params.data.requested_delivery_date != undefined) {
                        return getHyphenDDMMYYYY(params.data.requested_delivery_date);
                    }
                    else {
                        return "Not Available";
                    }
                }
                catch (e) { }
            },
        },
        {
            headerName: "Payment Term",
            field: "payment_term",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "LC Ref No",
            field: "lc_ref_no",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Actual LC No",
            field: "actual_lc_no",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "LC Expiry Date",
            field: "lc_expiry_date",
            width: 160,
            filter: true,
            resizable: true,
            valueGetter: function (params) {
                try {
                    if (params.data.lc_expiry_date != "" && params.data.lc_expiry_date != undefined) {
                        return getHyphenDDMMYYYY(params.data.lc_expiry_date);
                    }
                    else {
                        return "Not Available";
                    }
                }
                catch (e) { }
            },
        },
        {
            headerName: "Latest Shipment Date",
            field: "latest_shipment_date",
            width: 170,
            filter: true,
            resizable: true,
            valueGetter: function (params) {
                try {
                    if (params.data.latest_shipment_date != "" && params.data.latest_shipment_date != undefined) {
                        return getHyphenDDMMYYYY(params.data.latest_shipment_date);
                    }
                    else {
                        return "Not Available";
                    }
                }
                catch (e) { }
            },
        },
        {
            headerName: "3rd Party Inspection Req",
            field: "",
            width: 190,
            filter: true,
            resizable: true,
        },
        {
            headerName: "CP Invoice Date",
            field: "cp_invoice_date",
            width: 160,
            filter: true,
            resizable: true,
            valueGetter: function (params) {
                try {
                    if (params.data.cp_invoice_date != "" && params.data.cp_invoice_date != undefined) {
                        return getHyphenDDMMYYYY(params.data.cp_invoice_date);
                    }
                    else {
                        return "Not Available";
                    }
                }
                catch (e) { }
            },
        },
        {
            headerName: "Delivery Block",
            field: "delivery_block",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Payment Status",
            field: "payment_status",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Expected Payment Clearance By",
            field: "expected_payment_clearance_by",
            width: 220,
            filter: true,
            resizable: true,
            valueGetter: function (params) {
                try {
                    if (params.data.expected_payment_clearance_by != "" && params.data.expected_payment_clearance_by != undefined) {
                        return getHyphenDDMMYYYY(params.data.expected_payment_clearance_by);
                    }
                    else {
                        return "Not Available";
                    }
                }
                catch (e) { }
            },
        },
        {
            headerName: "VIN Type",
            field: "vin_type",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "HAZ Classification",
            field: "haz_classification",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Hardware Mode",
            field: "hardware_mode",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Weight Status",
            field: "weight_status",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Buyer",
            field: "buyer",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Consignee",
            field: "consignee",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "CHA",
            field: "cha",
            width: 160,
            filter: true,
            resizable: true,
        },
    ]
    const onClickHideAll = () => {
        setloadshow('show-n')
    }
    const onSubmitOrderBulkUpload = (event) => {
        event.preventDefault();
        const modifiedData = Array.from(modifiedRows).map(node => node);
        var updateIDs =[]
        modifiedData.map((e)=>{
            updateIDs.push(e.id)
        })
        if (gridApi.current) {
            gridApi.current.stopEditing();
            const gridRowData = gridApi.current.getSelectedRows();
            //console.log("gridRowData ",gridRowData)
            var gridRowDataarr = []
            if(gridRowData.length > 0)
            {
                gridRowData.map((itm) => {
                    gridRowDataarr.push(itm)
                    // if(itm.container_no != "" && itm.pick_up_date != "" && itm.shipping_seal != "" && itm.container_no != undefined && itm.pick_up_date != undefined && itm.shipping_seal != undefined)
                    // {
                    //     gridRowDataarr.push(itm)
                    // }
                })
            }
            //console.log(gridRowDataarr,'gridRowDataarrgridRowDataarr')
        }
        setloadshow('show-m')
        if(file != ''){
            var rparams = {
				data:csvcontent,
			}
            if(validbulkfile==true){
                redirectURL.post('/consignments/uploadBulkuploadforProductiondates',rparams).then((resp)=>{
                    if(resp.data.status=='Success'){
                        //console.log(resp.data, "uploaddata")
                        setshow(true)
                        setbasicTitle("Successfully Uploaded")
                        setbasicType("success")
                        setloadshow('show-n')
                        setoverly('show-n')
                        setsliderForBulkupload('')
                        document.getElementById("uploadfile").value = null
                        getPlantsData()
                        setsliderForBulkupload('')
                        setshowGrid(false)
                    }
                    else{
                        setshow(true)
                        setbasicTitle(resp.data.message)
                        setbasicType("danger")
                        setloadshow('show-n')
                        setoverly('show-n')
                    }
                
                   })
            }
            else{
                setshow(true);
            setbasicTitle('Please Upload Valid Columns');
            setbasicType("danger");
            setloadshow('show-n');
            setoverly('show-n');
            }
        }
    }
    const onSubmitOrderBulkUpload1 = (event)=>{
        event.preventDefault();
        setloadshow('show-m')
        if(file != ''){
            var rparams = {
				data:csvcontent,
			}
            if(validbulkfile==true){
                redirectURL.post('/consignments/uploadRevisedProductiondates',rparams).then((resp)=>{
                    if(resp.data.status=='Success'){
                        //console.log(resp.data, "uploaddata")
                        setshow(true)
                        setbasicTitle("Successfully Uploaded")
                        setbasicType("success")
                        setloadshow('show-n')
                        setoverly('show-n')
                        setsliderForBulkupload('')
                        document.getElementById("uploadfile").value = null
                        getPlantsData()
                        setsliderForBulkupload('')
                        setshowGrid(false)
                    }
                    else{
                        setshow(true)
                        setbasicTitle(resp.data.message)
                        setbasicType("danger")
                        setloadshow('show-n')
                        setoverly('show-n')
                    }
                
                   })
            }
            else{
                setshow(true);
            setbasicTitle('Please Upload Valid Columns');
            setbasicType("danger");
            setloadshow('show-n');
            setoverly('show-n');
            }
        }
    }
    const refreshDataNow = (params) => {
        // //console.log("paramss", params)
        onClickCounters(params)
        getPlantsData()
        props.refresNow()
    }
    const handleCellValueChanged = (params) => {
        params.api.selectNode(params.node, true, false);
        //console.log("params",params.node.id)
        const modifiedNodes = new Set(modifiedRows);
        modifiedNodes.add(params.node);
        var id = params.node.id;
        setEditRowsIds(prevState => ([...prevState, id]));
        setModifiedRows(modifiedNodes);
        
      };
    
    var changeOrderFileHandlerFcrBulk = async (e) => {
        setfile(e.target.files[0])
        setshowGrid(true)
        var validCols = ["Work Order No", "Production Date", "Remarks(By IB)"]
        var verifyCols = verifyUploadFileColumns(validCols, e.target.files[0])
            .then(result => {
                setvalidbulkfile(result)
            })
            .catch(error => {
                seterrormsg(error)
            });
        var csvdata = await BulkForceFileHandler(e, [
            { name: 'Work Order No', inputName: 'work_order_no', required: true },
            { name: 'Production Date', inputName: 'production_date', required: true },
        ])
        setcsvcontent(csvdata)
        setrowData1(csvdata)
    }
    var changeOrderFileHandlerFcrBulk1 = async (e) => {
        setfile(e.target.files[0])
        setshowGrid(true)
        var validCols = ["Work Order No", "Revised Production Date"]
        var verifyCols = verifyUploadFileColumns(validCols, e.target.files[0])
            .then(result => {
                setvalidbulkfile(result)
            })
            .catch(error => {
                seterrormsg(error)
            });
        var csvdata = await BulkForceFileHandler(e, [
            { name: 'Work Order No', inputName: 'work_order_no', required: true },
            { name: 'Revised Production Date', inputName: 'revised_production_date', required: true },
        ])
        setcsvcontent(csvdata)
        setrowData1(csvdata)
    }
    const onClickEditProductionData = (rowdata) => {
        setreload(true)
        var row = rowdata.data 
        var production_date = row.production_date
        var data=[{'production_date': production_date,'work_order_no': row.work_order_no}]
        var params = {
            data:data
        }
        redirectURL.post('/consignments/uploadBulkuploadforProductiondates',params).then((resp)=>{
            if(resp.data.status=='success'){
                setshow(true)
                setbasicTitle("Successfully Updated")
                setbasicType("success")
            }
            else{
                setshow(true)
                setbasicTitle(resp.data.message)
                setbasicType("danger")
                
            }
           })
        // redirectURL.post('/consignments/editProductionDate', params).then((resp) => {
        //     if (resp.data.status == 'success') {
        //         setshow(true)
        //         setbasicTitle("Successfully Updated")
        //         setbasicType("success")
        //     }
        // })
    }
    const onClickRevisedProductionData = (rowdata) => {
        var row = rowdata.data
        var revised_production_date = row.revised_production_date
        var data=[{'revised_production_date': revised_production_date,'work_order_no': row.work_order_no}]
        var params = {
            data:data
        }
        redirectURL.post('/consignments/uploadBulkuploadforRevisedProductiondates',params).then((resp)=>{
            if(resp.data.status=='Success'){
                setshow(true)
                setbasicTitle("Successfully Updated")
                setbasicType("success")
            }
            else{
                setshow(true)
                setbasicTitle(resp.data.message)
                setbasicType("danger")
                
            }
           })
    }
    var closeAlert = () => {
        setshow(false)
        setloadshow('show-n')
    }
    function currentCounter() {
        return
    } 
    const onClickCounters=(param)=>{
        
        var filteredData
        if(param=='all'){
            setrowData(counters.allData)
            setIsClicked({all:true})
            setcheck(5)
        }
         else if(param==1){
            
            setrowData(counters.new_plant)
            setIsClicked({new:true})
            setcheck(1)
            setcheckforbulk(true)
        }
        else if(param==2){
            setrowData(counters.planned_plant)
            summaryViewcols.push()
            setIsClicked({planned:true})
            setcheck(2)
            setcheckforbulk(true)
        }
        else if(param==3){
            setrowData(counters.modified_plant)
            setIsClicked({modified:true})
            setcheck(3)
            setcheckforbulk(false)
        }
        else if(param==4){
             setrowData(counters.closed_data)
            setIsClicked({closed:true})
            setcheck(4)
        }
        else if(param==6){
            setrowData(counters.ib_stuffing_data)
           setIsClicked({ib_stuffing:true})
           setcheck(6)
       }
    }
   const onCloseModal = () => {
       setopen(false)
       setsliderForBulkupload('')
        setshowGrid(false)
        document.getElementById("uploadfile").value = null
        setoverly('show-n')
    }
    const columnwithDefs = summaryviewcheck == 1 ? summaryViewcols : detailedviewcols
    var productionDateCol = {
        headerName: "Production Date",
        field: "production_date",
        width: 150,
        filter: true,
        resizable: true,
        editable: true,
        cellEditor:DateEditor,
        //  cellEditor: params => params.data.planned_plant != 1 || params.data.modified_plant!=1 ? DateEditor : undefined,
        valueGetter: function (params) {
            try {
                if (params.data.production_date != "" && params.data.production_date != undefined) {
                    return getHyphenDDMMYYYY(params.data.production_date);
                } else {
                    return "Not Available";
                }
            } catch (e) {
                // Handle the exception if needed
            }
        },
        comparator: dateComparator1,
        
    }
    var productionCol = {
        headerName: "Production Date",
        field: "production_date",
        width: 150,
        filter: true,
        resizable: true,
        valueGetter: function (params) {
            try {
                if (params.data.production_date != "" && params.data.production_date != undefined) {
                    return getHyphenDDMMYYYY(params.data.production_date);
                } else {
                    return "Not Available";
                }
            } catch (e) {
                // Handle the exception if needed
            }
        },        
    }
    var submitCol={
        headerName: "",
        field: "",
        width: 80,
        filter: true, resizable: true,
        // cellRenderer: 'SubmitBtn'
        cellRendererFramework: SubmitBtn

    }
    var revisedProdCol =   {
            headerName: "Revised Production Date",
            field: "revised_production_date",
            width: 170,
            filter: true,
            resizable: true,
            editable: true,
            //  cellEditor: params => params.data.planned_plant != 1 || params.data.modified_plant!=1 ? DateEditor : undefined,
            cellEditor:DateEditor,
            valueGetter: function (params) {
                try {
                 if (params.data.revised_production_date != "" && params.data.revised_production_date != undefined) {
                         return getHyphenDDMMYYYY(params.data.revised_production_date);
                    } else {
                        return "Not Available";
                 }
                 } catch (e) {
                     // Handle the exception if needed
                 }
             },
             comparator: dateComparator1,
         }
         var revisedCol =   {
            headerName: "Revised Production Date",
            field: "revised_production_date",
            width: 170,
            filter: true,
            resizable: true,
            valueGetter: function (params) {
                try {
                 if (params.data.revised_production_date != "" && params.data.revised_production_date != undefined) {
                         return getHyphenDDMMYYYY(params.data.revised_production_date);
                    } else {
                        return "Not Available";
                 }
                 } catch (e) {
                 }
             },
         }
         var submitRevisedCol= {
            headerName: "",
            field: "",
            width: 80,
            filter: true, resizable: true,
            // hide:hideformodified,
            // cellRenderer: 'SubmitBtn'
            cellRendererFramework: SubmitRevisedBtn

        }
    if(isClicked.new==1){
        summaryViewcols.push(productionDateCol)
        summaryViewcols.push(submitCol)
        detailedviewcols.push(productionDateCol)
        detailedviewcols.push(submitCol)
    }
    else if(isClicked.planned==1){
        summaryViewcols.push(productionCol)
        summaryViewcols.push(revisedProdCol)
        summaryViewcols.push(submitRevisedCol)
    }
    else if(isClicked.modified==1){
        summaryViewcols.push(productionCol)
        summaryViewcols.push(revisedCol)

    }
    const columnDefs1 = [
        {
        headerName: "Work Order No",
        field: "work_order_no",
        width: 160,
        filter: true,
        resizable: true,
         },
        {
            headerName: "Production Date",
            field: "production_date",
            width: 160,
            filter: true,
            resizable: true,
            // cellEditor: DateEditor,
            editable: (params) => {
                const date = params.data.production_date;
                // Check if date is in DD-MM-YYYY format
                const datePattern = /^\d{2}-\d{2}-\d{4}$/;
                return !(date && datePattern.test(date)); // Editable if date is invalid
            },
            valueGetter: (params) => {
                const date = params.data.production_date;
                // Check if date is in DD-MM-YYYY format
                const datePattern = /^\d{2}-\d{2}-\d{4}$/;
                if (date && datePattern.test(date)) {
                    return date;
                } else {
                    return "Invalid Date Format";
                }
            },
            // valueSetter: (params) => {
            //     const newValue = params.newValue;
            //     // Check if the new value is in DD-MM-YYYY format
            //     const datePattern = /^\d{4}-\d{2}-\d{2}$/;
            //     if (newValue && datePattern.test(newValue)) {
            //         const [year, month, day] = newValue.split('-')
            //         const formattedDate = `${day}-${month}-${year}`
            //         params.data.production_date = formattedDate; // Update the data if valid
            //         return true;
            //     } else {
            //         return false; // Reject the change if invalid
            //     }
            // },
            cellStyle: (params) => {
                const date = params.value;
                // Apply red color if the date format is invalid
                if (date === "Invalid Date Format") {
                    return { background: 'red' ,
                        color:'black'
                    };
                }
                return null;
            },
            // comparator: dateComparator1,
        },
    ]
    const columnDefs2 = [
        {
        headerName: "Work Order No",
        field: "work_order_no",
        width: 160,
        filter: true,
        resizable: true,
         },
        {
            headerName: "Revised Production Date",
            field: "revised_production_date",
            width: 170,
            filter: true,
            resizable: true,
            // cellEditor:DateEditor,
            valueGetter: (params) => {
                const date = params.data.revised_production_date;
                // Check if date is in DD-MM-YYYY format
                const datePattern = /^\d{2}-\d{2}-\d{4}$/;
                if (date && datePattern.test(date)) {
                    return date;
                } else {
                    return "Invalid Date Format";
                }
            },
            editable: (params) => {
                const date = params.data.revised_production_date;
                // Check if date is in DD-MM-YYYY format
                const datePattern = /^\d{2}-\d{2}-\d{4}$/;
                return !(date && datePattern.test(date)); // Editable if date is invalid
            },
            // valueSetter: (params) => {
            //     const newValue = params.newValue;
            //     // Check if the new value is in DD-MM-YYYY format
            //     const datePattern = /^\d{4}-\d{2}-\d{2}$/;
            //     if (newValue && datePattern.test(newValue)) {
            //         const [year, month, day] = newValue.split('-')
            //         const formattedDate = `${day}-${month}-${year}`
            //         params.data.revised_production_date = formattedDate; // Update the data if valid
            //         return true;
            //     } else {
            //         return false; // Reject the change if invalid
            //     }
            // },
            cellStyle: (params) => {
                const date = params.value;
                // Apply red color if the date format is invalid
                if (date === "Invalid Date Format") {
                    return { background: 'red' ,
                        color:'black'
                    };
                }
                return null;
            },
            // comparator: dateComparator1
        },
    ]
    return (
        <div class="container-fluid" style={{ padding: '10px' }}>
            <SweetAlert
                show={show}
                type={basicType}
                title={basicTitle}
                onConfirm={closeAlert}
            >
            </SweetAlert>
            {props.allcounter==1?
           '':
           <div className="form-group d-flex justify-content-between  mt-2 pl-0 mb-0" style={{right:"15px"}}>
                <h5 className="heading ml-50p">
                    <a href="/exportoperationscontroltower" className="txt-dec-none clr333">
                                </a>Plant Activities
                 </h5>
                 {/* {checkforbulk?
                <button type="button" className="btn-bulkupload mr-20p" style={{ fontSize: "0.8rem", }} onClick={()=> onclickbulkupload()}>
                        <img src={require("../../assets/images/upload.png")} alt="Icon" className="mr-1" />
                        Bulk Upload</button>:""} */}
            </div>}
                {/* <div className='btn btn-danger' style={{ color: "#fff", height: "35px", marginTop: "32px", fontSize: "15px", textAlign: "center", paddingTop: "7px" }} onClick={onClickSaveGridState}>
                        <i className="icofont icofont-save" style={{ marginRight: '5px' }}></i>Save Grid Layout
                    </div> */}
            <div className={props.allcounter == 1 ? '' : "card mb-20p"} style={props.allcounter === 1 ? {} : { padding: '25px', margin: '20px' }}>
            {props.allcounter==1?'':
            <span style={{textAlign:'end',color:'red',fontSize:'12px'}}>* New / Modified</span>}
                <div className="row bghighliter">
                    
                    <div className="plantCounterdiv"  style={{  background: isClicked.new ? 'rgb(64,81,120)' : '', cursor:"pointer"}}  onClick={() => onClickCounters('1')}>
                        <img src={isClicked.new?require("../../assets/images/new-white.png"):require("../../assets/images/new.png")} alt="Icon" style={{ height: '25px', margin: '8px' }} />
                        <div  className="col-9 txt-center" style={{ padding: '3px', color: isClicked.new ? '#FFFFFF' : '#0664AE'}}>
                            <h5 className="sptile-tracking" style={{ fontWeight: 'bold', fontSize: '11px' }} >
                                New
                            </h5>
                            <div>{counters.new_plant.length}</div>
                        </div>
                    </div>
                    <div className="plantCounterdiv"  style={{ background: isClicked.planned ? 'rgb(64,81,120)' : '' , cursor:"pointer"}}  onClick={() => onClickCounters('2')}>
                        <img src={isClicked.planned?require("../../assets/images/planned-white.png"):require("../../assets/images/planned.png")} alt="Icon" style={{ height: '25px', margin: '8px' }} />
                        <div  className="col-9 txt-center" style={{ padding: '3px', color: isClicked.planned ? '#FFFFFF' : '#0664AE'  }}>
                            <h5 className="sptile-tracking" style={{ fontWeight: 'bold', fontSize: '11px' }} >
                                Planned
                            </h5>
                            <div>{counters.planned_plant.length}</div>
                        </div>
                    </div>
                    <div className="plantCounterdiv"  style={{ background: isClicked.modified ? 'rgb(64,81,120)' : '', cursor:"pointer"}}   onClick={() => onClickCounters('3')}>
                        <img src={isClicked.modified?require("../../assets/images/modified-white.png"):require("../../assets/images/edit.png")} alt="Icon" style={{ height: '23px', margin: '8px' }} />
                        <div  className="col-9 txt-center" style={{ padding: '3px', color: isClicked.modified ? '#FFFFFF' : '#0664AE'  }}>
                            <h5 className="sptile-tracking" style={{ fontWeight: 'bold', fontSize: '11px' }} >
                                Modified
                            </h5>
                            <div>{counters.modified_plant.length}</div>
                        </div>
                    </div>
                    {/* <div className="plantCounterdiv"  style={{ background: isClicked.ib_stuffing ? 'rgb(64,81,120)' : ''}}   onClick={() => onClickCounters('6')}>
                        <img src={isClicked.ib_stuffing?require("../../assets/images/planned-white.png"):require("../../assets/images/planned.png")} alt="Icon" style={{ height: '23px', margin: '8px' }} />
                        <div  className=" txt-center" style={{ padding: '3px', color: isClicked.ib_stuffing ? '#FFFFFF' : '#0664AE'  }}>
                            <h5 className="sptile-tracking" style={{ fontWeight: 'bold', fontSize: '11px' }} >
                                IB Stuffing Date
                            </h5>
                            <div>{counters.ib_stuffing_data.length}</div>
                        </div>
                    </div> */}
                    <div className="plantCounterdiv"  style={{ background: isClicked.closed ? 'rgb(64,81,120)' : '', cursor:"pointer"}}   onClick={() => onClickCounters('4')}>
                    <img src={isClicked.closed==1?require("../../assets/images/all-white.png"):require("../../assets/images/all.png")} alt="Icon" style={{ height: '21px', margin: '8px' }} />
                        {/* <img src={isClicked.closed?require("../../assets/images/closed-white.png"):require("../../assets/icons/closed_clr.png")} alt="Icon" style={{ height: '20px', margin: '8px' }} /> */}
                        <div  className="col-9 txt-center" style={{ padding: '3px', color: isClicked.closed ? '#FFFFFF' : '#0664AE'  }}>
                            <h5 className="sptile-tracking" style={{ fontWeight: 'bold', fontSize: '11px' }} >
                                Completed
                            </h5>
                            <div>{(counters.closed_data!='' && counters.closed_data!=undefined)?counters.closed_data.length:0}</div>
                        </div>
                    </div>
                </div>
                <div className="d-flex justify-content-end">
                <div className="form-group">
                {checkforbulk?
                <button type="button" className="btn-bulkupload mr-20p" style={{ fontSize: "0.8rem", }} onClick={()=> onclickbulkupload()}>
                        <img src={require("../../assets/images/upload.png")} alt="Icon" className="mr-1" />
                        Bulk Upload</button>:""}
                </div>
                </div>
                {check==4?<ClosedComponentForPlant check={1} data={tabsforcontainerReport} onReloadData={handleReloadData}/>:''}
                {check==1?<Newcomponent check={1} onRequest={refreshDataNow} data={counters.new_plant} onReloadData={handleReloadData}/>:''}
                {check==2?<Plannedcomponent check={2}  onRequest={refreshDataNow} data={counters.planned_plant} onReloadData={handleReloadData} />:''}
                {check==3?<Modifiedcomponent check={3} data={counters.modified_plant} onRequest={refreshDataNow} onReloadData={handleReloadData}/>:''}
                {check==5?<Newcomponent check={1} data={counters.allData} onReloadData={handleReloadData}/>:''}
                {check==6?<IBStuffingForPlant check={2} data={counters.ib_stuffing_data} onReloadData={handleReloadData}/>:''}
            </div>
            {sliderForBulkupload == 'slider-translate' &&
                <Modal open={open} onClose={onCloseModal} classNames={{ modal: 'custom-modal' }} >
                   {isClicked.new==true?<> <div className="slide-r-title slider_title_style title-radius">
                        <h6>Bulk Upload</h6>
                        {/* <span className="float-right closebtn" style={{ marginTop: '-30px', marginRight: "25px" }} onClick={onClickHide} >X</span> */}
                    </div>
                    <div className="slide-r-body" style={{ position: "relative" }}>
                        <div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div>
                        <div className={"row"} style={{ padding: '5px 0px', width: '100%', zIndex: '9999', }} >
                            <div className="theme-form col-xl-12 col-lg-12">
                                <form action="" onSubmit={onSubmitOrderBulkUpload}>
                                    <div className="col-xl-12 col-lg-12 row form-group">
                                        <label className="col-xl-12 col-lg-12">Upload File *</label>
                                        <div className="d-flex justify-content-between">
                                            <input
                                                type="file"
                                                name="uploadfile"
                                                id="uploadfile"
                                                className="form-control ml-3"
                                                onChange={changeOrderFileHandlerFcrBulk}
                                                style={{ width: "400px" }}
                                                required
                                            />
                                            <img src={require("../../assets/icons/red-dot.png")} className="img-dot ml-70p mt-8p" /><span className="mt-8p ml-10p">Invalid Date Format</span>
                                        </div>
                                    </div>
                                    {showGrid &&
                                        <div id="idgrid" style={{ width: "20.2rem" }} className="ag-theme-balham m-10p ml-15p">
                                            <AgGridReact
                                                // modules={this.state.modules}
                                                columnDefs={columnDefs1}
                                                // defaultColDef={this.state.defaultColDef}
                                                rowData={rowData1}
                                                domLayout="autoHeight"
                                                ref={gridRef}
                                                onCellEditingStopped={handleCellValueChanged}
                                                 onGridReady={onGridReady}
                                            />
                                        </div>}
                                    <div className="col-xl-12 col-lg-12 form-group mt-10p">
                                        <button type="submit" className="btn btn-success">Submit</button>
                                    </div>
                                </form>
                                {!showGrid && <div className="col-xl-12 col-lg-12 form-group">
                                    <a href={require("../../assets/json/plant_based_upload_data.csv")} target="_blank" className="btn btn-warning">Download Template</a>
                                </div>}

                                <p style={{ color: 'red' }} className="ml-15p">Note: </p>
                                <p className="ml-15p">Work Order No: It Should be in Numbers<br />Production Date: It Should be in DD-MM-YYYY format</p>

                            </div>
                        </div>
                    </div></>:<> <div className="slide-r-title slider_title_style title-radius">
                        <h6>Bulk Upload</h6>
                        {/* <span className="float-right closebtn" style={{ marginTop: '-30px', marginRight: "25px" }} onClick={onClickHide} >X</span> */}
                    </div>
                    <div className="slide-r-body" style={{ position: "relative" }}>
                        <div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div>
                        <div className={"row"} style={{ padding: '5px 0px', width: '100%', zIndex: '9999', }} >
                            <div className="theme-form col-xl-12 col-lg-12">
                                <form action="" onSubmit={onSubmitOrderBulkUpload1}>
                                    <div className="col-xl-12 col-lg-12 row form-group">
                                        <label className="col-xl-12 col-lg-12">Upload File *</label>
                                        <div className="d-flex justify-content-between">
                                            <input
                                                type="file"
                                                name="uploadfile"
                                                id="uploadfile"
                                                className="form-control ml-3"
                                                onChange={changeOrderFileHandlerFcrBulk1}
                                                style={{ width: "400px" }}
                                                required
                                            />
                                            <img src={require("../../assets/icons/red-dot.png")} className="img-dot ml-70p mt-8p" /><span className="mt-8p ml-10p">Invalid Date Format</span>
                                        </div>
                                    </div>
                                    {showGrid &&
                                        <div id="idgrid" style={{ width: "21rem" }} className="ag-theme-balham m-10p ml-15p">
                                            <AgGridReact
                                                // modules={this.state.modules}
                                                columnDefs={columnDefs2}
                                                // defaultColDef={this.state.defaultColDef}
                                                rowData={rowData1}
                                                domLayout="autoHeight"
                                            />
                                        </div>}
                                    <div className="col-xl-12 col-lg-12 form-group mt-10p">
                                        <button type="submit" className="btn btn-success">Submit</button>
                                    </div>
                                </form>
                                {!showGrid && <div className="col-xl-12 col-lg-12 form-group">
                                    <a href={require("../../assets/json/plant_based_upload_for_planned.csv")} target="_blank" className="btn btn-warning">Download Template</a>
                                </div>}
                                <p style={{ color: 'red' }} className="ml-15p">Note: </p>
                                <p className="ml-15p">Work Order No: It Should be in Numbers<br />Revised Production Date: It Should be in DD-MM-YYYY format</p>
                            </div>
                        </div>
                    </div></>}
                </Modal>} 
         
            

            <div className={"dataLoadpage " + (loadshow)}>
            </div>
            <div className={"dataLoadpageimg " + (loadshow)}>
                <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
            </div>
            <div className={"overlay-part " + (overly)} onClick={onClickHideAll}></div>
        </div>
    )
}
function isEmpty(obj) {
    for (var key in obj) {
        if (obj.hasOwnProperty(key))
            return false;
    }
    return true;
}
function timeConvert(n) {
    var diffMs = n;
    var diffDays = Math.floor(diffMs / 86400000); // days
    var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
    var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
    return diffHrs + " hour(s) and " + (diffMins / 60) + " minute(s).";
}
var currentinfowindow = null;
function getTimeInfo(marker, timedet, coords) {
    var timeinfo = new window.google.maps.InfoWindow({
        content: "Time at this location: " + timedet
    });
    marker.addListener('click', function () {

        if (currentinfowindow != null) {
            currentinfowindow.close();
            currentinfowindow = timeinfo;
            timeinfo.open(marker.get('map'), marker);
        }
        else {
            currentinfowindow = timeinfo;
            timeinfo.open(marker.get('map'), marker);
        }
    });
}
function secondsToString(seconds) {
    var numdays = Math.floor(seconds / 86400);
    var numhours = Math.floor((seconds % 86400) / 3600);
    var numminutes = Math.floor(((seconds % 86400) % 3600) / 60);
    var numseconds = ((seconds % 86400) % 3600) % 60;
    return numdays + " days " + numhours + " hours " + numminutes + " minutes ";
}
function secondsToDays(seconds) {
    var numdays = Math.floor(seconds / 86400);
    var numhours = Math.floor((seconds % 86400) / 3600);
    var numminutes = Math.floor(((seconds % 86400) % 3600) / 60);
    var numseconds = ((seconds % 86400) % 3600) % 60;
    return numdays;
}
function CheckForHtmlTag() {
    var rs = document.getElementById("invalid_reason");
    var reg = /<(.|\n)*?>/g;
    if (reg.test(rs.value)) {
        var ErrorText = "Oops! HTML or Script is not allowed.";
        rs.value = ''
        //alert();
        return 1;
    }
}
function dateComparator(date1, date2) {
    if (date1 != "" && date2 != "" && date1 != undefined && date2 != undefined) {
        var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
        date1 = date1.replace(date1.split(" ")[0].split("-")[1], date_1);
        var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
        date2 = date2.replace(date2.split(" ")[0].split("-")[1], date_2);
        var date1Number = monthToComparableNumber(date1);
        var date2Number = monthToComparableNumber(date2);
        if (date1Number === null && date2Number === null) {
            return 0;
        }
        if (date1Number === null) {
            return -1;
        }
        if (date2Number === null) {
            return 1;
        }
        return date1Number - date2Number;
    }
}
function monthToComparableNumber(date) {
    if (date === undefined || date === null || date.length !== 16) {
        return null;
    }
    var yearNumber = date.substring(6, 10);
    var monthNumber = date.substring(3, 5);
    var dayNumber = date.substring(0, 2);
    var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
    return result;
}
function getMonthNumber(monthName) {
    var months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May',
        'Jun', 'Jul', 'Aug', 'Sep',
        'Oct', 'Nov', 'Dec'
    ];
    var monthNum = months.indexOf(monthName) + 1;
    if (monthNum < 10) {
        monthNum = "0" + monthNum.toString();
    }
    return monthNum;
}
function loadDateTimeScript() {
    $('.datetimepicker_mask').datetimepicker({
        mask: '39-19-9999',
        format: 'd-m-Y',
        onShow: false
    });
    $('.datetimepicker_date').datetimepicker({
        format: 'd-m-Y',
        timepicker: false,
        onShow: false
    });
    var index = window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src = "https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
    script.async = true
    script.defer = true
    index.parentNode.insertBefore(script, index)
}
function GetSortDescOrder(prop) {
    return function (a, b) {
        if (a[prop] < b[prop]) {
            return 1;
        } else if (a[prop] > b[prop]) {
            return -1;
        }
        return 0;
    }
}
function GetSortAscOrder(prop) {
    return function (a, b) {
        if (a[prop] > b[prop]) {
            return 1;
        } else if (a[prop] < b[prop]) {
            return -1;
        }
        return 0;
    }
}
function GetSortASCOrder(prop) {
    return function (a, b) {
        if (a[prop] > b[prop]) {
            return 1;
        } else if (a[prop] < b[prop]) {
            return -1;
        }
        return 0;
    }
}
function secondsToDhms(seconds) {
    seconds = Number(seconds);
    var d = Math.floor(seconds / (3600 * 24));
    var h = Math.floor(seconds % (3600 * 24) / 3600);
    var m = Math.floor(seconds % 3600 / 60);
    var s = Math.floor(seconds % 60);
    var dDisplay = d >= 0 ? d + (d == 1 ? " Day " : " Days ") : "";
    var hDisplay = h >= 0 ? h + (h == 1 ? " Hr " : " Hrs ") : "";
    var mDisplay = m >= 0 ? m + (m == 1 ? " Mins " : " Mins ") : "";
    var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
    return dDisplay + hDisplay + mDisplay;
}
function distinctArrayBy(arr, propName) {
    var result = arr.reduce(function (arr1, e1) {
        var matches = arr1.filter(function (e2) {
            return e1[propName] == e2[propName];
        })
        if (matches.length == 0)
            arr1.push(e1)
        return arr1;
    }, []);

    return result;
}
function dateComparator1(date1, date2) {
    if (date1 != "" && date2 != "" && date1 != undefined && date2 != undefined) {
        var date_1 = getMonthNumber(date1.split("-")[1]);
        date1 = date1.replace(date1.split("-")[1], date_1);
        var date_2 = getMonthNumber(date2.split("-")[1]);
        date2 = date2.replace(date2.split("-")[1], date_2);
        var date1Number = monthToComparableNumber1(date1);
        var date2Number = monthToComparableNumber1(date2);
        if (date1Number === null && date2Number === null) {
            return 0;
        }
        if (date1Number === null) {
            return -1;
        }
        if (date2Number === null) {
            return 1;
        }
        return date1Number - date2Number;
    }
}
function monthToComparableNumber1(date) {
    if (date === undefined || date === null || date.length !== 10) {
        return null;
    }
    var yearNumber = date.substring(6, 10);
    var monthNumber = date.substring(3, 5);
    var dayNumber = date.substring(0, 2);
    var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
    return result;
}
function loadDateTimeScript2() {
    $('.datetimepicker_mask').datetimepicker({
        format: 'd-m-Y'
    });
    $('.device_filter_data').datetimepicker({
        format: 'Y-m-d',
        timepicker: true
    });
    var index = window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src = "https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
    script.async = true
    script.defer = true
    index.parentNode.insertBefore(script, index)
}
function getDatePicker() {
    function Datepicker() { }
    Datepicker.prototype.init = function (params) {
        this.eInput = document.createElement("input");
        this.eInput.value = params.value;
        window.$(".datepicker").datepicker({ dateFormat: "dd-mm-yy" });
    };
    Datepicker.prototype.getGui = function () {
        return this.eInput;
    };
    Datepicker.prototype.afterGuiAttached = function () {
        this.eInput.focus();
        this.eInput.select();
    };
    Datepicker.prototype.getValue = function () {
        return this.eInput.value;
    };
    Datepicker.prototype.destroy = function () { };
    Datepicker.prototype.isPopup = function () {
        return false;
    };
    return Datepicker;
}
function sortByCreatedDate(arr, descending = true) {
    if (arr != "" && arr != undefined) {
      if (arr.length > 0) {
        const comparison = (a, b) => {
          const dateA = new Date(a.modified_date);
          const dateB = new Date(b.modified_date);
          if (descending) {
            return dateB - dateA; // Ascending order
          } else {
            return dateA - dateB; // Descending order
          }
        };
        arr.sort(comparison);
        return arr;
      }
    } else {
      return arr;
    }
  }
function DateEditor() { }
DateEditor.prototype.init = function (params) {
    this.eInput = document.createElement('input');
    this.eInput.value = params.value;
    $(this.eInput).datepicker({
        dateFormat: "yy-mm-dd",
        changeMonth: true,
        changeYear: true,
        showTime: false,
    });
};
DateEditor.prototype.getGui = function () {
    return this.eInput;
};
DateEditor.prototype.afterGuiAttached = function () {
    this.eInput.focus();
    this.eInput.select();
};
DateEditor.prototype.getValue = function () {
    return this.eInput.value;
};
DateEditor.prototype.destroy = function () {
};
DateEditor.prototype.isPopup = function () {
    return false;
};
const verifyUploadFileColumns = async (validCols, file) => {
    //console.log(validCols,'1684')
    return new Promise((resolve, reject) => {
        if (file !== "" && file !== undefined) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const lines = e.target.result.split('\n');
                const headers = lines[0].split(',');
                //console.log(headers,'1691')
                const invalidCols = headers.filter(header => !validCols.includes(header.trim()));
                if (invalidCols.length === 0) {
                    resolve(true);
                } else {
                    resolve(`Invalid column(s): ${invalidCols.join(', ')}`);
                }
            };
            reader.readAsText(file);
        } else {
            reject('No file selected');
        }
    });
};

export default Plant