import React, { useState, useEffect, useRef } from "react";
import SweetAlert from "react-bootstrap-sweetalert";

import { AgGridReact } from "ag-grid-react"; // the AG Grid React Component
import "ag-grid-enterprise";
import "ag-grid-enterprise/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-enterprise/styles/ag-theme-balham.css";
import "react-datetime/css/react-datetime.css";
import Datetime from "react-datetime";
import AcknowledgeBtnComponent from "./acknowledgeBtn";
import UpdateAcknowledgeBtnComponent from "./update&aknowledgeBtn";
import IBBasedTPTData from "../../assets/json/ib_based_tpt_data.csv";
import PTData from '../../assets/json/payment_term_description.json'

import SubmitRevisedBtn from "./submitrevisedbtn";

import $ from "jquery";
import "jquery-ui/ui/core";
import "jquery-ui/ui/widgets/datepicker";
import Select from "react-select";
import SearchIcon from "../../assets/icons/search-icon.svg";
import RefreshIcon from "../../assets/images/refresh-icon.svg";
import SubmitBtn from "./submitBtn";
import Datepicker from "./datepicker";
import {
  getHyphenYYYYMMDDHHMMSS,
  getDDMMMYYYY,
  getHyphenDDMMYYYY,
  getHyphenYYYYMMDD,
} from "../common/utils";
import moment from "moment";
import BulkForceFileHandler from "../layouts/changebulkuploadhandler";
import IBtptAllocation from "./ibtptallocationComponent";
import IntransitToPlant from "./intransittoplant";
import AtPlant from "./atplant";
import CountryFlagComponent from "./countryFlag";
import UpdateIBStuffingComponent from "./updateIBStuffingDateComponent";
import UpdateIBStuffingComponent1 from "./updateibstuffingcomponent1";
import IBallComponent from "./ibAllcomponent";
import IBBuffer from "./bufferInIBComponent";
import customNextIcon from "../../assets/icons/next.png";
import customPreviousIcon from "../../assets/icons/back.png";
import customFirstIcon from "../../assets/icons/back_left.png";
import customLastIcon from "../../assets/icons/next_right.png";
import { fetchGrid } from "../fetchGridStructure";
import redirectURL from "../redirectURL";
import Modal from "react-responsive-modal";

const IBUpdatesComponent = (props) => {
  var gridRef = useRef();
  const gridApi = useRef(null);
  var [check, setcheck] = useState(1);
  var [checkinput, setcheckinput] = useState(1);
  var [checkForDateFilter, setcheckForDateFilter] = useState(0);
  const [isClicked, setIsClicked] = useState({
    ibStuffing: true,
    ibtptallocation: false,
    IntransitToPlant: false,
    AtPlant: false,
    all: true,
    modified: false,
    buffer: false,
  });
  var [summaryviewcheck, setsummaryviewcheck] = useState(1);
  var [detailedviewcheck, setdetailedviewcheck] = useState(0);
  var [rowData, setrowData] = useState([]);
  var [ptd, setptd] = useState(PTData.PTData ?? [])
  var [originalData, setoriginalData] = useState([]);
  var [woReleaseDate, setWoReleaseDate] = useState({
    wo_release_from_date: "",
    wo_release_to_date: "",
  });
  var [selectedval, setselectedval] = useState({
    dropdownval: "",
    inputval: "",
  });
  var [loadshow, setloadshow] = useState("show-n");
  var [overly, setoverly] = useState("show-n");
  var [validbulkfile, setvalidbulkfile] = useState(false);
  var [errormsg, seterrormsg] = useState("");
  var [sliderForBulkupload, setsliderForBulkupload] = useState("");
  var [file, setfile] = useState("");
  var [csvcontent, setcsvcontent] = useState("");
  var [show, setshow] = useState(false);
  var [basicTitle, setbasicTitle] = useState("");
  var [basicType, setbasicType] = useState("");
  var [checkforbulk, setcheckforbulk] = useState(true);
  var typeofselection = [
    { label: "Ship To Country", value: "ship_to_country" },
    { label: "POD", value: "pod" },
    { label: "CHA", value: "cha" },
    { label: "Plant", value: "plant" },
    // { label: 'WO Release Date', value: 'wo_release_date' },
    { label: "Production Date", value: "production_date" },
    { label: "Work Order No", value: "work_order_no" },
    // { label: 'Latest Shipment Date', value: 'latest_shipment_date' },
    // { label: 'Tentative Vessel ETD', value: 'tentative_vessel_etd' },
    // { label: 'Tentative Vessel ETD', value: 'tentative_vessel_etd' },
  ];
  const [showAddForm, setshowAddForm] = useState("");
  const [showAddForm1, setshowAddForm1] = useState("");
  var [showGrid, setshowGrid] = useState(false);
  const [manualUploadDetails, setmanualUploadDetails] = useState({
    wrno: "",
    remarks_by_ib: "",
    stuffing_date: "",
  });
  var [open, setopen] = useState(false);

  var [api, setAPI] = useState("");

  var [csvfilename, setcsvfilename] = useState("");
  var [bulkUploadFields, setbulkUploadFields] = useState([]);
  var [validcols, setvalidcols] = useState([]);
  var [workordernoList, setworkOrderList] = useState([]);
  var [ibcount, setIBCounters] = useState({
    atplant: [],
    intransit_to_plant: [],
    ib_tpt_allocation: [],
    ib_stuffing: [],
    buffer: [],
  });
  var [tabsforintransitToPlant, settabsforintransitToPlant] = useState({
    intransit_planned: [],
    intransit_new: [],
    intransit_modified: [],
    container_new_1: [],
    container_new_2: [],
    container_new_3: [],
  });
  var [tabsForAtPlant, setTabsForAtPlant] = useState({
    at_plant_new: [],
    at_plant_planned: [],
    at_plant_modified: [],
    container_new_1: [],
    container_new_2: [],
    container_new_3: [],
  });
  var [tabsfortpt, settabsfortpt] = useState({
    ib_tpt_allocation_new: [],
    ib_tpt_allocation_plannd: [],
    ib_tpt_allocation_modified: [],
  });
  var [selected_row, setselected_row] = useState({});
  var [checkresetfilter, setcheckresetfilter] = useState(0);
  var [checkresetdatefilters, setcheckresetdatefilters] = useState(0);

  var [allIBData, setAllIBData] = useState([]);
  var [bufferdata, setbufferdata] = useState([]);
  var [classForTab, setclassforTab] = useState({
    planned: 0,
    new: 1,
    modified: 0,
  });
  var [tabDataforstuffing, setTabDataForstuffing] = useState({
    ib_stuffing_new_plant: [],
    ib_stuffing_planned_plant: [],
    ib_stuffing_modified_plant: [],
  });
  var [refreshSelected, setRefreshSelected] = useState(0);
  var [rowData1, setrowData1] = useState([]);
  var [rowData2, setrowData2] = useState([]);
  
  const [summaryViewcols, setsummaryViewcols] = useState([
    {
      headerName: "Work Order No",
      field: "work_order_no",
      width: 130,
      filter: true,
      resizable: true,
    },
    {
      headerName: "Production Date",
      field: "production_date",
      width: 160,
      filter: true,
      resizable: true,
      valueGetter: function (params) {
        try {
          if (
            params.data.production_date != "" &&
            params.data.production_date != undefined
          ) {
            return getHyphenDDMMYYYY(params.data.production_date);
          } else {
            return "Not Available";
          }
        } catch (e) {}
      },
    },
    // {
    //   headerName: "Tentative Vessel ETD",
    //   field: "tentative_vessel_etd",
    //   width: 200,
    //   filter: true,
    //   resizable: true,
    //   valueGetter: function (params) {
    //     try {
    //       if (
    //         params.data.tentative_vessel_etd != "" &&
    //         params.data.tentative_vessel_etd != undefined
    //       ) {
    //         return getHyphenDDMMYYYY(params.data.tentative_vessel_etd);
    //       } else {
    //         return "Not Available";
    //       }
    //     } catch (e) {}
    //   },
    // },
    {
      headerName: "Stuffing Date",
      field: "stuffing_date",
      width: 160,
      filter: true,
      resizable: true,
      valueGetter: function (params) {
        try {
          if (
            params.data.stuffing_date != "" &&
            params.data.stuffing_date != undefined
          ) {
            return getHyphenDDMMYYYY(params.data.stuffing_date);
          } else {
            return "Not Available";
          }
        } catch (e) {}
      },
    },
    {
      headerName: "CHA",
      field: "cha",
      width: 160,
      filter: true,
      resizable: true,
    },
    {
      headerName: "CHA Name",
      field: "cha_name",
      width: 120,
      filter: true,
      resizable: true,
    },
    {
      headerName: "Plant Name",
      field: "plant_name",
      width: 120,
      filter: true,
      resizable: true,
    },
    {
      headerName: "Transporter Name",
      field: "tpt_name",
      width: 160,
      filter: true,
      resizable: true,
    },
    {
      headerName: "Ship To Country",
      field: "ship_to_country",
      width: 120,
      filter: true,
      resizable: true,
      // cellRenderer: CountryFlagComponent
    },
    {
      headerName: "Brand",
      field: "brand",
      width: 120,
      filter: true,
      resizable: true,
    },
    {
      headerName: "Brand Variant",
      field: "brand_variant",
      width: 150,
      filter: true,
      resizable: true,
    },
    {
      headerName: "Quantity",
      field: "quantity",
      width: 120,
      filter: true,
      resizable: true,
    },
    {
      headerName: "40ft Containers",
      field: "ft40_containers",
      width: 150,
      filter: true,
      resizable: true,
    },
    {
      headerName: "20ft Containers",
      field: "ft20_containers",
      width: 150,
      filter: true,
      resizable: true,
    },
    {
      headerName: "Trucks",
      field: "truck_and_courier",
      width: 130,
      filter: true,
      resizable: true,
    },
    {
      headerName: "POD",
      field: "pod",
      width: 150,
      filter: true,
      resizable: true,
    },
    {
      headerName: "Plant",
      field: "plant",
      width: 120,
      filter: true,
      resizable: true,
    },
    {
      headerName: "Quotation",
      field: "quotation",
      width: 130,
      filter: true,
      resizable: true,
    },

    {
      headerName: "Latest Shipment Date",
      field: "latest_shipment_date",
      width: 180,
      filter: true,
      resizable: true,
      valueGetter: function (params) {
        try {
          if (
            params.data.latest_shipment_date != "" &&
            params.data.latest_shipment_date != undefined
          ) {
            return getHyphenDDMMYYYY(params.data.latest_shipment_date);
          } else {
            return "Not Available";
          }
        } catch (e) {}
      },
    },
    {
      headerName: "Expected Payment Clearance By",
      field: "expected_payment_clearance_by",
      width: 220,
      filter: true,
      resizable: true,
      valueGetter: function (params) {
        try {
          if (
            params.data.expected_payment_clearance_by != "" &&
            params.data.expected_payment_clearance_by != undefined
          ) {
            return getHyphenDDMMYYYY(params.data.expected_payment_clearance_by);
          } else {
            return "Not Available";
          }
        } catch (e) {}
      },
    },
    {
      headerName: "Payment Term",
      field: "payment_term",
      valueGetter: function (params) {
        try {
            //console.log(ptd, "ptd");
            //console.log(params.data.payment_term, "params.data.payment_term");
            if (params.data.payment_term != "" && params.data.payment_term != undefined) {
                let temp = ptd.find(e => e.payment_term == params.data.payment_term)
                if (temp)
                    return temp.payment_term + " - " + temp.description
                else
                    return params.data.payment_term
            }
            else {
                return "Not Available";
            }
        }
        catch (e) { }
      },
      width: 350,
      filter: true,
      resizable: true,
    },
    // {
    //   headerName: "Tentative Shipping Line",
    //   field: "tentative_shipping_line",
    //   width: 250,
    //   filter: true,
    //   resizable: true,
    // },
    // {
    //   headerName: "Tentative Gate Open Date",
    //   field: "tentative_gate_open_date",
    //   width: 200,
    //   filter: true,
    //   resizable: true,
    //   valueGetter: function (params) {
    //     try {
    //       if (
    //         params.data.tentative_gate_open_date != "" &&
    //         params.data.tentative_gate_open_date != undefined
    //       ) {
    //         return getHyphenDDMMYYYY(params.data.tentative_gate_open_date);
    //       } else {
    //         return "Not Available";
    //       }
    //     } catch (e) {}
    //   },
    // },
    // {
    //   headerName: "Tentative Cut Off Date",
    //   field: "tentative_cut_off_date",
    //   width: 190,
    //   filter: true,
    //   resizable: true,
    //   valueGetter: function (params) {
    //     try {
    //       if (
    //         params.data.tentative_cut_off_date != "" &&
    //         params.data.tentative_cut_off_date != undefined
    //       ) {
    //         return getHyphenDDMMYYYY(params.data.tentative_cut_off_date);
    //       } else {
    //         return "Not Available";
    //       }
    //     } catch (e) {}
    //   },
    // },
    {
      headerName: "Remarks (By CHA)",
      field: "remarks_by_cha",
      width: 160,
      filter: true,
      resizable: true,
    },
    {
      headerName: "Freight Forwarder",
      field: "freight_forwarder",
      width: 250,
      filter: true,
      resizable: true,
    },
    {
      headerName: "No Of Free Days",
      field: "no_of_free_days",
      width: 160,
      filter: true,
      resizable: true,
    },
  ]);
  var columnDefs1 = [
    {
      headerName: "Work Order No",
      field: "work_order_no",
      width: 160,
      filter: true,
      resizable: true,
    },
    {
      headerName: "Transporter Code",
      field: "transporter_code",
      width: 200,
      filter: true,
      resizable: true,
    },
  ];
  var columnDefs2 = [
    {
      headerName: "Work Order No",
      field: "work_order_no",
      width: 160,
      filter: true,
      resizable: true,
    },
    {
      headerName: "Stuffing Date",
      field: "stuffing_date",
      width: 160,
      filter: true,
      resizable: true,
      // cellEditor: "agTextCellEditor",
      editable: (params) => {
        const date = params.data.stuffing_date;
        // Check if date is in DD-MM-YYYY format
        const datePattern = /^\d{2}-\d{2}-\d{4}$/;
        return !(date && datePattern.test(date)); // Editable if date is invalid
    },
    // valueSetter: (params) => {
    //     const newValue = params.newValue;
    //     // Check if the new value is in DD-MM-YYYY format
    //     const datePattern = /^\d{2}-\d{2}-\d{4}$/;
    //     if (newValue && datePattern.test(newValue)) {
    //       params.data.stuffing_date = newValue;
    //       return true;
    //     } else {
    //       return false;
    //     }
    //   },
      valueGetter: (params) => {
        const date = params.data.stuffing_date;
        // Check if date is in DD-MM-YYYY format
        const datePattern = /^\d{2}-\d{2}-\d{4}$/;
        if (date && datePattern.test(date)) {
          return date;
        } else {
          return "Invalid Date Format";
        }
      },
      cellStyle: (params) => {
        const date = params.value;
        // Apply red color if the date format is invalid
        if (date === "Invalid Date Format") {
          return { background: "red", color: "black" };
        }
        return null;
      },
    },
    {
        headerName: "Remarks By IB",
        field: "remarks_by_ib",
        width: 160,
        filter: true,
        resizable: true,
      },
  ];

  useEffect(() => {
    if (showAddForm === "slide30") {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [showAddForm]);
  useEffect(() => {
    if (gridApi.current) {
      getUserGridState();
    }
    loadDateTimeScript();
  }, [gridApi]);

  useEffect(() => {
    setloadshow("show-m");
    getibcount();
    getbufferdata();
  }, [props.data]);
  const onGridReady = (params) => {
    gridApi.current = params;
    // gridColumnApi.current = params.columnApi;
    getUserGridState();
  };
  const getbufferdata = () => {
    redirectURL.post("/consignments/getbufferforib").then((resp) => {
      //console.log(resp.data);
      if (resp.data != "" && resp.data != undefined) {
        var data = resp.data.work_order_level_data;
        data = data.filter((e) => e.buffer_approved != 1);
        setbufferdata({
          wr_data: data,
          container_data: resp.data.containerDetails,
        });
        setIBCounters((prev) => ({
          ...prev,
          buffer: data,
        }));
      }
      setloadshow("show-n")
    });
  };
  // const getibcount = async () => {
  //     try {
  //       var ibdata = [];
  //       var data = props.data;

  //       // Stuffing data
  //       var ibstuffingdata = data.ibstuffingdata;
  //       var records1 = ibstuffingdata.stuffing_new;
  //       var records2 = ibstuffingdata.stuffing_planned;
  //       var records3 = ibstuffingdata.stuffing_modified;
  //       sortByCreatedDate(records1);

  //       setrowData(records1);
  //       setoriginalData(records1);

  //       var ib_stuffing_planned_plant = records2;
  //       var ib_stuffing_modified_plant = records3;

  //       setTabDataForstuffing({
  //         ib_stuffing_new_plant: sortByCreatedDate(records1),
  //         ib_stuffing_planned_plant: sortByCreatedDate(ib_stuffing_planned_plant),
  //         ib_stuffing_modified_plant: sortByCreatedDate(ib_stuffing_modified_plant),
  //       });

  //       var ib_stuffing = [...records1];

  //       // IB TPT data
  //       var ibtptdata = data.ibtptdata;
  //       var ib_tpt_allocation_new = ibtptdata.tpt_new;
  //       var ib_tpt_allocation_planned = ibtptdata.tpt_planned;
  //       var ib_tpt_allocation_modified = ibtptdata.tpt_modified;

  //       var ib_tpt = [...ib_tpt_allocation_new, ...ib_tpt_allocation_planned, ...ib_tpt_allocation_modified];

  //       settabsfortpt({
  //         ib_tpt_allocation_new: ib_tpt_allocation_new,
  //         ib_tpt_allocation_planned: ib_tpt_allocation_planned,
  //         ib_tpt_allocation_modified: ib_tpt_allocation_modified,
  //       });

  //       ibdata.push(...ib_stuffing, ...ib_tpt);

  //       setIBCounters((prev) => ({
  //         ...prev,
  //         ib_stuffing: ib_stuffing,
  //         ib_tpt_allocation: ib_tpt,
  //       }));

  //       // At plant
  //       var atplantdata = data.atplantdata;
  //       var at_plant_new = atplantdata.work_order_level_data4;
  //       var container_new_1 = atplantdata.containerDetails4;
  //       var container_new_2 = atplantdata.containerDetails5;
  //       var container_new_3 = atplantdata.containerDetails6;
  //       var at_plant_planned = atplantdata.work_order_level_data5;
  //       var at_plant_modified = atplantdata.work_order_level_data6;

  //       setTabsForAtPlant({
  //         at_plant_new: at_plant_new,
  //         at_plant_planned: at_plant_planned,
  //         at_plant_modified: at_plant_modified,
  //         container_new_1: container_new_1,
  //         container_new_2: container_new_2,
  //         container_new_3: container_new_3,
  //       });
  //       if(at_plant_new!='' && at_plant_new!=undefined){
  //         var atplant = [...at_plant_new];

  //       ibdata.push(...atplant);
  //       setIBCounters((prev) => ({
  //         ...prev,
  //         atplant: atplant,
  //       }));
  //       }

  //       // Intransit data
  //       var intransittoplantdata = data.intransittoplantdata;
  //       //console.log(intransittoplantdata,'intransittoplantdata')
  //       var intransit_to_plant_new = intransittoplantdata.work_order_level_data1;
  //       var container_new_1 = intransittoplantdata.containerDetails1;
  //       var container_new_2 = intransittoplantdata.containerDetails2;
  //       var container_new_3 = intransittoplantdata.containerDetails3;
  //       var intransit_to_plant_planned = intransittoplantdata.work_order_level_data2;
  //       var intransit_to_plant_modified = intransittoplantdata.work_order_level_data3;

  //       settabsforintransitToPlant({
  //         intransit_new: intransit_to_plant_new,
  //         intransit_planned: intransit_to_plant_planned,
  //         intransit_modified: intransit_to_plant_modified,
  //         container_new_1: container_new_1,
  //         container_new_2: container_new_2,
  //         container_new_3: container_new_3,
  //       });

  //       var intransit_to_plant = [...intransit_to_plant_modified, ...intransit_to_plant_planned, ...intransit_to_plant_new];

  //       intransit_to_plant = intransit_to_plant.filter((e) => e.container_gate_in != 1);

  //       ibdata.push(...intransit_to_plant);

  //       setIBCounters((prev) => ({
  //         ...prev,
  //         intransit_to_plant: intransit_to_plant,
  //       }));

  //       setAllIBData(ibdata);
  //     } catch (e) {
  //       console.error(e);
  //     }
  //   };

  const getibcount = async () => {
    try {
      var ibdata = [];
      var resp3 = await redirectURL.post("/consignments/getAtPlantIB");
      //console.log("ramya", resp3.data);
      var at_plant_new = resp3.data.work_order_level_data1;
      var container_new_1 = resp3.data.containerDetails1;
      var container_new_2 = resp3.data.containerDetails2;
      var container_new_3 = resp3.data.containerDetails3;

      var at_plant_planned = resp3.data.work_order_level_data2;
      var at_plant_modified = resp3.data.work_order_level_data3;
      setTabsForAtPlant({
        at_plant_new: at_plant_new,
        at_plant_planned: at_plant_planned,
        at_plant_modified: at_plant_modified,
        container_new_1: container_new_1,
        container_new_2: container_new_2,
        container_new_3: container_new_3,
      });
      var atplant = [...at_plant_new];
      ibdata.push(...atplant);
      setIBCounters((prev) => ({
        ...prev,
        atplant: atplant,
      }));

      const resp1 = await redirectURL.post("/consignments/getIBStuffing");
      //console.log(resp1.data, "126126");
      var records = [];
      var records1 = resp1.data.stuffing_new;
      var records2 = resp1.data.stuffing_planned;
      var records3 = resp1.data.ib_modified;
      sortByCreatedDate(records1);
      if (classForTab.modified == 1) {
        setrowData(records3);
      } else if (classForTab.planned == 1) {
        setrowData(records2);
      } else {
        setrowData(records1);
      }
      setoriginalData(records1);
      var records2 = resp1.data.stuffing_planned;
      var ib_stuffing_planned_plant = records2;
      var ib_stuffing_modified_plant = records3;
      setTabDataForstuffing({
        ib_stuffing_new_plant: sortByCreatedDate(records1),
        ib_stuffing_planned_plant: sortByCreatedDate(ib_stuffing_planned_plant),
        ib_stuffing_modified_plant: sortByCreatedDate(
          ib_stuffing_modified_plant
        ),
      });
      var ib_stuffing = [...records1];
      //console.log(ib_stuffing, "ib_stuffingib_stuffing");
      const resp5 = await redirectURL.post("/consignments/getIBtptdata");
      //console.log(resp5.data, "144");
      var ib_tpt_allocation_new = resp5.data.tpt_new;
      var ib_tpt_allocation_plannd = resp5.data.tpt_planned;
      var ib_tpt_allocation_modified = resp5.data.tpt_modified;
      var ib_tpt = [
        ...ib_tpt_allocation_new,
        ...ib_tpt_allocation_plannd,
        ...ib_tpt_allocation_modified,
      ];
      settabsfortpt({
        ib_tpt_allocation_new: ib_tpt_allocation_new,
        ib_tpt_allocation_plannd: ib_tpt_allocation_plannd,
        ib_tpt_allocation_modified: ib_tpt_allocation_modified,
      });
      ibdata.push(...ib_stuffing, ...ib_tpt);
      setIBCounters((prev) => ({
        ...prev,
        ib_stuffing: ib_stuffing,
        ib_tpt_allocation: ib_tpt,
      }));

      const resp2 = await redirectURL.post(
        "/consignments/getIntransitToPlantData"
      );
      var intransit_to_plant_new = resp2.data.work_order_level_data1;
      var container_new_1 = resp2.data.containerDetails1;
      var container_new_2 = resp2.data.containerDetails2;
      var container_new_3 = resp2.data.containerDetails3;
      var intransit_to_plant_planned = resp2.data.work_order_level_data2;
      var intransit_to_plant_modified = resp2.data.work_order_level_data3;
      settabsforintransitToPlant({
        intransit_new: intransit_to_plant_new,
        intransit_planned: intransit_to_plant_planned,
        intransit_modified: intransit_to_plant_modified,
        container_new_1: container_new_1,
        container_new_2: container_new_2,
        container_new_3: container_new_3,
      });

      var intransit_to_plant = [
        ...intransit_to_plant_modified,
        ...intransit_to_plant_planned,
        ...intransit_to_plant_new,
      ];
      intransit_to_plant = intransit_to_plant.filter(
        (e) => e.container_gate_in != 1
      );
      ibdata.push(...intransit_to_plant);
      setIBCounters((prev) => ({
        ...prev,
        intransit_to_plant: intransit_to_plant,
      }));
      //console.log("ramya");
      setAllIBData(ibdata);
    } catch (error) {}
  };
  const onCloseModal = () => {
    setopen(false);
    setsliderForBulkupload("");
    setshowGrid(false);
    document.getElementById("uploadfile").value = null;
    setoverly("show-n");
  };
  const onSelectWON = (e) => {
    setmanualUploadDetails((prev) => ({
      ...prev,
      wrno: { label: e.value, value: e.value },
    }));
  };
  const changeHandleDate = (e) => {
    var d = moment(e._d);
    var formattedDate = d.format("DD-MM-YYYY");
    return formattedDate;
  };
  const onClickRevisedProductionData = (rowdata) => {
    var row = rowdata.data;
    var ib_stuffing_date = row.stuffing_date;
    ib_stuffing_date = ib_stuffing_date.replace(/\//g, "-");
    ib_stuffing_date = moment.parseZone(ib_stuffing_date).format("DD-MM-YYYY");
    var data = [
      { ib_stuffing_date: ib_stuffing_date, work_order_no: row.work_order_no },
    ];
    var params = {
      ib_stuffing_date: ib_stuffing_date,
      work_order_no: row.work_order_no,
      cha_vessel_modified: 1,
    };

    if (ib_stuffing_date != "" && ib_stuffing_date != undefined) {
      redirectURL
        .post("/consignments/updateModifiedFields", params)
        .then((resp) => {
          if (resp.data.status == "Success") {
            setshow(true);
            setbasicTitle("Successfully Updated IB Stuffing Date");
            setbasicType("success");
            props.onRequest();
          } else {
            setshow(true);
            setbasicTitle(resp.data.message);
            setbasicType("danger");
          }
          props.onReloadData(true);
          //console.log(resp.data);
        });
    } else {
      setshow(true);
      setbasicTitle("Please Enter Tentative Vessel ETD");
      setbasicType("danger");
    }
  };
  const handleDatesSelected = (startDate, endDate) => {
    // Access the selected dates in the parent component
    var wo_release_from_date = startDate.format("DD-MM-YYYY");
    var wo_release_to_date = endDate.format("DD-MM-YYYY");
    setWoReleaseDate({
      wo_release_from_date: wo_release_from_date,
      wo_release_to_date: wo_release_to_date,
    });
  };
  const onClickAcknowledgeBtn = (rowdata) => {
    var row = rowdata.data;
    // if(row.cha_do_new==1){

    // }
    // else{
    var params;
    if (row.update_ack != 1) {
      params = {
        ib_stuffing: 1,
        work_order_no: row.work_order_no,
        unsetField: ["ib_stuffing_modified", "cha_do_planned"],
        setField: "cha_do_modified",
        record: row,
      }
    }
    else if (row.cha_tentative_details_updated == 1) {
      params = {
        ib_stuffing: 1,
        work_order_no: row.work_order_no,
        unsetField: ["ib_stuffing_modified", "cha_do_planned"],
        setField: "cha_do_modified",
        record: row,
      }
    }
   
      redirectURL.post("/consignments/acknowledgeData", params).then((resp) => {
        if (resp.data.status == "Success") {
          setshow(true);
          setbasicTitle("Acknowledgement successful");
          setbasicType("success");
          // setclassforTab(
          //   {
          //       planned: 0,
          //       new: 1,
          //       modified: 0,
          //   }
          // )
          props.refresNow();
          props.onRequest();
          props.onReloadData(true);
        }
      });
    

    // }
  };
  const getRowStyle = (params) => {
    if (
      params.data &&
      (params.data.cha_vessel_modified == 1 || params.data.modified_plant == 1)
    ) {
      //console.log("399", params);
      return { backgroundColor: "#FFB9B9" };
    }
    return null;
  };
  const onClickIBStuffingDate = (e) => {
    setselected_row(e.data);
    setshowAddForm("slide30");
    setoverly("show-m");
  };
  const onClickIBStuffingDate1 = (e) => {
    setselected_row(e.data);
    setshowAddForm1("slide30");
    setoverly("show-m");
    setmanualUploadDetails((prev) => ({
      ...prev,
      wrno: { label: e.data.work_order_no, value: e.data.work_order_no },
      remarks_by_ib: e.data.remarks_by_ib,
      stuffing_date: getHyphenDDMMYYYY(e.data.stuffing_date),
    }));
  };
  const onClickupdateAcknowledgeBtn = (e) => {
    setselected_row(e.data);
    setshowAddForm1("slide30");
    setoverly("show-m");
    setmanualUploadDetails((prev) => ({
      ...prev,
      wrno: { label: e.data.work_order_no, value: e.data.work_order_no },
      remarks_by_ib: e.data.remarks_by_ib,
      stuffing_date: getHyphenDDMMYYYY(e.data.stuffing_date),
    }));
  };
  const onClickHideAll = () => {
    setloadshow("show-n");
  };
  const filterData = () => {
    var typeoffield = selectedval.dropdownval.value;
    var val = selectedval.inputval;
    if (val != "" && val != undefined) {
      if (typeoffield == "ship_to_country") {
        var filteredData = originalData.filter(
          (item) => item.ship_to_country == val
        );
      } else if (typeoffield == "work_order_no") {
        if (val != "" && val != undefined) {
          filteredData = originalData.filter(
            (item) => item.work_order_no == val
          );
        } else {
          filteredData = originalData;
        }
      } else if (typeoffield == "") {
        if (val != "" && val != undefined) {
          filteredData = originalData.filter(
            (item) => item.work_order_no == val
          );
        } else {
          filteredData = originalData;
        }
      } else if (typeoffield == "pod") {
        var filteredData = originalData.filter((item) => item.pod == val);
      } else if (typeoffield == "plant") {
        var filteredData = originalData.filter((item) => item.plant == val);
      } else if (typeoffield == "cha") {
        var filteredData = originalData.filter((item) => item.cha == val);
      }
      //console.log(filteredData);
      setrowData(filteredData);
      setcheckresetfilter(1);
    } else {
      setbasicTitle("Please Enter Value");
      setbasicType("danger");
      setoverly("show-n");
      setshow(true);
    }
  };
  const refreshData = () => {
    setcheckresetfilter(0);
    setrowData(originalData);
    setselectedval((prev) => ({
      ...prev,
      inputval: "",
    }));
  };
  const handleInputfilter = (e) => {
    const val = e.target.value.trim().toUpperCase(); // Trim and convert input value to uppercase
    const typeoffield = selectedval.dropdownval.value;
    let filteredData;

    if (typeoffield === "work_order_no") {
      if (val !== "") {
        filteredData = originalData.filter((each) => {
          return each.work_order_no.includes(val);
        });
      } else {
        filteredData = originalData;
      }
    } else {
      // Handle other field types if needed
    }

    setselectedval((prev) => ({
      ...prev,
      inputval: e.target.value,
    }));
    setrowData(filteredData);
  };
  const onClickCounters = (param) => {
    if (param == 5) {
      setcheck(5);
      setIsClicked({ all: true });
    } else if (param == 1) {
      setcheck(1);
      setIsClicked({ ibStuffing: true });
      setcheckforbulk(true);
    } else if (param == 2) {
      setcheck(2);
      setIsClicked({ ibtptallocation: true });
      setcheckforbulk(true);
      setAPI("uploadBulkuploadforIBdata");
      uploadBulkData();
    } else if (param == 3) {
      setcheck(3);
      setIsClicked({ IntransitToPlant: true });
      setcheckforbulk(false);
    } else if (param == 4) {
      setcheck(4);
      setIsClicked({ AtPlant: true });
      setcheckforbulk(false);
    } else if (param == 6) {
      setcheck(6);
      setIsClicked({ modified: true });
      setcheckforbulk(false);
    } else if (param == 7) {
      setcheck(7);
      setIsClicked({ buffer: true });
      setcheckforbulk(false);
    }
  };
  const showdata = (e) => {
    if (e == "detailed view") {
      setdetailedviewcheck(1);
      setsummaryviewcheck(0);
    } else if (e == "summary view") {
      setdetailedviewcheck(0);
      setsummaryviewcheck(1);
    }
  };
  const handleFilterOptions = (e) => {
    if (
      e.value == "production_date" ||
      e.value == "wo_release_date" ||
      e.value == "latest_shipment_date"
    ) {
      setcheckForDateFilter(1);
      setcheckinput(0);
    } else {
      setcheckinput(1);
      setcheckForDateFilter(0);
    }
    setselectedval((prev) => ({
      ...prev,
      dropdownval: e,
    }));
  };
  const onClickHideManualUpload = () => {
    setshowAddForm("");
    setoverly("show-n");
    setmanualUploadDetails((prev) => ({
      ...prev,
      wrno: { label: "", value: "" },
      remarks_by_ib: "",
      stuffing_date: "",
    }));
  };
  const onClickHideManualUpload1 = () => {
    setshowAddForm1("");
    setoverly("show-n");
    setmanualUploadDetails((prev) => ({
      ...prev,
      wrno: { label: "", value: "" },
      remarks_by_ib: "",
      stuffing_date: "",
    }));
  };
  const onsubmitdata = (e) => {
    e.preventDefault();
    var data = [
      {
        work_order_no: selected_row.work_order_no,
        stuffing_date: manualUploadDetails.stuffing_date,
        remarks_by_ib: manualUploadDetails.remarks_by_ib,
      },
    ];
    var rparams = {
      data: data,
    };
    if (
      manualUploadDetails.stuffing_date != "" &&
      manualUploadDetails.stuffing_date != undefined
    ) {
      redirectURL
        .post("/consignments/uploadBulkuploadforIBdata", rparams)
        .then((resp) => {
          //console.log(resp.data, "176");
          if (resp.data.status == "Success") {
            setshow(true);
            setbasicTitle("Stuffing Date has been Updated Successfully");
            setbasicType("success");
            setloadshow("show-n");
            setoverly("show-n");
            setshowAddForm("");
            refreshDataNow("vesselAvailability");
          } else {
            setshow(true);
            setbasicTitle(resp.data.message);
            setbasicType("danger");
            setloadshow("show-n");
            setoverly("show-n");
            setshowAddForm("");
          }
          //console.log(resp.data);
        });
    } else {
      setshow(true);
      setbasicTitle("Please Enter All Manditory Fields!");
      setbasicType("danger");
      setloadshow("show-n");
      setoverly("show-n");
    }
    setmanualUploadDetails((prev) => ({
      ...prev,
      wrno: { label: "", value: "" },
      remarks_by_ib: "",
      stuffing_date: "",
    }));
  };
  const onsubmitdata1 = (e) => {
    e.preventDefault();
    //console.log(selected_row,"selected_rowwwww")
    if (selected_row.cha_changes_before_container_pickup == 1 || selected_row.pod_changes_before_container_pickup == 1 || selected_row.ship_to_country_changes_before_container_pickup == 1 || selected_row.cha_tentative_details_updated == 1 || selected_row.container_count_decrease_before_pickup == 1 || selected_row.container_count_increase_before_pickup == 1 || selected_row.ib_stuffing_modified == 1){      
      var data = [
        {
          work_order_no: selected_row.work_order_no,
          stuffing_date: manualUploadDetails.stuffing_date,
          remarks_by_ib: manualUploadDetails.remarks_by_ib,
          ib_stuffing_modified: 1,
        },
      ];
    }
    else {
      var data = [
        {
          work_order_no: selected_row.work_order_no,
          stuffing_date: manualUploadDetails.stuffing_date,
          remarks_by_ib: manualUploadDetails.remarks_by_ib,
          ib_stuffing_planned: 1,
        },
      ];
    }
    var rparams = {
      data: data,
      row: selected_row,
    };
    if (
      manualUploadDetails.stuffing_date != "" &&
      manualUploadDetails.stuffing_date != undefined && data[0].ib_stuffing_modified === 1
    ) {
      redirectURL.post("/consignments/updatedata", rparams).then((resp) => {
        // //console.log(resp.data, "partiallllllllll");
        if (resp.data.status == "Success") {
          setshow(true);
          setbasicTitle("IB Stuffing Date has been successfully updated!");
          setbasicType("success");
          // setclassforTab(
          //   {
          //     planned: 0,
          //     new: 1,
          //     modified: 0,
          //   }
          // )
          setloadshow("show-n");
          setoverly("show-n");
          setshowAddForm1("");
          refreshDataNow("vesselAvailability");
          setmanualUploadDetails((prev) => ({
            ...prev,
            wrno: { label: "", value: "" },
            remarks_by_ib: "",
            stuffing_date: "",
          }));
        } else {
          setshow(true);
          setbasicTitle(resp.data.message);
          setbasicType("danger");
          setloadshow("show-n");
          setoverly("show-n");
          setshowAddForm("");
        }
        //console.log(resp.data);
      });
    } 
    else if(data[0].ib_stuffing_planned) {
      redirectURL.post("/consignments/updatestuffingibplan", rparams).then((resp) => {
        // //console.log(resp.data, "revisedddddddd");
        if (resp.data.status == "Success") {
          setshow(true);
          setbasicTitle("IB Stuffing Date has been successfully updated!");
          setbasicType("success");
        //   setclassforTab(
        //     {
        //         planned: 0,
        //         new: 1,
        //         modified: 0,
        //     }
        // )
          setloadshow("show-n");
          setoverly("show-n");
          setshowAddForm1("");
          refreshDataNow("vesselAvailability");
          setmanualUploadDetails((prev) => ({
            ...prev,
            wrno: { label: "", value: "" },
            remarks_by_ib: "",
            stuffing_date: "",
          }));
        } 
    else {
          setshow(true);
          setbasicTitle(resp.data.message);
          setbasicType("danger");
          setloadshow("show-n");
          setoverly("show-n");
          setshowAddForm("");
        }
        //console.log(resp.data);
      });
    }
    else 
    {
      setshow(true);
      setbasicTitle("Please Update IB Stuffing Date");
      setbasicType("danger");
      setloadshow("show-n");
      setoverly("show-n");
    }
  };
  // const filterDatafordates = () => {
  //     var from_date = woReleaseDate.wo_release_from_date
  //     var to_date = woReleaseDate.wo_release_to_date

  //     if(from_date!='' && from_date!=undefined&&from_date!=null&&to_date!=''&&to_date!=undefined&&to_date!=null){
  //         from_date = moment(from_date, 'DD-MM-YYYY')
  //         to_date = moment(to_date, 'DD-MM-YYYY')
  //         var filteredData = []
  //         var selectedOpt = selectedval.dropdownval.value
  //         if (selectedOpt == 'wo_release_date') {
  //             filteredData = originalData.filter(item => {
  //                 var itemDate = getHyphenDDMMYYYY(item.wo_release_date)
  //                 itemDate = moment(itemDate, 'DD-MM-YYYY')
  //                 return itemDate.isBetween(from_date, to_date, null, '[]')
  //             });
  //         }
  //         else if (selectedOpt == 'production_date') {
  //             filteredData = originalData.filter(item => {
  //                 var itemDate = getHyphenDDMMYYYY(item.production_date)
  //                 itemDate = moment(itemDate, 'DD-MM-YYYY')
  //                 return itemDate.isBetween(from_date, to_date, null, '[]')
  //             });
  //         }
  //         else if (selectedOpt == 'latest_shipment_date') {
  //             filteredData = originalData.filter(item => {
  //                 var itemDate = getHyphenDDMMYYYY(item.latest_shipment_date)
  //                 itemDate = moment(itemDate, 'DD-MM-YYYY')
  //                 return itemDate.isBetween(from_date, to_date, null, '[]')
  //             });
  //         }
  //         setrowData(filteredData)
  //     }
  //     else{
  //         setbasicTitle('Please Select Start Date and End date')
  //             setbasicType('danger')
  //             setoverly('show-n')
  //             setshow(true)
  //         }

  // }
  const filterDatafordates = () => {
    var from_date = woReleaseDate.wo_release_from_date;
    var to_date = woReleaseDate.wo_release_to_date;
    if (
      from_date != "" &&
      from_date != undefined &&
      from_date != null &&
      to_date != "" &&
      to_date != undefined &&
      to_date != null
    ) {
      from_date = moment(from_date, "DD-MM-YYYY");
      to_date = moment(to_date, "DD-MM-YYYY");
      var selectedOpt = selectedval.dropdownval.value;
      if (selectedOpt == "wo_release_date") {
        var filteredData = originalData.filter((item) => {
          var itemDate = getHyphenDDMMYYYY(item.wo_release_date);
          itemDate = moment(itemDate, "DD-MM-YYYY");
          //console.log(itemDate, "182");
          return itemDate.isBetween(from_date, to_date, null, "[]");
        });
        setrowData(filteredData);
        setRefreshSelected(0);
        setcheckresetdatefilters(1);
      }
      if (selectedOpt == "production_date") {
        var filteredData = originalData.filter((item) => {
          var itemDate = getHyphenDDMMYYYY(item.production_date);
          itemDate = moment(itemDate, "DD-MM-YYYY");
          //console.log(itemDate, "182");
          return itemDate.isBetween(from_date, to_date, null, "[]");
        });
        setrowData(filteredData);
        setRefreshSelected(0);
        setcheckresetdatefilters(1);
      }
    } else {
      setbasicTitle("Please Select Start Date and End date");
      setbasicType("danger");
      setoverly("show-n");
      setshow(true);
    }
  };
  const onClickHide = () => {
    setsliderForBulkupload("");
    document.getElementById("uploadfile").value = null;
    setoverly("show-n");
  };
  var onclickbulkupload = () => {
    setsliderForBulkupload("slider-translate");
    setoverly("show-m");
    setopen(true);
    if (isClicked.ibtptallocation == true) {
      setcheck(2);
      uploadBulkData();
    }
  };
  var uploadBulkData = (validCols, bulkUploadFields, apiforupload, columndefs) => {
    //console.log("2095", validCols);
    setvalidcols(validCols);
    setbulkUploadFields(bulkUploadFields);
    setAPI(apiforupload);
    setcsvfilename(csvfilename);
  };
  const onSubmitOrderBulkUpload1 = (event) => {
    event.preventDefault();
    setloadshow("show-m");
    if (file != "") {
      var rparams = {
        data: csvcontent,
        
      };
      //console.log(rparams, "1167");
      if (validbulkfile == true) {
        redirectURL
          .post("/consignments/updateTransporterDetailToWO", rparams)
          .then((resp) => {
            if (resp.data.status == "Success") {
              setshow(true);
              setshowGrid(false);
              setbasicTitle("Successfully Uploaded");
              setbasicType("success");
              setloadshow("show-n");
              setoverly("show-n");
              setsliderForBulkupload("");
              document.getElementById("uploadfile").value = null;
              setsliderForBulkupload("");
              refreshDataNow("vesselAvailability");
            } else {
              setshow(true);
              setbasicTitle(resp.data.message);
              setbasicType("danger");
              setloadshow("show-n");
              setoverly("show-n");
              setsliderForBulkupload("");
              document.getElementById("uploadfile").value = null;
            }
            //console.log(resp.data);
          });
      } else {
        setshow(true);
        setbasicTitle("Please Upload Valid Columns");
        setbasicType("danger");
        setloadshow("show-n");
        setoverly("show-n");
      }
    }
  };
  var changeOrderFileHandlerFcrBulk1 = async (e) => {
    setfile(e.target.files[0]);
    var validCols = ["Work Order No", "Transporter Code"];
    var verifyCols = verifyUploadFileColumns(validCols, e.target.files[0])
      .then((result) => {
        //console.log(result);
        setvalidbulkfile(result);
      })
      .catch((error) => {
        seterrormsg(error);
      });
    var csvdata = await BulkForceFileHandler(e, [
      { name: "Work Order No", inputName: "work_order_no", required: true },
      {
        name: "Transporter Code",
        inputName: "transporter_code",
        required: true,
      },
    ]);
    //console.log(csvdata, "1234");
    setshowGrid(true);

    setcsvcontent(csvdata);
    setrowData1(csvdata);
  };
  const onSubmitOrderBulkUpload = (event) => {
    event.preventDefault();
    setloadshow("show-m");
    if (file != "") {
      var rparams = {
        data: csvcontent,
      };
      //console.log(rparams, "1167");
      if (validbulkfile == true) {
        redirectURL
          .post("/consignments/uploadBulkuploadforIBdata", rparams)
          .then((resp) => {
            if (resp.data.status == "Success") {
              setshow(true);
              setshowGrid(false);
              setbasicTitle("Successfully Uploaded");
              setbasicType("success");
              setloadshow("show-n");
              setoverly("show-n");
              setsliderForBulkupload("");
              document.getElementById("uploadfile").value = null;
              setsliderForBulkupload("");
              refreshDataNow("vesselAvailability");
            } else {
              setshow(true);
              setbasicTitle(resp.data.message);
              setbasicType("danger");
              setloadshow("show-n");
              setoverly("show-n");
              setsliderForBulkupload("");
              document.getElementById("uploadfile").value = null;
            }
            //console.log(resp.data);
          });
      } else {
        setshow(true);
        setbasicTitle("Please Upload Valid Columns");
        setbasicType("danger");
        setloadshow("show-n");
        setoverly("show-n");
      }
    }
  };
  var changeOrderFileHandlerFcrBulk = async (e) => {
    setfile(e.target.files[0]);
    var validCols = ["Work Order No", "Stuffing Date", "Remarks By IB"];
    var verifyCols = verifyUploadFileColumns(validCols, e.target.files[0])
      .then((result) => {
        //console.log(result);
        setvalidbulkfile(result);
      })
      .catch((error) => {
        seterrormsg(error);
      });
    var csvdata = await BulkForceFileHandler(e, [
      { name: "Work Order No", inputName: "work_order_no", required: true },
      {
        name: "Stuffing Date",
        inputName: "stuffing_date",
        required: true,
      },
      { name: "Remarks By IB", inputName: "remarks_by_ib", required: true },

    ]);
    //console.log(csvdata, "1234");
    setshowGrid(true);

    setcsvcontent(csvdata);
    setrowData2(csvdata);
  };
  const getUserGridState = () => {
    redirectURL
      .post("/consignments/usergridstates", { screenurl: "/ibstuffingnew" })
      .then(async (resp) => {
        //console.log("resp.data0999", resp.data);
        restoreGridStates(resp.data);
        var grid = resp.data;
        var oCols = fetchGrid(summaryViewcols, grid[0].gridcolumns);
        //console.log("oCols", oCols);
      });
  };
  const restoreGridStates = (griddata) => {
    try {
      if (griddata.length > 0) {
        var oCols = fetchGrid(summaryViewcols, griddata[0].gridcolumns);
        setsummaryViewcols(oCols);
      }
    } catch (e) {
      //console.log(e);
    }
  };

  const onGridState = () => {
    ////console.log(gridApi);

    /*Get  Current Columns State and Store in this.colState */
    const colState = gridApi.current.columnApi.getColumnState();

    /*Get Current RowGroup Columns State and Store in this.rowGroupState */
    const rowGroupState = gridApi.current.columnApi.getRowGroupColumns();

    /*Get Current Pivot Columns State and Store in this.pivotColumns,
     * User should enable the Pivot mode.*/
    const pivotColumns = gridApi.current.columnApi.getPivotColumns();

    /*Get Current Filter State and Store in window.filterState */

    window.filterState = gridApi.current.api.getFilterModel();
    gridApi.current.api.setFilterModel(window.filterState);
    getUserGridState();
  };
  var onClickSaveGridState = () => {
    try {
      // //console.log("SaveGrid", gridApi.current.getSortModel())
      window.colState = gridApi.current.columnApi.getColumnState();
      window.groupState = gridApi.current.columnApi.getColumnGroupState();
      // window.sortState = gridApi.current.columnApi.getSortModel();
      // window.filterState = gridApi.current.api.getFilterModel();
      var screenpage = "/ibstuffingnew";
      let reqparams = {
        gridcolumns: window.colState,
        gridgroup: window.groupState,
        gridcolsort: window.sortState,
        gridcolfilter: window.filterState,
        userId: localStorage.getItem("userid"),
        screenurl: "/ibstuffingnew",
        screentitle: screenpage,
      };
      //console.log("reqparams after save grid", reqparams);
      redirectURL
        .post("/consignments/saveGridStates", reqparams)
        .then((response) => {
          //console.log("response forr", response);
          // if(response.data.status === "200"){
          setshow(true);
          setbasicTitle("Successfully saved grid layout");
          setbasicType("success");
          // setState1({
          // 	screenurl: window.location.pathname,
          // 	screentitle: screenpage
          // })
          setTimeout(() => {
            getUserGridState();
          }, 2000);
          // getUserGridState();
          // }
        })
        .catch(function (e) {
          //console.log("Error ", e);
        });
      //console.log("hit");
    } catch (e) {
      //console.log(e);
    }
  };
  const onclickTab = (e) => {
    if (e == "New") {
      setclassforTab({
        new: 1,
        planned: 0,
        modified: 0,
      });
      setcheckforbulk(true);
      setrowData(tabDataforstuffing.ib_stuffing_new_plant);
      setoriginalData(tabDataforstuffing.ib_stuffing_new_plant);
    } else if (e == "Planned") {
      setclassforTab({
        new: 0,
        planned: 1,
        modified: 0,
      });
      setcheckforbulk(false);
      setrowData(tabDataforstuffing.ib_stuffing_planned_plant);
      setoriginalData(tabDataforstuffing.ib_stuffing_planned_plant);
    } else if (e == "Modified") {
      setclassforTab({
        new: 0,
        planned: 0,
        modified: 1,
      });
      setcheckforbulk(false);
      //console.log(tabDataforstuffing.ib_stuffing_modified_plant, "modifieddddd")
      setrowData(tabDataforstuffing.ib_stuffing_modified_plant);
      setoriginalData(tabDataforstuffing.ib_stuffing_modified_plant);
    }
  };
  const refreshDataNow = (params) => {
    //console.log("paramss", params);
    getbufferdata();
    onClickCounters(params);
    getibcount();
    props.refresNow("ibdata");
  };
  var detailedviewcols = [
    {
      headerName: "Work Order No",
      field: "work_order_no",
      width: 130,
      filter: true,
      resizable: true,
    },
    {
      headerName: "Production Date",
      field: "production_date",
      width: 160,
      filter: true,
      resizable: true,
      valueGetter: function (params) {
        try {
          if (
            params.data.production_date != "" &&
            params.data.production_date != undefined
          ) {
            return getHyphenDDMMYYYY(params.data.production_date);
          } else {
            return "Not Available";
          }
        } catch (e) {}
      },
    },
    // {
    //   headerName: "Tentative Vessel ETD",
    //   field: "tentative_vessel_etd",
    //   width: 200,
    //   filter: true,
    //   resizable: true,
    //   valueGetter: function (params) {
    //     try {
    //       if (
    //         params.data.tentative_vessel_etd != "" &&
    //         params.data.tentative_vessel_etd != undefined
    //       ) {
    //         return getHyphenDDMMYYYY(params.data.tentative_vessel_etd);
    //       } else {
    //         return "Not Available";
    //       }
    //     } catch (e) {}
    //   },
    // },
    {
      headerName: "Stuffing Date",
      field: "stuffing_date",
      width: 160,
      filter: true,
      resizable: true,
      valueGetter: function (params) {
        try {
          if (
            params.data.stuffing_date != "" &&
            params.data.stuffing_date != undefined
          ) {
            return getHyphenDDMMYYYY(params.data.stuffing_date);
          } else {
            return "Not Available";
          }
        } catch (e) {}
      },
    },
    {
      headerName: "Ship To Country",
      field: "ship_to_country",
      width: 120,
      filter: true,
      resizable: true,
      // cellRenderer: CountryFlagComponent
    },
    {
      headerName: "Brand",
      field: "brand",
      width: 120,
      filter: true,
      resizable: true,
    },
    {
      headerName: "Brand Variant",
      field: "brand_variant",
      width: 150,
      filter: true,
      resizable: true,
    },
    {
      headerName: "Quantity",
      field: "quantity",
      width: 120,
      filter: true,
      resizable: true,
    },
    {
      headerName: "CHA Name",
      field: "cha_name",
      width: 120,
      filter: true,
      resizable: true,
    },
    {
      headerName: "Plant Name",
      field: "plant_name",
      width: 120,
      filter: true,
      resizable: true,
    },
    {
      headerName: "Transporter Name",
      field: "tpt_name",
      width: 200,
      filter: true,
      resizable: true,
    },
    {
      headerName: "40ft Containers",
      field: "ft40_containers",
      width: 150,
      filter: true,
      resizable: true,
    },
    {
      headerName: "20ft Containers",
      field: "ft20_containers",
      width: 150,
      filter: true,
      resizable: true,
    },
    {
      headerName: "Trucks",
      field: "truck_and_courier",
      width: 130,
      filter: true,
      resizable: true,
    },
    {
      headerName: "POD",
      field: "pod",
      width: 150,
      filter: true,
      resizable: true,
    },
    {
      headerName: "Plant",
      field: "plant",
      width: 120,
      filter: true,
      resizable: true,
    },
    {
      headerName: "PO NO",
      field: "po_no",
      width: 120,
      filter: true,
      resizable: true,
    },
    {
      headerName: "Quotation",
      field: "quotation",
      width: 130,
      filter: true,
      resizable: true,
    },

    {
      headerName: "Latest Shipment Date",
      field: "latest_shipment_date",
      width: 180,
      filter: true,
      resizable: true,
      valueGetter: function (params) {
        try {
          if (
            params.data.latest_shipment_date != "" &&
            params.data.latest_shipment_date != undefined
          ) {
            return getHyphenDDMMYYYY(params.data.latest_shipment_date);
          } else {
            return "Not Available";
          }
        } catch (e) {}
      },
    },
    {
      headerName: "Expected Payment Clearance By",
      field: "expected_payment_clearance_by",
      width: 220,
      filter: true,
      resizable: true,
      valueGetter: function (params) {
        try {
          if (
            params.data.expected_payment_clearance_by != "" &&
            params.data.expected_payment_clearance_by != undefined
          ) {
            return getHyphenDDMMYYYY(params.data.expected_payment_clearance_by);
          } else {
            return "Not Available";
          }
        } catch (e) {}
      },
    },
    {
      headerName: "HAZ Classification",
      field: "haz_classification",
      width: 160,
      filter: true,
      resizable: true,
    },
    {
      headerName: "Assy Config Type",
      field: "assy_config_type",
      width: 160,
      filter: true,
      resizable: true,
    },
    {
      headerName: "Payment Term",
      field: "payment_term",
      width: 160,
      filter: true,
      resizable: true,
    },
    // {
    //   headerName: "Tentative Shipping Line",
    //   field: "tentative_shipping_line",
    //   width: 180,
    //   filter: true,
    //   resizable: true,
    // },
    // {
    //   headerName: "Tentative Gate Open Date",
    //   field: "tentative_gate_open_date",
    //   width: 200,
    //   filter: true,
    //   resizable: true,
    //   valueGetter: function (params) {
    //     try {
    //       if (
    //         params.data.tentative_gate_open_date != "" &&
    //         params.data.tentative_gate_open_date != undefined
    //       ) {
    //         return getHyphenDDMMYYYY(params.data.tentative_gate_open_date);
    //       } else {
    //         return "Not Available";
    //       }
    //     } catch (e) {}
    //   },
    // },
    // {
    //   headerName: "Tentative Cut Off Date",
    //   field: "tentative_cut_off_date",
    //   width: 200,
    //   filter: true,
    //   resizable: true,
    //   valueGetter: function (params) {
    //     try {
    //       if (
    //         params.data.tentative_cut_off_date != "" &&
    //         params.data.tentative_cut_off_date != undefined
    //       ) {
    //         return getHyphenDDMMYYYY(params.data.tentative_cut_off_date);
    //       } else {
    //         return "Not Available";
    //       }
    //     } catch (e) {}
    //   },
    // },
    {
      headerName: "Remarks (By CHA)",
      field: "remarks_by_cha",
      width: 160,
      filter: true,
      resizable: true,
    },
    {
      headerName: "3rd Party Inspection Req",
      field: "third_party_inspection_req",
      width: 190,
      filter: true,
      resizable: true,
    },
    {
      headerName: "Port Of Loading",
      field: "port_of_loading",
      width: 190,
      filter: true,
      resizable: true,
    },
    {
      headerName: "VIN Type Description",
      field: "vin_type_description",
      width: 160,
      filter: true,
      resizable: true,
    },
    {
      headerName: "VIN Type",
      field: "vin_type",
      width: 160,
      filter: true,
      resizable: true,
    },
  ];
  var closeAlert = () => {
    setshow(false);
    setloadshow("show-n");
  };
  var counters = props.ibcount;
  var newCount = tabDataforstuffing.ib_stuffing_new_plant;
  var plannedCount = tabDataforstuffing.ib_stuffing_planned_plant;
  var modifiedCount = tabDataforstuffing.ib_stuffing_modified_plant;
  var updateStuffing = {
    headerName: "Update",
    field: "update_stuffing_date",
    width: 80,
    pinned: "left",
    cellRenderer: "UpdateIBStuffingComponent",
  };
  var updateStuffing1 = [
    {
      headerName: "Update",
      field: "",
      width: 80,
      pinned: "left",
      cellRenderer: "UpdateIBStuffingComponent1",
    },
    {
      headerName: "Remarks by IB",
      field: "remarks_by_ib",
      width: 180,
    },
  ];
  var acknowledgebtn = {
    headerName: "",
    field: "",
    width: 120,
    filter: true,
    resizable: true,
    pinned: "left",
    cellRendererFramework: AcknowledgeBtnComponent,
  };
  var acknowledgebtn1 = {
    headerName: "",
    field: "",
    width: 120,
    filter: true,
    resizable: true,
    pinned: "left",
    cellRendererFramework: UpdateAcknowledgeBtnComponent,
  };
  var ib_stuffing_update = [
    {
      headerName: "IB Stuffing Dae",
      field: "stuffing_date",
      width: 170,
      filter: true,
      resizable: true,
      editable: true,
      //  cellEditor: params => params.data.planned_plant != 1 || params.data.modified_plant!=1 ? DateEditor : undefined,
      cellEditor: DateEditor,
      valueGetter: function (params) {
        try {
          if (
            params.data.stuffing_date != "" &&
            params.data.stuffing_date != undefined
          ) {
            return getHyphenDDMMYYYY(params.data.stuffing_date);
          } else {
            return "Not Available";
          }
        } catch (e) {}
      },
      comparator: dateComparator1,
    },
    {
      headerName: "",
      field: "",
      width: 100,
      filter: true,
      resizable: true,
      cellRendererFramework: SubmitRevisedBtn,
    },
  ];
  var columns1 = [...summaryViewcols];
  if (classForTab.new == 1) {
    columns1.push(updateStuffing);
    detailedviewcols.push(updateStuffing);
  }
  if(classForTab.planned==1){
      columns1.push(...updateStuffing1)
      detailedviewcols.push(...updateStuffing1)
  }
  if (classForTab.modified == 1) {
    // if (update_ib_ack == 1){
    //   columns1.push(acknowledgebtn, ...ib_stuffing_update, acknowledgebtn1);
    // }
    columns1.push(acknowledgebtn, ...ib_stuffing_update, acknowledgebtn1);
    detailedviewcols.push(
      acknowledgebtn,
      ...ib_stuffing_update,
      acknowledgebtn1
    );
  }
  const columnwithDefs = summaryviewcheck == 1 ? columns1 : detailedviewcols;
  columnwithDefs.forEach((e) => {
    e.cellClass = function (params) {
      if (params.data && params.data[`${e.field}_modified`] === 1) {
        return "bgColorDangerDark";
      } else {
        return null;
      }
    };
  });
  //console.log(tabsforintransitToPlant, "tabsForAtPlant");
  const refreshData1 = () => {
    setcheckresetdatefilters(0);
    setrowData(originalData);
    setRefreshSelected(1);
    //console.log("refreshclickfunc");
  };
  //console.log(checkforbulk,'checkforbulk')
  return (
    <div class="container-fluid" style={{ padding: "10px" }}>
      <SweetAlert
        show={show}
        type={basicType}
        title={basicTitle}
        onConfirm={closeAlert}
      ></SweetAlert>
      {props.allcounter == 1 ? (
        ""
      ) : (
        <div
          className="form-group d-flex justify-content-between  mt-2 pl-0"
          style={{ right: "0px" }}
        >
          <h5 className="heading ml-50p">
            <a
              href="/exportoperationscontroltower"
              className="txt-dec-none clr333"
            ></a>
            IB Activities
          </h5>
          {/* {checkforbulk ? (
            <button
              type="button"
              className="btn-bulkupload mr-20p"
              style={{ fontSize: "0.8rem" }}
              onClick={() => onclickbulkupload()}
            >
              <img
                src={require("../../assets/images/upload.png")}
                alt="Icon"
                className="mr-1"
              />
              Bulk Upload
            </button>
          ) : (
            ""
          )} */}
        </div>
      )}
      <div
        className={props.allcounter == 1 ? "" : "card mb-20p"}
        style={
          props.allcounter === 1 ? {} : { padding: "25px", margin: "20px" }
        }
      >
        {props.allcounter == 1 ? (
          ""
        ) : (
          <span style={{ textAlign: "end", color: "red", fontSize: "12px" }}>
            * New / Modified
          </span>
        )}
        <div className="row bghighliter">
          {/* <div className="plantCounterdiv hp" style={{ width: '156px', background: isClicked.all ? 'rgb(64,81,120)' : '' }} onClick={() => onClickCounters('5')}>
                        <img src={isClicked.all?require("../../assets/images/all-white.png"):require("../../assets/images/all.png")} alt="Icon" style={{ height: '25px', margin: '8px' }} />
                        <div className="col-9 txt-center" style={{ padding: '3px', color: isClicked.all ? '#FFFFFF' : '#0664AE' }}>
                            <h5 className="fnt-s" style={{ fontWeight: 'bold'}}>
                                All
                            </h5>
                            <div>{(props.allIbData!=''&& props.allIbData!=undefined)?props.allIbData.length:0}</div>
                        </div>

                    </div> */}
          <div
            className="plantCounterdiv hp"
            style={{
              background: isClicked.ibStuffing ? "rgb(64,81,120)" : "",
              cursor: "pointer",
            }}
            onClick={() => onClickCounters("1")}
          >
            <img
              src={
                isClicked.ibStuffing
                  ? require("../../assets/images/new-white.png")
                  : require("../../assets/images/new.png")
              }
              alt="Icon"
              style={{ height: "25px", margin: "8px" }}
            />
            <div
              className="col-9 txt-center"
              style={{
                padding: "3px",
                color: isClicked.ibStuffing ? "#FFFFFF" : "#0664AE",
              }}
            >
              <h5 className="fnt-s" style={{ fontWeight: "bold" }}>
                Stuffing Date
              </h5>
              <div>
                {ibcount.ib_stuffing != "" && ibcount.ib_stuffing != undefined
                  ? ibcount.ib_stuffing.length
                  : 0}{" "}
                /{" "}
                {tabDataforstuffing.ib_stuffing_modified_plant != "" &&
                tabDataforstuffing.ib_stuffing_modified_plant != undefined
                  ? tabDataforstuffing.ib_stuffing_modified_plant.length
                  : 0}{" "}
              </div>
            </div>
          </div>
          <div
            className="plantCounterdiv hp"
            style={{
              background: isClicked.ibtptallocation ? "rgb(64,81,120)" : "",
              cursor: "pointer",
            }}
            onClick={() => onClickCounters("2")}
          >
            <img
              src={
                isClicked.ibtptallocation
                  ? require("../../assets/images/planned-white.png")
                  : require("../../assets/images/planned.png")
              }
              alt="Icon"
              style={{ height: "25px", margin: "8px" }}
            />
            <div
              className="col-9 txt-center"
              style={{
                padding: "3px",
                color: isClicked.ibtptallocation ? "#FFFFFF" : "#0664AE",
              }}
            >
              <h5 className="fnt-s" style={{ fontWeight: "bold" }}>
                Tpt Allocation
              </h5>
              <div>
                {tabsfortpt.ib_tpt_allocation_new.length} /{" "}
                {tabsfortpt.ib_tpt_allocation_modified.length}
              </div>
            </div>
          </div>
          <div
            className="plantCounterdiv hp"
            style={{
              background: isClicked.IntransitToPlant ? "rgb(64,81,120)" : "",
              cursor: "pointer",
            }}
            onClick={() => onClickCounters("3")}
          >
            <img
              src={
                isClicked.IntransitToPlant
                  ? require("../../assets/images/CHAwhiteicon.png")
                  : require("../../assets/images/chaupdatesicon.png")
              }
              alt="Icon"
              style={{ height: "22px", margin: "8px" }}
            />
            <div
              className="txt-center"
              style={{
                padding: "3px",
                color: isClicked.IntransitToPlant ? "#FFFFFF" : "#0664AE",
              }}
            >
              <h5 className="fnt-s" style={{ fontWeight: "bold" }}>
                Intransit to Plant
              </h5>
              <div>
                {tabsforintransitToPlant.intransit_new != "" &&
                tabsforintransitToPlant.intransit_new != undefined
                  ? tabsforintransitToPlant.intransit_new.length
                  : 0}{" "}
                /{" "}
                {tabsforintransitToPlant.intransit_modified != "" &&
                tabsforintransitToPlant.intransit_modified != undefined
                  ? tabsforintransitToPlant.intransit_modified.length
                  : 0}
              </div>
            </div>
          </div>
          <div
            className="plantCounterdiv hp"
            style={{
              background: isClicked.AtPlant ? "rgb(64,81,120)" : "",
              cursor: "pointer",
            }}
            onClick={() => onClickCounters("4")}
          >
            <img
              src={
                isClicked.AtPlant
                  ? require("../../assets/images/plantwhiteicon.png")
                  : require("../../assets/images/plantupdatesicon.png")
              }
              alt="Icon"
              style={{ height: "23px", margin: "8px" }}
            />
            <div
              className="col-9 txt-center"
              style={{
                padding: "3px",
                color: isClicked.AtPlant ? "#FFFFFF" : "#0664AE",
              }}
            >
              <h5 className="fnt-s" style={{ fontWeight: "bold" }}>
                At Plant
              </h5>
              <div>
                {tabsForAtPlant.at_plant_new != "" &&
                tabsForAtPlant.at_plant_new != undefined
                  ? tabsForAtPlant.at_plant_new.length
                  : 0}{" "}
                /{" "}
                {tabsForAtPlant.at_plant_modified != "" &&
                tabsForAtPlant.at_plant_modified != undefined
                  ? tabsForAtPlant.at_plant_modified.length
                  : 0}
              </div>
            </div>
          </div>
          {/* <div className="plantCounterdiv hp" style={{ background: isClicked.modified ? 'rgb(64,81,120)' : ''}} onClick={() => onClickCounters('6')}>
                    <img src={isClicked.modified?require("../../assets/images/modified-white.png"):require("../../assets/images/edit.png")} alt="Icon" style={{ height: '23px', margin: '8px' }} />
                        <div className="col-9 txt-center" style={{ padding: '3px',color: isClicked.modified ? '#FFFFFF' : '#0664AE' }}>
                            <h5 className="fnt-s" style={{ fontWeight: 'bold'}}>
                                Modified
                            </h5>
                            <div>0</div>
                        </div>

                    </div> */}
          <div
            className="plantCounterdiv hp"
            style={{
              background: isClicked.buffer ? "rgb(64,81,120)" : "",
              cursor: "pointer",
            }}
            onClick={() => onClickCounters("7")}
          >
            {/* <img src={isClicked.modified?require("../../assets/images/modified-white.png"):require("../../assets/images/edit.png")} alt="Icon" style={{ height: '23px', margin: '8px' }} /> */}
            <div
              className="col-9 txt-center"
              style={{
                padding: "3px",
                color: isClicked.buffer ? "#FFFFFF" : "#0664AE",
              }}
            >
              <h5 className="fnt-s" style={{ fontWeight: "bold" }}>
                Buffer
              </h5>
              <div>{ibcount.buffer.length}</div>
            </div>
          </div>
          {/* {check == 1 ?
                        <div className="float-right mt-24p" >
                            <button className='btn_style' style={{fontSize:'13px'}} onClick={() => manualbulkUploadForCha()} >Update IB Stuffing Date</button>
                        </div>
                        : ''} */}
        </div>
        <div className="d-flex justify-content-end">
        <div className="form-group">
        {checkforbulk ? (
            <button
              type="button"
              className="btn-bulkupload mr-20p"
              style={{ fontSize: "0.8rem" }}
              onClick={() => onclickbulkupload()}
            >
              <img
                src={require("../../assets/images/upload.png")}
                alt="Icon"
                className="mr-1"
              />
              Bulk Upload
            </button>
          ) : (
            ""
          )}
        </div>
        </div>
        <div className="row" style={{ marginLeft: "14px" }}>
          {check == 1 ? (
            <div className="d-flex flex-row">
              <div className="d-flex flex-row">
                <button
                  className="d-flex flex-row justify-content-center align-items-center px-3"
                  style={{
                    background: summaryviewcheck == 1 ? "#0664AE" : "",
                    // color: state.isTileView ? "": "#0664AE",
                    borderTopLeftRadius: "11px",
                    borderBottomLeftRadius: "11px",
                    border: "1px solid #0664AE",
                    height: "38px",
                    padding: "22px",
                  }}
                  onClick={() => showdata("summary view")}
                >
                  <div
                    style={{
                      color: summaryviewcheck == 1 ? "white" : "#0664AE",
                      fontSize: "12px",
                      fontWeight: 700,
                      marginLeft: "10px",
                      lineHeight: "12px",
                    }}
                  >
                    Summary View
                  </div>
                </button>

                <button
                  className="d-flex flex-row justify-content-center align-items-center px-3"
                  style={{
                    background: detailedviewcheck == 1 ? "#0664AE" : "",
                    borderTopRightRadius: "11px",
                    borderBottomRightRadius: "11px",
                    border: "1px solid #0664AE",
                    height: "38px",
                    padding: "22px",
                  }}
                  onClick={() => showdata("detailed view")}
                >
                  <div
                    style={{
                      color: detailedviewcheck == 1 ? "white" : "#0664AE",
                      fontSize: "12px",
                      fontWeight: 700,
                      marginLeft: "10px",
                      lineHeight: "12px",
                    }}
                  >
                    Detailed View
                  </div>
                </button>
              </div>
            </div>
          ) : (
            ""
          )}
          {check == 1 ? (
            <div className="form-group col-xl-2 col-lg-2 mt-14p">
              <Select
                closeMenuOnSelect={true}
                name="filterModeType"
                className="border-radius-0"
                styles={{
                  control: (provided, state) => ({
                    ...provided,
                    fontSize: "0.8rem",
                    color: "#6e707e",
                    borderRadius: "11px",
                    height: "48px",
                    width: "260px",
                    // padding:"22px",
                    textAlign: "center",
                  }),
                }}
                onChange={handleFilterOptions}
                options={typeofselection}
                value={selectedval.dropdownval}
                required
              />
            </div>
          ) : (
            ""
          )}
          {checkinput == 1 && check == 1 ? (
            <div
              className="d-flex mt-14p mt-d ml-85p"
              style={{ position: "relative" }}
            >
              <input
                style={{
                  border: "1px solid #cccccc",
                  borderRadius: "11px",
                  height: "48px",
                  outline: "none",
                  paddingLeft: "6px",
                }}
                onChange={handleInputfilter}
                value={selectedval.inputval}
              />
              {checkresetfilter != 1 ? (
                <img
                  src={SearchIcon}
                  className="search_icon"
                  onClick={filterData}
                />
              ) : (
                <img
                  src={RefreshIcon}
                  className="search_icon"
                  onClick={refreshData}
                />
              )}
            </div>
          ) : (
            ""
          )}
          {checkForDateFilter == 1 ? (
            <div className="mt-14p mt-d">
              {" "}
              <Datepicker
                id="production_date"
                onDatesSelected={handleDatesSelected}
                onRefreshSelected={refreshSelected}
              />
              {checkresetdatefilters != 1 ? (
                <img
                  src={SearchIcon}
                  className="search_icon_for_dates pointer"
                  onClick={filterDatafordates}
                />
              ) : (
                <img
                  src={RefreshIcon}
                  className="search_icon_for_dates pointer"
                  onClick={refreshData1}
                />
              )}
            </div>
          ) : (
            ""
          )}
          {check !== 2 && (
            <div style={{ height: "30px", marginBottom: "10px" }}></div>
          )}
        </div>
        {check == 5 ? <IBallComponent check={5} data={props.allIbData} /> : ""}
        {check == 2 ? (
          <IBtptAllocation
            check={2}
            onRequest={refreshDataNow}
            data={tabsfortpt}
            uploadBulkData={uploadBulkData}
            setcheckforbulk={setcheckforbulk}
          />
        ) : (
          ""
        )}
        {check == 3 ? (
          <IntransitToPlant
            check={3}
            data={tabsforintransitToPlant}
            onRequest={refreshDataNow}
          />
        ) : (
          ""
        )}
        {check == 4 ? (
          <AtPlant onRequest={refreshDataNow} data={tabsForAtPlant} check={4} />
        ) : (
          ""
        )}
        {check == 7 ? (
          <IBBuffer onRequest={refreshDataNow} check={7} data={bufferdata} />
        ) : (
          ""
        )}
        {check == 1 ? (
          <div className="" style={{ margin: "10px", marginTop: "12px" }}>
            <div className="d-flex flex-row" style={{ position: "relative" }}>
              <button
                className="d-flex flex-row justify-content-center align-items-center px-3"
                style={{
                  background: classForTab.new == 1 ? "#0664AE" : "#FFFFFF",
                  // color: state.isTileView ? "": "#0664AE",
                  borderTopLeftRadius: "11px",
                  borderBottomLeftRadius: "11px",
                  border: "1px solid #0664AE",
                  height: "38px",
                  padding: "22px",
                }}
                onClick={() => onclickTab("New")}
              >
                <div
                  style={{
                    color: classForTab.new == 1 ? "white" : "#0664AE",
                    fontSize: "12px",
                    fontWeight: 700,
                    marginLeft: "10px",
                    lineHeight: "12px",
                  }}
                >
                  New -{" "}
                  <span>
                    {newCount != "" && newCount != undefined
                      ? newCount.length
                      : 0}
                  </span>
                </div>
              </button>

              <button
                className="d-flex flex-row justify-content-center align-items-center px-3"
                style={{
                  background: classForTab.planned == 1 ? "#0664AE" : "#FFFFFF",
                  border: "1px solid #0664AE",
                  height: "38px",
                  padding: "22px",
                }}
                onClick={() => onclickTab("Planned")}
              >
                <div
                  style={{
                    color: classForTab.planned == 1 ? "white" : "#0664AE",
                    fontSize: "12px",
                    fontWeight: 700,
                    marginLeft: "10px",
                    lineHeight: "12px",
                  }}
                >
                  Planned -{" "}
                  <span>
                    {plannedCount != "" && plannedCount != undefined
                      ? plannedCount.length
                      : 0}
                  </span>
                </div>
              </button>
              <button
                className="d-flex flex-row justify-content-center align-items-center px-3"
                style={{
                  background: classForTab.modified == 1 ? "#0664AE" : "#FFFFFF",
                  borderTopRightRadius: "11px",
                  borderBottomRightRadius: "11px",
                  border: "1px solid #0664AE",
                  height: "38px",
                  padding: "22px",
                }}
                onClick={() => onclickTab("Modified")}
              >
                <div
                  style={{
                    color: classForTab.modified == 1 ? "white" : "#0664AE",
                    fontSize: "12px",
                    fontWeight: 700,
                    marginLeft: "10px",
                    lineHeight: "12px",
                  }}
                >
                  Modified -{" "}
                  <span>
                    {modifiedCount != "" && modifiedCount != undefined
                      ? modifiedCount.length
                      : 0}
                  </span>
                </div>
              </button>
              {classForTab.new === 1 && summaryviewcheck === 1 ? (
                <div
                  style={{ position: "absolute", right: "10px", top: "-25px" }}
                >
                 <button
                                        type="button"
                                        title="Save Grid Layout"
                                        name="savegrid"
                                        className="btn btn-danger action_btn"
                                        style={{height: "32px", marginTop: "30px", paddingBottom:"30px" }}

                                        onClick={onClickSaveGridState}>
                                        <i className="f12 icofont icofont-save"></i>
                                        </button>
                </div>
              ) : (
                ""
              )}
            </div>
            {/* <div style={{display:'flex',justifyContent:'end'}}>
                            {props.allcounter==1?
                            <p style={{color:'red', marginRight:"10px"}}>Note: Modification Pending At Previous Action</p>:''}
                         </div> */}
            <div className="" style={{ margin: "10px", marginTop: "0px" }}>
              <div
                id="idgrid"
                style={{ width: "100%", height: "478px" }}
                className="ag-theme-balham"
              >
                <AgGridReact
                  ref={gridApi}
                  columnDefs={columnwithDefs}
                  defaultColDef={{
                    sortable: true,
                    filter: true,
                    editable: false,
                    resizable: true,
                    menuTabs: ["filterMenuTab"],
                    // rowHeight:80
                  }}
                  // paddingTop={10}
                  // rowHeight={70}
                  // headerHeight={70}
                  rowData={rowData}
                  suppressRowClickSelection={true}
                  frameworkComponents={{
                    CountryFlagComponent: CountryFlagComponent,
                    UpdateIBStuffingComponent1: UpdateIBStuffingComponent1,
                    UpdateIBStuffingComponent: UpdateIBStuffingComponent,
                    SubmitRevisedBtn: SubmitRevisedBtn,
                    AcknowledgeBtnComponent: AcknowledgeBtnComponent,
                    UpdateAcknowledgeBtnComponent:
                      UpdateAcknowledgeBtnComponent,
                  }}
                  enableCellChangeFlash={true}
                  suppressCellFlash={true}
                  enableRangeSelection={true}
                  paginationPageSize={50}
                  onGridReady={onGridReady}
                  onGridState={onGridState}
                  rowSelection={"multiple"}
                  masterDetail={true}
                  pagination={true}
                  sideBar={{
                    toolPanels: [
                      {
                        id: "columns",
                        labelDefault: "Columns",
                        labelKey: "columns",
                        iconKey: "columns",
                        toolPanel: "agColumnsToolPanel",
                      },
                      {
                        id: "filters",
                        labelDefault: "Filters",
                        labelKey: "filters",
                        iconKey: "filter",
                        toolPanel: "agFiltersToolPanel",
                      },
                    ],
                  }}
                  statusBar={{
                    statusPanels: [
                      // {
                      //     statusPanel: "agTotalAndFilteredRowCountComponent",
                      //     align: "left"
                      // },
                      // {
                      //     statusPanel: "agTotalRowCountComponent",
                      //     align: "center"
                      // },
                      { statusPanel: "agFilteredRowCountComponent" },
                      { statusPanel: "agSelectedRowCountComponent" },
                      { statusPanel: "agAggregationComponent" },
                    ],
                  }}
                  gridOptions={{
                    icons: {
                      // You can customize other icons as well
                      next: `<img src="${customNextIcon}" alt="Next" />`,
                      previous: `<img src="${customPreviousIcon}" alt="Previous" />`,
                      first: `<img src="${customFirstIcon}" alt="First" />`,
                      last: `<img src="${customLastIcon}" alt="Last" />`,
                    },
                  }}
                  // ref={gridRef}
                  getRowStyle={getRowStyle}
                  context={{
                    onClickupdateAcknowledgeBtn,
                    onClickIBStuffingDate1,
                    onClickIBStuffingDate,
                    onClickRevisedProductionData,
                    onClickAcknowledgeBtn,
                  }}
                />
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
      {sliderForBulkupload == "slider-translate" &&
      isClicked.ibStuffing == true ? (
        <Modal
          open={open}
          onClose={onCloseModal}
          classNames={{ modal: "custom-modal" }}
        >
          {classForTab.new == true ? 
          (
            <>
              {" "}
              <div className="slide-r-title slider_title_style title-radius">
                <h6>Bulk Upload</h6>
              </div>
              <div className="slide-r-body" style={{ position: "relative" }}>
                <div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div>
                <div
                  className={"row"}
                  style={{ padding: "5px 0px", width: "100%", zIndex: "9999" }}
                >
                  <div className="theme-form col-xl-12 col-lg-12">
                    <form action="" onSubmit={onSubmitOrderBulkUpload}>
                      <div className="col-xl-12 col-lg-12 row form-group">
                        <label className="col-xl-12 col-lg-12">
                          Upload File *
                        </label>
                        <div className="d-flex justify-content-between">
                          <input
                            type="file"
                            name="uploadfile"
                            id="uploadfile"
                            className="form-control ml-3"
                            onChange={changeOrderFileHandlerFcrBulk}
                            style={{ width: "400px" }}
                            required
                          />
                          <img
                            src={require("../../assets/icons/red-dot.png")}
                            className="img-dot ml-70p mt-8p"
                          />
                          <span className="mt-8p ml-10p">
                            Invalid Date Format
                          </span>
                        </div>
                      </div>
                      {showGrid && (
                        <div
                          id="idgrid"
                          style={{ width: "30.3rem" }}
                          className="ag-theme-balham m-10p ml-15p"
                        >
                          <AgGridReact
                            // modules={this.state.modules}
                            columnDefs={columnDefs2}
                            // defaultColDef={this.state.defaultColDef}
                            rowData={rowData2}
                            domLayout="autoHeight"
                          />
                        </div>
                      )}
                      <div className="col-xl-12 col-lg-12 form-group mt-10p">
                        <button type="submit" className="btn btn-success">
                          Submit
                        </button>
                      </div>
                    </form>
                    {!showGrid && (
                      <div className="col-xl-12 col-lg-12 form-group">
                        <a
                          href={require("../../assets/json/ib_based_data.csv")}
                          target="_blank"
                          className="btn btn-warning"
                        >
                          Download Template
                        </a>
                      </div>
                    )}

                    <p style={{ color: "red" }} className="ml-15p">
                      Note:{" "}
                    </p>
                    <p className="ml-15p">
                    Stuffing Date should be in 'DD-MM-YYYY' format.
                    </p>
                  </div>
                </div>
              </div>
            </>
          ) : 
        //   (
        //     <>
        //       {" "}
        //       <div className="slide-r-title slider_title_style title-radius">
        //         <h6>Bulk Upload</h6>
        //       </div>
        //       <div className="slide-r-body" style={{ position: "relative" }}>
        //         <div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div>
        //         <div
        //           className={"row"}
        //           style={{ padding: "5px 0px", width: "100%", zIndex: "9999" }}
        //         >
        //           <div className="theme-form col-xl-12 col-lg-12">
        //             <form action="" onSubmit={onSubmitOrderBulkUpload1}>
        //               <div className="col-xl-12 col-lg-12 row form-group">
        //                 <label className="col-xl-12 col-lg-12">
        //                   Upload File *
        //                 </label>
        //                 <div className="d-flex justify-content-between">
        //                   <input
        //                     type="file"
        //                     name="uploadfile"
        //                     id="uploadfile"
        //                     className="form-control ml-3"
        //                     onChange={changeOrderFileHandlerFcrBulk}
        //                     style={{ width: "400px" }}
        //                     required
        //                   />
        //                   <img
        //                     src={require("../../assets/icons/red-dot.png")}
        //                     className="img-dot ml-70p mt-8p"
        //                   />
        //                   <span className="mt-8p ml-10p">
        //                     Invalid Date Format
        //                   </span>
        //                 </div>
        //               </div>
        //               {showGrid && (
        //                 <div
        //                   id="idgrid"
        //                   style={{ width: "44rem" }}
        //                   className="ag-theme-balham m-10p ml-15p"
        //                 >
        //                   <AgGridReact
        //                     // modules={this.state.modules}
        //                     columnDefs={columnDefs2}
        //                     // defaultColDef={this.state.defaultColDef}
        //                     rowData={rowData1}
        //                     domLayout="autoHeight"
        //                   />
        //                 </div>
        //               )}
        //               <div className="col-xl-12 col-lg-12 form-group mt-10p">
        //                 <button type="submit" className="btn btn-success">
        //                   Submit
        //                 </button>
        //               </div>
        //             </form>
        //             {!showGrid && (
        //               <div className="col-xl-12 col-lg-12 form-group">
        //                 <a
        //                   href={require("../../assets/json/cha_based_upload_data.csv")}
        //                   target="_blank"
        //                   className="btn btn-warning"
        //                 >
        //                   Download Template
        //                 </a>
        //               </div>
        //             )}
        //             <p style={{ color: "red" }} className="ml-15p">
        //               Note:{" "}
        //             </p>
        //             <p className="ml-15p">
        //               Tentative Shipment Line & Remarks should be in 'String'
        //               format. <br />
        //               Tentative Vessel ETD should be in 'DD-MM-YYYY'
        //               format.Tentative Gate Open Date & Tentative cut off date
        //               should be in 'DD-MM-YYYY HH:MM' format2
        //             </p>
        //           </div>
        //         </div>
        //       </div>
        //     </>
        //   )
        <></>
          }
        </Modal>
      ) : (
        ""
      )}
      {/* <div className={"slide-r " + sliderForBulkupload} style={{ overflow: "auto" }}>
                <div className="slide-r-title slider_title_style">
                    <h6>Bulk Upload</h6>
                    <span className="float-right closebtn" style={{ marginTop: '-30px', marginRight: "25px" }} onClick={onClickHide} >X</span>
                </div>
                <div className="slide-r-body" style={{ position: "relative" }}>
                    <div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div>
                    <div className={"row"} style={{ padding: '5px 0px', width: '100%', zIndex: '9999', }} >

                        <div className="theme-form col-xl-12 col-lg-12">

                            <form action="" onSubmit={onSubmitOrderBulkUpload}>
                                <div className="col-xl-12 col-lg-12 row form-group">
                                    <label className="col-xl-12 col-lg-12">Upload File *</label>
                                    <input
                                        type="file"
                                        name="uploadfile"
                                        id="uploadfile"
                                        className="form-control"
                                        onChange={changeOrderFileHandlerFcrBulk}
                                        required
                                    />
                                </div>

                                <div className="col-xl-12 col-lg-12 form-group">
                                    <button type="submit" className="btn btn-success">Submit</button>
                                </div>
                            </form>
                            <div className="col-xl-12 col-lg-12 form-group">
                                <a href={require("../../assets/json/ib_stuffing_upload_data.csv")} target="_blank" className="btn btn-warning">Download Template</a>
                            </div>
                            <p style={{ color: 'red' }}>Note: </p>
                            <p>Stuffing Date should be in 'DD-MM-YYYY' format. <br />
                                Remarks should be in String format.</p>
                        </div>
                    </div>
                </div>

            </div> */}
      {isClicked.ibtptallocation == true &&
      sliderForBulkupload == "slider-translate" ? (
        <Modal
          open={open}
          onClose={onCloseModal}
          classNames={{ modal: "custom-modal" }}
        >
          <div className="slide-r-title slider_title_style title-radius">
            <h6>Bulk Upload</h6>
          </div>
          <div className="slide-r-body" style={{ position: "relative" }}>
            <div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div>
            <div
              className={"row"}
              style={{ padding: "5px 0px", width: "100%", zIndex: "9999" }}
            >
              <div className="theme-form col-xl-12 col-lg-12">
                <form action="" onSubmit={onSubmitOrderBulkUpload1}>
                  <div className="col-xl-12 col-lg-12 row form-group">
                    <label className="col-xl-12 col-lg-12">Upload File *</label>
                    <div className="d-flex justify-content-between">
                      <input
                        type="file"
                        name="uploadfile"
                        id="uploadfile"
                        className="form-control ml-3"
                        onChange={changeOrderFileHandlerFcrBulk1}
                        style={{ width: "400px" }}
                        required
                      />
                      <img
                        src={require("../../assets/icons/red-dot.png")}
                        className="img-dot ml-70p mt-8p"
                      />
                      <span className="mt-8p ml-10p">Invalid Date Format</span>
                    </div>
                  </div>
                  {showGrid && (
                    <div
                      id="idgrid"
                      style={{ width: "46%" }}
                      className="ag-theme-balham m-10p ml-15p"
                    >
                      <AgGridReact
                        // modules={this.state.modules}
                        columnDefs={columnDefs1}
                        // defaultColDef={this.state.defaultColDef}
                        rowData={rowData1}
                        domLayout="autoHeight"
                      />
                    </div>
                  )}
                  <div className="col-xl-12 col-lg-12 form-group mt-10p">
                    <button type="submit" className="btn btn-success">
                      Submit
                    </button>
                  </div>
                </form>
                {!showGrid && (
                  <div className="col-xl-12 col-lg-12 form-group">
                    <a
                      href={IBBasedTPTData}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="btn btn-warning"
                    >
                      Download Template
                    </a>
                  </div>
                )}

                <p style={{ color: "red" }} className="ml-15p">
                  Note:{" "}
                </p>
                <p className="ml-15p">
                  Stuffing Date should be in 'DD-MM-YYYY' format. <br />
                  Remarks should be in String format.
                </p>
              </div>
            </div>
          </div>
        </Modal>
      ) : (
        ""
      )}
      <div
        className={"sliderBlock2 " + showAddForm}
        style={{ overflow: "auto" }}
      >
        <div className="slide-r-title slider_title_style">
          <h6>Enter IB Stuffing Date</h6>
          <span
            className="float-right closebtn"
            style={{ marginTop: "-30px", marginRight: "25px" }}
            onClick={onClickHideManualUpload}
          >
            X
          </span>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <form onSubmit={onsubmitdata}>
              <div className="slide-body">
                <div className="row p-20p">
                  <div className="form-group col-xl-12 col-lg-12">
                    <p className="mb-5p">
                      Work Order No: {selected_row.work_order_no}
                    </p>
                  </div>
                  <div className="form-group col-xl-12 col-lg-12">
                    <label className="mb-5p">
                      Stuffing Date<span className="err-txt">*</span>
                    </label>
                    <Datetime
                      key={manualUploadDetails.stuffing_date}
                      placeholder="Date"
                      disableCloseOnClickOutside={false}
                      closeOnSelect={true}
                      name="stuffing_date"
                      value={manualUploadDetails.stuffing_date}
                      dateFormat="DD-MM-YYYY"
                      id="stuffing_date"
                      timeFormat={false}
                      onChange={(e) =>
                        setmanualUploadDetails((prev) => ({
                          ...prev,
                          stuffing_date: changeHandleDate(e),
                        }))
                      }
                      required
                    />
                  </div>
                  <div className="form-group col-xl-12 col-lg-12">
                    <label className="mb-5p">
                      Remarks By IB
                    </label>
                    <input
                      type="text"
                      name="remarks_by_ib"
                      // placeholder='Enter Partner Type'
                      id="inputcode"
                      value={manualUploadDetails.remarks_by_ib}
                      className="form-control"
                      autoComplete="off"
                      onChange={(e) =>
                        setmanualUploadDetails((prev) => ({
                          ...prev,
                          remarks_by_ib: e.target.value,
                        }))
                      }
                      // required
                    />
                  </div>
                  <div className="form-group col-sm-12 mb-20p text-center">
                    <button type="submit" className="btn btn-success">
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div
        className={"sliderBlock2 " + showAddForm1}
        style={{ overflow: "auto" }}
      >
        <div className="slide-r-title slider_title_style">
          <h6>Update IB Stuffing Date</h6>
          <span
            className="float-right closebtn"
            style={{ marginTop: "-30px", marginRight: "25px" }}
            onClick={onClickHideManualUpload1}
          >
            X
          </span>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <form onSubmit={onsubmitdata1}>
              <div className="slide-body">
                <div className="row p-20p">
                  <div className="form-group col-xl-12 col-lg-12">
                    <p className="mb-5p">
                      Work Order No: {selected_row.work_order_no}
                    </p>
                  </div>
                  <div className="form-group col-xl-12 col-lg-12">
                    <label className="mb-5p">
                      Stuffing Date<span className="err-txt"></span>
                    </label>
                    <Datetime
                      key={manualUploadDetails.stuffing_date}
                      placeholder="Date"
                      disableCloseOnClickOutside={false}
                      closeOnSelect={true}
                      name="stuffing_date"
                      value={manualUploadDetails.stuffing_date}
                      dateFormat="DD-MM-YYYY"
                      id="stuffing_date"
                      timeFormat={false}
                      onChange={(e) =>
                        setmanualUploadDetails((prev) => ({
                          ...prev,
                          stuffing_date: changeHandleDate(e),
                        }))
                      }
                      required
                    />
                  </div>
                  <div className="form-group col-xl-12 col-lg-12">
                    <label className="mb-5p">
                      Remarks By IB<span className="err-txt"></span>
                    </label>
                    <input
                      type="text"
                      name="remarks_by_ib"
                      // placeholder='Enter Partner Type'
                      id="inputcode"
                      value={manualUploadDetails.remarks_by_ib}
                      className="form-control"
                      autoComplete="off"
                      onChange={(e) =>
                        setmanualUploadDetails((prev) => ({
                          ...prev,
                          remarks_by_ib: e.target.value,
                        }))
                      }
                      // required
                    />
                  </div>
                  <div className="form-group col-sm-12 mb-20p text-center">
                    <button type="submit" className="btn btn-success">
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className={"dataLoadpage " + loadshow}></div>
      <div className={"dataLoadpageimg " + loadshow}>
        <div className="loader-box">
          <div className="loader-box">
            <div className="rotate dashed colored"></div>
          </div>
        </div>
      </div>
      <div className={"overlay-part " + overly} onClick={onClickHideAll}></div>
    </div>
  );
};
function isEmpty(obj) {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) return false;
  }
  return true;
}
function timeConvert(n) {
  // var num = n/1000;
  // var hours = (num / (3600*24));
  // var rhours = Math.floor(hours);
  // var minutes = (n) / (60*60);
  // var rminutes = Math.round(minutes);

  var diffMs = n;
  var diffDays = Math.floor(diffMs / 86400000); // days
  var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
  var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes

  return diffHrs + " hour(s) and " + diffMins / 60 + " minute(s).";
}
var currentinfowindow = null;
function getTimeInfo(marker, timedet, coords) {
  var timeinfo = new window.google.maps.InfoWindow({
    content: "Time at this location: " + timedet,
  });

  marker.addListener("click", function () {
    if (currentinfowindow != null) {
      currentinfowindow.close();
      currentinfowindow = timeinfo;
      timeinfo.open(marker.get("map"), marker);
    } else {
      currentinfowindow = timeinfo;
      timeinfo.open(marker.get("map"), marker);
    }
  });
}

function secondsToString(seconds) {
  var numdays = Math.floor(seconds / 86400);
  var numhours = Math.floor((seconds % 86400) / 3600);
  var numminutes = Math.floor(((seconds % 86400) % 3600) / 60);
  var numseconds = ((seconds % 86400) % 3600) % 60;
  return numdays + " days " + numhours + " hours " + numminutes + " minutes ";
}

function secondsToDays(seconds) {
  var numdays = Math.floor(seconds / 86400);
  var numhours = Math.floor((seconds % 86400) / 3600);
  var numminutes = Math.floor(((seconds % 86400) % 3600) / 60);
  var numseconds = ((seconds % 86400) % 3600) % 60;
  return numdays;
}
function CheckForHtmlTag() {
  var rs = document.getElementById("invalid_reason");
  var reg = /<(.|\n)*?>/g;
  if (reg.test(rs.value)) {
    var ErrorText = "Oops! HTML or Script is not allowed.";
    rs.value = "";
    //alert();
    return 1;
  }
}
function dateComparator(date1, date2) {
  // //console.log("dateComparator");
  // //console.log(date1.length);
  if (date1 != "" && date2 != "" && date1 != undefined && date2 != undefined) {
    // //console.log(date1,date2);
    var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
    date1 = date1.replace(date1.split(" ")[0].split("-")[1], date_1);
    var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
    date2 = date2.replace(date2.split(" ")[0].split("-")[1], date_2);
    // //console.log(date1,date2);
    var date1Number = monthToComparableNumber(date1);
    var date2Number = monthToComparableNumber(date2);
    // //console.log(date1Number,date2Number);
    if (date1Number === null && date2Number === null) {
      return 0;
    }
    if (date1Number === null) {
      return -1;
    }
    if (date2Number === null) {
      return 1;
    }
    return date1Number - date2Number;
  }
}
function monthToComparableNumber(date) {
  // //console.log(date,date.length);
  if (date === undefined || date === null || date.length !== 16) {
    return null;
  }
  var yearNumber = date.substring(6, 10);
  var monthNumber = date.substring(3, 5);
  var dayNumber = date.substring(0, 2);
  ////console.log(yearNumber,monthNumber,dayNumber);
  var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
  return result;
}

function getMonthNumber(monthName) {
  var months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  var monthNum = months.indexOf(monthName) + 1;
  if (monthNum < 10) {
    monthNum = "0" + monthNum.toString();
  }
  return monthNum;
}

function loadDateTimeScript() {
  $(".datetimepicker_mask").datetimepicker({
    mask: "39-19-9999 29:59",
    format: "d-m-Y H:i",
    onShow: false,
  });
  $(".datetimepicker_date").datetimepicker({
    // mask:'39-19-9999',
    format: "d-m-Y",
    timepicker: false,
    onShow: false,
  });
  var index = window.document.getElementsByTagName("script")[1];
  var script = window.document.createElement("script");
  script.src =
    "https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js";
  //script.src="http://xdsoft.net/jqplugins/datetimepicker/"
  script.async = true;
  script.defer = true;
  index.parentNode.insertBefore(script, index);
}

function GetSortDescOrder(prop) {
  return function (a, b) {
    if (a[prop] < b[prop]) {
      return 1;
    } else if (a[prop] > b[prop]) {
      return -1;
    }
    return 0;
  };
}

function GetSortAscOrder(prop) {
  return function (a, b) {
    if (a[prop] > b[prop]) {
      return 1;
    } else if (a[prop] < b[prop]) {
      return -1;
    }
    return 0;
  };
}

function GetSortASCOrder(prop) {
  return function (a, b) {
    if (a[prop] > b[prop]) {
      return 1;
    } else if (a[prop] < b[prop]) {
      return -1;
    }
    return 0;
  };
}

function secondsToDhms(seconds) {
  seconds = Number(seconds);
  var d = Math.floor(seconds / (3600 * 24));
  var h = Math.floor((seconds % (3600 * 24)) / 3600);
  var m = Math.floor((seconds % 3600) / 60);
  var s = Math.floor(seconds % 60);

  var dDisplay = d >= 0 ? d + (d == 1 ? " Day " : " Days ") : "";
  var hDisplay = h >= 0 ? h + (h == 1 ? " Hr " : " Hrs ") : "";
  var mDisplay = m >= 0 ? m + (m == 1 ? " Mins " : " Mins ") : "";
  var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
  return dDisplay + hDisplay + mDisplay;
}

function distinctArrayBy(arr, propName) {
  var result = arr.reduce(function (arr1, e1) {
    var matches = arr1.filter(function (e2) {
      return e1[propName] == e2[propName];
    });
    if (matches.length == 0) arr1.push(e1);
    return arr1;
  }, []);

  return result;
}
function dateComparator1(date1, date2) {
  ////console.log(date1,date2);
  if (date1 != "" && date2 != "" && date1 != undefined && date2 != undefined) {
    // //console.log(date1,date2);
    var date_1 = getMonthNumber(date1.split("-")[1]);
    date1 = date1.replace(date1.split("-")[1], date_1);
    var date_2 = getMonthNumber(date2.split("-")[1]);
    date2 = date2.replace(date2.split("-")[1], date_2);
    var date1Number = monthToComparableNumber1(date1);
    var date2Number = monthToComparableNumber1(date2);
    ////console.log(date1Number,date2Number);
    if (date1Number === null && date2Number === null) {
      return 0;
    }
    if (date1Number === null) {
      return -1;
    }
    if (date2Number === null) {
      return 1;
    }
    //console.log(date1Number, date2Number, "comparator");
    return date1Number - date2Number;
  }
}
function monthToComparableNumber1(date) {
  ////console.log(date.length);

  ////console.log(date);
  if (date === undefined || date === null || date.length !== 10) {
    return null;
  }

  var yearNumber = date.substring(6, 10);

  var monthNumber = date.substring(3, 5);

  var dayNumber = date.substring(0, 2);

  var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;

  return result;
}
function loadDateTimeScript2() {
  // alert("timeDate");
  $(".datetimepicker_mask").datetimepicker({
    format: "d-m-Y",
  });
  $(".device_filter_data").datetimepicker({
    format: "Y-m-d",
    timepicker: true,
  });
  var index = window.document.getElementsByTagName("script")[1];
  var script = window.document.createElement("script");
  script.src =
    "https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js";
  //script.src="http://xdsoft.net/jqplugins/datetimepicker/"
  script.async = true;
  script.defer = true;
  index.parentNode.insertBefore(script, index);
}
function getDatePicker() {
  function Datepicker() {}

  Datepicker.prototype.init = function (params) {
    this.eInput = document.createElement("input");

    //this.eInput = this.eInput.setAttribute("class","datepicker");

    this.eInput.value = params.value;

    //console.log(this.eInput);

    window.$(".datepicker").datepicker({ dateFormat: "dd-mm-yy" });
  };

  Datepicker.prototype.getGui = function () {
    return this.eInput;
  };

  Datepicker.prototype.afterGuiAttached = function () {
    this.eInput.focus();

    this.eInput.select();
  };

  Datepicker.prototype.getValue = function () {
    return this.eInput.value;
  };

  Datepicker.prototype.destroy = function () {};

  Datepicker.prototype.isPopup = function () {
    return false;
  };

  return Datepicker;
}
function DateEditor() {}
// gets called once before the renderer is used

DateEditor.prototype.init = function (params) {
  // create the cell

  //console.log(params, "params");

  this.eInput = document.createElement("input");

  this.eInput.value = params.value;
  // https://jqueryui.com/datepicker/

  $(this.eInput).datetimepicker({
    dateFormat: "yy-mm-dd",

    changeMonth: true,

    changeYear: true,
  });
};

// gets called once when grid ready to insert the element

DateEditor.prototype.getGui = function () {
  return this.eInput;
};

// focus and select can be done after the gui is attached

DateEditor.prototype.afterGuiAttached = function () {
  this.eInput.focus();

  this.eInput.select();
};

// returns the new value after editing

DateEditor.prototype.getValue = function () {
  return this.eInput.value;
};

// any cleanup we need to be done here

DateEditor.prototype.destroy = function () {
  // but this example is simple, no cleanup, we could
  // even leave this method out as it's optional
};

// if true, then this editor will appear in a popup

DateEditor.prototype.isPopup = function () {
  // and we could leave this method out also, false is the default

  return false;
};
const verifyUploadFileColumns = async (validCols, file) => {
  return new Promise((resolve, reject) => {
    if (file !== "" && file !== undefined) {
      const reader = new FileReader();
      //console.log(validCols, "2751");
      //console.log(file, "2751");

      reader.onload = (e) => {
        const lines = e.target.result.split("\n");
        const headers = lines[0].split(",");
        const invalidCols = headers.filter(
          (header) => !validCols.includes(header.trim())
        );
        if (invalidCols.length === 0) {
          resolve(true);
        } else {
          resolve(`Invalid column(s): ${invalidCols.join(", ")}`);
        }
      };
      reader.readAsText(file);
    } else {
      reject("No file selected");
    }
  });
};

function sortByCreatedDate(arr, descending = true) {
  if (arr != "" && arr != undefined) {
    if (arr.length > 0) {
      const comparison = (a, b) => {
        const dateA = new Date(a.modified_date);
        const dateB = new Date(b.modified_date);
        if (descending) {
          return dateB - dateA; // Ascending order
        } else {
          return dateA - dateB; // Descending order
        }
      };
      arr.sort(comparison);
      return arr;
    }
  } else {
    return arr;
  }
}
export default IBUpdatesComponent;
