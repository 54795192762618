import React, {Component} from 'react';
export default class Error extends React.Component {
    constructor(props) {
      super(props);
      this.state = { error: null, errorInfo: null };
    }
    
    componentDidCatch(error, errorInfo) {
        console.log("error ", error)
      // Catch errors in any components below and re-render with error message
      this.setState({
        error: error,
        errorInfo: errorInfo
      })
      // You can also log error messages to an error reporting service here
    }
    
    render() {
      if (this.state.errorInfo) {
        console.log("this.state.errorInfo ", this.state.errorInfo)
        var err = this.state.error.toString();
        console.log("this.state.error ", err)
        if(err.includes("cross-origin error") == true)
        {
          window.location.reload()
        }
        // Error path
        return (
          (err.includes("cross-origin error") == true)?
          "Loading ...":<div>
          
            <h2>Something went wrong.</h2>
            <details style={{ whiteSpace: 'pre-wrap' }}>
              {this.state.error && this.state.error.toString()}
              <br />
              {this.state.errorInfo.componentStack}
            </details>
          </div>
        
        );
      }
      // Normally, just render children
      return this.props.children;
    }  
  }