

import React, { useEffect, useState, useRef } from "react";
import SweetAlert from 'react-bootstrap-sweetalert';

import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import 'ag-grid-enterprise';
import 'ag-grid-enterprise/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-enterprise/styles/ag-theme-balham.css';
import BulkForceFileHandler from './changebulkuploadhandler'
import PlantUpdatesComponent from "./plantUpdates";
import CHAUpdatesComponent from "./chaUpdates";
import IBUpdatesComponent from "./ibUpdatesComponent";
import TransporterComponent from "./transporterComponent";
import CompletedComponent from "./completedComponent";
import AllComponent from "./allComponents";
import Dashboard from "./dashboard";
import Dashboards from './dashboards';
import redirectURL from "../redirectURL";
import Modal from 'react-responsive-modal';
import { all } from "axios";
import refreshIcon from "../../assets/images/refresh-icon.svg";

const SummaryDashboard = () => {
    var [loadshow, setloadshow] = useState('show-n')
    var [overly, setoverly] = useState('show-n')
    var [basicTitle, setbasicTitle] = useState('')
    var [basicType, setbasicType] = useState('')
    var [show, setshow] = useState(false)
    var [gridApi, setGridApi] = useState(null);
    var [gridColumnApi, setgridColumnApi] = useState(null)
    var [subcounters, setsubcounters] = useState('AllUpdates')
    var [sliderForBulkupload, setsliderForBulkupload] = useState('')
    var [open, setopen]= useState(false) 
    var [file, setfile] = useState('')
    var [csvcontent, setcsvcontent] = useState("")
    var [bgclr, setbgclr] = useState({
        pending: '',
        plantUpdates: '',
        chaUpdates: '',
        IBUpdates: '',
        transportercounter: '',
        completedcounter: '',
        alltabcounter: ""
    })
    var [icon, setIcon] = useState({
        ibicon: 0,
        planticon: 0,
        CHAicon: 0,
        transportericon: 0,
        completedicon: 0,
        alltabicon: 0,
    })
    var [summaryviewcheck, setsummaryviewcheck] = useState(1)
    var [detailedviewcheck, setdetailedviewcheck] = useState(0)
    var [counters, setcounters] = useState({
        plantCount_new: 0,
        plantCount_modified:0,
        chaCount: 0,
        ib_stuffing: 0,
        transporters: 0
    })
    var [data, setData] = useState({
        chaData: [],
        ibData: [],
        transporterData: []
    })
    var [countersdata, setcountersdata] = useState({
        plantCountdata: [],
        ib_tpt_allocation_data: [],
        container_detailes_data: [],
        intransit_to_plant_data: [],
        at_plant_data: [],
        intransit_port_data: [],
        completed_data: []
    })
    var [checkforbulkupload, setcheckforbulkupload] = useState(1)
    var [ibcount, setIBCounters] = useState({
        atplant: [],
        intransit_to_plant: [],
        ib_tpt_allocation: [],
        ib_stuffing: []
    })
    var [chacount, setCHACounters] = useState({
        at_plant_cha: [],
        vessel_avai: [],
        upload_do: [],
        intransit_port: [],
        container_report: []
    })
    var [tabsCount,setTabsCount]=useState({
        cha_vessel_new:0,
        cha_vessel_modified:0,
        cha_do_new:0,
        cha_do_modified:0,
        cha_shipping_new:0,
        cha_shipping_modified:0,
        cha_reporting_new:0,
        cha_reporting_modified:0,
        cha_leo_new:0,
        cha_leo_modified:0,
        cha_gate_new:0,
        cha_gate_modified:0,
        cha_onboard_modified:0,
        cha_onboard_new:0,
        cha_bill_new:0,
        cha_bill_planned:0,
        cha_bill_modified:0,
    })
    var [tabsCountib,setTabsCountib]=useState({
        ib_stuffing_new:0,
        ib_stuffing_modified:0,
        ib_tpt_new:0,
        ib_tpt_modified:0,
        ib_intransit_new:0,
        ib_intransit_modified:0,
        ib_at_plant_new:0,
        ib_at_plant_modified:0
    })
    var [chaTabsCount,setChaTabsCount]=useState({
        cha_new:0,
        cha_modified:0
    })
      var [ibdata,setibdata] = useState({
        ibtptdata:[],
        atplantdata:[],
        intransittoplantdata:[],
        ibstuffingdata:[]
      })
    var [ibTabsCount,setibTabsCount]=useState({
        ib_new:0,
        ib_modified:0
    })
    var [tptTabsCount,settptTabsCount]=useState({
        trans_new:0,
        trans_modified:0
    })
    var [transcount, settranscount] = useState({
        transporterdetails: [],
        containerdetails: []
    })
    var [tabsCountTrans,setTabsCountTrans] = useState({
        tpt_trucks_modified:0,
        tpt_trucks_new:0,
        tpt_container_new:0,
        tpt_container_modified:0
    })
    const [reloadData, setReloadData] = useState(true);
    var [alldata,setalldata]=useState([])
    var [plantData1,setplantData1] = useState([])
    var [completedCount, setcompletedCount] = useState(0)
    // var [tabActive, settabActive] = useState('BL/Booking')
    var [tabActive, settabActive] = useState('dashboards')
    var [allcount, setallcount] = useState({
        all_new:0,
        all_modified:0
    })
    var [tabForCompleted,setTabForCompleted] = useState({
        work_order_level_data:[],
        contaCompletediner_completed:[]
    })
    var [transdata,settransData] = useState({
        tptContainerData:[],
        transporterDetails:[]
    })
    var gridRef = useRef();
    useEffect(() => {
        if (gridRef.current) {
            setGridApi(gridRef.current.api);
            setgridColumnApi(gridRef.current.columnApi)
        }
    }, [gridRef]);
    useEffect(() => {
        if (reloadData) { // Changes to Multiple time Loading Issue Reload 
            setloadshow('show-m');
            allData()
            getIBData()
            getChacount()
        }
        // getPlantData()
        // getibcount()
        //  getchacount()
        //  gettransportercount()
        setReloadData(false);
    }, [reloadData]);
    
    const allData = async()=>{
        setloadshow("show-m");
        redirectURL.post('/consignments/getentiredata').then((resp)=>{
            //alldata
            var work_order_level_data_all = resp.data.allData
            work_order_level_data_all.sort((firstItem, secondItem) => secondItem.status - firstItem.status);
            work_order_level_data_all=sortByStatus(work_order_level_data_all)
            setalldata(work_order_level_data_all)
            //plantdata
            var plantData = resp.data.plantData
            var new_plant = []
            if (plantData.new_plant !== '' && plantData.new_plant !== undefined) {
                var new_plant = plantData.new_plant;
              }
            var planned_plant=[]
            if(plantData.planned_plant!='' && plantData.planned_plant!=undefined){
                planned_plant = plantData.planned_plant 
            }
            var modified_plant=[]
            if(plantData.modified_plant!='' && plantData.modified_plant!=undefined){
                modified_plant = plantData.modified_plant 
            }
            var plantCount=[...new_plant]
                      setcounters(prev => ({
                    ...prev,
                    plantCount_new: new_plant.length,
                    plantCount_modified:modified_plant.length
                }))
                    setcountersdata(prev => ({
                    ...prev,
                    plantCountdata: plantCount
                }))
                setplantData1(plantData)
                //completeddata
                var completedData = resp.data.completeddata
                var work_order_level_data = completedData.work_order_level_data_complete 
                var contaCompletediner_completed = completedData.containerDetails_complete 
                setTabForCompleted({
                 work_order_level_data:work_order_level_data,
                 contaCompletediner_completed:contaCompletediner_completed
                })
                 setcompletedCount(work_order_level_data.length)
                 setcountersdata(prev => ({
                     ...prev,
                     completed_data: work_order_level_data
                 }))
                 //transporterdta 
                 const transporterDetails = resp.data.work_order_level_data
                 const tpt_trucks_new = transporterDetails.filter((e) => e.tpt_trucks_new ==1);
                 const tpt_trucks_modified = transporterDetails.filter((e) => e.tpt_trucks_modified == 1);
                 setTabsCountTrans((prev) => ({
                   ...prev,
                   tpt_trucks_new: tpt_trucks_new,
                   tpt_trucks_modified: tpt_trucks_modified,
                 }));
                 var tptContainerData = resp.data.tptContainersData
                 const container_new = tptContainerData.work_order_level_data_container;
                 const container_modified = tptContainerData.work_order_level_data_container3;
                 setTabsCountTrans((prev) => ({
                   ...prev,
                   tpt_container_modified: container_modified,
                   tpt_container_new: container_new,
                 }));
                 var transporter_new =[]
                 if(tpt_trucks_new!='' && tpt_trucks_new!=undefined){
                   transporter_new.push(...tpt_trucks_new)
                 } 
                 if(container_new!='' && container_new!=undefined){
                   transporter_new.push(...container_new)
                 } 
                 var transporter_modified =[]
                 if(tpt_trucks_modified!='' && tpt_trucks_modified!=undefined){
                   transporter_modified.push(...tpt_trucks_modified)
                 } 
                 if(container_modified!='' && container_modified!=undefined){
                   transporter_modified.push(...container_modified)
                 } 
                 settptTabsCount(prev=>({
                   ...prev,
                   trans_new: transporter_new.length,
               }))
               settptTabsCount(prev=>({
                   ...prev,
                   trans_modified: transporter_modified.length,
               }))
               settransData({
                tptContainerData:tptContainerData,
                transporterDetails:transporterDetails
               })
               setloadshow("show-n")

        })
    }
    // const getPlantData=async ()=>{

    //     redirectURL.post('/consignments/getplantsData').then((resp) => {
    //         console.log(resp.data,'89')
    //         var new_plant = []
    //         if (resp.data.new_plant !== '' && resp.data.new_plant !== undefined) {
    //             var new_plant = resp.data.new_plant;
    //           }
    //         var planned_plant=[]
    //         if(resp.data.planned_plant!='' && resp.data.planned_plant!=undefined){
    //             planned_plant = resp.data.planned_plant 
    //         }
    //         var modified_plant=[]
    //         if(resp.data.modified_plant!='' && resp.data.modified_plant!=undefined){
    //             modified_plant = resp.data.modified_plant 
    //         }
    //         var plantCount=[...new_plant]
    //                   setcounters(prev => ({
    //                 ...prev,
    //                 plantCount_new: new_plant.length,
    //                 plantCount_modified:modified_plant.length
    //                 // chaCount:chaCount.length,
    //             }))
    //                 setcountersdata(prev => ({
    //                 ...prev,
    //                 plantCountdata: plantCount
    //             }))
    //     });
    // }
    // const gettransportercount = async () => {
    //     try {
    //       const resp1 = await redirectURL.post('/consignments/getAllConsignmentsData');
    //       const transporterDetails = resp1.data.work_order_level_data
      
    //       const tpt_trucks_new = transporterDetails.filter((e) => e.tpt_trucks_new ==1);
    //       const tpt_trucks_modified = transporterDetails.filter((e) => e.tpt_trucks_modified == 1);
    //       setTabsCountTrans((prev) => ({
    //         ...prev,
    //         tpt_trucks_new: tpt_trucks_new,
    //         tpt_trucks_modified: tpt_trucks_modified,
    //       }));
      
    //       const resp2 = await redirectURL.post('/consignments/getDataForContainersDetails');
    //       const container_new = resp2.data.work_order_level_data1;
    //       const container_modified = resp2.data.work_order_level_data3;
    //       setTabsCountTrans((prev) => ({
    //         ...prev,
    //         tpt_container_modified: container_modified,
    //         tpt_container_new: container_new,
    //       }));
    //       var transporter_new =[]
    //       if(tpt_trucks_new!='' && tpt_trucks_new!=undefined){
    //         transporter_new.push(...tpt_trucks_new)
    //       } 
    //       if(container_new!='' && container_new!=undefined){
    //         transporter_new.push(...container_new)
    //       } 
    //       var transporter_modified =[]
    //       if(tpt_trucks_modified!='' && tpt_trucks_modified!=undefined){
    //         transporter_modified.push(...tpt_trucks_modified)
    //       } 
    //       if(container_modified!='' && container_modified!=undefined){
    //         transporter_modified.push(...container_modified)
    //       } 
    //       console.log(transporter_new, 'transporter_new'); 
    //       settptTabsCount(prev=>({
    //         ...prev,
    //         trans_new: transporter_new.length,
    //     }))
    //     settptTabsCount(prev=>({
    //         ...prev,
    //         trans_modified: transporter_modified.length,
    //     }))
    //     } catch (error) {
    //       console.error('Error fetching data:', error);
    //     }
    //   };
    //     var transporter_new=[]
    //     var transporter_modified=[]
    //     try {
    //         const resp1 = await redirectURL.post('/consignments/getAllConsignmentsData');
    //         var transporterDetails = resp1.data.work_order_level_data.filter(e => e.tpt_trucks_planned==1||e.tpt_trucks_new==1||e.tpt_trucks_modified==1);
    //         var tpt_trucks_new = transporterDetails.filter(e=>e.tpt_trucks_new==1)
    //         var tpt_trucks_modified = transporterDetails.filter(e=>e.tpt_trucks_modified==1)
    //       setTabsCountTrans((prev) => ({
    //         ...prev,
    //         tpt_trucks_new: tpt_trucks_new,
    //         tpt_trucks_modified: tpt_trucks_modified,
    //       }));
    //       transporter_new.push(...tpt_trucks_new);
    //       transporter_modified.push(...tpt_trucks_modified);

    //         const resp2 = await redirectURL.post('/consignments/getDataForContainersDetails');
    //         var container_new = resp2.data.work_order_level_data1;
    //         var container_modified = resp2.data.work_order_level_data3;
    //         if(container_new!='' && container_new!=undefined&&container_modified!='' && container_modified!=undefined){
    //             setTabsCountTrans((prev) => ({
    //                 ...prev,
    //                 tpt_container_modified: container_new,
    //                 tpt_container_new: container_modified,
    //               }));
    //               transporter_new.push(...container_new);
    //               console.log(transporter_new,'transporter_new')
    //               transporter_modified.push(...container_modified);
    //               settptTabsCount({
    //                 trans_new:transporter_new,
    //                 trans_modified:transporter_modified
    //               })
    //         }
    //     } catch (error) {
    //         console.error('Error fetching data:', error);
    //     }
    // };
    const getIBData = async () => {
        const ibdata_new = [];
        const ibdata_modified = [];
      
        try {
          const resp = await redirectURL.post('/consignments/getIBCounterData');
          
          // ib tpt data
          const ibtptdata = resp.data.ibtptData;
          const ib_tpt_allocation_new = Array.isArray(ibtptdata.tpt_new) ? ibtptdata.tpt_new : [];
          const ib_tpt_allocation_modified = Array.isArray(ibtptdata.tpt_modified) ? ibtptdata.tpt_modified : [];
      
          setTabsCountib((prev) => ({
            ...prev,
            ib_tpt_new: ib_tpt_allocation_new,
            ib_tpt_modified: ib_tpt_allocation_modified,
          }));
      
          ibdata_new.push(...ib_tpt_allocation_new);
          ibdata_modified.push(...ib_tpt_allocation_modified);
      
          // ib stuffing
          const ibstuffingdata = resp.data.ibstuffingdata;
          const ib_stuffing_new = Array.isArray(ibstuffingdata.stuffing_new) ? ibstuffingdata.stuffing_new : [];
          const ib_stuffing_modified = Array.isArray(ibstuffingdata.stuffing_modified) ? ibstuffingdata.stuffing_modified : [];
      
          setTabsCountib((prev) => ({
            ...prev,
            ib_stuffing_new: ib_stuffing_new,
            ib_stuffing_modified: ib_stuffing_modified,
          }));
      
          ibdata_new.push(...ib_stuffing_new);
          ibdata_modified.push(...ib_stuffing_modified);
      
          // intransit to plant
          const intransittoplantdata = resp.data.ibintransitplantdata;
          const intransit_to_plant_new = Array.isArray(intransittoplantdata.work_order_level_data1)
            ? intransittoplantdata.work_order_level_data1
            : [];
          const intransit_to_plant_modified = Array.isArray(intransittoplantdata.work_order_level_data3)
            ? intransittoplantdata.work_order_level_data3
            : [];
      
          setTabsCountib((prev) => ({
            ...prev,
            ib_intransit_new: intransit_to_plant_new,
            ib_intransit_modified: intransit_to_plant_modified,
          }));
      
          ibdata_new.push(...intransit_to_plant_new);
          ibdata_modified.push(...intransit_to_plant_modified);
      
          // at plant
          const atplantdata = resp.data.atplantdata;
          const at_plant_new = Array.isArray(atplantdata.work_order_level_data4)
            ? atplantdata.work_order_level_data4
            : [];
          const at_plant_modified = Array.isArray(atplantdata.work_order_level_data6)
            ? atplantdata.work_order_level_data6
            : [];
      
          setTabsCountib((prev) => ({
            ...prev,
            ib_at_plant_new: at_plant_new,
            ib_at_plant_modified: at_plant_modified,
          }));
      
          ibdata_new.push(...at_plant_new);
          ibdata_modified.push(...at_plant_modified);
          //buffer
          var work_order_level_data_buffer = resp.data.work_order_level_data_buffer 
          work_order_level_data_buffer=work_order_level_data_buffer.filter(e=>e.buffer_approved!=1)
          const buffer_ib = Array.isArray(work_order_level_data_buffer)
          ? work_order_level_data_buffer
          : [];
          ibdata_new.push(...buffer_ib);
            // redirectURL.post('/consignments/getbufferforib').then((resp)=>{
            //     if (resp.data != '' && resp.data != undefined) {
            //         ibdata_new.push(...resp.data.work_order_level_data)
            //     }
            // })
          setibTabsCount({
            ib_new: ibdata_new.length,
            ib_modified: ibdata_modified.length,
          });
      
          setibdata({
            ibtptdata: ibtptdata,
            atplantdata: atplantdata,
            intransittoplantdata: intransittoplantdata,
            ibstuffingdata: ibstuffingdata,
          });
        } catch (e) {
          console.error(e);
        }
        setloadshow('show-n');
      };
      
    
    // const getIBData = async()=>{
    //     const ibdata_new = [];
    //     const ibdata_modified = [];
    //     try{
    //         redirectURL.post('/consignments/getIBCounterData').then((resp)=>{
    //             //ib tpt data
    //             var ibtptdata = resp.data.ibtptData
    //             const ib_tpt_allocation_new = Array.isArray(ibtptdata.tpt_new) ? ibtptdata.tpt_new : [];
    //             const ib_tpt_allocation_modified = Array.isArray(ibtptdata.tpt_modified) ? ibtptdata.tpt_modified : [];
    //             setTabsCountib((prev) => ({
    //               ...prev,
    //               ib_tpt_new: ib_tpt_allocation_new,
    //               ib_tpt_modified: ib_tpt_allocation_modified,
    //             }));
    //             ibdata_new.push(...ib_tpt_allocation_new);
    //             ibdata_modified.push(...ib_tpt_allocation_modified);
    //             //ib stuffing
    //             var ibstuffingdata = resp.data.ibstuffingdata
    //             console.log(ibstuffingdata,'ibstuffingdata')
    //             const ib_stuffing_new = ibstuffingdata.stuffing_new
    //             const ib_stuffing_modified =ibstuffingdata.stuffing_modified
            
    //             setTabsCountib((prev) => ({
    //               ...prev,
    //               ib_stuffing_new: ib_stuffing_new,
    //               ib_stuffing_modified: ib_stuffing_modified,
    //             }));
            
    //             ibdata_new.push(...ib_stuffing_new);
    //             ibdata_modified.push(...ib_stuffing_modified);
    //             //intransit to plant
    //             var intransittoplantdata = resp.data.ibintransitplantdata
    //             const intransit_to_plant_new = Array.isArray(intransittoplantdata.work_order_level_data1)
    //             ? intransittoplantdata.work_order_level_data1
    //             : [];
    //           const intransit_to_plant_modified = Array.isArray(intransittoplantdata.work_order_level_data3)
    //             ? intransittoplantdata.work_order_level_data3
    //             : [];
    //           setTabsCountib((prev) => ({
    //             ...prev,
    //             ib_intransit_new: intransit_to_plant_new,
    //             ib_intransit_modified: intransit_to_plant_modified,
    //           }));
          
    //           ibdata_new.push(...intransit_to_plant_new);
    //           ibdata_modified.push(...intransit_to_plant_modified);
    
    //           //at plant 
    //           var atplantdata = resp.data.atplantdata
    //           const at_plant_new = Array.isArray(atplantdata.work_order_level_data4)
    //             ? atplantdata.work_order_level_data1
    //             : [];
    //           const at_plant_modified = Array.isArray(atplantdata.work_order_level_data6)
    //             ? atplantdata.work_order_level_data3
    //             : [];
          
    //           setTabsCountib((prev) => ({
    //             ...prev,
    //             ib_at_plant_new: at_plant_new,
    //             ib_at_plant_modified: at_plant_modified,
    //           }));
          
    //           ibdata_new.push(...at_plant_new);
    //           ibdata_modified.push(...at_plant_modified);
    //           setibTabsCount({
    //             ib_new: ibdata_new.length,
    //             ib_modified: ibdata_modified.length,
    //           });
    //           setibdata({
    //             ibtptdata:ibtptdata,
    //             atplantdata:atplantdata,
    //             intransittoplantdata:intransittoplantdata,
    //             ibstuffingdata:ibstuffingdata
    //           })
    
    //         })
    //     }
    //     catch(e){}
    // }
    const getchadata = async()=>{
        var chadata_new = []
        var chadata_modified=[]
        try{
            redirectURL.post('/consignments/getCHAcounterdata').then((resp)=>{
                var vesseldata = resp.data.vesseldata
                var cha_vessel_new = vesseldata.vessel_new 
                var cha_vessel_modified = vesseldata.vessel_modified
                setTabsCount(prev=>({
                    ...prev,
                    cha_vessel_new:cha_vessel_new,
                    cha_vessel_modified:cha_vessel_modified
                }))
                chadata_new.push(...cha_vessel_new);
                    chadata_modified.push(...cha_vessel_modified)


                var doData = resp.data.doData
                    var cha_do_new = doData.do_new
                    var cha_do_modified = doData.do_modified
                    setTabsCount(prev=>({
                        ...prev,
                        cha_do_new:cha_do_new,
                        cha_do_modified:cha_do_modified
                    }))
                    chadata_new.push(...cha_do_new);
                        chadata_modified.push(...cha_do_modified)
                    //at plant
                        var atplantdata = resp.data.atplantdata
                        var at_plant_new = atplantdata.work_order_level_data1;
                        var at_plant_modified = atplantdata.work_order_level_data3;
                        setTabsCount(prev=>({
                            ...prev,
                            cha_shipping_new:at_plant_new,
                            cha_shipping_modified:at_plant_modified
                        }))
                        chadata_new.push(...at_plant_new);
                            chadata_modified.push(...at_plant_modified)
                
            })
        }
        catch(e){}
    }
    // const getibcount = async () => {
    //     try {
    //       const ibdata_new = [];
    //       const ibdata_modified = [];
      
    //       const resp5 = await redirectURL.post('/consignments/getIBtptdata');
    //       const ib_tpt_allocation_new = Array.isArray(resp5.data.tpt_new) ? resp5.data.tpt_new : [];
    //       const ib_tpt_allocation_modified = Array.isArray(resp5.data.tpt_modified) ? resp5.data.tpt_modified : [];
      
    //       setTabsCountib((prev) => ({
    //         ...prev,
    //         ib_tpt_new: ib_tpt_allocation_new,
    //         ib_tpt_modified: ib_tpt_allocation_modified,
    //       }));
      
    //       ibdata_new.push(...ib_tpt_allocation_new);
    //       ibdata_modified.push(...ib_tpt_allocation_modified);
      
    //       const resp7 = await redirectURL.post('/consignments/getIBStuffing');
    //       const ib_stuffing_new = Array.isArray(resp7.data.stuffing_new) ? resp7.data.stuffing_new : [];
    //       const ib_stuffing_modified = Array.isArray(resp7.data.ib_modified) ? resp7.data.ib_modified : [];
      
    //       setTabsCountib((prev) => ({
    //         ...prev,
    //         ib_stuffing_new: ib_stuffing_new,
    //         ib_stuffing_modified: ib_stuffing_modified,
    //       }));
      
    //       ibdata_new.push(...ib_stuffing_new);
    //       ibdata_modified.push(...ib_stuffing_modified);
      
    //       const resp2 = await redirectURL.post('/consignments/getIntransitToPlantData');
    //       const intransit_to_plant_new = Array.isArray(resp2.data.work_order_level_data1)
    //         ? resp2.data.work_order_level_data1
    //         : [];
    //       const intransit_to_plant_modified = Array.isArray(resp2.data.work_order_level_data3)
    //         ? resp2.data.work_order_level_data3
    //         : [];
      
    //       setTabsCountib((prev) => ({
    //         ...prev,
    //         ib_intransit_new: intransit_to_plant_new,
    //         ib_intransit_modified: intransit_to_plant_modified,
    //       }));
      
    //       ibdata_new.push(...intransit_to_plant_new);
    //       ibdata_modified.push(...intransit_to_plant_modified);
      
    //       const resp3 = await redirectURL.post('/consignments/getAtPlantIB');
    //       const at_plant_new = Array.isArray(resp3.data.work_order_level_data1)
    //         ? resp3.data.work_order_level_data1
    //         : [];
    //       const at_plant_modified = Array.isArray(resp3.data.work_order_level_data3)
    //         ? resp3.data.work_order_level_data3
    //         : [];
      
    //       setTabsCountib((prev) => ({
    //         ...prev,
    //         ib_at_plant_new: at_plant_new,
    //         ib_at_plant_modified: at_plant_modified,
    //       }));
      
    //       ibdata_new.push(...at_plant_new);
    //       ibdata_modified.push(...at_plant_modified);
      
    //       console.log(ibdata_new, 'ibdata_new');
    //       console.log(ibdata_modified, 'ibdata_modified');
      
    //       setibTabsCount({
    //         ib_new: ibdata_new.length,
    //         ib_modified: ibdata_modified.length,
    //       });
    //     } catch (error) {
    //       console.error('Error in getibcount:', error);
    //     }
    //   };
    //     try {
    //         var ibdata_new=[]
    //         var ibdata_modified=[]
    //         const resp5 = await redirectURL.post('/consignments/getIBtptdata');
    //         var ib_tpt_allocation_new = resp5.data.tpt_new
    //         var ib_tpt_allocation_modified = resp5.data.tpt_modified
    //         setTabsCountib(prev=>({
    //             ...prev,
    //             ib_tpt_new:ib_tpt_allocation_new,
    //             ib_tpt_modified:ib_tpt_allocation_modified
    //         }))
    //         ibdata_new.push(...ib_tpt_allocation_new);
    //         ibdata_modified.push(...ib_tpt_allocation_modified)
    //         const resp7 = await redirectURL.post('/consignments/getIBStuffing');
    //         var ib_stuffing_new = resp7.data.stuffing_new; 
    //         var ib_stuffing_modified = resp7.data.ib_modified
    //         setTabsCountib(prev=>({
    //             ...prev,
    //             ib_stuffing_new:ib_stuffing_new,
    //             ib_stuffing_modified:ib_stuffing_modified
    //         }))
    //         ibdata_new.push(...ib_stuffing_new);
    //         ibdata_modified.push(...ib_stuffing_modified)
    //         const resp2 = await redirectURL.post('/consignments/getIntransitToPlantData');
    //         var intransit_to_plant_new = resp2.data.work_order_level_data1;
    //         var intransit_to_plant_modified = resp2.data.work_order_level_data3;
    //         setTabsCountib(prev=>({
    //             ...prev,
    //             ib_intransit_new:intransit_to_plant_new,
    //             ib_intransit_modified:intransit_to_plant_modified
    //         }))
    //         ibdata_new.push(...intransit_to_plant_new);
    //         ibdata_modified.push(...intransit_to_plant_modified)
           
    //         const resp3 = await redirectURL.post('/consignments/getAtPlantIB');
    //         var at_plant_new = resp3.data.work_order_level_data1;
    //         var at_plant_modified = resp3.data.work_order_level_data3;
    //         setTabsCountib(prev=>({
    //             ...prev,
    //             ib_at_plant_new:at_plant_new,
    //             ib_at_plant_modified:at_plant_modified
    //         }))
    //         ibdata_new.push(...at_plant_new);
    //         ibdata_modified.push(...at_plant_modified) 
    //         console.log(ibdata_new,'ibdata_new')
    //         console.log(ibdata_modified,'ibdata_modified')
    //         setibTabsCount({
    //             ib_new:ibdata_new.length,
    //             ib_modified:ibdata_modified.length
    //         })
    //     } catch (error) {
    //     }
    // };
    // const getChacount = async ()=>{
    //     try{
    //         var chadata_new = []
    //         var chadata_modified=[]
    //         var chadata=[]
    //         var resp = await redirectURL.post('/consignments/getchadata1')
    //         var cha_vessel_new = resp.data.vessel_new 
    //         var cha_vessel_modified = resp.data.vessel_modified
    //         if(localStorage.getItem('plant_code')!='' && localStorage.getItem('plant_code')!=undefined&&localStorage.getItem('plant_code')!='undefined'&&localStorage.getItem('email')!='ch01@enmovil.in'){
    //             cha_vessel_new = cha_vessel_new.filter(e=>e.cha==localStorage.getItem('plant_code'))
    //             cha_vessel_modified = cha_vessel_modified.filter(e=>e.cha==localStorage.getItem('plant_code'))
    //         }
    //         setTabsCount(prev=>({
    //             ...prev,
    //             cha_vessel_new:cha_vessel_new,
    //             cha_vessel_modified:cha_vessel_modified
    //         }))
    //         chadata_new.push(...cha_vessel_new);
    //             chadata_modified.push(...cha_vessel_modified)
    //         //upload do 
    //         var upload_do_data = resp.data.upload_do_data
    //         var cha_do_new = upload_do_data.filter(e=>e.cha_do_new==1)
    //         var cha_do_modified = upload_do_data.filter(e=>e.cha_do_modified==1||e.container_count_increase_check==1)
    //         if(localStorage.getItem('plant_code')!='' && localStorage.getItem('plant_code')!=undefined&&localStorage.getItem('plant_code')!='undefined'&&localStorage.getItem('email')!='ch01@enmovil.in'){
    //             cha_do_new = cha_do_new.filter(e=>e.cha==localStorage.getItem('plant_code'))
    //             cha_do_modified = cha_do_modified.filter(e=>e.cha==localStorage.getItem('plant_code'))
    //         }
    //         setTabsCount(prev=>({
    //             ...prev,
    //             cha_do_new:cha_do_new,
    //             cha_do_modified:cha_do_modified
    //         }))
    //         chadata_new.push(...cha_do_new);
    //             chadata_modified.push(...cha_do_modified)
    //         //at plant 
    //         var at_plant_new = resp.data.work_order_level_data_at_plant_new;
    //         var at_plant_modified = resp.data.work_order_level_data_at_plant_modified;
    //         if(localStorage.getItem('plant_code')!='' && localStorage.getItem('plant_code')!=undefined&&localStorage.getItem('plant_code')!='undefined'&&localStorage.getItem('email')!='ch01@enmovil.in'){
    //             at_plant_new = at_plant_new.filter(e=>e.cha==localStorage.getItem('plant_code'))
    //             at_plant_modified = at_plant_modified.filter(e=>e.cha==localStorage.getItem('plant_code'))
    //         }
    //         setTabsCount(prev=>({
    //             ...prev,
    //             cha_shipping_new:at_plant_new,
    //             cha_shipping_modified:at_plant_modified
    //         }))
    //         chadata_new.push(...at_plant_new);
    //         chadata_modified.push(...at_plant_modified)
    //         //container report 
    //         var container_report_new = resp.data.work_order_level_data_container_new;
    //         var container_report_modified = resp.data.work_order_level_data_container_modified;
    //         if(localStorage.getItem('plant_code')!='' && localStorage.getItem('plant_code')!=undefined&&localStorage.getItem('plant_code')!='undefined'&&localStorage.getItem('email')!='ch01@enmovil.in'){
    //             container_report_new = container_report_new.filter(e=>e.cha==localStorage.getItem('plant_code'))
    //             container_report_modified = container_report_modified.filter(e=>e.cha==localStorage.getItem('plant_code'))
    //         }
    //         setTabsCount(prev=>({
    //             ...prev,
    //             cha_reporting_new:container_report_new,
    //             cha_reporting_modified:container_report_modified
    //         }))
    //         chadata_new.push(...container_report_new);
    //         chadata_modified.push(...container_report_modified)
    //         //leo date 
    //         var leo_new = resp.data.work_order_level_data_leo_new;
    //         var leo_modified = resp.data.work_order_level_data_leo_modified;
    //         if(localStorage.getItem('plant_code')!='' && localStorage.getItem('plant_code')!=undefined&&localStorage.getItem('plant_code')!='undefined'&&localStorage.getItem('email')!='ch01@enmovil.in'){
    //             leo_new = leo_new.filter(e=>e.cha==localStorage.getItem('plant_code'))
    //             leo_modified = leo_modified.filter(e=>e.cha==localStorage.getItem('plant_code'))
    //         }
    //         setTabsCount(prev=>({
    //             ...prev,
    //             cha_leo_new:leo_new,
    //             cha_leo_modified:leo_modified
    //         }))
    //         chadata_new.push(...leo_new);
    //         chadata_modified.push(...leo_modified)
    //         // gate new
    //         var gate_new = resp.data.work_order_level_data_port_gate_new;
    //         var gate_modified = resp.data.work_order_level_data3;
    //         if(localStorage.getItem('plant_code')!='' && localStorage.getItem('plant_code')!=undefined&&localStorage.getItem('plant_code')!='undefined'&&localStorage.getItem('email')!='ch01@enmovil.in'){
    //             gate_new = gate_new.filter(e=>e.cha==localStorage.getItem('plant_code'))
    //             gate_modified = gate_modified.filter(e=>e.cha==localStorage.getItem('plant_code'))
    //         }
    //         setTabsCount(prev=>({
    //             ...prev,
    //             cha_gate_new:gate_new,
    //             cha_gate_modified:gate_modified
    //         }))
    //         chadata_new.push(...gate_new);
    //         chadata_modified.push(...gate_modified)
    //         //shipping onboard 
    //         var onboard_new = resp.data.work_order_level_data_shipping_new;
    //         if(localStorage.getItem('plant_code')!='' && localStorage.getItem('plant_code')!=undefined&&localStorage.getItem('plant_code')!='undefined'&&localStorage.getItem('email')!='ch01@enmovil.in'){
    //             onboard_new = onboard_new.filter(e=>e.cha==localStorage.getItem('plant_code'))
    //         }
    //         setTabsCount(prev=>({
    //             ...prev,
    //             cha_onboard_new:onboard_new,
    //         }))
    //         chadata_new.push(...onboard_new);
    //         //bill of lading 
    //         var bill_new = resp.data.work_order_level_data_bill_new;
    //         if(localStorage.getItem('plant_code')!='' && localStorage.getItem('plant_code')!=undefined&&localStorage.getItem('plant_code')!='undefined'&&localStorage.getItem('email')!='ch01@enmovil.in'){
    //             bill_new = bill_new.filter(e=>e.cha==localStorage.getItem('plant_code'))
    //         }
    //         setTabsCount(prev=>({
    //             ...prev,
    //             bill_new:bill_new,
    //         }))
    //         chadata_new.push(...bill_new);
    //         //buffer 
    //         var buffer_data = resp.data.work_order_level_data 
    //         buffer_data=buffer_data.filter(e=>e.pendency_in_buffer>0)        
    //             if(localStorage.getItem('plant_code')!='' && localStorage.getItem('plant_code')!=undefined&&localStorage.getItem('plant_code')!='undefined'&&localStorage.getItem('email')!='ch01@enmovil.in'){
    //                 buffer_data = buffer_data.filter(e=>e.cha==localStorage.getItem('plant_code'))
    //         }
    //         chadata_new.push(...buffer_data);
    //         console.log(chadata_new,"chadatanewww")
    //         console.log(chadata_modified,"chadatamodified")

    //         setChaTabsCount({
    //             cha_new:chadata_new.length,
    //             cha_modified:chadata_modified.length,
    //         })
    //     }
    //     catch(e){

    //     }
    // }
    const getChacount = async () => {
        try {
            var chadata_new = [];
            var chadata_modified = [];
            var resp = await redirectURL.post('/consignments/getchadata1');
    
            console.log('Response data:', resp.data); // Log the entire response
    
            var cha_vessel_new = resp.data.vessel_new;
            var cha_vessel_modified = resp.data.vessel_modified;
            console.log('cha_vessel_new:', cha_vessel_new); // Log vessel data
            console.log('cha_vessel_modified:', cha_vessel_modified);
    
            // if (localStorage.getItem('plant_code') && localStorage.getItem('email') !== 'ch01@enmovil.in') {
            //     cha_vessel_new = cha_vessel_new.filter(e => e.cha === localStorage.getItem('plant_code'));
            //     cha_vessel_modified = cha_vessel_modified.filter(e => e.cha === localStorage.getItem('plant_code'));
            // }
    
            setTabsCount(prev => ({
                ...prev,
                cha_vessel_new: cha_vessel_new,
                cha_vessel_modified: cha_vessel_modified
            }));
    
            chadata_new.push(...cha_vessel_new);
            chadata_modified.push(...cha_vessel_modified);

            console.log(chadata_new, "chadatanewarr")
    
            console.log('cha_vessel_new:', cha_vessel_new); // Log vessel data
            console.log('cha_vessel_modified:', cha_vessel_modified);
    
            var upload_do_data = resp.data.upload_do_data;
            var cha_do_new = upload_do_data.filter(e => e.cha_do_new === 1);
            var cha_do_modified = upload_do_data.filter(e => e.cha_do_modified === 1 || e.container_count_increase_check === 1);
    
            // if (localStorage.getItem('plant_code') && localStorage.getItem('email') !== 'ch01@enmovil.in') {
            //     cha_do_new = cha_do_new.filter(e => e.cha === localStorage.getItem('plant_code'));
            //     cha_do_modified = cha_do_modified.filter(e => e.cha === localStorage.getItem('plant_code'));
            // }
    
            setTabsCount(prev => ({
                ...prev,
                cha_do_new: cha_do_new,
                cha_do_modified: cha_do_modified
            }));
    
            chadata_new.push(...cha_do_new);
            chadata_modified.push(...cha_do_modified);
    
            console.log('cha_do_new:', cha_do_new); // Log DO data
            console.log('cha_do_modified:', cha_do_modified);
    
            var at_plant_new = resp.data.work_order_level_data_at_plant_new;
            var at_plant_modified = resp.data.work_order_level_data_at_plant_modified;
    
            // if (localStorage.getItem('plant_code') && localStorage.getItem('email') !== 'ch01@enmovil.in') {
            //     at_plant_new = at_plant_new.filter(e => e.cha === localStorage.getItem('plant_code'));
            //     at_plant_modified = at_plant_modified.filter(e => e.cha === localStorage.getItem('plant_code'));
            // }
    
            setTabsCount(prev => ({
                ...prev,
                cha_shipping_new: at_plant_new,
                cha_shipping_modified: at_plant_modified
            }));
    
            chadata_new.push(...at_plant_new);
            chadata_modified.push(...at_plant_modified);
    
            console.log('at_plant_new:', at_plant_new); // Log plant data
            console.log('at_plant_modified:', at_plant_modified);
    
            var container_report_new = resp.data.work_order_level_data_container_new;
            var container_report_modified = resp.data.work_order_level_data_container_modified;
    
            // if (localStorage.getItem('plant_code') && localStorage.getItem('email') !== 'ch01@enmovil.in') {
            //     container_report_new = container_report_new.filter(e => e.cha === localStorage.getItem('plant_code'));
            //     container_report_modified = container_report_modified.filter(e => e.cha === localStorage.getItem('plant_code'));
            // }
    
            setTabsCount(prev => ({
                ...prev,
                cha_reporting_new: container_report_new,
                cha_reporting_modified: container_report_modified
            }));
    
            chadata_new.push(...container_report_new);
            chadata_modified.push(...container_report_modified);
    
            console.log('container_report_new:', container_report_new); // Log container data
            console.log('container_report_modified:', container_report_modified);
    
            var leo_new = resp.data.work_order_level_data_leo_new;
            var leo_modified = resp.data.work_order_level_data_leo_modified;
    
            // if (localStorage.getItem('plant_code') && localStorage.getItem('email') !== 'ch01@enmovil.in') {
            //     leo_new = leo_new.filter(e => e.cha === localStorage.getItem('plant_code'));
            //     leo_modified = leo_modified.filter(e => e.cha === localStorage.getItem('plant_code'));
            // }
    
            setTabsCount(prev => ({
                ...prev,
                cha_leo_new: leo_new,
                cha_leo_modified: leo_modified
            }));
    
            chadata_new.push(...leo_new);
            chadata_modified.push(...leo_modified);
    
            console.log('leo_new:', leo_new); // Log LEO data
            console.log('leo_modified:', leo_modified);
    
            var gate_new = resp.data.work_order_level_data_port_gate_new;
            // var gate_modified = resp.data.work_order_level_data3;
    
            // if (localStorage.getItem('plant_code') && localStorage.getItem('email') !== 'ch01@enmovil.in') {
            //     gate_new = gate_new.filter(e => e.cha === localStorage.getItem('plant_code'));
            //     // gate_modified = gate_modified.filter(e => e.cha === localStorage.getItem('plant_code'));
            // }
    
            setTabsCount(prev => ({
                ...prev,
                cha_gate_new: gate_new,
                // cha_gate_modified: gate_modified
            }));
    
            chadata_new.push(...gate_new);
            // chadata_modified.push(...gate_modified);
    
            console.log('gate_new:', gate_new); // Log gate data
            // console.log('gate_modified:', gate_modified);
    
            var onboard_new = resp.data.work_order_level_data_shipping_new;
    
            // if (localStorage.getItem('plant_code') && localStorage.getItem('email') !== 'ch01@enmovil.in') {
            //     onboard_new = onboard_new.filter(e => e.cha === localStorage.getItem('plant_code'));
            // }
    
            setTabsCount(prev => ({
                ...prev,
                cha_onboard_new: onboard_new,
            }));
    
            chadata_new.push(...onboard_new);
    
            console.log('onboard_new:', onboard_new); // Log onboard data
    
            var bill_new = resp.data.work_order_level_data_bill_new;
    
            // if (localStorage.getItem('plant_code') && localStorage.getItem('email') !== 'ch01@enmovil.in') {
            //     bill_new = bill_new.filter(e => e.cha === localStorage.getItem('plant_code'));
            // }
    
            setTabsCount(prev => ({
                ...prev,
                bill_new: bill_new,
            }));
    
            chadata_new.push(...bill_new);
    
            console.log('bill_new:', bill_new); // Log bill data
    
            var buffer_data = resp.data.work_order_level_data_buffer;
            // buffer_data = buffer_data.filter(e => e.pendency_in_buffer > 0);
    
            // if (localStorage.getItem('plant_code') && localStorage.getItem('email') !== 'ch01@enmovil.in') {
            //     buffer_data = buffer_data.filter(e => e.cha === localStorage.getItem('plant_code'));
            // }
    
            chadata_new.push(...buffer_data);
    
            console.log('buffer_data:', buffer_data); // Log buffer data
    
            console.log('chadata_new:', chadata_new); // Log final chadata_new
            console.log('chadata_modified:', chadata_modified); // Log final chadata_modified
    
            setChaTabsCount({
                cha_new: chadata_new.length,
                cha_modified: chadata_modified.length,
            });
        } catch (e) {
            console.error('Error fetching data:', e);
        }
    }
    
    console.log(chaTabsCount, "chatabscountttt")
    const getchacount = async () => {
        var chadata_new = []
        var chadata_modified=[]
        var chadata=[]
        try {
            const resp = await redirectURL.post('/consignments/getvesseldata');
            var cha_vessel_new = resp.data.vessel_new 
            var cha_vessel_modified = resp.data.vessel_modified
            if(localStorage.getItem('plant_code')!='' && localStorage.getItem('plant_code')!=undefined&&localStorage.getItem('plant_code')!='undefined'&&localStorage.getItem('email')!='ch01@enmovil.in'){
                cha_vessel_new = cha_vessel_new.filter(e=>e.cha==localStorage.getItem('plant_code'))
                cha_vessel_modified = cha_vessel_modified.filter(e=>e.cha==localStorage.getItem('plant_code'))
            }
            setTabsCount(prev=>({
                ...prev,
                cha_vessel_new:cha_vessel_new,
                cha_vessel_modified:cha_vessel_modified
            }))
            
            chadata_new.push(...cha_vessel_new);
                chadata_modified.push(...cha_vessel_modified)
        } catch (error) {
            console.error("Error in the first try-catch block:", error);
        }
        try {
            const resp1 = await redirectURL.post('/consignments/getUploadDO');
            var upload_do_data = resp1.data.records
            var upload_do_modified = resp1.data.upload_do_modified
            var cha_do_new = upload_do_data.filter(e=>e.cha_do_new==1)
            var cha_do_modified = upload_do_data.filter(e=>e.cha_do_modified==1)
            if(localStorage.getItem('plant_code')!='' && localStorage.getItem('plant_code')!=undefined&&localStorage.getItem('plant_code')!='undefined'&&localStorage.getItem('email')!='ch01@enmovil.in'){
                cha_do_new = cha_do_new.filter(e=>e.cha==localStorage.getItem('plant_code'))
                cha_do_modified = cha_do_modified.filter(e=>e.cha==localStorage.getItem('plant_code'))
            }
            setTabsCount(prev=>({
                ...prev,
                cha_do_new:cha_do_new,
                cha_do_modified:cha_do_modified
            }))
            chadata_new.push(...cha_do_new);
                chadata_modified.push(...cha_do_modified)
        } catch (error) {
            console.error("Error in the first try-catch block:", error);
        }
        try {
            var resp3 = await redirectURL.post('/consignments/getAtPlantCHA');
            var at_plant_new = resp3.data.work_order_level_data1;
            var at_plant_modified = resp3.data.work_order_level_data3;
            if(localStorage.getItem('plant_code')!='' && localStorage.getItem('plant_code')!=undefined&&localStorage.getItem('plant_code')!='undefined'&&localStorage.getItem('email')!='ch01@enmovil.in'){
                at_plant_new = at_plant_new.filter(e=>e.cha==localStorage.getItem('plant_code'))
                at_plant_modified = at_plant_modified.filter(e=>e.cha==localStorage.getItem('plant_code'))
            }
            setTabsCount(prev=>({
                ...prev,
                cha_shipping_new:at_plant_new,
                cha_shipping_modified:at_plant_modified
            }))
            chadata_new.push(...at_plant_new);
                chadata_modified.push(...at_plant_modified)
            
        } catch (e) {
            console.error("Error in the second try-catch block:", e);
        }
        try {
            const resp2 = await redirectURL.post('/consignments/getContainersReportData');
            var container_report_new = resp2.data.work_order_level_data1;
            var container_report_modified = resp2.data.work_order_level_data3;
            if(localStorage.getItem('plant_code')!='' && localStorage.getItem('plant_code')!=undefined&&localStorage.getItem('plant_code')!='undefined'&&localStorage.getItem('email')!='ch01@enmovil.in'){
                container_report_new = container_report_new.filter(e=>e.cha==localStorage.getItem('plant_code'))
                container_report_modified = container_report_modified.filter(e=>e.cha==localStorage.getItem('plant_code'))
            }
            setTabsCount(prev=>({
                ...prev,
                cha_reporting_new:container_report_new,
                cha_reporting_modified:container_report_modified
            }))
            chadata_new.push(...container_report_new);
                chadata_modified.push(...container_report_modified)
        } catch (e) {
            console.error("Error in the second try-catch block:", e);
        }
        try {
            const resp6 = await redirectURL.post('/consignments/getLeoDateData');
            var leo_new = resp6.data.work_order_level_data1;
            var leo_modified = resp6.data.work_order_level_data3;
            if(localStorage.getItem('plant_code')!='' && localStorage.getItem('plant_code')!=undefined&&localStorage.getItem('plant_code')!='undefined'&&localStorage.getItem('email')!='ch01@enmovil.in'){
                leo_new = leo_new.filter(e=>e.cha==localStorage.getItem('plant_code'))
                leo_modified = leo_modified.filter(e=>e.cha==localStorage.getItem('plant_code'))
            }
            setTabsCount(prev=>({
                ...prev,
                cha_leo_new:leo_new,
                cha_leo_modified:leo_modified
            }))
            chadata_new.push(...leo_new);
                chadata_modified.push(...leo_modified)
        } catch (e) {
            console.error("Error in the second try-catch block:", e);
        }
        try {
            const resp7 = await redirectURL.post('/consignments/getGateInportscreendata');
            var gate_new = resp7.data.work_order_level_data1;
            var gate_modified = resp7.data.work_order_level_data3;
            if(localStorage.getItem('plant_code')!='' && localStorage.getItem('plant_code')!=undefined&&localStorage.getItem('plant_code')!='undefined'&&localStorage.getItem('email')!='ch01@enmovil.in'){
                gate_new = gate_new.filter(e=>e.cha==localStorage.getItem('plant_code'))
                gate_modified = gate_modified.filter(e=>e.cha==localStorage.getItem('plant_code'))
            }
            setTabsCount(prev=>({
                ...prev,
                cha_gate_new:gate_new,
                cha_gate_modified:gate_modified
            }))
            chadata_new.push(...gate_new);
                chadata_modified.push(...gate_modified)
        } catch (e) {
            console.error("Error in the second try-catch block:", e);
        }
        try {
            const resp9 = await redirectURL.post('/consignments/getshippingonboarddetails');
            var onboard_new = resp9.data.work_order_level_data1;
            if(localStorage.getItem('plant_code')!='' && localStorage.getItem('plant_code')!=undefined&&localStorage.getItem('plant_code')!='undefined'&&localStorage.getItem('email')!='ch01@enmovil.in'){
                onboard_new = onboard_new.filter(e=>e.cha==localStorage.getItem('plant_code'))
            }
            setTabsCount(prev=>({
                ...prev,
                cha_onboard_new:onboard_new,
            }))
            chadata_new.push(...onboard_new);
        } catch (e) {
            console.error("Error in the second try-catch block:", e);
        }
        try {
            const resp10 = await redirectURL.post('/consignments/getbillofladingdata');
            var bill_new = resp10.data.work_order_level_data1;
            if(localStorage.getItem('plant_code')!='' && localStorage.getItem('plant_code')!=undefined&&localStorage.getItem('plant_code')!='undefined'&&localStorage.getItem('email')!='ch01@enmovil.in'){
                bill_new = bill_new.filter(e=>e.cha==localStorage.getItem('plant_code'))
            }
            setTabsCount(prev=>({
                ...prev,
                bill_new:bill_new,
            }))
            chadata_new.push(...bill_new);
        } catch (e) {
            console.error("Error in the second try-catch block:", e);
        }
        try {
            const resp11 = await redirectURL.post('/consignments/getbufferforcha');
            var buffer_data = resp11.data.work_order_level_data 
            buffer_data=buffer_data.filter(e=>e.pendency_in_buffer>0)        
                if(localStorage.getItem('plant_code')!='' && localStorage.getItem('plant_code')!=undefined&&localStorage.getItem('plant_code')!='undefined'&&localStorage.getItem('email')!='ch01@enmovil.in'){
                    buffer_data = buffer_data.filter(e=>e.cha==localStorage.getItem('plant_code'))
            }
            chadata_new.push(...buffer_data);
        } catch (e) {
            console.error("Error in the second try-catch block:", e);
        }
        
        setChaTabsCount({
            cha_new:chadata_new.length,
            cha_modified:chadata_modified.length,
        })
        setloadshow('show-n');
    };
    
    var gridOptions = {
        headerHeight: 50
    }
    const onGridReady = (params) => {
        params.api.setHeaderHeight(gridOptions.headerHeight);
    };
    const onSubmitOrderBulkUpload = (event) => {
        event.preventDefault();
        setloadshow('show-m')
        if (file != '') {
            var rparams = {
                uploadFile: file,
                userId: localStorage.getItem('userid'),
                csvcontent: csvcontent,
                email: localStorage.getItem("email")
            }
            redirectURL.post('/consignments/uploadPlantData', rparams).then((resp) => {
                if (resp.data.status == 'Success') {
                    setshow(true)
                    setbasicTitle("Successfully Uploaded")
                    setbasicType("success")
                    setloadshow('show-n')
                    setoverly('show-n')
                    setsliderForBulkupload('')
                    document.getElementById("uploadfile").value = null
                    refreshLatestData()
                    setsubcounters('AllUpdates')
                }
                else{
                    if (resp.data.message == "invalid syntax (<string>, line 1)"){
                        setshow(true)
                        setbasicTitle("Upload the valid file")
                        setbasicType("danger")
                        setloadshow('show-n')
                        setoverly('show-n')
                    }else{
                        setshow(true)
                        setbasicTitle(resp.data.message)
                        setbasicType("danger")
                        setloadshow('show-n')
                        setoverly('show-n')
                    }
                   
                }
            })
        }
    }
    const onClickHideAll = () => {
        setsliderForBulkupload('')
        setopen(false)
        document.getElementById("uploadfile").value = null
        setoverly('show-n')
    }
    const onClickHide = () => {
        setloadshow('show-n')
    }
    const showSubcounters = (params) => {
        setReloadData(true);
        if (params == '1') {
            setcheckforbulkupload(1)
            setsubcounters('AllUpdates')
            setbgclr({
                alltabcounter: '#0664AE'
            })
            setIcon({
                alltabicon: 1
            })
        }
        else if (params == '3') {
            setsubcounters('plantUpdates')
            setbgclr({
                plantUpdates: '#0664AE'
            })
            setIcon({
                planticon: 1
            })
            setcheckforbulkupload(0)
        }
        else if (params == '4') {
            setsubcounters('CHAUpdates')
            setbgclr({
                chaUpdates: '#0664AE'
            })
            setIcon({
                CHAicon: 1
            })
            setcheckforbulkupload(0)
        }
        else if (params == '5') {
            setsubcounters('IBUpdates')
            setbgclr({
                IBUpdates: '#0664AE'
            })
            setIcon({
                ibicon: 1
            })
            setcheckforbulkupload(0)
        }
        else if (params == '6') {
            setsubcounters('TransUpdates')
            setbgclr({
                transportercounter: '#0664AE'
            })
            setIcon({
                transportericon: 1
            })
        }
        else if (params == '7') {
            setsubcounters('CompletedUpdates')
            setbgclr({
                completedcounter: '#0664AE'
            })
            setIcon({
                completedicon: 1
            })
        }
    }
    const onclickbulkupload = () => {
        setsliderForBulkupload("slider-translate")
        setopen(true)
        setoverly('show-m')
    }
    const onclickRefreshPage = () => {
        // refreshLatestData(); // Fetch data for TransUpdates 
        setReloadData(true);        
    }; 
    var changeOrderFileHandlerFcrBulk = async (e) => {
        setfile(e.target.files[0])
        var csvdata = await BulkForceFileHandler(e, [
            { name: 'Work Order No', inputName: 'work_order_no', required: true },
            { name: 'WO Created Date', inputName: 'wo_created_date', required: true },
            { name: 'WO Release Date', inputName: 'wo_release_date', required: true },
            { name: 'Plan Month', inputName: 'plan_month', required: true },
            { name: 'Ship to Country', inputName: 'ship_to_country', required: true },
            { name: 'Destination Country', inputName: 'destination_country', required: true },
            { name: 'POD', inputName: 'pod', required: true },
            { name: 'Plant', inputName: 'plant', required: true },
            { name: 'SKU Code', inputName: 'sku_code', required: true },
            { name: 'Brand', inputName: 'brand', required: true },
            { name: 'Brand Variant', inputName: 'brand_variant', required: true },
            { name: 'Quantity', inputName: 'quantity', required: true },
            { name: 'Assy Config Type', inputName: 'assy_config_type', required: true },
            { name: 'Order Type', inputName: 'order_type', required: true },
            { name: 'Quotation', inputName: 'quotation', required: true },
            { name: '2wh/3wh/4wh', inputName: '2wh/3wh/4wh', required: true },
            { name: '40ft Containers', inputName: '40ft_containers', required: true },
            { name: '20ft Containers', inputName: '20ft_containers', required: true },
            { name: 'Trucks', inputName: 'trucks', required: true },
            // { name: 'Production Date', inputName: 'production_date', required: true },
            { name: 'PO No', inputName: 'po_no', required: true },
            { name: 'Requested Delivery Date', inputName: 'requested_delivery_date', required: true },
            { name: 'Payment Term', inputName: 'payment_term', required: true },
            { name: 'LC Ref No', inputName: 'lc_ref_no', required: true },
            { name: 'Actual LC No', inputName: 'actual_lc_no', required: true },
            { name: 'LC expiry date', inputName: 'lc_expiry_date', required: true },
            { name: 'Latest shipment date', inputName: 'latest_shipment_date', required: true },
            { name: '3rd Party Inspection Req', inputName: '3rd_party_inspection_req', required: true },
            { name: 'CP Invoice Date', inputName: 'cp_invoice_date', required: true },
            { name: 'Delivery Block', inputName: 'delivery_block', required: true },
            { name: 'Payment Status', inputName: 'payment_status', required: true },
            { name: 'Expected Payment Clearence By', inputName: 'expected_payment_clearance_by', required: true },
            { name: 'Haz Classification', inputName: 'haz_classification', required: true },
            { name: 'Hardware Mode', inputName: 'hardware_mode', required: true },
            { name: 'Weight Status', inputName: 'weight_status', required: true },
            { name: 'Buyer', inputName: 'buyer', required: true },
            { name: 'Consignee', inputName: 'consignee', required: true },
            { name: 'CHA', inputName: 'cha', required: true },
            { name: 'Vin Type', inputName: 'vin_type', required: true },
            { name: 'Vin Type Description', inputName: 'vin_type_description', required: true },
            { name: 'Port Of Loading', inputName: 'port_of_loading', required: true },

        ])
        setcsvcontent(csvdata)
    }
    const showdata = (e) => {
        if (e == 'detailed view') {
            setdetailedviewcheck(1)
            setsummaryviewcheck(0)
        }
        else if (e == 'summary view') {
            setdetailedviewcheck(0)
            setsummaryviewcheck(1)
        }
    }
    var closeAlert = () => {
        setshow(false)
        setloadshow('show-n')
    }
    var refreshLatestData = (params) => {
        setloadshow('show-m')
        // gettransportercount()
        allData()
        getIBData()
        // getPlantData()
        // getibcount()
        // getchacount();
       getChacount()
    }
    var all_new = counters.plantCount_new + chaTabsCount.cha_new + ibTabsCount.ib_new + tptTabsCount.trans_new;
    // var all_new = alldata.length
    var all_modified = counters.plantCount_modified + chaTabsCount.cha_modified + ibTabsCount.ib_modified + tptTabsCount.trans_modified;
    const rowData = []
    return (
        <div class="container-fluid" style={{ padding: '10px' }}>
            <SweetAlert
                show={show}
                type={basicType}
                title={basicTitle}
                onConfirm={closeAlert}
            >
            </SweetAlert>
           
            <div className="d-flex flex-row justify-content-between ml-4 mt-2p">
                <div className="">
                    <ul className={"nav nav-tabs"}>
                        <li className={"nav-item"}>
                            <a
                                // className={`nav-link ${tabActive == "BL/Booking" ? "tab-active" : ""
                                //     }`}
                                className={`nav-link ${tabActive == "dashboards" ? "tab-active" : ""
                            }`}

                                aria-current="page"
                                // onClick={() => settabActive("BL/Booking")}
                                onClick={()=> settabActive('dashboards')}
                                style={{
                                    borderTop: "none",
                                    borderLeft: "none",
                                    borderRight: "none",
                                }}
                            >
                                Dashboard
                            </a>
                        </li>
                        <li className={"nav-item"}>
                            <a
                                className={`nav-link ${tabActive == "Container" ? "tab-active" : ""
                                    }`}
                                onClick={() => { settabActive("Container"); showSubcounters('1') }}
                                style={{
                                    borderTop: "none",
                                    borderLeft: "none",
                                    borderRight: "none",
                                }}
                            >
                                Work Flow
                            </a>
                        </li>
                    </ul>
                </div>
                <div className="form-group d-flex justify-content-end">
                    {(icon.alltabicon == 1 && tabActive != 'BL/Booking') ?
                        (<button type="button" className="btn-bulkupload mr-20p" style={{ fontSize: "0.8rem", }} onClick={() => onclickbulkupload()}>
                            <img src={require("../../assets/images/upload.png")} alt="Icon" className="mr-1" />
                            Bulk Upload</button>)
                        : ""}
                    {
                        (tabActive == "Container") ?
                            (<button type="button" className="btn-bulkupload mr-20p" onClick={() => onclickRefreshPage()} title="Refresh" style={{ fontSize: "0.8rem" }}>
                                <img src={refreshIcon} alt="Refresh Icon" />
                            </button>)
                            :
                            ""
                    }
                </div>
            </div>
            {tabActive == 'dashboards' ?
                // <Dashboard allcounter={1} count={countersdata} />
                <Dashboards/>

                :
                <div className="card mb-20p ml-15p mr-15p " style={{ padding: '25px', borderRadius: '18px', paddingTop:'10px'}}>
                    <span style={{textAlign:'end',color:'red',fontSize:'12px'}}>* New / Modified</span>
                    <div className="row">
                        <div className="col counter-aligh" style={{ boxShadow: "0 1px 11px 0 rgb(0 0 0 / 10%)", height: '75px', backgroundColor: bgclr.alltabcounter,cursor:"pointer" }} onClick={() => showSubcounters('1')}>
                            {/* <span className="arwUp " style={{ display: (subcounters == 'plantUpdates') ? "block" : "none" }}></span> */}
                            <img src={icon.alltabicon == 1 ? require("../../assets/icons/all_icon_whitee.png") : require("../../assets/icons/allicon.png")} alt="Icon" style={{ height: '25px', margin: '8px' }} />
                            <div className="align-txt1 col-10 txt-center" style={{ padding: '10px', color: subcounters == 'AllUpdates' ? '#FFFFFF' : '#0664AE'}}>
                                <h5 className="sptile-tracking" style={{ fontWeight: 'bold', fontSize: '13px', }} >
                                    All
                                </h5>
                                <div style={{ fontWeight: 'bold' }}>{all_new} / {all_modified}</div>
                            </div>
                        </div>
                        <div className="col counter-aligh" style={{ boxShadow: "0 1px 11px 0 rgb(0 0 0 / 10%)", height: '75px', backgroundColor: bgclr.plantUpdates,cursor:"pointer" }} onClick={() => showSubcounters('3')}>

                            <span className="arwUp " style={{ display: (subcounters == 'plantUpdates') ? "block" : "none" }}></span>
                            <img src={icon.planticon == 1 ? require("../../assets/images/plantwhiteicon.png") : require("../../assets/images/plantupdatesicon.png")} alt="Icon" style={{ height: '30px', margin: '8px' }} />
                            <div className="align-txt1 col-10 txt-center" style={{ padding: '10px', color: subcounters == 'plantUpdates' ? '#FFFFFF' : '#0664AE' }}>
                                <h5 className="sptile-tracking" style={{ fontWeight: 'bold', fontSize: '13px', }} >
                                    Plant <br />
                                </h5>
                                <div style={{ fontWeight: 'bold' }}>{(counters.plantCount_new!=''&&counters.plantCount_new!=undefined)?counters.plantCount_new:0} / {(counters.plantCount_modified!=''&&counters.plantCount_modified!=undefined)?counters.plantCount_modified:0}</div>
                            </div>

                        </div>
                        <div className="col counter-aligh" style={{ boxShadow: "0 1px 11px 0 rgb(0 0 0 / 10%)", height: '75px', backgroundColor: bgclr.chaUpdates, cursor:"pointer" }} onClick={() => showSubcounters('4')}>
                            <span className="arwUp " style={{ display: (subcounters == 'CHAUpdates') ? "block" : "none" }}></span>
                            <img src={icon.CHAicon == 1 ? require("../../assets/images/CHAwhiteicon.png") : require("../../assets/images/chaupdatesicon.png")} alt="Icon" style={{ height: '30px', margin: '8px' }} />
                            <div className="align-txt1 col-10 txt-center" style={{ padding: '8px', color: subcounters == 'CHAUpdates' ? '#FFFFFF' : '#0664AE' }}>
                                <h5 className="sptile-tracking" style={{ fontWeight: 'bold', fontSize: '13px' }} >
                                    CHA
                                </h5>
                                <div style={{ fontWeight: 'bold' }}>{chaTabsCount.cha_new} / {chaTabsCount.cha_modified}</div>
                            </div>
                        </div>
                        <div className="col counter-aligh" style={{ boxShadow: "0 1px 11px 0 rgb(0 0 0 / 10%)", height: '75px', backgroundColor: bgclr.IBUpdates,cursor:"pointer" }} onClick={() => showSubcounters('5')}>
                            <span className="arwUp " style={{ display: (subcounters == 'IBUpdates') ? "block" : "none" }}></span>
                            <img src={icon.ibicon == 1 ? require("../../assets/images/ibwhiteicon.png") : require("../../assets/images/ibteamupdatesicon.png")} alt="Icon" style={{ height: '30px', margin: '8px' }} />
                            <div className="align-txt1 col-10 txt-center" style={{ padding: '10px', color: subcounters == 'IBUpdates' ? '#FFFFFF' : '#0664AE' }}>
                                <h5 className="sptile-tracking" style={{ fontWeight: 'bold', fontSize: '13px' }} >
                                    IB Team
                                </h5>
                                <div style={{ fontWeight: 'bold' }}>{ibTabsCount.ib_new} / {ibTabsCount.ib_modified}</div>
                            </div>
                        </div>
                        {/* <div className="col counter-aligh" style={{ boxShadow: "0 1px 11px 0 rgb(0 0 0 / 10%)", height: '75px', backgroundColor: bgclr.transportercounter }} onClick={() => showSubcounters('6')}>
                            <span className="arwUp " style={{ display: (subcounters == 'IBUpdates') ? "block" : "none" }}></span>
                            <img src={icon.transportericon == 1 ? require("../../assets/images/transportericon.png") : require("../../assets/images/transporterclricon.png")} alt="Icon" style={{ height: '18px', margin: '5px' }} />
                            <div className="align-txt1 col-10 txt-center" style={{ padding: '10px', color: subcounters == 'TransUpdates' ? '#FFFFFF' : '#0664AE' }}>
                                <h5 className="sptile-tracking" style={{ fontWeight: 'bold', fontSize: '13px' }} >
                                    Transporter
                                </h5>
                                <div style={{ fontWeight: 'bold' }}>{counters.transporters}</div>
                            </div>

                        </div> */}
                        <div className="col counter-aligh" style={{ boxShadow: "0 1px 11px 0 rgb(0 0 0 / 10%)", height: '75px', backgroundColor: bgclr.transportercounter, cursor:"pointer" }} onClick={() => showSubcounters('6')}>
                            <span className="arwUp " style={{ display: (subcounters == 'TransUpdates') ? "block" : "none" }}></span>
                            <img src={icon.transportericon == 1 ? require("../../assets/images/transportericon.png") : require("../../assets/images/transporterclricon.png")} alt="Icon" style={{ height: '20px', margin: '4px' }} />
                            <div className="align-txt1 col-10 txt-center" style={{ padding: '10px', color: subcounters == 'TransUpdates' ? '#FFFFFF' : '#0664AE' }}>
                                <h5 className="sptile-tracking" style={{ fontWeight: 'bold', fontSize: '13px' }} >
                                    Transporter
                                </h5>
                                <div style={{ fontWeight: 'bold' }}>{tptTabsCount.trans_new} / {tptTabsCount.trans_modified}</div>
                            </div>

                        </div>
                        <div className="col counter-aligh" style={{ boxShadow: "0 1px 11px 0 rgb(0 0 0 / 10%)", height: '75px', backgroundColor: bgclr.completedcounter, cursor:"pointer" }} onClick={() => showSubcounters('7')}>
                            <img src={icon.completedicon==1?require("../../assets/images/all-white.png"):require("../../assets/images/all.png")} alt="Icon" style={{ height: '26px', margin: '8px' }} />
                            <div className="col-10 txt-center" style={{ padding: '10px', color: subcounters == 'CompletedUpdates' ? '#FFFFFF' : '#0664AE' }}>
                                <h5 className="sptile-tracking" style={{ fontWeight: 'bold', fontSize: '13px' }} >
                                    Completed
                                </h5>
                                <div style={{ fontWeight: 'bold' }}>{completedCount}</div>
                            </div>

                        </div>
                        <div className="container-fluid">
                            {subcounters == 'AllUpdates' ? <AllComponent allcounter={1} data={alldata} count={countersdata} /> : ''}
                            {subcounters == 'plantUpdates' ? <PlantUpdatesComponent data={plantData1} allcounter={1} refresNow={refreshLatestData}/> : ""}
                            {subcounters == 'CHAUpdates' ? <CHAUpdatesComponent data={alldata} allcounter={1}  refresNow={refreshLatestData} /> : ""}
                            {subcounters == 'IBUpdates' ? <IBUpdatesComponent allcounter={1} ibcount={ibcount} data={ibdata} allIbData={data.ibData}  refresNow={refreshLatestData} /> : ''}
                            {subcounters == 'TransUpdates' ? <TransporterComponent data={transdata} allcounter={1} transcount={transcount} allTransData={data.transporterData} refresNow={refreshLatestData} /> : ''}
                            {subcounters == 'CompletedUpdates' ? <CompletedComponent allcounter={1} data={tabForCompleted} /> : ''}
                        </div>
                    </div>
                </div>}
                {sliderForBulkupload == 'slider-translate' &&
                <Modal open={open} onClose={onClickHideAll} classNames={{ modal: 'custom-modal' }} >
                   <div className="slide-r-title slider_title_style">
                    <h6>Upload Workorders</h6>
                </div>
                <div className="slide-r-body" style={{ position: "relative" }}>
                    <div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div>
                    <div className={"row"} style={{ padding: '5px 0px', width: '100%', zIndex: '9999', }} >

                        <div className="theme-form col-xl-12 col-lg-12">

                            <form action="" onSubmit={onSubmitOrderBulkUpload}>
                                <div className="col-xl-12 col-lg-12 row form-group">
                                    <label className="col-xl-12 col-lg-12">Upload File *</label>
                                    <input
                                        type="file"
                                        name="uploadfile"
                                        id="uploadfile"
                                        className="form-control"
                                        onChange={changeOrderFileHandlerFcrBulk}
                                        required
                                    />
                                </div>

                                <div className="col-xl-12 col-lg-12 form-group">
                                    <button type="submit" className="btn btn-success">Submit</button>
                                </div>
                            </form>
                            <div className="col-xl-12 col-lg-12 form-group">
                                <a href={require("../../assets/json/plant_based_data.csv")} target="_blank" className="btn btn-warning">Download Template</a>
                            </div>
                        </div>
                    </div>
                </div>
                </Modal>}
            <div className={"dataLoadpage " + (loadshow)}>
            </div>
            <div className={"dataLoadpageimg " + (loadshow)}>
                <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
            </div>
            <div className={"overlay-part " + (overly)} onClick={onClickHide}></div>


        </div>
    )
}
function sortByStatus(arr) {
    return arr.slice().sort((firstItem, secondItem) => firstItem.status - secondItem.status);
}
export default SummaryDashboard