import React, { useState, useEffect, useRef } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';

import Select from "react-select";
import Datepicker from "../layouts/datepicker";
import moment from "moment";
import { getHyphenYYYYMMDDHHMMSS, getDDMMMYYYY, getHyphenYYYYMMDD, getHyphenDDMMYYYY } from '../common/utils';
import SearchIcon from "../../assets/icons/search-icon.svg";
import RefreshIcon from "../../assets/images/refresh-icon.svg";
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import 'ag-grid-enterprise';
import 'ag-grid-enterprise/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-enterprise/styles/ag-theme-balham.css';
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import CountryFlagComponent from '../layouts/countryFlag';
import DestinationFlagComponent from '../layouts/destinationCountryFlag';
import ViewDOdetailsComponent from "../layouts/viewDOdetails";
import customNextIcon from "../../assets/icons/next.png";
import customPreviousIcon from "../../assets/icons/back.png";
import customFirstIcon from "../../assets/icons/back_left.png";
import customLastIcon from "../../assets/icons/next_right.png";
import redirectURL from "../redirectURL";
const TptOnTimePerf = () => {
    const gridApi = useRef(null);
    const gridColumnApi = useRef(null);

    
    var [rowData, setrowData] = useState([])
    var [originalData, setoriginalData] = useState([])
    var [show, setshow] = useState(false)
    var [reload, setreload] = useState(false)
    var [basicTitle, setbasicTitle] = useState('')
    var [basicType, setbasicType] = useState('')
    var [overly, setoverly] = useState('show-n')
    var [loadshow, setloadshow] = useState('show-n')
   
    useEffect(() => {
		if (gridApi.current) {
		    getUserGridState();
		}
	}, [gridApi]);
    useEffect(() => {
        redirectURL.post('/consignments/getshippingplanedata').then((resp) => {
            try{
                var records =resp.data.records;
               
                setrowData()
                setoriginalData()
            }
            catch(e){}
        })
    }, [])
    const onGridReady = params => {
        gridApi.current = params;
        // gridColumnApi.current = params.columnApi;
        getUserGridState();
    };
   
    const getUserGridState = () => {
        redirectURL.post("/consignments/usergridstates", {screenurl: window.location.pathname })
            .then(async (resp) => {
                console.log("resp.data0999", resp.data)
                restoreGridStates(resp.data);
            })
    }
    const restoreGridStates = (griddata) => {
		// console.log("11111",usergridstate,"griddata",griddata[0].gridcolumns)
		
		try{
		if (griddata.length > 0) {
			console.log('hit here loading',griddata.length)
			var windowstates = griddata;
			console.log("windowstates.gridcolumns", windowstates[0].gridcolumns)
			//  gridApi.current.columnApi.setColumnState(windowstates[0].gridcolumns);
			 gridApi.current.columnApi.setColumnState(griddata[0].gridcolumns);
			// console.log("windowstates[0].gridgroup", windowstates[0].gridgroup)
			 gridApi.current.columnApi.setColumnGroupState(windowstates[0].gridgroup);

			// console.log("windowstates[0].gridcolsort", windowstates[0].gridcolsort)
			gridApi.current.api.setSortModel(windowstates[0].gridcolsort);

			// console.log("windowstates[0].gridcolfilter", windowstates[0].gridcolfilter)
			gridApi.current.api.setFilterModel(windowstates[0].gridcolfilter);
		}
	}
		catch (e) { }
	
	}
    const onGridState = () => {
		//console.log(gridApi);

		/*Get  Current Columns State and Store in this.colState */
		const colState = gridApi.current.columnApi.getColumnState();

		/*Get Current RowGroup Columns State and Store in this.rowGroupState */
		const rowGroupState =  gridApi.current.columnApi.getRowGroupColumns();

		/*Get Current Pivot Columns State and Store in this.pivotColumns, 
		 * User should enable the Pivot mode.*/
		const pivotColumns =  gridApi.current.columnApi.getPivotColumns();

		/*Get Current Filter State and Store in window.filterState */

		window.filterState = gridApi.current.api.getFilterModel();
	    gridApi.current.api.setFilterModel(window.filterState);
        getUserGridState();


	}
    
    
    var onClickSaveGridState = () => {
		try {
            // console.log("SaveGrid", gridApi.current.getSortModel())
			window.colState =  gridApi.current.columnApi.getColumnState();
			window.groupState =  gridApi.current.columnApi.getColumnGroupState();
			// window.sortState = gridApi.current.columnApi.getSortModel();
			// window.filterState = gridApi.current.api.getFilterModel();
			var screenpage = '/shipmentplanningreport'
			let reqparams = {
				gridcolumns: window.colState,
				gridgroup: window.groupState,
				gridcolsort: window.sortState,
				gridcolfilter: window.filterState,
				userId: localStorage.getItem("userid"),
				screenurl: window.location.pathname,
				screentitle: screenpage
			}
			console.log("reqparams after save grid", reqparams);
			redirectURL.post("/consignments/saveGridStates", reqparams)
				.then((response) => {
					console.log("response forr",response)
					// if(response.data.status === "200"){
						setshow(true)
						setbasicTitle("Successfully saved grid layout")
						setbasicType("success")
						// setState1({
						// 	screenurl: window.location.pathname,
						// 	screentitle: screenpage
						// })
						setTimeout(()=>{
							getUserGridState();
						},2000)
						// getUserGridState();
					// }
				})
				.catch(function (e) {
					console.log("Error ", e)
				})
				console.log('hit')
		}
		catch (e) {
			console.log(e)
		}
	}
    
    // const onClickSaveGridState = () => {
    //     try {
    //         if (gridApi.current) {
    //             const columnState = gridColumnApi.current.getColumnState();
    //             const columnGroupState = gridColumnApi.current.getColumnGroupState();
    //             const sortState = gridApi.current.api.getSortModel(); // Accessing getSortModel from gridApi.current.api
    //             const filterState = gridApi.current.api.getFilterModel();
    
    //             var screenpage = '/shipmentplanningreport';
    //             let reqparams = {
    //                 gridcolumns: columnState,
    //                 gridgroup: columnGroupState,
    //                 gridcolsort: sortState,
    //                 gridcolfilter: filterState,
    //                 userId: localStorage.getItem("userid"),
    //                 screenurl: window.location.pathname,
    //                 screentitle: screenpage
    //             };
    
    //             redirectURL.post("/consignments/saveGridStates", reqparams)
    //                 .then((response) => {
    //                     setshow(true);
    //                     setbasicTitle("Successfully saved grid layout");
    //                     setbasicType("success");
    //                     setTimeout(() => {
    //                         getUserGridState();
    //                     }, 2000);
    //                 })
    //                 .catch(function (e) {
    //                     console.log("Error ", e);
    //                 });
    //         } else {
    //             console.error('Grid API is .');
    //         }
    //     } catch (e) {
    //         console.log(e);
    //     }
    // };
    
  
    const columnDefs = [
        {
            headerName: "",
            field: "",
            width: 100,
            filter: true,
            resizable: true,
        },
        {
            headerName: "",
            field: "",
            width: 200,
            filter: true,
            resizable: true,
        },
        {
            headerName: "PY",
            field: "py",
            width: 120,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Apr",
            field: "apr",
            width: 120,
            filter: true,
            resizable: true,

        },
        {
            headerName: "May",
            field: "may",
            width: 120,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Jun",
            field: "jun",
            width: 120,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Jul",
            field: "jul",
            width: 120,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Aug",
            field: "aug",
            width: 120,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Sep",
            field: "sep",
            width: 120,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Oct",
            field: "oct",
            width: 120,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Nov",
            field: "nov",
            width: 120,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Dec",
            field: "dec",
            width: 120,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Jan",
            field: "jan",
            width: 120,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Feb",
            field: "feb",
            width: 120,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Mar",
            field: "mar",
            width: 120,
            filter: true,
            resizable: true,
        },


    ]

    var closeAlert = () => {
        setshow(false)
        setloadshow('show-n')
    }

    return (
        <>
            <SweetAlert
                show={show}
                type={basicType}
                title={basicTitle}
                onConfirm={closeAlert}
            >
            </SweetAlert>
            <div className="form-group d-flex justify-content-between">
                <h1 className="heading ml-40p mt-30p">
                    <i className="icofont icofont-vehicle-delivery-van cus-i"></i> Tpt On Time Performance
                </h1>
              

                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    {/* <div className='btn_hover' style={{ height: "35px", marginTop: "24px",marginRight:"15px", fontSize: "12px", textAlign: "center", paddingTop: "8px", padding:"5px" }} onClick={onClickSaveGridState}>
                        <i className="icofont icofont-save" style={{ marginRight: '5px' }}></i>Save Grid Layout
                    </div> */}
                     <button
                                        type="button"
                                        title="Save Grid Layout"
                                        name="savegrid"
                                        className="btn btn-danger action_btn"
                                        style={{height: "32px", marginTop: "30px", paddingBottom:"30px" }}

                                        onClick={onClickSaveGridState}>
                                        <i className="f12 icofont icofont-save"></i>
                                        </button>
            </div>
            </div>

            <div className="ml-30p mr-30p" style={{ margin: '10px', marginTop: '20px' }}>
                <div id="idgrid" style={{ width: "100%", height: "530px" }} className="ag-theme-balham ml-30p">
                    <AgGridReact
                        ref={gridApi}
                        // modules={AllModules}
                        columnDefs={columnDefs}
                        defaultColDef={{
                            sortable: true,
                            filter: true,
                            editable: false,
                            resizable: true,
                            menuTabs: ['filterMenuTab']
                        }}
                        rowData={rowData}
                        suppressRowClickSelection={true}
                        enableCellChangeFlash={true}
                        suppressCellFlash={true}
                        enableRangeSelection={true}
                        paginationPageSize={100}
                        onGridReady={onGridReady}
                        onGridState={onGridState}
                        rowSelection={'multiple'}
                        masterDetail={true}
                        pagination={true}
                        sideBar={{
                            toolPanels: [
                                {
                                    id: "columns",
                                    labelDefault: "Columns",
                                    labelKey: "columns",
                                    iconKey: "columns",
                                    toolPanel: "agColumnsToolPanel"
                                },
                                {
                                    id: "filters",
                                    labelDefault: "Filters",
                                    labelKey: "filters",
                                    iconKey: "filter",
                                    toolPanel: "agFiltersToolPanel"
                                }
                            ]
                        }}
                        statusBar={{
                            statusPanels: [
                                { statusPanel: "agFilteredRowCountComponent" },
                                { statusPanel: "agSelectedRowCountComponent" },
                                { statusPanel: "agAggregationComponent" }
                            ]
                        }}
                        gridOptions={{
                            icons: {
                                next: `<img src="${customNextIcon}" alt="Next" />`,
                                previous: `<img src="${customPreviousIcon}" alt="Previous" />`,
                                first: `<img src="${customFirstIcon}" alt="First" />`,
                                last: `<img src="${customLastIcon}" alt="Last" />`,
                            },
                        }}
                    />

                </div>
            </div>
        </>

    )
}


export default TptOnTimePerf;