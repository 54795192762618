import React, { useState, useEffect, useRef, useReducer } from "react"
import SweetAlert from 'react-bootstrap-sweetalert';

import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import 'ag-grid-enterprise';
import 'ag-grid-enterprise/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-enterprise/styles/ag-theme-balham.css';
import 'react-datetime/css/react-datetime.css';
import SearchIcon from "../../assets/icons/search-icon.svg";
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import Datetime from "react-datetime";

import Datepicker from "../layouts/datepicker";
import SubmitRevisedBtn from "./submitrevisedbtn";
import customNextIcon from "../../assets/icons/next.png";
import customPreviousIcon from "../../assets/icons/back.png";
import customFirstIcon from "../../assets/icons/back_left.png";
import customLastIcon from "../../assets/icons/next_right.png";
import DestinationFlagComponent from "./destinationCountryFlag";
import CountryFlagComponent from "./countryFlag";
import AcknowledgeBtnComponent from "./acknowledgeBtn";
import UpdateAcknowledgeBtnComponent from "./update&aknowledgeBtn";

import { getHyphenYYYYMMDDHHMMSS, getDDMMMYYYY, getHyphenYYYYMMDD, getHyphenDDMMYYYY } from '../common/utils';
import PTData from '../../assets/json/payment_term_description.json'
import moment from "moment";
import Select from "react-select";
import redirectURL from "../redirectURL";
import { RowNode } from "@ag-grid-community/all-modules";
import RefreshIcon from "../../assets/images/refresh-icon.svg";


const Modifiedcomponent = (props) => {
    var [gridApi, setGridApi] = useState(null);
    var [gridColumnApi, setgridColumnApi] = useState(null)
    var gridRef = useRef();
    var [checkinput, setcheckinput] = useState(0)
    var [rowData, setrowData] = useState(props.data)

    var [checkForDateFilter, setcheckForDateFilter] = useState(0)
    var [summaryviewcheck, setsummaryviewcheck] = useState(1)
    var [detailedviewcheck, setdetailedviewcheck] = useState(0)
    var [selectedval, setselectedval] = useState({
        dropdownval: '',
        inputval: ''
    })
    var [selected_row, setselected_row] = useState({});
    const [showAddForm1, setshowAddForm1] = useState("");
    const [reloadData, setReloadData] = useState(true);

    var [show, setshow] = useState(false)
    var [reload, setreload] = useState(false)
    var [basicTitle, setbasicTitle] = useState('')
    var [basicType, setbasicType] = useState('')
    var [originalData, setoriginalData] = useState(props.data)
    var [loadshow, setloadshow] = useState('show-n')
    var [overly, setoverly] = useState('show-n')
    var [ptd, setptd] = useState(PTData.PTData ?? [])
    var typeofselection = [
        { label: 'Ship to Country', value: 'ship_to_country' },
        { label: 'POD', value: 'pod' },
        { label: 'SKU', value: 'sku_code' },
        { label: 'Brand', value: 'brand' },
       { label: 'WO Release Date', value: 'wo_release_date' },
       { label: 'Work Order No', value: 'work_order_no' },
    ]
    var [checkresetfilter,setcheckresetfilter] = useState(0)
    var [refreshSelected, setRefreshSelected ] = useState(0);
    const [manualUploadDetails, setmanualUploadDetails] = useState({
        wrno: "",
        production_date:""
      });
    useEffect(() => {
        setrowData(props.data)
        setoriginalData(props.data)
    },[props.data])
    const showdata = (e) => {
        if (e == 'detailed view') {
            setdetailedviewcheck(1)
            setsummaryviewcheck(0)
        }
        else if (e == 'summary view') {
            setdetailedviewcheck(0)
            setsummaryviewcheck(1)
        }
    }
    const handleFilterOptions = (e) => {
        if (e.value == 'wo_release_date') {
            setcheckForDateFilter(1)
            setcheckinput(0)
        }
        else {
            setcheckinput(1)
            setcheckForDateFilter(0)
        }
        setselectedval(prev => ({
            ...prev,
            dropdownval: e
        }))
    }
    const handleInputfilter = (e) => {
        const val = e.target.value.trim().toUpperCase(); // Trim and convert input value to uppercase
        const typeoffield = selectedval.dropdownval.value;
        let filteredData;

        if (typeoffield === 'work_order_no') {
            if (val !== '') {
                filteredData = originalData.filter((each) => {
                    return each.work_order_no.includes(val);
                });
            } else {
                filteredData = originalData;
            }
        } else {
            // Handle other field types if needed
        }

        setselectedval(prev => ({
            ...prev,
            inputval: e.target.value
        }))
        setrowData(filteredData);

    }
    const onClickAcknowledgeBtn=(rowdata)=>{
        var row = rowdata.data 
        var params = {work_order_no:row.work_order_no,row:row}
        redirectURL.post('/consignments/acknowledgePlantData',params).then((resp)=>{
            if(resp.data.status=='Success'){
                setshow(true)
                    setbasicTitle("Acknowledgement successful")
                    setbasicType("success")
                    props.onRequest()
                    props.onReloadData(true);
            }
        })
    }
    const onClickRevisedProductionData = (rowdata) => {
        var row = rowdata.data
        var revised_production_date = row.revised_production_date
        revised_production_date=moment.parseZone(revised_production_date).format('DD-MM-YYYY')
        var data=[{'revised_production_date': revised_production_date,'work_order_no': row.work_order_no}]
        var params = {'revised_production_date': revised_production_date,'work_order_no': row.work_order_no,'modified_plant':1}

        if(revised_production_date!='' && revised_production_date!=undefined){
            redirectURL.post('/consignments/uploadBulkuploadforRevisedProductiondates',params).then((resp)=>{
                if(resp.data.status=='Success'){
                    setshow(true)
                    setbasicTitle("Successfully Updated Revised Production Date")
                    setbasicType("success")
                    props.onRequest()
                }
                else{
                    setshow(true)
                    setbasicTitle(resp.data.message)
                    setbasicType("danger")
                    
                }
                props.onReloadData(true);
                //console.log(resp.data)
               })
        }
        else{
            setshow(true)
            setbasicTitle('Please Enter Revised Production Date')
            setbasicType("danger")
        }
    }
    const filterData = () => {
        var typeoffield = selectedval.dropdownval.value
        var val = selectedval.inputval
        var filteredData
        if (typeoffield == 'ship_to_country') {
            filteredData = originalData.filter(item => item.ship_to_country.toLowerCase() == val.toLowerCase());
        }

        else if (typeoffield == 'work_order_no') {
            if (val != '' && val != undefined) {
                filteredData = originalData.filter(item => item.work_order_no == val);
            }
            else {
                filteredData = originalData
            }
        }
        else if (typeoffield == '') {
            if (val != '' && val != undefined) {
                filteredData = originalData.filter(item => item.work_order_no == val);
            }
            else {
                filteredData = originalData
            }
        }
        else if (typeoffield == 'pod') {
            filteredData = originalData.filter(item => item.pod.toLowerCase() == val.toLowerCase());
        }
        else if (typeoffield == 'sku_code') {
            filteredData = originalData.filter(item => item.sku_code.toLowerCase() == val.toLowerCase());
        }
        else if (typeoffield == 'brand') {
            //console.log('96')
            filteredData = originalData.filter(item => item.brand.toLowerCase() == val.toLowerCase());
        }
        else if (typeoffield == 'variant') {
            filteredData = originalData.filter(item => item.variant.toLowerCase() == val.toLowerCase());
        }
        //console.log(filteredData)
        setrowData(filteredData)
        setcheckresetfilter(1);


    }
    var closeAlert = () => {
        setshow(false)
        setloadshow('show-n')
    }
    var summaryViewcols = [
        {
            headerName: "",
            field: "",
            width: 120,
            filter: true, resizable: true,
            pinned: 'left',
            // hide:hideformodified,
            // cellRenderer: 'SubmitBtn'
            cellRendererFramework: AcknowledgeBtnComponent
        },
        {
            headerName: "",
            field: "",
            width: 120,
            filter: true, resizable: true,
            pinned: 'left',
            // hide:hideformodified,
            // cellRenderer: 'SubmitBtn'
            cellRendererFramework:  UpdateAcknowledgeBtnComponent
        },
        {
            headerName: "Work Order No",
            field: "work_order_no",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "WO Release Date",
            field: "wo_release_date",
            width: 160,
            filter: true,
            resizable: true,
            valueGetter: function (params) {
                try {
                    if (params.data.wo_release_date != "" && params.data.wo_release_date != undefined) {
                        return getHyphenDDMMYYYY(params.data.wo_release_date);
                    }
                    else {
                        return "Not Available";
                    }
                }
                catch (e) { }
            },
        },
        {
            headerName: "Stuffing Date",
            field: "stuffing_date",
            width: 160,
            filter: true,
            resizable: true,
            valueGetter: function (params) {
                try {
                    if (params.data.stuffing_date != "" && params.data.stuffing_date != undefined) {
                        return getHyphenDDMMYYYY(params.data.stuffing_date);
                    }
                    else {
                        return "Not Available";
                    }
                }
                catch (e) { }
            },
        },
        {
            headerName: "Plan Month",
            field: "plan_month",
            width: 160,
            filter: true,
            resizable: true,
        },
        // {
        //     headerName: "Status",
        //     field: "status",
        //     width: 160,
        //     filter: true,
        //     resizable: true,
        //     valueGetter: function (params) {
        //         if (params.data.status == 1) {
        //             return 'New'
        //         }
        //         else if (params.data.status == 2) {
        //             return 'Planned'
        //         }
        //         else if (params.data.status == 3) {
        //             return 'Modified'
        //         }
        //     }
        // },
        {
            headerName: "Ship to Country",
            field: "ship_to_country",
            width: 160,
            filter: true,
            resizable: true,
            // cellRenderer: 'CountryFlagComponent'

        },
        {
            headerName: "SKU Code",
            field: "sku_code",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Brand",
            field: "brand",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Brand Variant",
            field: "brand_variant",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Quantity",
            field: "quantity",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Assy Config Type",
            field: "assy_config_type",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Quotation",
            field: "quotation",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "40ft Containers",
            field: "ft40_containers",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "20ft Containers",
            field: "ft20_containers",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "POD",
            field: "pod",
            width: 200,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Trucks",
            field: "truck_and_courier",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Latest Shipment Date",
            field: "latest_shipment_date",
            width: 160,
            filter: true,
            resizable: true,
            valueGetter: function (params) {
                try {
                    if (params.data.latest_shipment_date != "" && params.data.latest_shipment_date != undefined) {
                        return getHyphenDDMMYYYY(params.data.latest_shipment_date);
                    }
                    else {
                        return "Not Available";
                    }
                }
                catch (e) { }
            },
        },
      
        {
            headerName: "Hardware Mode",
            field: "hardware_mode",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "CHA Name",
            field: "cha_name",
            width: 120,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Plant Name",
            field: "plant_name",
            width: 120,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Transporter Name",
            field: "tpt_name",
            width: 200,
            filter: true,
            resizable: true,
        },

        {
            headerName: "Production Date",
            field: "production_date",
            width: 150,
            filter: true,
            resizable: true,
            valueGetter: function (params) {
                try {
                    if (params.data.production_date != "" && params.data.production_date != undefined) {
                        return getHyphenDDMMYYYY(params.data.production_date);
                    } else {
                        return "Not Available";
                    }
                } catch (e) {
                    // Handle the exception if needed
                }
            },        
        },
        // Request from QA , Revised Production Date and Submit Button Should not be visible in the Plant Modified 
    //     {
    //         headerName: "Revised Production Date",
    //         field: "revised_production_date",
    //         width: 170,
    //         filter: true,
    //         resizable: true,
    //         editable: function(params){
    //             return params.data.tpt_container_planned!=1 &&( !params.data.booking_no || !params.data.confirmcheck==1 && !params.data.plant_gate_out==1)  // Changes to Exception - 8 
    //         },
    //         //  cellEditor: params => params.data.planned_plant != 1 || params.data.modified_plant!=1 ? DateEditor : undefined,
    //         cellEditor:DateEditor,
    //         valueGetter: function (params) {
    //             try {
    //              if (params.data.revised_production_date != "" && params.data.revised_production_date != undefined) {
    //                      return getHyphenDDMMYYYY(params.data.revised_production_date);
    //                 } else {
    //                     return "Not Available";
    //              }
    //              } catch (e) {
    //                  // Handle the exception if needed
    //              }
    //          },
    //          comparator: dateComparator1,
    //      },
    //    {
    //         headerName: "",
    //         field: "",
    //         width: 100,
    //         filter: true, resizable: true,
    //         // hide:hideformodified,
    //         // cellRenderer: 'SubmitBtn'
    //         cellRendererFramework: SubmitRevisedBtn
    //     },
    ]

    var detailedviewcols = [
        {
            headerName: "Work Order No",
            field: "work_order_no",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "WO Created Date",
            field: "wo_created_date",
            width: 160,
            filter: true,
            resizable: true,
            valueGetter: function (params) {
                try {
                    if (params.data.wo_created_date != "" && params.data.wo_created_date != undefined) {
                        return getHyphenDDMMYYYY(params.data.wo_created_date);
                    }
                    else {
                        return "Not Available";
                    }
                }
                catch (e) { }
            },
        },
        {
            headerName: "WO Release Date",
            field: "wo_release_date",
            width: 160,
            filter: true,
            resizable: true,
            valueGetter: function (params) {
                try {
                    if (params.data.wo_release_date != "" && params.data.wo_release_date != undefined) {
                        return getHyphenDDMMYYYY(params.data.wo_release_date);
                    }
                    else {
                        return "Not Available";
                    }
                }
                catch (e) { }
            },
        },
        {
            headerName: "Plan Month",
            field: "plan_month",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Ship to Country",
            field: "ship_to_country",
            width: 160,
            filter: true,
            resizable: true,
            // cellRenderer: 'CountryFlagComponent'
        },
        {
            headerName: "Destination Country",
            field: "destination_country",
            width: 160,
            filter: true,
            resizable: true,
            // cellRenderer: 'DestinationFlagComponent'
        },
        {
            headerName: "POD",
            field: "pod",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Plant",
            field: "plant",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "SKU Code",
            field: "sku_code",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Brand",
            field: "brand",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Brand Variant",
            field: "brand_variant",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Quantity",
            field: "quantity",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Assy Config Type",
            field: "assy_config_type",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Order Type",
            field: "order_type",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Quotation",
            field: "quotation",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "2wh/3wh/4wh",
            field: "wh2_wh3_wh4",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "40ft Containers",
            field: "ft40_containers",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "20ft Containers",
            field: "ft20_containers",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Trucks",
            field: "truck_and_courier",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "PO NO",
            field: "po_no",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Requested Delivery Date",
            field: "requested_delivery_date",
            width: 190,
            filter: true,
            resizable: true,
            valueGetter: function (params) {
                try {
                    if (params.data.requested_delivery_date != "" && params.data.requested_delivery_date != undefined) {
                        return getHyphenDDMMYYYY(params.data.requested_delivery_date);
                    }
                    else {
                        return "Not Available";
                    }
                }
                catch (e) { }
            },
        },
        {
            headerName: "Payment Term",
            field: "payment_term",
            valueGetter: function (params) {
                try {
                    //console.log(ptd, "ptd");
                    //console.log(params.data.payment_term, "params.data.payment_term");
                    if (params.data.payment_term != "" && params.data.payment_term != undefined) {
                        let temp = ptd.find(e => e.payment_term == params.data.payment_term)
                        if (temp)
                            return temp.payment_term + " - " + temp.description
                        else
                            return params.data.payment_term
                    }
                    else {
                        return "Not Available";
                    }
                }
                catch (e) { }
            },
            width: 350,
            filter: true,
            resizable: true,
        },
        {
            headerName: "LC Ref No",
            field: "lc_ref_no",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Actual LC No",
            field: "actual_lc_no",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "LC Expiry Date",
            field: "lc_expiry_date",
            width: 160,
            filter: true,
            resizable: true,
            valueGetter: function (params) {
                try {
                    if (params.data.lc_expiry_date != "" && params.data.lc_expiry_date != undefined) {
                        return getHyphenDDMMYYYY(params.data.lc_expiry_date);
                    }
                    else {
                        return "Not Available";
                    }
                }
                catch (e) { }
            },
        },
        {
            headerName: "3rd Party Inspection Req",
            field: "third_party_inspection_req",
            width: 190,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Port Of Loading",
            field: "port_of_loading",
            width: 190,
            filter: true,
            resizable: true,
        },
        {
            headerName: "VIN Type",
            field: "vin_type_description",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Latest Shipment Date",
            field: "latest_shipment_date",
            width: 170,
            filter: true,
            resizable: true,
            valueGetter: function (params) {
                try {
                    if (params.data.latest_shipment_date != "" && params.data.latest_shipment_date != undefined) {
                        return getHyphenDDMMYYYY(params.data.latest_shipment_date);
                    }
                    else {
                        return "Not Available";
                    }
                }
                catch (e) { }
            },
        },
        // {
        //     headerName: "3rd Party Inspection Req",
        //     field: "third_party_inspection_req",
        //     width: 190,
        //     filter: true,
        //     resizable: true,
        // },
        {
            headerName: "CP Invoice Date",
            field: "cp_invoice_date",
            width: 160,
            filter: true,
            resizable: true,
            valueGetter: function (params) {
                try {
                    if (params.data.cp_invoice_date != "" && params.data.cp_invoice_date != undefined) {
                        return getHyphenDDMMYYYY(params.data.cp_invoice_date);
                    }
                    else {
                        return "Not Available";
                    }
                }
                catch (e) { }
            },
        },
        {
            headerName: "Delivery Block",
            field: "delivery_block",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Payment Status",
            field: "payment_status",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Expected Payment Clearance By",
            field: "expected_payment_clearance_by",
            width: 220,
            filter: true,
            resizable: true,
            valueGetter: function (params) {
                try {
                    if (params.data.expected_payment_clearance_by != "" && params.data.expected_payment_clearance_by != undefined) {
                        return getHyphenDDMMYYYY(params.data.expected_payment_clearance_by);
                    }
                    else {
                        return "Not Available";
                    }
                }
                catch (e) { }
            },
        },
      
        {
            headerName: "HAZ Classification",
            field: "haz_classification",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Hardware Mode",
            field: "hardware_mode",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Weight Status",
            field: "weight_status",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Buyer",
            field: "buyer",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Consignee",
            field: "consignee",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "CHA",
            field: "cha",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "CHA Name",
            field: "cha_name",
            width: 120,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Plant Name",
            field: "plant_name",
            width: 120,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Transporter Name",
            field: "tpt_name",
            width: 200,
            filter: true,
            resizable: true,
        },
        // Changes to The Revised Production and Submit Button ,Request from QA that, Revised Production and Submit Button  Should not be Visible in Plant Modified 
    //     {
    //         headerName: "Revised Production Date",
    //         field: "revised_production_date",
    //         width: 170,
    //         filter: true,
    //         resizable: true,
    //         editable: true,
    //         //  cellEditor: params => params.data.planned_plant != 1 || params.data.modified_plant!=1 ? DateEditor : undefined,
    //         cellEditor:DateEditor,
    //         valueGetter: function (params) {
    //             try {
    //              if (params.data.revised_production_date != "" && params.data.revised_production_date != undefined) {
    //                      return getHyphenDDMMYYYY(params.data.revised_production_date);
    //                 } else {
    //                     return "Not Available";
    //              }
    //              } catch (e) {
    //                  // Handle the exception if needed
    //              }
    //          },
    //          comparator: dateComparator1,
    //      },
    //    {
    //         headerName: "",
    //         field: "",
    //         width: 100,
    //         filter: true, resizable: true,
    //         // hide:hideformodified,
    //         // cellRenderer: 'SubmitBtn'
    //         cellRendererFramework: SubmitRevisedBtn
    //     },


    ]
    const filterDatafordates = () => {
        var from_date = woReleaseDate.wo_release_from_date
        var to_date = woReleaseDate.wo_release_to_date
        if(from_date!='' && from_date!=undefined && from_date!=null && to_date!='' && to_date!=undefined&&to_date!=null){
            from_date = moment(from_date, 'DD-MM-YYYY')
        //console.log(from_date, 'from_date')
        to_date = moment(to_date, 'DD-MM-YYYY')
        //console.log(to_date, 'to_date')

        var filteredData = originalData.filter(item => {
            var itemDate = getHyphenDDMMYYYY(item.wo_release_date)
            itemDate = moment(itemDate, 'DD-MM-YYYY')
            //console.log(itemDate, 'itemDate')
            return itemDate.isBetween(from_date, to_date, null, '[]')
        });
        //console.log(filteredData, 'filteredData')
        setrowData(filteredData)
        setRefreshSelected(0);
        setcheckresetfilter(1);
        }
        else{
            setbasicTitle('Please Select Start Date and End date')
            setbasicType('danger')
            setoverly('show-n')
            setshow(true)
        }
    }
    var [woReleaseDate, setWoReleaseDate] = useState({
        wo_release_from_date: '',
        wo_release_to_date: ''
    })
    const handleDatesSelected = (startDate, endDate) => {
        // Access the selected dates in the parent component
        var wo_release_from_date = startDate.format('DD-MM-YYYY');
        var wo_release_to_date = endDate.format('DD-MM-YYYY')
        setWoReleaseDate({
            wo_release_from_date: wo_release_from_date,
            wo_release_to_date: wo_release_to_date
        })
    };
    // const handlebulkupload = ()=>{
    //     props.onclickbulkupload();
    // }

    const columnwithDefs = summaryviewcheck == 1 ? summaryViewcols : detailedviewcols
    columnwithDefs.forEach((e) => {
        e.cellClass = function (params) {
          const fieldModified = `${e.field}_modified`;
          if (params.data && params.data[fieldModified] === 1) {
            return "bgColorDangerDark";
          } else {
            return null;
          }
        };
      });
    const refreshData = ()=>{
        setcheckresetfilter(0)
        setrowData(props.data)
        setselectedval(prev=>({
            ...prev,
            inputval:''
        }))

    }
    const refreshData1=()=>{
        setcheckresetfilter(0);
        setrowData(originalData);
        setRefreshSelected(1);
        //console.log("refreshclickfunc")
 }  
 const onClickupdateAcknowledgeBtn = (e) => {
    setselected_row(e.data);
    setshowAddForm1("slide30");
    setoverly("show-m");
    setmanualUploadDetails((prev) => ({
      ...prev,
      production_date: getHyphenDDMMYYYY(e.data.production_date),
      wrno: { label: e.data.work_order_no, value: e.data.work_order_no }
    }));
  };
  const onClickHideManualUpload1 = () => {
    setshowAddForm1("");
    setoverly("show-n");
    setmanualUploadDetails((prev) => ({
      ...prev,
      wrno: { label: "", value: "" },
      production_date:""
    }));
  };
  const onsubmitdata1 = (e) => {
    e.preventDefault();
    if (selected_row.cha_tentative_details_updated == 1 || selected_row.container_count_decrease_before_pickup || selected_row.container_count_increase_before_pickup) {
      var data = [
        {
          work_order_no: selected_row.work_order_no,
          
         production_date:
            manualUploadDetails.production_date,
        
          modified_plant: 1,
        },
      ];
    } 
    else if (selected_row.ship_to_country_changes_before_container_pickup === 1){
        var data = [
            {
              work_order_no: selected_row.work_order_no,
              
             production_date:
                manualUploadDetails.production_date,
            
                planned_plant: 1, // Changes to Exception - 6 
            },
          ];
    }
    else {
      var data = [
        {
            work_order_no: selected_row.work_order_no,
          
         production_date:
            manualUploadDetails.production_date,
         
          planned_plant: 1,
        },
      ];
    }
    var rparams = {
      data: data,
      row: selected_row,
    };
    redirectURL.post("/consignments/updatedata", rparams).then((resp) => {
      //console.log(resp.data, "176");
      if (resp.data.status == "Success") {
        setshow(true);
        setbasicTitle(
          "Production date has been successfully updated!"
        );
        setbasicType("success");
        setloadshow("show-n");
        setoverly("show-n");
        // refreshDataNow();
        setmanualUploadDetails({});
        setmanualUploadDetails({
          wrno: "",
          production_date: ""
        });
        $("#production_date").val("");
       
        setshowAddForm1("");
        props.onRequest()
      } else {
        setshow(true);
        setbasicTitle(resp.data.message);
        setbasicType("danger");
        setloadshow("show-n");
        // setoverly('show-n')
        // setshowAddForm('')
      }
    });
    setReloadData(true);
  };
  const changeHandleDate = (e) => {
    var d = moment(e._d);
    var formattedDate = d.format("DD-MM-YYYY");
    return formattedDate;
  };

    return (
        <>
            <SweetAlert
                show={show}
                type={basicType}
                title={basicTitle}
                onConfirm={closeAlert}
            >
            </SweetAlert>
            <div className="row" style={{marginLeft:"14px"}}>
                {props.check == 3 ?
                    <div className="d-flex flex-row">
                        <div className="d-flex flex-row">
                            <button
                                className="d-flex flex-row justify-content-center align-items-center px-3"
                                style={{
                                    background: summaryviewcheck == 1 ? '#0664AE' : "",
                                    // color: state.isTileView ? "": "#0664AE",
                                    borderTopLeftRadius: "11px",
                                    borderBottomLeftRadius: "11px",
                                    border: "1px solid #0664AE",
                                    height: "38px",
                                    padding: '22px'
                                }}
                                onClick={() => showdata('summary view')}
                            >
                                <div
                                    style={{
                                        color: summaryviewcheck == 1 ? "white" : '#0664AE',
                                        fontSize: "12px",
                                        fontWeight: 700,
                                        marginLeft: "10px",
                                        lineHeight: "12px",
                                    }}
                                >
                                    Summary View
                                </div>
                            </button>

                            <button
                                className="d-flex flex-row justify-content-center align-items-center px-3"
                                style={{
                                    background: detailedviewcheck == 1 ? '#0664AE' : "",
                                    borderTopRightRadius: "11px",
                                    borderBottomRightRadius: "11px",
                                    border: "1px solid #0664AE",
                                    height: "38px",
                                    padding: '22px'
                                }}
                                onClick={() => showdata('detailed view')}
                            >
                                <div
                                    style={{
                                        color: detailedviewcheck == 1 ? "white" : '#0664AE',
                                        fontSize: "12px",
                                        fontWeight: 700,
                                        marginLeft: "10px",
                                        lineHeight: "12px",
                                    }}
                                >
                                    Detailed View
                                </div>
                            </button>
                        </div>

                    </div> : ''}
                {props.check == 3 ?

                    <div className="form-group col-xl-3 col-lg-3 mt-14p ml-18p">
                        <Select
                            closeMenuOnSelect={true}
                            name="filterModeType"
                            className="border-radius-0"
                            styles={{
                                control: (provided, state) => ({
                                    ...provided,
                                    fontSize: "0.8rem",
                                    color: "#6e707e",
                                    borderRadius:"11px",
                                    height:"48px",
                                    width:"260px",
                                    // padding:"22px",
                                    textAlign:"center"
                                }),
                            }}
                            onChange={handleFilterOptions}
                            options={typeofselection}
                            value={selectedval.dropdownval}
                            required
                        />
                    </div> : ''}
                {checkinput == 1 ?
                    <div
                        className="d-flex mt-14p ml-8p mr-10p"
                        style={{ position: "relative" }}
                    >
                        <input
                            style={{
                                border: "1px solid #cccccc",
                                borderRadius: "11px",
                                height: "48px",
                                outline: "none",
                                paddingLeft: "6px",
                            }}
                            onChange={handleInputfilter}
                            value={selectedval.inputval}
                        />
                         {checkresetfilter!=1?
                        <img
                            src={SearchIcon}
                            className="search_icon"
                            onClick={filterData}
                        />:<img
                        src={RefreshIcon}
                        className="search_icon"
                        onClick={refreshData}
                    />}
                    </div>
                    : ''}
                {checkForDateFilter == 1 ?
                    <div className="mt-14p">  <Datepicker id='production_date' onDatesSelected={handleDatesSelected} onRefreshSelected={refreshSelected} />  
                     {checkresetfilter != 1 ?
                        <img
                            src={SearchIcon}
                            className="search_icon_for_dates pointer"
                            onClick={filterDatafordates}
                        /> 
                        : <img
                            src={RefreshIcon}
                            className="search_icon_for_dates pointer"
                            onClick={refreshData1}
                        />}</div>

                    : ""}
                    
            </div>
            <div className="" style={{ margin: '10px', marginTop: '12px' }}>
                    <div id="idgrid" style={{ width: "100%", height: "478px" }} className="ag-theme-balham">
                        <AgGridReact
                            columnDefs={columnwithDefs}
                            defaultColDef={{
                                sortable: true,
                                filter: true,
                                editable: false,
                                resizable: true,
                                menuTabs: ['filterMenuTab']
                            }}
                            // paddingTop={10}
                            // rowHeight={70}
                            // headerHeight = {70}
                            rowData={rowData}
                            suppressRowClickSelection={true}
                            frameworkComponents={{CountryFlagComponent:CountryFlagComponent,DestinationFlagComponent:DestinationFlagComponent,AcknowledgeBtnComponent:AcknowledgeBtnComponent, UpdateAcknowledgeBtnComponent: UpdateAcknowledgeBtnComponent,SubmitRevisedBtn:SubmitRevisedBtn}}
                            enableCellChangeFlash={true}
                            suppressCellFlash={true}
                            enableRangeSelection={true}
                            paginationPageSize={50}
                            rowSelection={'multiple'}
                            masterDetail={true}
                            pagination={true}
                            sideBar={{
                                toolPanels: [
                                    {
                                        id: "columns",
                                        labelDefault: "Columns",
                                        labelKey: "columns",
                                        iconKey: "columns",
                                        toolPanel: "agColumnsToolPanel"
                                    },
                                    {
                                        id: "filters",
                                        labelDefault: "Filters",
                                        labelKey: "filters",
                                        iconKey: "filter",
                                        toolPanel: "agFiltersToolPanel"
                                    }
                                ]
                            }}
                            statusBar={{
                                statusPanels: [
                                    // {
                                    //     statusPanel: "agTotalAndFilteredRowCountComponent",
                                    //     align: "left"
                                    // },
                                    // {
                                    //     statusPanel: "agTotalRowCountComponent",
                                    //     align: "center"
                                    // },
                                    { statusPanel: "agFilteredRowCountComponent" },
                                    { statusPanel: "agSelectedRowCountComponent" },
                                    { statusPanel: "agAggregationComponent" }
                                ]
                            }}
                            gridOptions={{
                                icons: {
                                    // You can customize other icons as well
                                    next: `<img src="${customNextIcon}" alt="Next" />`,
                                    previous: `<img src="${customPreviousIcon}" alt="Previous" />`,
                                    first: `<img src="${customFirstIcon}" alt="First" />`,
                                    last: `<img src="${customLastIcon}" alt="Last" />`,
                                  },
                            }}
                            ref={gridRef}
                            context={{onClickRevisedProductionData,onClickAcknowledgeBtn,onClickupdateAcknowledgeBtn}}
                        />

                    </div>
                </div>
                <div
        className={"sliderBlock2 " + showAddForm1}
        style={{ overflow: "auto" }}
      >
        <div className="slide-r-title slider_title_style">
          <h6>Update Production Date</h6>
          <span
            className="float-right closebtn"
            style={{ marginTop: "-30px", marginRight: "25px" }}
            onClick={onClickHideManualUpload1}
          >
            X
          </span>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <form onSubmit={onsubmitdata1}>
              <div className="slide-body">
                <div className="row p-20p">
                  <div className="form-group col-xl-12 col-lg-12">
                    <p className="mb-5p">
                      Work Order No: {selected_row.work_order_no}
                    </p>
                  </div>
                 
                  <div className="form-group col-xl-12 col-lg-12">
                    <label className="mb-5p">
                      Production Date <span className="err-txt"></span>
                    </label>
                    <Datetime
                      key={manualUploadDetails.production_date}
                      placeholder="Date"
                      disableCloseOnClickOutside={false}
                      closeOnSelect={true}
                      name="tentative_gate_open_date"
                      value={manualUploadDetails.production_date}
                      dateFormat="DD-MM-YYYY"
                      id="tentative_gate_open_date"
                      timeFormat={false}
                      onChange={(e) =>
                        setmanualUploadDetails((prev) => ({
                          ...prev,
                          production_date: changeHandleDate(e),
                        }))
                      }
                      required
                    />
                  </div>
                
                  {/* <h6 style={{ color: "red", fontSize: "14px", marginTop: "2%" }}>Note: All date fields should be greater than production date</h6> */}
                  <div className="form-group col-sm-12 mb-20p text-center">
                    <button type="submit" className="btn btn-success">
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

        </>
    )

}
function isEmpty(obj) {
    for (var key in obj) {
        if (obj.hasOwnProperty(key))
            return false;
    }
    return true;
}
function timeConvert(n) {
    var diffMs = n;
    var diffDays = Math.floor(diffMs / 86400000); // days
    var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
    var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
    return diffHrs + " hour(s) and " + (diffMins / 60) + " minute(s).";
}
var currentinfowindow = null;
function getTimeInfo(marker, timedet, coords) {
    var timeinfo = new window.google.maps.InfoWindow({
        content: "Time at this location: " + timedet
    });
    marker.addListener('click', function () {

        if (currentinfowindow != null) {
            currentinfowindow.close();
            currentinfowindow = timeinfo;
            timeinfo.open(marker.get('map'), marker);
        }
        else {
            currentinfowindow = timeinfo;
            timeinfo.open(marker.get('map'), marker);
        }
    });
}
function secondsToString(seconds) {
    var numdays = Math.floor(seconds / 86400);
    var numhours = Math.floor((seconds % 86400) / 3600);
    var numminutes = Math.floor(((seconds % 86400) % 3600) / 60);
    var numseconds = ((seconds % 86400) % 3600) % 60;
    return numdays + " days " + numhours + " hours " + numminutes + " minutes ";
}
function secondsToDays(seconds) {
    var numdays = Math.floor(seconds / 86400);
    var numhours = Math.floor((seconds % 86400) / 3600);
    var numminutes = Math.floor(((seconds % 86400) % 3600) / 60);
    var numseconds = ((seconds % 86400) % 3600) % 60;
    return numdays;
}
function CheckForHtmlTag() {
    var rs = document.getElementById("invalid_reason");
    var reg = /<(.|\n)*?>/g;
    if (reg.test(rs.value)) {
        var ErrorText = "Oops! HTML or Script is not allowed.";
        rs.value = ''
        //alert();
        return 1;
    }
}
function dateComparator(date1, date2) {
    if (date1 != "" && date2 != "" && date1 != undefined && date2 != undefined) {
        var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
        date1 = date1.replace(date1.split(" ")[0].split("-")[1], date_1);
        var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
        date2 = date2.replace(date2.split(" ")[0].split("-")[1], date_2);
        var date1Number = monthToComparableNumber(date1);
        var date2Number = monthToComparableNumber(date2);
        if (date1Number === null && date2Number === null) {
            return 0;
        }
        if (date1Number === null) {
            return -1;
        }
        if (date2Number === null) {
            return 1;
        }
        return date1Number - date2Number;
    }
}
function monthToComparableNumber(date) {
    if (date === undefined || date === null || date.length !== 16) {
        return null;
    }
    var yearNumber = date.substring(6, 10);
    var monthNumber = date.substring(3, 5);
    var dayNumber = date.substring(0, 2);
    var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
    return result;
}
function getMonthNumber(monthName) {
    var months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May',
        'Jun', 'Jul', 'Aug', 'Sep',
        'Oct', 'Nov', 'Dec'
    ];
    var monthNum = months.indexOf(monthName) + 1;
    if (monthNum < 10) {
        monthNum = "0" + monthNum.toString();
    }
    return monthNum;
}
function loadDateTimeScript() {
    $('.datetimepicker_mask').datetimepicker({
        mask: '39-19-9999',
        format: 'd-m-Y',
        onShow: false
    });
    $('.datetimepicker_date').datetimepicker({
        format: 'd-m-Y',
        timepicker: false,
        onShow: false
    });
    var index = window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src = "https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
    script.async = true
    script.defer = true
    index.parentNode.insertBefore(script, index)
}
function GetSortDescOrder(prop) {
    return function (a, b) {
        if (a[prop] < b[prop]) {
            return 1;
        } else if (a[prop] > b[prop]) {
            return -1;
        }
        return 0;
    }
}
function GetSortAscOrder(prop) {
    return function (a, b) {
        if (a[prop] > b[prop]) {
            return 1;
        } else if (a[prop] < b[prop]) {
            return -1;
        }
        return 0;
    }
}
function GetSortASCOrder(prop) {
    return function (a, b) {
        if (a[prop] > b[prop]) {
            return 1;
        } else if (a[prop] < b[prop]) {
            return -1;
        }
        return 0;
    }
}
function secondsToDhms(seconds) {
    seconds = Number(seconds);
    var d = Math.floor(seconds / (3600 * 24));
    var h = Math.floor(seconds % (3600 * 24) / 3600);
    var m = Math.floor(seconds % 3600 / 60);
    var s = Math.floor(seconds % 60);
    var dDisplay = d >= 0 ? d + (d == 1 ? " Day " : " Days ") : "";
    var hDisplay = h >= 0 ? h + (h == 1 ? " Hr " : " Hrs ") : "";
    var mDisplay = m >= 0 ? m + (m == 1 ? " Mins " : " Mins ") : "";
    var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
    return dDisplay + hDisplay + mDisplay;
}
function distinctArrayBy(arr, propName) {
    var result = arr.reduce(function (arr1, e1) {
        var matches = arr1.filter(function (e2) {
            return e1[propName] == e2[propName];
        })
        if (matches.length == 0)
            arr1.push(e1)
        return arr1;
    }, []);

    return result;
}
function dateComparator1(date1, date2) {
    if (date1 != "" && date2 != "" && date1 != undefined && date2 != undefined) {
        var date_1 = getMonthNumber(date1.split("-")[1]);
        date1 = date1.replace(date1.split("-")[1], date_1);
        var date_2 = getMonthNumber(date2.split("-")[1]);
        date2 = date2.replace(date2.split("-")[1], date_2);
        var date1Number = monthToComparableNumber1(date1);
        var date2Number = monthToComparableNumber1(date2);
        if (date1Number === null && date2Number === null) {
            return 0;
        }
        if (date1Number === null) {
            return -1;
        }
        if (date2Number === null) {
            return 1;
        }
        //console.log(date1Number, date2Number, "comparator")
        return date1Number - date2Number;
    }
}
function monthToComparableNumber1(date) {
    if (date === undefined || date === null || date.length !== 10) {
        return null;
    }
    var yearNumber = date.substring(6, 10);
    var monthNumber = date.substring(3, 5);
    var dayNumber = date.substring(0, 2);
    var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
    return result;
}
function loadDateTimeScript2() {
    $('.datetimepicker_mask').datetimepicker({
        format: 'd-m-Y'
    });
    $('.device_filter_data').datetimepicker({
        format: 'Y-m-d',
        timepicker: true
    });
    var index = window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src = "https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
    script.async = true
    script.defer = true
    index.parentNode.insertBefore(script, index)
}
function getDatePicker() {
    function Datepicker() { }
    Datepicker.prototype.init = function (params) {
        this.eInput = document.createElement("input");
        this.eInput.value = params.value;
        //console.log(this.eInput)
        window.$(".datepicker").datepicker({ dateFormat: "dd-mm-yy" });
    };
    Datepicker.prototype.getGui = function () {
        return this.eInput;
    };
    Datepicker.prototype.afterGuiAttached = function () {
        this.eInput.focus();
        this.eInput.select();
    };
    Datepicker.prototype.getValue = function () {
        return this.eInput.value;
    };
    Datepicker.prototype.destroy = function () { };
    Datepicker.prototype.isPopup = function () {
        return false;
    };
    return Datepicker;
}
function DateEditor() { }
DateEditor.prototype.init = function (params) {
    this.eInput = document.createElement('input');
    this.eInput.value = params.value;
    $(this.eInput).datepicker({
        dateFormat: "yy-mm-dd",
        changeMonth: true,
        changeYear: true,
        showTime: false,
    });
};
DateEditor.prototype.getGui = function () {
    return this.eInput;
};
DateEditor.prototype.afterGuiAttached = function () {
    this.eInput.focus();
    this.eInput.select();
};
DateEditor.prototype.getValue = function () {
    return this.eInput.value;
};
DateEditor.prototype.destroy = function () {
};
DateEditor.prototype.isPopup = function () {
    return false;
};
export default Modifiedcomponent