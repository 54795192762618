import React from 'react';
var moment = require('moment');

const AcknowledgeBtnComponent = (props) => {
    const {
        update_ack,
        update_ib_ack,
        update_prod_ack,
        container_count_increase_check,
        cha_do_modified,
        tpt_trucks_modified,
        tpt_container_modified,
        ib_plant_gate_in_modified,
        at_plant_modified,
        shipping_modified,
        modified_plant,
        pod_changes_before_container_pickup,
        ship_to_country_changes_before_container_pickup,
        production_date,
        stuffing_date,
        pod_modified,
        container_count,
        remaining_trucks,
        remaining_containers,
        trucks_count,
        container_count_decrease_check,
        container_count_decrease_before_pickup,
        container_count_increase_before_pickup,
        production_date_modified, //  Changes to Exception - 8 
        ib_stuffing_modified ,
        ib_tpt_modified
    } = props.data;

    const handleRouteClick = (e) => {
        e.stopPropagation();
        props.context.onClickAcknowledgeBtn(props);
    };

    const checkIsValidBtn = () => {
        if (production_date && stuffing_date) {
            const prdtdate = moment(production_date).utcOffset("+05:30", true);
            const stfdate = moment(stuffing_date).utcOffset("+05:30", true);
            return stfdate.isSameOrAfter(prdtdate); // Directly compare both cases
        }
        return !stuffing_date; // Valid if stuffing_date is missing
    };

    const isContainerCountModified = (countCheck, ...modifiers) =>
        countCheck === 1 && modifiers.some(modifier => modifier === 1);

    const buttonHiding = () => {
        if (pod_modified === 1 && cha_do_modified === 1) return true;
        if(production_date_modified===1 &&( ib_stuffing_modified ===1 || cha_do_modified==1 || ib_tpt_modified) &&checkIsValidBtn()){ //  Changes to Exception - 8  && Production date is greater than stuffing date
            return false
        }
        const isUpdateAck = update_ack === 1 || update_ib_ack === 1;
        const isProdAckUpdated = update_prod_ack === 1;
        const isPlantModified = modified_plant === 0 || modified_plant === "undefined";
        const isShipToORPodChanges =  cha_do_modified==1 && (ship_to_country_changes_before_container_pickup==1 ||  pod_changes_before_container_pickup==1)
        const isContainerCountIncreased = isContainerCountModified(
            container_count_increase_check,
            cha_do_modified,
            tpt_trucks_modified,
            tpt_container_modified,
            ib_plant_gate_in_modified,
            at_plant_modified,
            shipping_modified
        );
        const isContainerCountDecrease = isContainerCountModified(
            container_count_decrease_check,
            at_plant_modified
        );

        let isTptModified = false;
        if (tpt_trucks_modified === 1) {
            const noTrucksOrContainers = remaining_trucks === 0 && remaining_containers === 0;
            const trucksMatch = remaining_trucks > 0 && trucks_count === remaining_trucks;
            const containersMatch = remaining_containers > 0 && container_count === remaining_containers;

            if ((!noTrucksOrContainers && !(trucksMatch || containersMatch)) || container_count_decrease_before_pickup == 1 ) {
                isTptModified = true;
            }
            if(container_count_increase_before_pickup==1){
                isTptModified = true;
            }
        }

        return isUpdateAck || isPlantModified || isProdAckUpdated ||
            isContainerCountIncreased || !checkIsValidBtn() ||
            isTptModified || isContainerCountDecrease || isShipToORPodChanges;
    };

    const shouldHideButton = buttonHiding();

    return (
        <div>
            {!shouldHideButton && (
                <button
                    style={{ color: "#fff" }}
                    onClick={handleRouteClick}
                    className="btn btn-primary custom-btn f10 label label-success mb-5p"
                    title=""
                >
                    Acknowledge
                </button>
            )}
        </div>
    );
};

export default AcknowledgeBtnComponent;
